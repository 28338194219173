/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

PO Transactions

PO Transactions Viewer

Cash Drawer Association API
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { VarianceReport, Error } from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary This endpoint is used to update Variance report of branch
 */
export const useGetBranchmanagementVarianceHook = () => {
  const getBranchmanagementVariance = useCustomInstance<VarianceReport[]>();

  return () => {
    return getBranchmanagementVariance({ url: `/branchmanagement/variance`, method: "get" });
  };
};

export const getGetBranchmanagementVarianceQueryKey = () => [`/branchmanagement/variance`];

export type GetBranchmanagementVarianceQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetBranchmanagementVarianceHook>>
>;
export type GetBranchmanagementVarianceQueryError = ErrorType<Error | void>;

export const useGetBranchmanagementVariance = <
  TData = AsyncReturnType<ReturnType<typeof useGetBranchmanagementVarianceHook>>,
  TError = ErrorType<Error | void>,
>(options?: {
  query?: UseQueryOptions<
    AsyncReturnType<ReturnType<typeof useGetBranchmanagementVarianceHook>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetBranchmanagementVarianceQueryKey();

  const getBranchmanagementVariance = useGetBranchmanagementVarianceHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetBranchmanagementVarianceHook>>
  > = () => getBranchmanagementVariance();

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetBranchmanagementVarianceHook>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};
