//ADD,REMOVE,REPRINT, and all other flows will be handled here..

import { amountSignedPound, currencyStyle } from "@bbo/utils/currencyFormatter";

/**
 * Alert/Popup view with
 * @param props
 * @returns
 */

interface iProps {
  associatedEntityID?: string;
  associatedEntityName?: string;
  associatedEntityType?: string;
  entityName?: string;
  itemDetails?: any;
  onSuccess?: () => void;
  onCancel?: () => void;
}

const PopUp = (props: iProps) => {
  const onSuccess = () => {
    if (props.onSuccess) {
      props.onSuccess();
    }
  };

  const onCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-xl popupmodel">
          <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4	">
            <div className="flex p-4  items-center justify-start">
              <div>
                <h2 className="text-blueGray-500 text-lg leading-relaxed heading-5 font-bold text-color">
                  {props?.associatedEntityName
                    ? `${props?.entityName}/${props?.associatedEntityName}`
                    : `Safe/${props?.entityName}`}
                </h2>
              </div>
            </div>
            <div className="flex pl-4 items-center  flex justify-between">
              <div>Balance</div>
              <div className={currencyStyle(props?.itemDetails.balance)}>
                <b>{amountSignedPound(props?.itemDetails.balance)}</b>
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-between p-4  rounded-b">
              {props?.associatedEntityName && (
                <button
                  disabled
                  className="col-span-2 btn-disable branchOverviewButton font-bold heading mr-2"
                  type="button"
                  value="ForceLogOff"
                  onClick={onSuccess}
                  data-cy="ForceLogOff">
                  Forced Logoff
                </button>
              )}
              <button
                className="col-span-2  branchOverviewButton font-bold heading"
                type="button"
                value="RePrint"
                onClick={onCancel}
                data-cy="RePrint">
                Re-print Label
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default PopUp;
