import { SafeDetails } from "@bbo/api/generator";
import { useState } from "react";
import { StockCardData } from "../StockBranchOverview/StockCardData/StockCardData";
import { ActionList } from "./ActionList";
import { useTableList } from "./useTableList";

type Props = {
  header: string;
  data: SafeDetails;
  isStockData?: boolean;
};

const SafeItem = ({ data, isStockData, ...rest }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
  };

  if (data) {
    const actionData = { ...data, currentVariance: data.lastVariance };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { orderedTable } = useTableList(data ?? {});
    return (
      <>
        <div
          className="accounting-card widthAccounting inline-block mr-8"
          onClick={() => setShowModal(true)}>
          <h2 className="account-group mb-1">Safe</h2>
          <div className="main Safe w-full bg-white cursor-pointer block mr-8 inline-block cursor-pointer">
            {isStockData ? (
              <StockCardData
                data={{
                  currentVariance: data.lastVariance,
                  lastBalancedDateTime: data.lastBalancedDateTime,
                  toDateVariance: data.toDateVariance,
                }}
              />
            ) : (
              <table className="w-full border-none">
                <tbody>{orderedTable}</tbody>
              </table>
            )}
          </div>
        </div>
        {showModal && <ActionList data={actionData} header={rest.header} onClose={handleClose} />}
      </>
    );
  }

  return null;
};

export default SafeItem;
