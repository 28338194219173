//ADD,REMOVE,REPRINT, and all other flows will be handled here..

import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { useGlobalState } from "@bbo/lib/state";
import { amountPound, amountUnSignedPound, currencyStyle } from "@bbo/utils/currencyFormatter";
import React, { useState } from "react";
import Select, {
  components,
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  GroupBase,
} from "react-select";
import { useBranchOverview } from "../BranchOverview/useBranchOverview";
/**
 * Alert/Popup view with
 * @param props
 * @returns
 */

type SelectRowProps = {
  discrepencyType?: string;
  reasonType?: string;
  varianceBalance: number;
};

interface iProps {
  selectedRow?: Array<SelectRowProps>;
  totalAmount?: number;
  onSuccess?: () => void;
  onCancel?: () => void;
}

const SettleMentPopUp = (props: iProps) => {
  const selectData = useAppSelector((root) => root.cashDetails);
  const [showSelect, setShowSelect] = useState<string>(null);
  const [selectValue, setselectValue] = useGlobalState("selectValue");
  const SETTLE_CASH = "Settle to Cash";

  const { cashData } = useBranchOverview();
  const onSuccess = () => {
    if (props.onSuccess) {
      props.onSuccess();
    }
  };
  const optionData = [];
  if (cashData) {
    optionData.push({
      value: selectData.safe.entityName,
      id: selectData.safe.entityID,
      type: selectData.safe.entity,
    });
  }
  // optionData.push(selectData.safe.entity_name)
  if (selectData) {
    selectData.counterTerminal?.map((value) => {
      optionData.push({
        value: value.associatedEntityName,
        id: value.associatedEntityID,
        type: value.associatedEntityType,
      });
    });

    const mOptions = optionData.map((val) => {
      return { value: val.id, label: val.value, type: val.type };
    });
    const onCancel = () => {
      if (props.onCancel) {
        props.onCancel();
      }
    };

    const handleChange = (param: { label: string; value: string; type: string }) => {
      setselectValue({ data: param, ShowSelect: selectValue.ShowSelect });
    };

    const selectShow = (event: { target: { value: React.SetStateAction<string> } }) => {
      setShowSelect(event.target.value);
      setselectValue({ data: null, ShowSelect: event.target.value });
    };

    const DropdownIndicator = (
      props: JSX.IntrinsicAttributes & DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>,
    ) => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <div className="arrowSelect"></div>
          </components.DropdownIndicator>
        )
      );
    };

    const customStyles = {
      control: (base: CSSObjectWithLabel) => ({
        ...base,
        border: "1px solid #444",
        width: "60%",
        padding: "8px 0px",
        "&:hover": {
          border: "1px solid #444",
        },
      }),
      option: (styles: CSSObjectWithLabel, state: { isFocused: boolean }) => ({
        ...styles,
        backgroundColor: state.isFocused ? "#9999" : null,
        color: "#444",
      }),
      menu: (provided: CSSObjectWithLabel) => ({
        ...provided,
        width: "60%",
      }),
      placeholder: (styles: CSSObjectWithLabel) => ({
        ...styles,
        fontStyle: "normal",
        fontWeight: "400",
        fontsize: "24px",
        lineHeight: "34px",
        color: "#444",
      }),
    }; // filter options styling

    return (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative my-6 mx-auto max-w-xl popupModelHighLevel">
            <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-8	">
              <div className="flex items-center justify-start">
                <div>
                  <h2 className="leading-relaxed text-color pr-2 amountHighLevel">
                    How would you like to settle?
                  </h2>
                </div>
              </div>
              {props.selectedRow && props.selectedRow.length > 1 && (
                <div className="listStyle  mt-6 mb-6">
                  {props.selectedRow.map((item, index) => (
                    <div key={index}>
                      {item.discrepencyType} - {item.reasonType}{" "}
                      {amountUnSignedPound(item.varianceBalance)}
                    </div>
                  ))}
                </div>
              )}
              <div className="pb-4 amountHighLevel">
                {props.selectedRow && props.selectedRow.length > 1 && <div>Total to settle</div>}
                {/* <div className="flex items-center justify-start mb-2 amountHighLevel"> */}
                <span>
                  <b>Amount = </b>
                </span>
                <span className={currencyStyle(props?.totalAmount)}>
                  <b>&nbsp;{amountPound(props?.totalAmount)}</b>
                </span>
              </div>
              <div className="flex items-center mb-4" onChange={selectShow.bind(this)}>
                <div className="flex items-center">
                  <input
                    type="radio"
                    value="Settle to Cash"
                    name="SettlePopUp"
                    className="inputRadioChecbox"
                  />
                  <label className="ml-2">
                    <b>Settle to Cash</b>
                  </label>
                </div>
                <div className="flex items-center ml-10">
                  <input
                    type="radio"
                    name="SettlePopUp"
                    data-testid="selectDiv"
                    value="Settle to Account"
                    className="inputRadioChecbox"
                  />
                  <label className="ml-2">
                    <b>Settle to Account</b>
                  </label>
                </div>
              </div>
              {showSelect === "Settle to Cash" && (
                <>
                  <label className="my-2">
                    <b>Please select accounting location for settlement.</b>
                  </label>
                  <Select
                    options={mOptions}
                    styles={customStyles}
                    placeholder="Please select an option"
                    onChange={handleChange}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator,
                    }}
                    closeMenuOnSelect={true}
                  />
                </>
              )}
              {/*footer*/}
              <div className="flex items-center pt-8 justify-end">
                <button
                  className="restNoBtnMcdHighLevel border-t border-r border-l border-b border-grey border-solid bg-transparent-500 text-black active:bg-transparent-600 font-bold text-sm px-10 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-6 ease-linear transition-all duration-150 hover"
                  type="button"
                  value="Cancel"
                  onClick={onCancel}
                  data-cy="Cancel">
                  Cancel
                </button>
                <button
                  disabled={!showSelect || (!selectValue.data && showSelect === SETTLE_CASH)}
                  className="brandRedBtnMcdHighLevel text-white active:brandRedBtnMcdHighLevel font-bold text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                  type="button"
                  value="Confirm"
                  onClick={onSuccess}
                  data-cy="Confirm">
                  Accept
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
      </>
    );
  }
};
export default SettleMentPopUp;
