import PopupConfirm from "@bbo/components/Alert";
import { useGlobalState } from "@bbo/lib/state";
import { useNavigate } from "react-router-dom";
interface iProps {
  onSuccess?: () => void;
}

export const SuspendPopup = (props: iProps) => {
  const navigate = useNavigate();
  const [userName] = useGlobalState("userName");
  const [adhocbalance] = useGlobalState("adhocbalance");
  const onSuccess = () => {
    if (props.onSuccess) {
      props.onSuccess();
    }
  };

  return (
    <PopupConfirm
      title="Suspended Pouches Available"
      header={`There is suspended pouch preparation by ${
        userName?.attributes?.name === adhocbalance.suspendedBy
          ? adhocbalance?.suspendedBy
          : `(${adhocbalance?.suspendedBy})`
      }`}
      labelCancel={
        userName?.attributes?.name === adhocbalance.suspendedBy ? "Go To Suspended Pouch" : "Cancel"
      }
      labelSuccess="Continue to Balance "
      renderlinkLabel={
        userName?.attributes?.name === adhocbalance.suspendedBy ? "Cancel" : undefined
      }
      tickicon={false}
      onCancel={() => {
        if (userName?.attributes?.name === adhocbalance.suspendedBy) {
          navigate("/pouchPreperation");
        } else navigate(-1);
      }}
      onRenderLink={() => {
        navigate(-1);
      }}
      onSuccess={() => onSuccess()}
    />
  );
};
