import React, { useEffect } from "react";
import "./Index.css";
interface AmountInputProps {
  handleChange: (value: string) => void;
  disabled: boolean;
  selectAmount?: string;
}
const AmountInput = ({ handleChange, disabled, selectAmount }: AmountInputProps) => {
  const [value, setValue] = React.useState<string>("");

  const handleInputChange = (event) => {
    let Mvalue = event.target.value;

    Mvalue = Mvalue.replace(".", "").replace("£", "");
    const rx_live = /^[0-9\b]+(\.\d{0,2})?$/;
    if (rx_live.test(Mvalue)) {
      Mvalue = `£${(Number(Mvalue.replace("£", "")) / 100).toFixed(2)}`;
      setValue(Mvalue);
    }
    handleChange(Mvalue);
  };

  useEffect(() => {
    setValue(selectAmount);
  }, [selectAmount]);

  return (
    <>
      <input
        disabled={!disabled}
        className="amountInput w-full mb-4"
        onChange={(e) => handleInputChange(e)}
        placeholder="£0.00"
        value={value}
        // onBlur={handleBlur}
      />
    </>
  );
};

export default AmountInput;
