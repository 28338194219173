import LabelUperLeft from "@bbo/components/table/labelUperLeft";
import ResultShown from "@bbo/components/table/resultShown";
import { PAGINATION_SIZE } from "@bbo/components/table/tableConstants";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { useGlobalState } from "@bbo/lib/state";
import { amountSignedPound, currencyStyle } from "@bbo/utils/currencyFormatter";
import { useEffect, useMemo } from "react";

type Props = {
  tabLabels: any[];
  varianceAmount?: number;
  selected: any;
  isSummary?: boolean;
  rowsLength?: number;
  isAwaitBranch?: boolean;
  isAwaitCentral?: boolean;
  onSelectTab: (params) => void;
};

const HeaderTabs = ({
  tabLabels,
  selected,
  isSummary,
  onSelectTab,
  isAwaitBranch,
  isAwaitCentral,
}: Props) => {
  const discrepancyRecord = useAppSelector((root) => root.DiscrepancyAccount);
  const [globalCurrentPage] = useGlobalState("curentPage");
  const [globalCurrentRows] = useGlobalState("currentRows");
  const [, setGlobalActiveFilters] = useGlobalState("activeFilters");

  useEffect(() => {
    setGlobalActiveFilters([]);
  }, []);
  const handleTabClick = (value) => {
    setGlobalActiveFilters([]);
    onSelectTab(value);
  };
  const resetFilter = () => {
    setGlobalActiveFilters([]);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (globalCurrentPage - 1) * PAGINATION_SIZE;
    const lastPageIndex = firstPageIndex + PAGINATION_SIZE;
    return globalCurrentRows.slice(firstPageIndex, lastPageIndex);
  }, [globalCurrentPage, globalCurrentRows]);

  return (
    <>
      {!isSummary && (
        <div className="h-2 w-full float-right pr-8 pt-2">
          {" "}
          <ResultShown
            currentPage={globalCurrentPage}
            totalCount={globalCurrentRows.length}
            pageSize={PAGINATION_SIZE}
            currentPageSize={currentTableData.length}
            isDiscrepancyTable={true}
          />
        </div>
      )}
      <div className={`${isSummary ? "horizontal-tab-list-summary" : "horizontal-tab-list"}`}>
        <div className="flex justify-between w-full">
          <div>
            <ul>
              {tabLabels.map((value) => (
                <li
                  key={value.value}
                  className={`${
                    isSummary
                      ? `tab-listview-discrpency${selected === value.value ? " active" : ""}`
                      : `tab-listview${selected === value.value ? " active" : ""}`
                  }`}
                  onClick={() => handleTabClick(value)}>
                  {value.value} {isSummary && <span>({value.valueLength})</span>}
                </li>
              ))}
            </ul>
          </div>
          {!isSummary && (
            <div className="headingHome">
              The Branch has a discrepancy of{" "}
              <span className={`pl-2 ${currencyStyle(discrepancyRecord.varianceBalance)}`}>
                {amountSignedPound(discrepancyRecord.varianceBalance)}
              </span>{" "}
            </div>
          )}
          {!isSummary && (
            <span className="resultShown">
              <LabelUperLeft
                showResetFilter={true}
                onResetAllFilters={resetFilter}
                isDiscrpencyTable={true}
              />
            </span>
          )}
        </div>

        {isAwaitBranch && (
          <h2 className="headingSummary">
            Awaiting Branch Action has a discrepancy of{" "}
            <span className={`pl-2 ${currencyStyle(discrepancyRecord.waitedVarianceBalance)}`}>
              {" "}
              {amountSignedPound(discrepancyRecord.waitedVarianceBalance)}
            </span>
          </h2>
        )}
        {isAwaitCentral && (
          <h2 className="headingSummary">
            Awaiting Central Support has a discrepancy of{" "}
            <span
              className={`pl-2 ${currencyStyle(discrepancyRecord.centralSupportVarianceBalance)}`}>
              {" "}
              {amountSignedPound(discrepancyRecord?.centralSupportVarianceBalance)}
            </span>
          </h2>
        )}
      </div>
    </>
  );
};

export default HeaderTabs;
