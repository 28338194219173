import { BalanceDetailSafeOverview } from "./BalanceDetailSafeOverview";
import "./index.css";
type Props = {
  selectedTab: string;
  selectedValue: any;
  fromHistory?: any;
  fromDiscrepancyAccount?: boolean;
};

export const BalanceDetailViewOverview = ({
  selectedTab,
  selectedValue,
  fromHistory,
  fromDiscrepancyAccount,
}: Props) => {
  switch (selectedTab) {
    case "Safe 1":
      return (
        <BalanceDetailSafeOverview
          selectedValue={selectedValue}
          fromHistory={fromHistory}
          fromDiscrepancyAccount={fromDiscrepancyAccount}
        />
      );

    default:
      return <BalanceDetailSafeOverview selectedValue={selectedValue} fromHistory={fromHistory} />;
  }
};
