import { usePostTransferTransaction } from "@bbo/api/generator";
import { AXIOS_INSTANCE } from "@bbo/api/generator/mutator/useCustomInstance";
import PopupConfirm from "@bbo/components/Alert";
import Loader from "@bbo/components/Loader";
import { ReceiptPrint } from "@bbo/components/PrintView";
import { PouchLadder } from "@bbo/features/PouchLadder";

import {
  COINS_DETAIL,
  COINS_DETAIL_PART_BAG,
  COINS_FULL_BAG_DETAIL,
  NOTES_DETAIL,
} from "@bbo/features/PouchLadder/PouchConstants";
import { PouchPreparationPopup } from "@bbo/features/PouchLadder/PouchPreparationPopup";
import { useAppSelector } from "@bbo/lib";
import usePouchApi from "@bbo/lib/hooks/usePouchApi";
import { useReceiptPrinter } from "@bbo/lib/hooks/useReceiptPrinter";
import { useScanner } from "@bbo/lib/hooks/useScanner";
import { CoinItem, Denomination, PouchDetail, PrintCashReceipt } from "@bbo/types";
import { CashPreparedPouch } from "@bbo/types/TransferTypes";
import { PouchPreparationTemplate } from "@bbo/utils/ReceiptPrinterTemplates/PouchPreparation";
import { amountInPound, inputInPence } from "@bbo/utils/currencyFormatter";
import {
  currentDate,
  currentDateDDMMYYYY,
  currentDateTime,
  currentOnlyTime,
  currentTime,
} from "@bbo/utils/dateTimeFormatter";
import { removeEmptyLinesFromAddress } from "@bbo/utils/receiptHelper";
import { preparedPouchParams } from "@bbo/utils/transferPayload";
import { deepLink, isDeviceMobile } from "@bbo/utils/util";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

/**
 * Pouch Preparation screen functional component
 * @returns
 */
// interface iProps {
//   header?: string;
//   content?: string;
//   cellClass?: string;
//   labelCancel?: string;
//   labelSuccess?: string;
//   onSuccess?: () => void;
//   onCancel?: () => void;
// }

const PouchPreparationFeature = () => {
  const [openSuccessfulPrintConfirmationPopup, setOpenSuccessfulPrintConfirmationPopup] =
    useState<boolean>(false);
  const [openReprintConfirmationPopup, setOpenReprintConfirmationPopup] = useState<boolean>(false);
  const navigate = useNavigate();
  const [submitNext, setsubmitNext] = useState<boolean>(false);
  const [DenominationShow, setDenominationShow] = useState<boolean>(false);
  const [barCode, setBarCode] = useState<string>("");
  const [, setnotesBag] = useState<boolean>(false);
  const [heading, setheading] = useState<string>("");
  const [, setBagCode] = useState<boolean>(false);
  const [looseBag, setlooseBag] = useState<boolean>(false);
  const [looseCoin, setlooseCoin] = useState<boolean>(false);
  const [initialCall, setInitialCall] = useState<boolean>(true);
  const [initialPostCall, setInitialPostCall] = useState<boolean>(true);
  const [CancelPopup, setCancelPopup] = useState<boolean>(false);
  const [SuspendPopup, setSuspendPopup] = useState<boolean>(false);
  const [isPouchPrepared, setPouchPrepared] = useState<boolean>(false);
  const [suspended, setSuspended] = useState<boolean>(false);
  const [cashdetail, setcashdetail] = React.useState<CoinItem[]>();
  const [notesdetail] = React.useState([...NOTES_DETAIL]);
  const [coindetail] = React.useState([...COINS_DETAIL]);
  const [operationType, setOperationType] = React.useState<string>("preparing");
  const [pouchDenomination, setPouchDenomination] = React.useState([]);
  const [pouchBagType, setPouchBagType] = React.useState<string>("");
  const [pouchDetails, setPouchDetails] = React.useState<PouchDetail>(null);
  const [suspendExistPopUp, setSuspendExistPopUp] = React.useState<boolean>(false);
  const [suspendLimitPopUp, setSuspendLimitPopUp] = React.useState<boolean>(false);
  const [isSuspendFlow, setIsSuspendFlow] = React.useState<boolean>(false);
  const [isDispatchPopup, setDispatchPopup] = React.useState<boolean>(false);
  const [isInvalidPouch, setInvalidPouch] = React.useState<boolean>(false);
  const [, setNotAutorizeToSuspend] = React.useState<boolean>(false);
  const [isSuspendedScanned, setIsSuspendedScanned] = React.useState<boolean>(false);
  const [showSuspendButton, setShowSuspendButton] = useState<boolean>(true);

  const barCodeInput = useRef(null);
  const [printData, setPrintData] = useState<PrintCashReceipt>(null);
  const [Total, setTotal] = useState<number>(0);
  const [isDelete, setIsDelete] = useState(false);
  const [oldDenomination, setOldDenomination] = useState(null);
  const [isReprint, setIsReprint] = useState(false);
  const receiptPrinterService = useReceiptPrinter();
  const branchDetails = useAppSelector((root) => root.branchDetails);
  const { username, fadCode, smartid } = useAppSelector((root) => root.auth.authUserData);
  const [isTranscommit, setTranscommit] = useState(false);
  const [isCancelSuccess, setCancelSuccess] = useState(false);
  const [isErrorPopup, setErrorPopup] = useState(false);
  const getPouchDetailsRequestPayload = {
    branchId: "1",
    pouchId: barCode,
    pouchType: "cash_pouch",
  };
  const [
    { data: getPouchResponse, status: getPouchStatus, error: getPouchError, statusCode: Code },
    getPouchDetails,
    refresh,
  ] = usePouchApi("", getPouchDetailsRequestPayload, "", "GET");

  useScanner(
    useCallback(async (text: string) => {
      setBarCode(text);
    }, []),
  );

  const savePouchDetailsRequestParma = {
    acceptedBy: pouchDetails?.acceptedBy,
    assignedBranchID: pouchDetails?.assignedBranchID,
    assignedBranchName: pouchDetails?.assignedBranchName,
    isBranchClose: pouchDetails?.isBranchClose,
    isCoinPouch: pouchDetails?.isCoinPouch,
    operationType: operationType,
    pouchBarcode: pouchDetails?.pouchBarcode,
    pouchID: pouchDetails?.pouchID,
    pouchBagType: pouchBagType,
    pouchType: pouchDetails?.pouchType,
    status: pouchDetails?.status,
    preparedBySmartID: pouchDetails?.preparedBySmartID || smartid,
    preparedByUserID: pouchDetails?.preparedByUserID || username,
    denominationLadder: pouchDenomination?.map((item, index) => {
      if (looseBag) {
        return {
          itemID: item.itemID,
          bag: item?.Quantity,
          denomination: item.Cash + item.currency,
          value: inputInPence(item.value),
          looseCoin: `${item.looseCoin}`,
          cashType: item.cashType,
          index,
        };
      } else {
        return {
          itemID: item.itemID,
          looseCoin: `${item.Quantity}`,
          denomination: item.Cash + item.currency,
          value: inputInPence(item.value),
          cashType: item.cashType,
          index,
        };
      }
    }),
  };

  const [{ data: addPouchResponse, status: addPouchStatus }, savePouchDetails, refersh1] =
    usePouchApi("", savePouchDetailsRequestParma, "", "POST", "");

  const requestParams = (status?: string): CashPreparedPouch => {
    let denomination = [];
    if (pouchDenomination.length === 0) {
      denomination = pouchDetails?.denominationLadder?.map((item, index) => {
        if (looseBag) {
          return {
            itemID: item.itemID,
            bag: Number(item?.quantity),
            denomination: item.denomination,
            value: item.valueInPence,
            looseCoin: item.looseCoin,
            cashType: item.material,
            index,
          };
        } else {
          return {
            itemID: item.itemID,
            looseCoin: item?.quantity,
            denomination: item.denomination,
            value: item.valueInPence,
            cashType: item.material,
            index,
          };
        }
      });
    } else {
      denomination = pouchDenomination?.map((item, index) => {
        if (looseBag) {
          return {
            itemID: item.itemID,
            bag: Number(item?.Quantity),
            denomination: item.Cash + item.currency,
            value: inputInPence(item.value),
            looseCoin: item.looseCoin,
            cashType: item.cashType,
            index,
          };
        } else {
          return {
            itemID: item.itemID,
            looseCoin: item.Quantity,
            denomination: item.Cash + item.currency,
            value: inputInPence(item.value),
            cashType: item.cashType,
            index,
          };
        }
      });
    }
    return {
      acceptedBy: pouchDetails?.acceptedBy,
      assignedBranchID: pouchDetails?.assignedBranchID,
      assignedBranchName: pouchDetails?.assignedBranchName,
      isBranchClose: pouchDetails?.isBranchClose,
      isCoinPouch: pouchDetails?.isCoinPouch,
      operationType: status === "delete" ? "delete" : operationType,
      pouchBarcode: pouchDetails?.pouchBarcode,
      pouchID: pouchDetails?.pouchID,
      pouchBagType: pouchBagType,
      pouchType: pouchDetails?.pouchType,
      status: status,
      fadCode: fadCode,
      preparedBySmartID: pouchDetails?.preparedBySmartID || smartid,
      preparedByUserID: pouchDetails?.preparedByUserID || username,
      denominationLadder: denomination,
    };
  };
  const {
    mutate: postPreparedPouch,
    isSuccess,
    isError,
    data: pouchCreated,
  } = usePostTransferTransaction();

  const handlePouchInfo = async () => {
    getPouchDetails("getPouchInformation");
    await getPouchResponse;
    setInitialPostCall(false);
    if (!initialPostCall) {
      refresh();
    }
  };
  const handleReprint = () => {
    setOpenSuccessfulPrintConfirmationPopup(false);
    setOpenReprintConfirmationPopup(true);
  };
  const listItems = () => {
    const listItems = [];
    pouchDenomination?.map((item, _index) => {
      const listObj: { Currency: string; Quantity: number; value: number } = {
        Currency: "",
        Quantity: 0,
        value: 0,
      };
      listObj.Currency = item.Cash + item.currency + " " + item.cashType;
      listObj.value = item.value;
      if (looseBag && item.looseCoin !== null && item.Quantity !== "0") {
        listObj.Quantity = item.looseCoin;
      } else if (item.Quantity !== null && item.Quantity !== "0") {
        listObj.Quantity = item.Quantity;
      }
      listItems.push(listObj);
    });
    return listItems;
  };
  const printCallFunction = () => {
    const branchAddressClean = removeEmptyLinesFromAddress(branchDetails.branch_address);
    setOpenReprintConfirmationPopup(false);
    receiptPrinterService.printReceipt({
      template: PouchPreparationTemplate,
      context: {
        title: "POUCH PREPARATION",
        fadcode: branchDetails.fadcode,
        branchName: branchDetails.branch_name,
        addressLine1: branchAddressClean[0],
        addressLine2: branchAddressClean[1],
        addressLine3: branchAddressClean[2],
        barCode: pouchDetails?.pouchID,
        pouchType: pouchDetails?.pouchType.includes("cash") ? "Cash" : "N/A",
        transactionId: pouchCreated?.basketId.substring(pouchCreated?.basketId.indexOf("-") + 1),
        postcode: branchAddressClean[branchAddressClean.length - 1],
        currentDate: currentDateDDMMYYYY(),
        currentTime: currentOnlyTime(),
        Location: "Safe",
        createdByUserName: pouchDetails?.preparedByUserID || username,
        createdBySmartID: pouchDetails?.preparedBySmartID || smartid,
        dateTimeSystem: currentDateTime(),
        list_items: listItems(),
        TotalValue: Total.toFixed(2),
      },
    });
  };
  const reload = () => {
    setcashdetail([]);
    navigate(0);
  };

  const handleResendReprint = () => {
    setIsReprint(true);
    printCallFunction();
    // reload();
  };

  const onHandleError = () => {
    setErrorPopup(false);
    setTranscommit(false);
    setPouchPrepared(false);
  };
  const savePouchInfo = async (status?: string) => {
    if (operationType === "suspend") {
      savePouchDetails("updatePouchDetails");
      await addPouchResponse;
    } else if (status === "delete") {
      delete AXIOS_INSTANCE.defaults.headers.common["fadcode"];
      postPreparedPouch({
        data: preparedPouchParams(oldDenomination),
      });
    } else {
      delete AXIOS_INSTANCE.defaults.headers.common["fadcode"];
      postPreparedPouch({
        data: preparedPouchParams(requestParams(status)),
      });
    }
    setInitialCall(false);
    if (!initialCall) {
      refersh1();
    }
  };

  useEffect(() => {
    if ((getPouchStatus === "fetched" && Code === 201) || Code === 200) {
      if (
        getPouchResponse?.pouchDetails[0].status === "Despatched" ||
        getPouchResponse?.pouchDetails[0].status === "despatched"
      ) {
        setIsSuspendFlow(false);
        setSuspended(false);
        setInvalidPouch(true);
      } else if (
        getPouchResponse?.isSuspendedPouch &&
        username === getPouchResponse?.suspendedBy &&
        getPouchResponse?.pouchDetails.length > 1
      ) {
        setSuspended(true);
        setsubmitNext(true);
        setIsSuspendFlow(true);
        setPouchDetails({ ...getPouchResponse?.pouchDetails[0] });
      } else if (
        getPouchResponse?.isSuspendedPouch &&
        username === getPouchResponse?.suspendedBy &&
        getPouchResponse?.pouchDetails.length === 1 &&
        getPouchResponse?.pouchDetails[0].status === "suspended"
      ) {
        setIsSuspendFlow(true);
        setsubmitNext(true);
        setIsSuspendedScanned(true);
        setPouchDetails({ ...getPouchResponse?.pouchDetails[0] });
      } else {
        setsubmitNext(true);
        setPouchDetails({ ...getPouchResponse?.pouchDetails[0] });
      }
    } else if (getPouchError?.data.message) {
      setSuspended(false);
      setInvalidPouch(true);
    } else {
      setSuspended(false);
    }
  }, [getPouchResponse, getPouchError, getPouchStatus, Code]);
  useEffect(() => {
    if (addPouchStatus === "fetched" || isSuccess) {
      if (operationType !== "preparing" && operationType !== "edit") {
        reload();
        setcashdetail([]);
      } else if ((operationType === "edit" && !isDelete) || operationType === "preparing") {
        setPouchPrepared(true);
        setTranscommit(false);
      }
      if (operationType === "edit") {
        setCancelSuccess(true);
        setIsDelete(false);
        setOldDenomination(null);
      }
    }
    if (isError && isTranscommit) {
      setErrorPopup(true);
      if (operationType === "edit") {
        setCancelSuccess(false);
      }
    }
  }, [addPouchStatus, isSuccess, isError]);
  useEffect(() => {
    if (isTranscommit && pouchDenomination) {
      if (isDelete && !isCancelSuccess) {
        savePouchInfo("delete");
      }
      if (isCancelSuccess) {
        savePouchInfo("preparing");
      }
    }
  }, [pouchDenomination, isCancelSuccess]);
  const enterBarCode = (e) => {
    e.preventDefault();
    const code = e.target.value;
    setBarCode(code);
  };

  const getQuantity = (item: Denomination): number => {
    if (item?.quantity !== 0) {
      return item?.quantity;
    } else if (item?.bag !== "") {
      return Number(item?.bag);
    } else return Number(item?.looseCoin);
  };

  const handlePouchBag = (name) => {
    setDenominationShow(true);
    if (name === "Useable Coins Part Bag") {
      setlooseBag(true);
      setlooseCoin(true);
      setcashdetail([...COINS_DETAIL_PART_BAG]);
      setPouchBagType(name);
      setheading("Useable Coins - Part Bag");
      setBagCode(true);
    } else if (name === "Useable Coins Full Bag") {
      setlooseCoin(false);
      setlooseBag(true);
      setPouchBagType(name);
      setcashdetail([...COINS_FULL_BAG_DETAIL]);
      setheading("Useable Coins - Full Bag");
      setBagCode(true);
    } else if (name === "Useable Notes") {
      setlooseCoin(false);
      setnotesBag(true);
      setPouchBagType(name);
      setcashdetail([...notesdetail]);
      setheading("Useable Notes");
      setBagCode(false);
    } else if (name === "Non Useable Notes") {
      setlooseCoin(false);
      setnotesBag(true);
      setPouchBagType(name);
      setcashdetail([...notesdetail]);
      setheading("Non Useable Notes");
      setBagCode(false);
    } else if (name === "Non Useable Coins") {
      setlooseCoin(false);
      setPouchBagType(name);
      setcashdetail([...coindetail]);
      setheading("Non Useable Coins");
      setBagCode(false);
    } else if (name === "Cancel") {
      setOperationType("cancel");
      savePouchInfo("cancel");
    } else if (name === "Edit" || name === "suspend") {
      setShowSuspendButton(false);
      let updateValue = [];
      setOperationType("edit");
      if (name === "Edit" || name === "suspend") {
        setheading(pouchDetails?.pouchBagType);
      }
      if (pouchDetails?.pouchBagType === "Non Useable Coins") {
        setlooseCoin(false);
        setBagCode(false);
        setPouchBagType(pouchDetails?.pouchBagType);
        updateValue = [...coindetail];
      } else if (pouchDetails?.pouchBagType === "Non Useable Notes") {
        setlooseCoin(false);
        setnotesBag(true);
        setBagCode(false);
        setPouchBagType(pouchDetails?.pouchBagType);
        updateValue = [...notesdetail];
      } else if (pouchDetails?.pouchBagType === "Useable Notes") {
        setlooseCoin(false);
        setnotesBag(true);
        setBagCode(false);
        setPouchBagType(pouchDetails?.pouchBagType);
        updateValue = [...notesdetail];
      } else if (pouchDetails?.pouchBagType === "Useable Coins Full Bag") {
        setlooseCoin(false);
        setlooseBag(true);
        setBagCode(true);
        setPouchBagType(pouchDetails?.pouchBagType);
        updateValue = [...COINS_FULL_BAG_DETAIL];
      } else if (pouchDetails?.pouchBagType === "Useable Coins Part Bag") {
        setlooseBag(true);
        setlooseCoin(true);
        setBagCode(true);
        setPouchBagType(pouchDetails?.pouchBagType);
        updateValue = [...coindetail];
      }
      pouchDetails?.denominationLadder?.map((item) => {
        updateValue[item.index].value =
          item?.valueInPence === 0 && item?.value !== 0
            ? amountInPound(item?.value)
            : amountInPound(item?.valueInPence);
        updateValue[item.index].Quantity = getQuantity(item); //item?.quantity !== 0 ? item?.quantity : item?.bag !== "" ? item?.bag : item?.looseCoin;
        //too hande suspend flow denomination ladder auto population...
        updateValue[item.index].looseCoin = item?.looseCoin;
        updateValue[item.index].alertCss = "normalCss";
        updateValue[item.index].QalertCss = "normalCss";
        updateValue[item.index].errorMsg = "";
        updateValue[item.index].QerrorMsg = "";
        updateValue[item.index].btnDisable = true;
      });
      setcashdetail([...updateValue]);
      setIsSuspendFlow(false);
      if (name === "Edit") {
        setOldDenomination(requestParams("delete"));
        setIsDelete(true);
      }
    }
  };

  useEffect(() => {
    if (isSuspendedScanned) {
      handlePouchBag("suspend");
    }
  }, [isSuspendedScanned, setIsSuspendedScanned]);

  const handleCancel = () => {
    if (pouchDetails?.status !== "available") {
      setOperationType("cancel");
      savePouchInfo("cancel");
    } else setDenominationShow(false);
    const updateValue = [...cashdetail];
    cashdetail?.map((item, index) => {
      updateValue[index].value = "";
      updateValue[index].Quantity = "";

      updateValue[index].looseCoin = "";
      updateValue[index].alertCss = "normalCss";
      updateValue[index].QalertCss = "normalCss";
      updateValue[index].errorMsg = "";
      updateValue[index].QerrorMsg = "";
      updateValue[index].btnDisable = true;
      return 0;
    });
    setcashdetail(updateValue);
  };

  const handleSuspend = () => {
    if (pouchDetails?.suspendCount === 2) {
      setSuspendLimitPopUp(true);
    } else if (getPouchResponse?.isSuspendedPouch) {
      setSuspendExistPopUp(true);
    } else {
      setSuspendPopup(true);
      setOperationType("suspend");
    }
  };

  const renderDescription = () => {
    if (!submitNext && !DenominationShow) {
      return (
        <h2 className="heading-3 font-bold">
          Scan or enter pouch barcode in the text box on the right.
        </h2>
      );
    }

    if (submitNext && !DenominationShow) {
      return (
        <h2 className="heading-3 font-bold">
          Select the content type from the options on the right.
        </h2>
      );
    }

    if (DenominationShow) {
      return (
        <h2 className="heading-3 font-bold">
          Enter denomination by quantity or value in the text boxes on the right-hand side.
        </h2>
      );
    }
    return "";
  };

  const renderBarcodeForm = () => (
    <>
      {isInvalidPouch && (
        <PopupConfirm
          header={
            getPouchError?.data.message
              ? "Pouch Barcode Failed to Scan"
              : getPouchError?.data.message
          }
          labelSuccess="Close"
          tickicon={false}
          onSuccess={() => setInvalidPouch(false)}
        />
      )}

      <input
        className="movementInput mb-4 lg:w-3/5"
        placeholder="Enter the barcode"
        onChange={(e) => enterBarCode(e)}
        value={barCode}
        ref={barCodeInput}
      />
      <div className="flex w-full justify-center">
        <button
          className="brandWhiteBtn mr-2 lg:w-1/4 font-bold"
          onClick={() => {
            setcashdetail([]);
            navigate(-1);
          }}>
          Cancel
        </button>
        <button className="accounting-button ml-2 lg:w-1/4" onClick={() => handlePouchInfo()}>
          Submit
        </button>
      </div>
    </>
  );

  /**
   * Dispatch button action handling
   * @param params pouch scan function button to perform dispatch
   * @param index key index
   * @returns
   */
  const renderDispatch = (params, index) => {
    const isMobile = isDeviceMobile();
    if (params.id === "pouch_despatch_1" && isMobile) {
      const refreshToken = localStorage.getItem(
        `CognitoIdentityServiceProvider.3bug83m4vi0j030qikm703k87n.${username}.refreshToken`,
      );
      const CT_DEEP_LINK = deepLink.pouchDispatch(refreshToken);
      return (
        <a
          key={index}
          className="transfer-out-tiles lg:w-3/5 font-bold heading mb-4"
          href={CT_DEEP_LINK}>
          {params.name}
        </a>
      );
    }

    return (
      <button
        key={index}
        className="transfer-out-tiles lg:w-3/5 font-bold heading mb-4"
        onClick={() => setDispatchPopup(true)}>
        {params.name}
      </button>
    );
  };

  /**
   * Pouch scan function actions buttons
   * @returns Pouch scan action buttons
   */
  const renderFunctionListButtons = () => {
    return pouchDetails?.functionList?.map((name, index) => {
      if (name.id !== "pouch_despatch_1") {
        return (
          <button
            key={index}
            className="transfer-out-tiles lg:w-3/5 font-bold heading mb-4"
            onClick={() => handlePouchBag(name.name)}>
            {name.name}
          </button>
        );
      }

      return renderDispatch(name, index);
    });
  };

  const showEditCancel = () => {
    if (
      operationType === "edit" &&
      isPouchPrepared &&
      username !== pouchDetails?.preparedByUserID
    ) {
      return true;
    } else if (
      operationType === "cancel" &&
      CancelPopup &&
      pouchDetails?.preparedByUserID !== "" &&
      username !== pouchDetails?.preparedByUserID
    ) {
      return true;
    }
    return false;
  };
  const renderPouchScanActionButtons = (
    <>
      {isSuspendFlow ? renderBarcodeForm() : renderFunctionListButtons()}
      {isDispatchPopup && (
        <PopupConfirm
          header={"Pouch Dispatch is only available via the Counter Terminal App."}
          labelSuccess="Close"
          tickicon={false}
          onSuccess={() => setDispatchPopup(false)}
        />
      )}
      {isInvalidPouch && (
        <PopupConfirm
          header={`This pouch is prepared by ${pouchDetails?.preparedByUserID} you are not authorised to edit or cancel it.`}
          labelSuccess="Close"
          tickicon={false}
          onSuccess={() => setInvalidPouch(false)}
        />
      )}
      {suspended && (
        <PopupConfirm
          header="There is a suspended pouch preparation, you can 
              resume that preparation to proceed."
          labelCancel="Cancel"
          labelSuccess="Resume"
          tickicon={false}
          onCancel={() => {
            setSuspended(false);
            setPouchDetails({ ...getPouchResponse?.pouchDetails[1] });
            setIsSuspendFlow(false);
            //setsubmitNext(true);
          }}
          onSuccess={() => {
            handlePouchBag("suspend");
            setSuspended(false);
          }}
        />
      )}
    </>
  );
  const pouchSet = (
    <>
      <PouchLadder
        heading={heading}
        cashdetail={cashdetail}
        looseBag={looseBag}
        looseCoin={looseCoin}
        showCancelButton={true}
        showCompleteButton={true}
        showSuspendButton={showSuspendButton}
        disableFlags={false}
        onCancel={(data, total) => {
          setOperationType("cancel");
          setPouchDenomination([...data]);
          setTotal(total.totalAmount);
          setCancelPopup(true);
          showEditCancel();
        }}
        onComplete={(data, total) => {
          setTranscommit(true);
          if (!isDelete) {
            setCancelSuccess(true);
          }
          setPouchDenomination([...data]);
          setTotal(total.totalAmount);
        }}
        onSuspend={(data) => {
          setPouchDenomination([...data]);
          handleSuspend();
        }}
      />
      {showEditCancel() && (
        <PopupConfirm
          header={`This pouch is prepared by ${pouchDetails?.preparedByUserID},
                    you are not authorised to edit or cancel it.`}
          labelSuccess="Close"
          tickicon={false}
          onSuccess={() => {
            setOperationType("preparing");
            setPouchPrepared(false);
            setCancelPopup(false);
            setDenominationShow(false);
            setNotAutorizeToSuspend(false);
          }}
        />
      )}
      {isPouchPrepared && !showEditCancel() && !isReprint && (
        <PouchPreparationPopup
          headerIcon={
            <svg width="60" height="46" viewBox="0 0 60 46" fill="none">
              <path
                d="M19.3167 35.9167L5.41668 22.0167L0.68335 26.7167L19.3167 45.35L59.3167 5.35002L54.6167 0.650024L19.3167 35.9167Z"
                fill="#008000"
              />
            </svg>
          }
          popupType="PouchPreparationSuccessfull"
          messageBody="Pouch Preparation is Successful."
          labelCancel="Cancel"
          onCancel={() => setPouchPrepared(false)}
          labelSuccess="Print Receipt"
          onSuccess={() => {
            printCallFunction();
            setOpenSuccessfulPrintConfirmationPopup(true);
          }}
        />
      )}
      {!showEditCancel() && CancelPopup && (
        <PopupConfirm
          header="Do you really want to cancel this task?"
          labelCancel="No"
          labelSuccess="Yes"
          tickicon={false}
          onCancel={() => {
            setOperationType("preparing");
            setCancelPopup(false);
          }}
          onSuccess={() => {
            setOperationType("preparing");
            setCancelPopup(false);
            handleCancel();
          }}
        />
      )}
      {SuspendPopup && (
        <PopupConfirm
          header="Do you really want to suspended this task.
                    Note - Suspensions rules cease at the end of the day (7pm) 
                    after which the pouch Preparation will be cancelled."
          labelCancel="Cancel"
          labelSuccess="Save"
          tickicon={false}
          onCancel={() => {
            setOperationType("preparing");
            setSuspendPopup(false);
          }}
          onSuccess={() => {
            setSuspendPopup(false);
            savePouchInfo("suspend");
          }}
        />
      )}
      {suspendExistPopUp && (
        <PopupConfirm
          header={`There is a suspended pouch prepared by ${getPouchResponse?.suspendedBy}, 
          you cannot suspend same type of pouch more than one.`}
          labelSuccess="Close"
          tickicon={false}
          onSuccess={() => setSuspendExistPopUp(false)}
        />
      )}
      {suspendLimitPopUp && (
        <PopupConfirm
          header="You cannot Suspend Pouch more than twice."
          labelSuccess="Close"
          tickicon={false}
          onSuccess={() => setSuspendLimitPopUp(false)}
        />
      )}

      {openSuccessfulPrintConfirmationPopup && (
        <PouchPreparationPopup
          headerIcon={
            <svg width="68" height="60" viewBox="0 0 68 60" fill="none">
              <path
                d="M57.3333 16.6667H54V0H14V16.6667H10.6667C5.13334 16.6667 0.666672 21.1333 0.666672 26.6667V46.6667H14V60H54V46.6667H67.3333V26.6667C67.3333 21.1333 62.8667 16.6667 57.3333 16.6667ZM20.6667 6.66667H47.3333V16.6667H20.6667V6.66667ZM47.3333 53.3333H20.6667V40H47.3333V53.3333ZM54 40V33.3333H14V40H7.33334V26.6667C7.33334 24.8333 8.83334 23.3333 10.6667 23.3333H57.3333C59.1667 23.3333 60.6667 24.8333 60.6667 26.6667V40H54Z"
                fill="#2B60DE"
              />
              <path
                d="M54 31.6667C55.841 31.6667 57.3333 30.1743 57.3333 28.3333C57.3333 26.4924 55.841 25 54 25C52.1591 25 50.6667 26.4924 50.6667 28.3333C50.6667 30.1743 52.1591 31.6667 54 31.6667Z"
                fill="#2B60DE"
              />
            </svg>
          }
          popupType="confirmSuccessfulPrint"
          messageBody="Print Successful."
          labelCancel="Cancel"
          onCancel={reload}
          labelSuccess="Confirm Successful Print"
          onSuccess={handleReprint}
        />
      )}
      {isErrorPopup && (
        <PouchPreparationPopup
          headerIcon={
            <svg width="68" height="68" viewBox="0 0 68 68" fill="none">
              <path
                d="M30.6667 44H37.3334V50.6667H30.6667V44ZM30.6667 17.3334H37.3334V37.3334H30.6667V17.3334ZM33.9667 0.666687C15.5667 0.666687 0.666748 15.6 0.666748 34C0.666748 52.4 15.5667 67.3334 33.9667 67.3334C52.4001 67.3334 67.3334 52.4 67.3334 34C67.3334 15.6 52.4001 0.666687 33.9667 0.666687ZM34.0001 60.6667C19.2667 60.6667 7.33342 48.7334 7.33342 34C7.33342 19.2667 19.2667 7.33335 34.0001 7.33335C48.7334 7.33335 60.6668 19.2667 60.6668 34C60.6668 48.7334 48.7334 60.6667 34.0001 60.6667Z"
                fill="#B20101"
              />
            </svg>
          }
          popupType="reprintConfirmationPopup"
          messageBody="Transaction has failed. Please Retry"
          labelSuccess="Retry"
          onSuccess={onHandleError}
        />
      )}

      {openReprintConfirmationPopup && (
        <PouchPreparationPopup
          headerIcon={
            <svg width="68" height="60" viewBox="0 0 68 60" fill="none">
              <path
                d="M57.3333 16.6667H54V0H14V16.6667H10.6667C5.13334 16.6667 0.666672 21.1333 0.666672 26.6667V46.6667H14V60H54V46.6667H67.3333V26.6667C67.3333 21.1333 62.8667 16.6667 57.3333 16.6667ZM20.6667 6.66667H47.3333V16.6667H20.6667V6.66667ZM47.3333 53.3333H20.6667V40H47.3333V53.3333ZM54 40V33.3333H14V40H7.33334V26.6667C7.33334 24.8333 8.83334 23.3333 10.6667 23.3333H57.3333C59.1667 23.3333 60.6667 24.8333 60.6667 26.6667V40H54Z"
                fill="#2B60DE"
              />
              <path
                d="M54 31.6667C55.841 31.6667 57.3333 30.1743 57.3333 28.3333C57.3333 26.4924 55.841 25 54 25C52.1591 25 50.6667 26.4924 50.6667 28.3333C50.6667 30.1743 52.1591 31.6667 54 31.6667Z"
                fill="#2B60DE"
              />
            </svg>
            // <svg width="68" height="68" viewBox="0 0 68 68" fill="none">
            //   <path
            //     d="M30.6667 44H37.3334V50.6667H30.6667V44ZM30.6667 17.3334H37.3334V37.3334H30.6667V17.3334ZM33.9667 0.666687C15.5667 0.666687 0.666748 15.6 0.666748 34C0.666748 52.4 15.5667 67.3334 33.9667 67.3334C52.4001 67.3334 67.3334 52.4 67.3334 34C67.3334 15.6 52.4001 0.666687 33.9667 0.666687ZM34.0001 60.6667C19.2667 60.6667 7.33342 48.7334 7.33342 34C7.33342 19.2667 19.2667 7.33335 34.0001 7.33335C48.7334 7.33335 60.6668 19.2667 60.6668 34C60.6668 48.7334 48.7334 60.6667 34.0001 60.6667Z"
            //     fill="#B20101"
            //   />
            // </svg>
          }
          popupType="reprintConfirmationPopup"
          messageBody="Do you want to reprint the receipt ?"
          labelCancel="No"
          // labelCancel="Cancel"
          onCancel={reload}
          labelSuccess="Yes"
          onSuccess={handleResendReprint}
        />
      )}
      {isReprint && (
        <PouchPreparationPopup
          headerIcon={
            <svg width="68" height="60" viewBox="0 0 68 60" fill="none">
              <path
                d="M57.3333 16.6667H54V0H14V16.6667H10.6667C5.13334 16.6667 0.666672 21.1333 0.666672 26.6667V46.6667H14V60H54V46.6667H67.3333V26.6667C67.3333 21.1333 62.8667 16.6667 57.3333 16.6667ZM20.6667 6.66667H47.3333V16.6667H20.6667V6.66667ZM47.3333 53.3333H20.6667V40H47.3333V53.3333ZM54 40V33.3333H14V40H7.33334V26.6667C7.33334 24.8333 8.83334 23.3333 10.6667 23.3333H57.3333C59.1667 23.3333 60.6667 24.8333 60.6667 26.6667V40H54Z"
                fill="#2B60DE"
              />
              <path
                d="M54 31.6667C55.841 31.6667 57.3333 30.1743 57.3333 28.3333C57.3333 26.4924 55.841 25 54 25C52.1591 25 50.6667 26.4924 50.6667 28.3333C50.6667 30.1743 52.1591 31.6667 54 31.6667Z"
                fill="#2B60DE"
              />
            </svg>
          }
          popupType="confirmSuccessfulPrint"
          messageBody="Reprint Successful."
          // labelCancel="OK"
          // onCancel={reload}
          labelSuccess="OK"
          onSuccess={reload}
        />
      )}
    </>
  );

  return (
    <>
      <>
        <div className="containerMain">
          <div className="w-full items-start p-4 transferOut-height overflow-x-auto">
            <div className="w-full grid grid-cols-1 gap-5 lg:grid-cols-2 lg:gap-5 h-full">
              <div className="flex items-center justify-center heading pouchPrepBox p-7">
                {renderDescription()}
              </div>
              <div className="flex items-center justify-center bg-white flex-col pouchPrepBox p-7">
                {!DenominationShow
                  ? !submitNext && !isSuspendFlow
                    ? renderBarcodeForm()
                    : renderPouchScanActionButtons
                  : pouchSet}
              </div>
              {getPouchStatus === "fetching" || (addPouchStatus === "fetching" && <Loader />)}
            </div>
          </div>
        </div>
        {printData && (
          <ReceiptPrint
            title={"POUCH PREPARATION"}
            data={printData}
            onPrintClose={() => {
              setPrintData(null);
              setOpenSuccessfulPrintConfirmationPopup(true);
            }}
          />
        )}
      </>
    </>
  );
};

export default PouchPreparationFeature;
