import { amountUnSignedPound } from "./currencyFormatter";

export type Denomination = {
  denomination: string;
  index: number;
  looseCoin: number;
  value: number;
  floatValue?: number;
};

export const cashText = (cash: string) => {
  if (cash.includes("£")) {
    return `£${cash.replace("£", "")} Note`;
  }
  if (cash.includes("p")) {
    return `${cash.replace("p", "")}p Coin`;
  }
  return cash;
};

export const convertDenomination = (denom: Denomination[]) => {
  return denom.map((item) => {
    const denomination = cashText(item.denomination);
    const { denomination: _, value, ...rest } = item;
    const newValue = amountUnSignedPound(value);
    return { denomination, value: newValue, ...rest };
  });
};

export const removeEmptyLinesFromAddress = (address: string) => {
  const addressArray = address.split(",");
  const cleanedArray = addressArray.filter((item) => item !== "");
  return cleanedArray;
};
