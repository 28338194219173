import { useGetTaskList, TaskData } from "@bbo/api/generator";
import { useAppDispatch, useAppSelector, useGlobalState } from "@bbo/lib";
import { SelectedTask, setSelectedTask } from "@bbo/lib/reduxStore/selectedTask.slice";
import { assertNever } from "@bbo/utils/assertNever";
import { SyntheticEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useHomeTab = () => {
  const { fadCode } = useAppSelector((root) => root.auth.authUserData);
  const { data, isLoading: isTaskListLoading } = useGetTaskList(fadCode);
  const [, setRouteState] = useGlobalState("routeState");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    data &&
      data?.find(
        (item) => item?.status === "Pending" && dispatch(setSelectedTask({ warningMsg: true })),
      );
  }, [data, dispatch]);

  const taskList = data || [];
  const handleCashFlowRouteByTask = ({ item }: { item: SelectedTask }) => {
    switch (item.task) {
      case "Cash Balance": {
        dispatch(setSelectedTask(item));
        // TODO: Route state needs review, doesn't seem like a good approach
        setRouteState({ RouteState: "taskManagement" });
        navigate("/cashBalance", { replace: true });
        return;
      }
      case "Transfer Out": {
        dispatch(setSelectedTask(item));
        setRouteState({ RouteState: "transferOut" });
        navigate("/transferOut");
        return;
      }
      case "Transfer In": {
        dispatch(setSelectedTask(item));
        setRouteState({ RouteState: "transferIn" });
        navigate("/transferIn");
        return;
      }
      default: {
        return assertNever(item.task);
      }
    }
  };

  const onCashFlow = (item: TaskData) => (e: SyntheticEvent) => {
    e.preventDefault();

    handleCashFlowRouteByTask({ item: item as SelectedTask });
  };

  return {
    taskList,
    isTaskListLoading,
    onCashFlow,
  };
};
