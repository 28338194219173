import { useGlobalState } from "@bbo/lib/state";
import { useEffect, useState } from "react";
import { AWAITING_BRANCH_ACTION, AWAITING_CENTRAL_SUPPORT } from "./constant";
import { DiscrepancyOverview } from "./DiscrepancyOverView";
import HeaderTabs from "./HeaderTabs";
import "./index.css";

// import Summary from "./Summary"
interface iProps {
  labels?: unknown[];
}

export const Summary = (props: iProps) => {
  const [selectedTab, setSelectedTab] = useGlobalState("summaryTab");
  const [isAwaitBranch, setIsAwaitBranch] = useState<boolean>(false);
  const [isAwaitCentral, setIsAwaitCentral] = useState<boolean>(false);

  const handleSelectTab = (params) => {
    setSelectedTab(params.value);
  };

  useEffect(() => {
    if (selectedTab === AWAITING_BRANCH_ACTION) {
      setIsAwaitCentral(false);
      setIsAwaitBranch(true);
    } else if (selectedTab === AWAITING_CENTRAL_SUPPORT) {
      setIsAwaitBranch(false);
      setIsAwaitCentral(true);
    } else {
      setIsAwaitBranch(false);
      setIsAwaitCentral(false);
    }
  }, [selectedTab]);

  return (
    <>
      <HeaderTabs
        tabLabels={props.labels}
        selected={selectedTab}
        onSelectTab={handleSelectTab}
        isSummary={true}
        isAwaitBranch={isAwaitBranch}
        isAwaitCentral={isAwaitCentral}
      />
      <DiscrepancyOverview selectedTab={selectedTab} />
    </>
  );
};
