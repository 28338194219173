/**
 * Ladder row component
 */

import { useEffect, useState } from "react";
import { LadderFlags, LadderItemProps } from "../../types";
import { handleCashNote, handleCashNote1, updateFlags } from "./utility";

/**
 * Pouch Preparation screen functional component
 * @returns
 */
interface iProps {
  item?: LadderItemProps;
  index?: number;
  looseBag?: boolean;
  looseCoin?: boolean;
  diabledFlags?: LadderFlags;
  onChange?: (params, index, flags) => void;
}

const LaddarRow = (props: iProps) => {
  const [data, setData] = useState(props.item);

  const { index, looseCoin, looseBag, diabledFlags } = props;

  useEffect(() => {
    setData(props.item);
  }, [props.item]);

  const CashText = (cash, type) => {
    if (type === "£") {
      return (
        <span className="textCash text-lg font-normal textCashalign"> £{cash.toFixed(2)}</span>
      );
    } else {
      return <span className="textCash text-lg font-normal textCashalign"> {cash}p</span>;
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    const mValue = value.replace(/[^0-9]/g, "");

    setData((params) => {
      const flags = updateFlags({ ...params, [name]: mValue }, diabledFlags, looseCoin);
      let data = null;
      const inputField = name !== "value" ? "Q" : "V";
      if (looseCoin || looseBag) {
        data = handleCashNote1(params, name, value, inputField);
        if (data.value && inputField === "Q") {
          data.value = `£${data.value.replace("£", "")}`;
        } else {
          data.value = `£${(Number(data.value.replace("£", "")) / 100).toFixed(2)}`;
        }
      } else {
        data = handleCashNote(params, name, value, inputField);
        if (data.value && inputField === "Q") {
          data.value = `£${data.value.replace("£", "")}`;
        } else {
          data.value = `£${(Number(data.value.replace("£", "")) / 100).toFixed(2)}`;
        }
      }
      // TODO: This is throwing a React error and needs moving out of the setData call
      props.onChange({ ...data }, index, flags);
      return data;
    });
  };

  return (
    <tr>
      <td className="text-left w-1/5">
        {" "}
        {CashText(data.Cash, data.currency)} {data.cashType}
      </td>
      <td className="width-10 text-left">X</td>
      <td className="text-left w-1/5">
        <input
          key={index}
          data-cy={data.Cash + "Q"}
          min="0"
          pattern="[0-9]"
          disabled={diabledFlags.inputQ}
          type="number"
          id="Quantity"
          name="Quantity"
          value={data.Quantity || ""}
          autoComplete="off"
          className={
            "h-9 p-2 commonTableBorder w-full text-right border-4px heading-6 inputCash " +
            data.Quantity
          }
          onChange={handleOnChange}
        />
      </td>
      {looseBag && looseCoin && (
        <td className="text-left w-1/5">
          {" "}
          <input
            data-cy={data.Cash + "V"}
            min="0"
            type="number"
            id="looseCoin"
            name="looseCoin"
            value={data.looseCoin || ""}
            disabled={diabledFlags.inputLC}
            autoComplete="off"
            className={
              "h-9 commonTableBorder p-2 w-full text-right border-4px heading-6 inputCash " +
              data.alertCss
            }
            onChange={handleOnChange}
          />
        </td>
      )}
      <td className="width-10 text-center">=</td>
      <td className="text-left w-1/5">
        {" "}
        <input
          key={index}
          data-cy={data.Cash + "V"}
          min="0"
          name="value"
          id="value"
          value={data.value || ""}
          disabled={diabledFlags.inputV}
          autoComplete="off"
          className={
            "h-9 commonTableBorder heading-6 text-right border-4px inputCash " +
            (looseCoin ? " width-100 " : "") +
            data.alertCss
          }
          onChange={handleOnChange}
        />
      </td>
    </tr>
  );
};

export default LaddarRow;
