import { CashDrawerData, ForexDrawerData } from "@bbo/api/generator";
import { useState } from "react";
import { StockCardData } from "../StockBranchOverview/StockCardData/StockCardData";
import { ActionList } from "./ActionList";
import { useTableList } from "./useTableList";

type Props = {
  header: string;
  data: CashDrawerData | ForexDrawerData;
  index: number;
  isStockData?: boolean;
};

const CashDrawerItem = ({ data, isStockData, ...rest }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
  };

  const actionData = { ...data, ...data.itemDetails };
  const { orderedTable } = useTableList(data.itemDetails ?? {});

  return (
    <>
      <div
        className="main cursor-pointer widthAccounting mr-8 inline-block"
        onClick={() => setShowModal(true)}>
        <div className="heading">
          <span>{data?.entityName}</span>
          {data?.associatedCounterTerminalID && (
            <>
              <i className="fas fa-link mx-2"></i>
              <span>{data.associatedCounterTerminal}</span>{" "}
            </>
          )}
        </div>
        <hr className="border-gray-200 border-bottom-none" />
        <div className="third-heading">
          {isStockData ? (
            <StockCardData data={data?.itemDetails} />
          ) : (
            <table className="w-full">
              <tbody className="counterPad">{orderedTable}</tbody>
            </table>
          )}
        </div>
      </div>
      {showModal && <ActionList data={actionData} header={rest.header} onClose={handleClose} />}
    </>
  );
};

export default CashDrawerItem;
