import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import ButtonCounterView, { ListItemProps } from "@bbo/screens/ButtonCounterView";

import Select from "react-select";
export const MiddleView = ({
  isDrawers,
  setisDrawers,
  activeHeadingName,
  listItems,
  onSelectHandleChange,
  selectView,
  selectOption,
}) => {
  const { entity } = useAppSelector((root) => root.selectedTask);
  return (
    <div className="pt-6">
      <h2 className="heading-3 font-bold mb-5 showDesktop">Cash Transfer To</h2>
      <div className="showDesktop bg-white transferOut-center-side overflow-y-auto">
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 pb-4 pr-4">
          <button
            className={`${isDrawers ? "text-lg text-color font-semibold" : "transferIntabs"}`}
            onClick={() => setisDrawers(false)}>
            Counters
          </button>
          {entity === "safe" && (
            <button
              className={`${isDrawers ? "transferIntabs" : "text-lg text-color font-semibold"}`}
              onClick={() => setisDrawers(true)}>
              Cash Drawers
            </button>
          )}
          {entity !== "safe" && (
            <button
              className={`${isDrawers ? "transferIntabs" : "text-lg text-color font-semibold"}`}
              onClick={() => setisDrawers(true)}>
              Safe
            </button>
          )}
        </div>
        <table className="w-full table-border">
          <tbody>
            <ButtonCounterView
              listItems={listItems as unknown as [ListItemProps]}
              activeHeadingName={activeHeadingName}
              selectOption={selectOption}
            />
          </tbody>
        </table>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 lg:hidden mb-5 mt-5 showMobile">
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 pb-4 pr-4">
          <button
            className={`${isDrawers ? "text-lg text-color font-semibold" : "transferIntabs"}`}
            onClick={() => setisDrawers(false)}>
            Counters
          </button>
          <button
            className={`${isDrawers ? "transferIntabs" : "text-lg text-color font-semibold"}`}
            onClick={() => setisDrawers(true)}>
            Cash Drawers
          </button>
        </div>
        <div className="pl-4">
          <h2 className="heading-2 font-bold mb-2">Cash Transfer To</h2>
        </div>
        <Select
          value={
            activeHeadingName
              ? {
                  value: activeHeadingName,
                  label: activeHeadingName,
                }
              : activeHeadingName
          }
          options={selectView}
          onChange={(e) => {
            onSelectHandleChange(e);
          }}
          closeMenuOnSelect={true}
        />
      </div>
    </div>
  );
};
