/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

PO Transactions

PO Transactions Viewer

Cash Drawer Association API
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  CashManagementApiResponse,
  DiscrepencyData,
  DiscrepencyHistory,
  VarianceAccountingDTO,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary  This endpoint is used to udpate discrepency movement of entire branch
 */
export const usePutDiscrepancyDataHook = () => {
  const putDiscrepancyData = useCustomInstance<void>();

  return (discrepencyData: DiscrepencyData[]) => {
    return putDiscrepancyData({
      url: `/cash_management/discrepency_movement`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: discrepencyData,
    });
  };
};

export type PutDiscrepancyDataMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof usePutDiscrepancyDataHook>>
>;
export type PutDiscrepancyDataMutationBody = DiscrepencyData[];
export type PutDiscrepancyDataMutationError = ErrorType<CashManagementApiResponse>;

export const usePutDiscrepancyData = <
  TError = ErrorType<CashManagementApiResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof usePutDiscrepancyDataHook>>,
    TError,
    { data: DiscrepencyData[] },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const putDiscrepancyData = usePutDiscrepancyDataHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof usePutDiscrepancyDataHook>>,
    { data: DiscrepencyData[] }
  > = (props) => {
    const { data } = props || {};

    return putDiscrepancyData(data);
  };

  return useMutation<
    AsyncReturnType<typeof putDiscrepancyData>,
    TError,
    { data: DiscrepencyData[] },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary  This endpoint is used to get discrepency movement of entire branch
 */
export const useGetDiscrepancyDataHook = () => {
  const getDiscrepancyData = useCustomInstance<DiscrepencyHistory>();

  return () => {
    return getDiscrepancyData({ url: `/cash_management/discrepency_movement`, method: "get" });
  };
};

export const getGetDiscrepancyDataQueryKey = () => [`/cash_management/discrepency_movement`];

export type GetDiscrepancyDataQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetDiscrepancyDataHook>>
>;
export type GetDiscrepancyDataQueryError = ErrorType<CashManagementApiResponse | void>;

export const useGetDiscrepancyData = <
  TData = AsyncReturnType<ReturnType<typeof useGetDiscrepancyDataHook>>,
  TError = ErrorType<CashManagementApiResponse | void>,
>(options?: {
  query?: UseQueryOptions<
    AsyncReturnType<ReturnType<typeof useGetDiscrepancyDataHook>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetDiscrepancyDataQueryKey();

  const getDiscrepancyData = useGetDiscrepancyDataHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetDiscrepancyDataHook>>
  > = () => getDiscrepancyData();

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetDiscrepancyDataHook>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary This endpoint is used to movement of variance to discrepancy account
 */
export const usePostDiscrepancyDataHook = () => {
  const postDiscrepancyData = useCustomInstance<void>();

  return (varianceAccountingDTO: VarianceAccountingDTO[]) => {
    return postDiscrepancyData({
      url: `/cash_management/discrepency_movement`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: varianceAccountingDTO,
    });
  };
};

export type PostDiscrepancyDataMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof usePostDiscrepancyDataHook>>
>;
export type PostDiscrepancyDataMutationBody = VarianceAccountingDTO[];
export type PostDiscrepancyDataMutationError = ErrorType<CashManagementApiResponse>;

export const usePostDiscrepancyData = <
  TError = ErrorType<CashManagementApiResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof usePostDiscrepancyDataHook>>,
    TError,
    { data: VarianceAccountingDTO[] },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const postDiscrepancyData = usePostDiscrepancyDataHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof usePostDiscrepancyDataHook>>,
    { data: VarianceAccountingDTO[] }
  > = (props) => {
    const { data } = props || {};

    return postDiscrepancyData(data);
  };

  return useMutation<
    AsyncReturnType<typeof postDiscrepancyData>,
    TError,
    { data: VarianceAccountingDTO[] },
    TContext
  >(mutationFn, mutationOptions);
};
