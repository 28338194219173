/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

PO Transactions

PO Transactions Viewer

Cash Drawer Association API
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { ForexDenominationLadder, BadRequest } from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Get forex denomination ladder
 * @summary get forex branch denomination
 */
export const useGetForexDenominationHook = () => {
  const getForexDenomination = useCustomInstance<ForexDenominationLadder>();

  return (branchId: string, currencyType: string) => {
    return getForexDenomination({
      url: `/forex-management/forex-denomination/${branchId}/${currencyType}`,
      method: "get",
    });
  };
};

export const getGetForexDenominationQueryKey = (branchId: string, currencyType: string) => [
  `/forex-management/forex-denomination/${branchId}/${currencyType}`,
];

export type GetForexDenominationQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetForexDenominationHook>>
>;
export type GetForexDenominationQueryError = ErrorType<BadRequest | void>;

export const useGetForexDenomination = <
  TData = AsyncReturnType<ReturnType<typeof useGetForexDenominationHook>>,
  TError = ErrorType<BadRequest | void>,
>(
  branchId: string,
  currencyType: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetForexDenominationHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetForexDenominationQueryKey(branchId, currencyType);

  const getForexDenomination = useGetForexDenominationHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetForexDenominationHook>>
  > = () => getForexDenomination(branchId, currencyType);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetForexDenominationHook>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(branchId && currencyType), ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};
