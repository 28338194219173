/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

PO Transactions

PO Transactions Viewer

Cash Drawer Association API
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { ForexManagementData, BadRequest, OnBoardedEntitiesDTO } from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Get on-boarding entities data.
 * @summary get forex branch overview
 */
export const useGetForexBranchOverviewHook = () => {
  const getForexBranchOverview = useCustomInstance<ForexManagementData>();

  return (branchId: string) => {
    return getForexBranchOverview({
      url: `/forex-management/forex-details/${branchId}`,
      method: "get",
    });
  };
};

export const getGetForexBranchOverviewQueryKey = (branchId: string) => [
  `/forex-management/forex-details/${branchId}`,
];

export type GetForexBranchOverviewQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetForexBranchOverviewHook>>
>;
export type GetForexBranchOverviewQueryError = ErrorType<BadRequest | void>;

export const useGetForexBranchOverview = <
  TData = AsyncReturnType<ReturnType<typeof useGetForexBranchOverviewHook>>,
  TError = ErrorType<BadRequest | void>,
>(
  branchId: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetForexBranchOverviewHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetForexBranchOverviewQueryKey(branchId);

  const getForexBranchOverview = useGetForexBranchOverviewHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetForexBranchOverviewHook>>
  > = () => getForexBranchOverview(branchId);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetForexBranchOverviewHook>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!branchId, ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Get on-boarding entities data.
 * @summary get stock branch overview
 */
export const useGetStockBranchOverviewHook = () => {
  const getStockBranchOverview = useCustomInstance<OnBoardedEntitiesDTO>();

  return (fadcode: string) => {
    return getStockBranchOverview({
      url: `/stock-management/stock-details/${fadcode}`,
      method: "get",
    });
  };
};

export const getGetStockBranchOverviewQueryKey = (fadcode: string) => [
  `/stock-management/stock-details/${fadcode}`,
];

export type GetStockBranchOverviewQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetStockBranchOverviewHook>>
>;
export type GetStockBranchOverviewQueryError = ErrorType<void>;

export const useGetStockBranchOverview = <
  TData = AsyncReturnType<ReturnType<typeof useGetStockBranchOverviewHook>>,
  TError = ErrorType<void>,
>(
  fadcode: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetStockBranchOverviewHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetStockBranchOverviewQueryKey(fadcode);

  const getStockBranchOverview = useGetStockBranchOverviewHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetStockBranchOverviewHook>>
  > = () => getStockBranchOverview(fadcode);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetStockBranchOverviewHook>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!fadcode, ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};
