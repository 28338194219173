import { BranchBalancing, useGetBPTPList } from "@bbo/api/generator";
import Loader from "@bbo/components/Loader";
import { Popup } from "@bbo/components/Popup";
import TableView from "@bbo/components/table";
import { TP_BP_HISTORY_COLUMN } from "@bbo/components/table/tableConstants";
import { TableRowCallbackParams } from "@bbo/components/table/types";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { useReceiptPrinter } from "@bbo/lib/hooks/useReceiptPrinter";
import { useGlobalState } from "@bbo/lib/state";
import { PrintCashReceipt } from "@bbo/types";
import { TpBpHistoryTemplate } from "@bbo/utils/ReceiptPrinterTemplates/TpBpHistory";
import { amountPound } from "@bbo/utils/currencyFormatter";
import {
  currentDate,
  currentDateDDMMYYYY,
  currentTime,
  getUnixTimeStamp,
  newTransferDateTime,
} from "@bbo/utils/dateTimeFormatter";
import { getValueFromStorage } from "@bbo/utils/util";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TpBpDetailView from "./TpBpDetailView";
import { removeEmptyLinesFromAddress } from "@bbo/utils/receiptHelper";

const TpBpHistory = () => {
  const fadeCode = getValueFromStorage("fadcode");
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({
    titleHeader: "",
    title: "",
    actions: [],
    rowData: {},
  });

  const [cashdate] = useGlobalState("dateCashbalance");
  const [, setloader] = useState<boolean>(true);
  const [GlobalUSerName] = useGlobalState("userName");
  const [TpBpRowSelected, setTpBpRowSelected] =
    useState<TableRowCallbackParams<BranchBalancing>>(null);
  const [, setparam] = useState<unknown>(fadeCode);
  const [actionName, setactionName] = useState<string>("");
  const [printData, setPrintData] = useState<PrintCashReceipt>(null);
  const receiptPrinterService = useReceiptPrinter();
  const { setTitle } = useTitle();
  const navigate = useNavigate();
  const branchDetails = useAppSelector((root) => root.branchDetails);

  const { fadCode } = useAppSelector((root) => root.auth.authUserData);

  const { data: branchBalancingHistory, isLoading } = useGetBPTPList(fadCode);

  useEffect(() => {
    setTitle("Balance / Trading Period History");
  }, [setTitle]);

  useEffect(() => {
    if (GlobalUSerName.UserAttributes === undefined) {
      setloader(false);
    } else {
      setloader(true);
    }
  }, [GlobalUSerName]);

  useEffect(() => {
    if (cashdate.todate) {
      const param = {
        branchId: fadeCode,
        fromDate: getUnixTimeStamp(cashdate.from),
        toDate: getUnixTimeStamp(cashdate.todate),
      };
      setparam(param);
    } else {
      setparam({ branchId: fadeCode });
    }
    // apiCall("getBPTPList");
    // refresh();
  }, [cashdate.todate]);

  const handleRowClick = (row: TableRowCallbackParams<BranchBalancing>) => {
    setTpBpRowSelected(row);
    localStorage.setItem("selectedTpBpHistoryRow", JSON.stringify(row.data));
    if (row.data.name.startsWith("TP")) {
      setactionName("Trading Period");
    } else setactionName("Balance Period");

    setShowPopup(true);
    setPopupData({
      ...popupData,
      titleHeader: "Detail View : ",
      title: row?.data?.name,
      rowData: row,
    });
  };

  const actions = [
    {
      name: `Go to ${actionName}`,
      handleAction: () => {
        if (actionName === "Trading Period") {
          navigate("../tradingPeriod", {
            state: { from: "TpBpHistory" },
          });
        } else {
          navigate("../balancePeriod", {
            state: { from: "TpBpHistory" },
          });
        }
      },
      style: "popupBtn border p-3 flex-auto mr-1",
    },
    {
      name: "Print",
      handleAction: () => {
        const branchAddressClean = removeEmptyLinesFromAddress(branchDetails.branch_address);
        setShowPopup(false);
        receiptPrinterService.printReceipt({
          template: TpBpHistoryTemplate,
          context: {
            title: "BALANCE / TRADING PERIOD HISTORY",
            fadcode: branchDetails.fadcode,
            branchName: branchDetails.branch_name,
            addressLine1: branchAddressClean[0],
            addressLine2: branchAddressClean[1],
            addressLine3: branchAddressClean[2],
            postcode: branchAddressClean[branchAddressClean.length - 1],
            currentDate: currentDateDDMMYYYY(),
            currentTime: currentTime(),
            createdByUserName: TpBpRowSelected?.data.createdByUserName,
            createdBySmartID: TpBpRowSelected?.data.createdBySmartID,
            dateTimeSystem: newTransferDateTime(TpBpRowSelected?.data.dateTime),
            totalVariance: amountPound(TpBpRowSelected?.data.cash.totalVariance),
          },
        });
      },
      style: "popupBtn border p-3 bg-red-600 text-white flex-auto ml-1",
    },
  ];
  return (
    <>
      <>
        {isLoading && <Loader />}
        {showPopup && (
          <Popup
            titleHeader={popupData.titleHeader}
            title={popupData.title}
            body={<TpBpDetailView data={popupData.rowData} />}
            actions={actions}
            onClose={() => {
              setShowPopup(false);
            }}
          />
        )}
        {/* {printData && (
          <ReceiptPrint
            title={"BALANCE / TRADING PERIOD HISTORY"}
            data={printData}
            onPrintClose={() => {
              navigate("../BalanceTradingPeriodHistory", {
                replace: true,
                state: { showLimit: false },
              });
              setPrintData(null);
            }}
          />
        )} */}
        <div className="w-full transferOut-height">
          <div className="container-margin lg:w-full h-full relative dynamictable">
            <TableView
              columns={TP_BP_HISTORY_COLUMN}
              onRowClicked={(row) => handleRowClick(row)}
              rows={branchBalancingHistory}
              showResetFilter
            />
          </div>
        </div>
      </>
    </>
  );
};

export default TpBpHistory;
