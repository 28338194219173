import { CURRENCY_ITEM_ID } from "@bbo/constants/currencyItemID";
import { useGlobalState } from "@bbo/lib/state";
import { amountUnSignedPound } from "@bbo/utils/currencyFormatter";
import { getOnlyDate, getTime } from "@bbo/utils/dateTimeFormatter";
import { formattedQuantity } from "@bbo/utils/util";
import React, { useEffect, useState } from "react";
import UserAvatar from "../../assets/images/downarrow.png";
import dropUP from "../../assets/images/dropUP.svg";

interface Iprops {
  data: any;
  onPrintConfirmation?: () => void;
  onPrintSummary?: () => void;
}

const PouchPopupModel = (props: Iprops) => {
  const [PouchModel, setPouchModel] = useGlobalState("PouchModel");
  const [isActive, setActive] = useState(false);
  const [viewActive, setviewActive] = useState(false);

  useEffect(() => {
    setPouchModel({ PouchModel: props.data.showModal });
  }, [props.data]);

  const toggleTable = () => {
    setActive(!isActive);
  };
  const toggleView = () => {
    setviewActive(!viewActive);
  };

  return (
    <>
      {PouchModel.PouchModel && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-2 mx-auto max-w-xl">
              <div className="border-0 rounded-lg shadow-lg relative height-39 overflow-y-auto flex flex-col w-full bg-white outline-none focus:outline-none p-4	">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-bold text-color">
                    Pouch Preparation History - Details
                  </h3>
                  <button
                    className="ml-auto border-0 text-black opacity-1 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setPouchModel({ PouchModel: false })}>
                    <span className="text-black opacity-1 w-6 text-3xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="flex items-center justify-center p-4  rounded-b">
                  {props.data.showModal && (
                    <table className="w-full movementTable">
                      <tbody className="bg-white divide-y divide-gray-200">
                        <tr>
                          <td>
                            <b>Pouch Content Details</b>
                          </td>
                          <td>
                            <div className="flex justify-between">
                              <span>{props.data.data.operationType}</span>{" "}
                              <button
                                className="ml-2 flex colorBlue items-center"
                                onClick={toggleTable}>
                                Break Down{" "}
                                <img
                                  className={`h-4 w-4 ${
                                    isActive
                                      ? "transform rotate-180 transition duration-150 ease-in-out"
                                      : ""
                                  }`}
                                  src={UserAvatar}
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                        {isActive && (
                          <tr>
                            <td colSpan={2}>
                              <table className="breakdownTable">
                                <thead>
                                  <tr>
                                    <th>
                                      <b>Denomination</b>
                                    </th>
                                    <th></th>
                                    {props.data.data?.pouchBagType?.includes("Bag") ? (
                                      props.data.data?.pouchBagType?.includes("Part Bag") ? (
                                        <>
                                          <th>
                                            <b>No. of bags</b>
                                          </th>
                                          <th>
                                            <b>Loose Coin</b>
                                          </th>
                                        </>
                                      ) : (
                                        <>
                                          <th>
                                            <b>No. of bags</b>
                                          </th>{" "}
                                          <th></th>
                                        </>
                                      )
                                    ) : (
                                      <th>
                                        <b>Quantity</b>
                                      </th>
                                    )}
                                    <th></th>
                                    <th>
                                      <b>Value</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {props.data.data.denominationLadder?.map((data, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{CURRENCY_ITEM_ID[data.itemID]}</td>
                                        <td>X</td>
                                        {props.data.data?.pouchBagType?.includes("Bag") ? (
                                          props.data.data?.pouchBagType?.includes("Part Bag") ? (
                                            <>
                                              <td>{formattedQuantity(data.quantity)}</td>
                                              <td>{data.looseCoin}</td>
                                            </>
                                          ) : (
                                            <>
                                              <td>{formattedQuantity(data.quantity)}</td>
                                              <th></th>
                                            </>
                                          )
                                        ) : (
                                          <>
                                            <td>
                                              {data.looseCoin !== " " && data.quantity === 0
                                                ? data.looseCoin
                                                : formattedQuantity(data.quantity)}
                                            </td>
                                          </>
                                        )}
                                        <td>=</td>
                                        <td>
                                          {data.value !== 0 && data.valueInPence === 0
                                            ? amountUnSignedPound(data.value)
                                            : amountUnSignedPound(data.valueInPence)}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>
                            <b>Pouch ID</b>
                          </td>
                          <td>{props.data.data.pouchID}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Pouch Type</b>
                          </td>
                          <td>{props.data.data.pouchTypeName}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Prepared by User Name</b>
                          </td>
                          <td>{props.data.data.preparedByUserID}</td>
                        </tr>
                        <tr>
                          <td>
                            <b> Prepared by Smart ID</b>
                          </td>
                          <td>{props.data.data.preparedBySmartID}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Date</b>
                          </td>
                          <td>{getOnlyDate(props.data.data.date)}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Time</b>
                          </td>
                          <td>{getTime(props.data.data.date)}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Value</b>
                          </td>
                          <td>{amountUnSignedPound(props.data.data.totalPouchValue)}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Status</b>
                          </td>
                          <td>{props.data.data.status}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Edit History Details </b>
                          </td>
                          <td>
                            <div className="flex justify-between">
                              <button
                                className="ml-2 flex colorBlue items-center"
                                onClick={toggleView}>
                                View Details{" "}
                                <img
                                  className={`h-4 w-4 ${
                                    viewActive
                                      ? "transform rotate-180 transition duration-150 ease-in-out"
                                      : ""
                                  }`}
                                  src={dropUP}
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                        {viewActive && (
                          <>
                            {props.data.data.pouchEditDetails?.map((editData, index) => {
                              return (
                                editData.preparedByUserID && (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td>
                                        <b>Edit by User Name</b>
                                      </td>{" "}
                                      <td>{editData.preparedByUserID}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Edited by Smart ID</b>
                                      </td>{" "}
                                      <td>{editData.preparedBySmartID}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Date</b>
                                      </td>
                                      <td>{getOnlyDate(editData.dateOfPreparation)}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Time</b>
                                      </td>{" "}
                                      <td>{getTime(editData.dateOfPreparation)}</td>
                                    </tr>
                                    <tr>
                                      <td colSpan={2}>
                                        <table className="breakdownTable">
                                          <thead>
                                            <tr>
                                              <th>
                                                <b>Denomination</b>
                                              </th>
                                              <th></th>
                                              {props.data.data?.pouchBagType?.includes("Bag") ? (
                                                props.data.data?.pouchBagType?.includes(
                                                  "Part Bag",
                                                ) ? (
                                                  <>
                                                    <th>
                                                      <b>No. of bags</b>
                                                    </th>
                                                    <th>
                                                      <b>Loose Coin</b>
                                                    </th>
                                                  </>
                                                ) : (
                                                  <>
                                                    <th>
                                                      <b>No. of bags</b>
                                                    </th>{" "}
                                                    <th></th>
                                                  </>
                                                )
                                              ) : (
                                                <th>
                                                  <b>Quantity</b>
                                                </th>
                                              )}
                                              <th></th>
                                              <th>
                                                <b>Value</b>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {editData.denominationLadder?.map((data, position) => {
                                              return (
                                                <tr key={position}>
                                                  <td>{CURRENCY_ITEM_ID[data.itemID]}</td>
                                                  <td>X</td>
                                                  {props.data.data?.pouchBagType?.includes(
                                                    "Bag",
                                                  ) ? (
                                                    props.data.data?.pouchBagType?.includes(
                                                      "Part Bag",
                                                    ) ? (
                                                      <>
                                                        <td>{formattedQuantity(data.quantity)}</td>
                                                        <td>{data.looseCoin}</td>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <td>{formattedQuantity(data.quantity)}</td>
                                                        <th></th>
                                                      </>
                                                    )
                                                  ) : (
                                                    <>
                                                      <td>
                                                        {data.looseCoin !== " " &&
                                                        data.quantity === 0
                                                          ? data.looseCoin
                                                          : formattedQuantity(data.quantity)}
                                                      </td>
                                                    </>
                                                  )}
                                                  <td>=</td>
                                                  <td>
                                                    {data.value !== 0 && data.valueInPence === 0
                                                      ? amountUnSignedPound(data.value)
                                                      : amountUnSignedPound(data.valueInPence)}
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )
                              );
                            })}
                          </>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
                <div className="flex justify-center pt-2">
                  <button className="accounting-button mr-1" onClick={props.onPrintSummary}>
                    Print Summary
                  </button>
                  <button className="accounting-button ml-1" onClick={props.onPrintConfirmation}>
                    Print Confirmation
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
};

export default PouchPopupModel;
