import { useState } from "react";

import { useGlobalState } from "@bbo/lib/state";
import "@bbo/screens/Index.css";

import { amountInPound } from "@bbo/utils/currencyFormatter";
import { CoinItem } from "../../types";
import { PouchLadder } from "../PouchLadder";
import { COINS_DETAIL, NOTES_DETAIL } from "../PouchLadder/PouchConstants";

export const PreparedPouch = () => {
  const [adhocbalance] = useGlobalState("adhocbalance");
  const [pouchPopUp, setPouchPopUp] = useState<boolean>(false);
  const [notesdetail, setnotesdetail] = useState([...NOTES_DETAIL.map((item) => ({ ...item }))]);
  const [coindetail, setcoindetail] = useState([...COINS_DETAIL.map((item) => ({ ...item }))]);
  const [cashDetailPopUp, setcashDetailPopUp] = useState<CoinItem[]>();
  const [looseBag, setlooseBag] = useState<boolean>(false);
  const [looseCoin, setlooseCoin] = useState<boolean>(false);

  const handlePouchInfo = (element) => {
    let updateValue = [];
    if (element.pouchBagType === "Non Useable Coins") {
      setlooseCoin(false);
      updateValue = [...coindetail];
    } else if (element.pouchBagType === "Non Useable Notes") {
      setlooseCoin(false);
      updateValue = [...notesdetail];
    } else if (element.pouchBagType === "Useable Notes") {
      setlooseCoin(false);
      updateValue = [...notesdetail];
    } else if (element.pouchBagType === "Useable Coins Full Bag") {
      setlooseCoin(false);
      setlooseBag(true);
      updateValue = [...coindetail];
    } else if (element.pouchBagType === "Useable Coins Part Bag") {
      setlooseBag(true);
      setlooseCoin(true);
      updateValue = [...coindetail];
    }
    element.denominationLadder?.map((item) => {
      updateValue[item.index].value = amountInPound(item?.valueInPence);
      updateValue[item.index].Quantity = item?.quantity || item?.bag;
      updateValue[item.index].looseCoin = item?.looseCoin;
      updateValue[item.index].alertCss = "normalCss";
      updateValue[item.index].QalertCss = "normalCss";
      updateValue[item.index].errorMsg = "";
      updateValue[item.index].QerrorMsg = "";
      updateValue[item.index].btnDisable = true;
      updateValue[item.index].itemID = item.itemID;
    });
    setcashDetailPopUp([...updateValue]);
    setPouchPopUp(true);
  };
  return (
    <>
      {/* <div className="flex justify-between width-97"><h2 className="heading-2 font-bold">Prepared Pouches</h2> <h2 className="heading">3 of {adhocbalance?.prepared_pouches?.length}</h2></div>  */}
      <h2 className="heading-2 font-bold pl-1">Prepared Pouches</h2>
      <div className="safeBalancePreparedHeight overflow-y-auto grid grid-cols-1 gap-4 mb-8">
        {adhocbalance?.preparedPouches?.map((element, index) => {
          return (
            <button
              key={index}
              className={`transfer-out-tiles heading font-bold  ${
                adhocbalance?.preparedPouches.length > 2 ? "w-full " : "width-97"
              }`}
              onClick={() => handlePouchInfo(element)}>
              <div className="flex justify-between w-full">
                <div>
                  <div className="float-left w-full text-left heading-3 font-bold">
                    Pouch Type : {element.pouchTypeName}
                  </div>
                  <div className="float-left w-full text-left heading text-color font-normal">
                    Pouch ID : {element.pouchID}
                  </div>
                </div>
                <div className="flex items-center heading-3 font-bold">
                  £{amountInPound(element.totalPouchValue)}
                </div>
              </div>
            </button>
          );
        })}
      </div>
      {adhocbalance?.suspendedPouches?.length > 0 && (
        <>
          {" "}
          <h2 className="heading-2 font-bold">Suspended Pouches</h2>
          {/* <div className="flex justify-between width-97"><h2 className="heading-2 font-bold">Suspended Pouches</h2> <h2 className="heading">2 of {adhocData?.suspended_pouches?.length}</h2></div>   */}
          <div className="safeBalanceSuspendedHeight overflow-y-auto grid grid-cols-1 gap-4">
            {adhocbalance?.suspendedPouches?.map((element, index) => {
              return (
                <button
                  key={index}
                  className={`transfer-out-tiles heading font-bold  ${
                    adhocbalance?.suspendedPouches.length > 1 ? "w-full " : "width-97"
                  }`}
                  onClick={() => handlePouchInfo(element)}>
                  <div className="flex justify-between w-full">
                    <div>
                      <div className="float-left w-full text-left heading-3 font-bold">
                        Pouch Type : {element.pouchTypeName}
                      </div>
                      <div className="float-left w-full text-left heading text-color font-normal">
                        Pouch ID : {element.pouchID}
                      </div>
                    </div>
                    <div className="flex items-center heading-3 font-bold">
                      £{amountInPound(element.totalPouchValue)}
                    </div>
                  </div>
                </button>
              );
            })}
          </div>
        </>
      )}
      {pouchPopUp && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-2 mx-auto max-w-3xl">
              <div className="border-0 shadow-lg relative overflow-y-auto flex flex-col w-full bg-white outline-none focus:outline-none p-4	">
                <div className="flex flex-col p-4  rounded-b">
                  <PouchLadder
                    heading="Pouch Type - Pouch ID"
                    cashdetail={cashDetailPopUp}
                    looseBag={looseBag}
                    looseCoin={looseCoin}
                    showCancelButton={false}
                    showCompleteButton={false}
                    showSuspendButton={false}
                    disableFlags={true}
                  />
                  <div className="flex justify-start pt-2">
                    <button
                      className="brandWhiteBtn mr-2 lg:w-1/3 font-bold"
                      onClick={() => {
                        setPouchPopUp(false);
                        setnotesdetail([...NOTES_DETAIL.map((item) => ({ ...item }))]);
                        setcoindetail([...COINS_DETAIL.map((item) => ({ ...item }))]);
                      }}>
                      Close Preview
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
};
