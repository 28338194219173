import Loader from "@bbo/components/Loader";
import { DISCREPANCY_STATUS, TRADING_PERIOD_POPUP_DATA } from "@bbo/constants";
import { CommitTpBp } from "@bbo/features/CommitTpBp";
import { useAppSelector, useGlobalState } from "@bbo/lib";
import useDiscrepancyApi from "@bbo/lib/hooks/useDiscrepancyApi";
import { inputInPence } from "@bbo/utils/currencyFormatter";
import { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createBpTpDiscrepancy } from "../../types/TransferTypes";
import CommitSuccessPopup from "../CommitTpBp/CommitSuccessPopup";
import { DetailView } from "./DetailView";
import { HighLevelView } from "./HighLevelView";
import { AXIOS_INSTANCE } from "@bbo/api/generator/mutator/useCustomInstance";
import {
  TRANSACTION_ITEM_ID,
  createDiscreapancyForUnknownVariance,
} from "@bbo/utils/transferPayload";
import { usePostTransferTransaction } from "@bbo/api/generator";

export enum tradePeriodButtonVisibilityStatus {
  COMMIT_BUTTON_VISIBILITY_STATUS_HIDE,
  COMMIT_BUTTON_VISIBILITY_STATUS_DISABLE,
  COMMIT_BUTTON_VISIBILITY_STATUS_SHOW,
}

interface tradingPeriodProps {
  isCtpButtonVisiblityInRange?: boolean;
  isTradingPeriodCompleted?: boolean;
  fromHistory?: boolean;
  selectedRow?: any;
  fromDiscrepancyAccount?: boolean;
}

export const TradingPeriodScreen = ({
  isCtpButtonVisiblityInRange,
  isTradingPeriodCompleted,
  fromHistory,
  selectedRow,
  fromDiscrepancyAccount,
}: tradingPeriodProps) => {
  const [totalCashVariance] = useGlobalState("totalCashVariance");
  const [varianceAmount, setVarianceAmount] = useState<string>();
  const [isCompleteTradingButtonEnabled, setIsCompleteTradingButtonEnabled] =
    useState<boolean>(false);
  const enableCompleteTradingPeriodButton = (val) => {
    setIsCompleteTradingButtonEnabled(val);
  };
  const [completeTradingPeriod, setCompleteTradingPeriod] = useState(false);
  const [showCommitSuccessPopup, setShowCommitSuccessPopup] = useState<boolean>(false);
  const [ctpButtonVisibilityStatus, setCtpButtonVisibilityStatus] =
    useState<tradePeriodButtonVisibilityStatus>(
      tradePeriodButtonVisibilityStatus.COMMIT_BUTTON_VISIBILITY_STATUS_DISABLE,
    );
  const [completeTradingPeriodSubmissionInProgress, setCompleteTradingPeriodSubmissionInProgress] =
    useState<boolean>(false);
  const { username, fadCode, smartid } = useAppSelector((root) => root.auth.authUserData);
  const branchDetails = useAppSelector((root) => root.branchDetails);

  const navigate = useNavigate();

  const saveDiscrepancyDetailsRequestParma = [
    {
      comment: "",
      reason: {
        code: "TP",
        id: "TP",
        title: "TP",
        subReason: {
          filterReason: {},
        },
      },
      entity: "",
      entityID: "",
      status: DISCREPANCY_STATUS.AWAITING_BRANCH_ACTION,
      varianceAmount: inputInPence(varianceAmount),
      // varianceAmount: "200",
    },
  ];
  const saveTpDiscrepnacyRequestParams = (): createBpTpDiscrepancy => {
    return {
      comment: "",
      entity: "",
      entityID: "",
      reason: {
        code: "TP",
        id: "TP",
        subReason: {
          filterReason: {},
        },
        title: "TP",
      },
      status: DISCREPANCY_STATUS.AWAITING_BRANCH_ACTION,
      varianceAmount: inputInPence(varianceAmount),
      userName: username,
      action: "Create",
      fadcode: fadCode,
      branchName: fadCode,
      userID: smartid,
      branchDetails: branchDetails,
    };
  };
  const { mutate: saveTpDiscrepancy, isSuccess } = usePostTransferTransaction({
    mutation: {
      onSuccess: () => {
        AXIOS_INSTANCE.defaults.headers.common["fadcode"] = branchDetails.fadcode;
      },
    },
  });
  const returnItemID = () => {
    if (inputInPence(varianceAmount) > 0) {
      return TRANSACTION_ITEM_ID.TP_BP_BALANCING_SURPLUS; //50832
    } else {
      return TRANSACTION_ITEM_ID.TP_BP_BALANCING_SHORTAGE; //50833
    }
  };
  useLayoutEffect(() => {
    setVarianceAmount(totalCashVariance.toString());
  }, [totalCashVariance]);

  useLayoutEffect(() => {
    if (isSuccess) {
      setCompleteTradingPeriodSubmissionInProgress(false);
      setShowCommitSuccessPopup(true);
    }
  }, [isSuccess]);

  useLayoutEffect(() => {
    if (isCompleteTradingButtonEnabled) {
      setCtpButtonVisibilityStatus(
        tradePeriodButtonVisibilityStatus.COMMIT_BUTTON_VISIBILITY_STATUS_SHOW,
      );
    } else {
      setCtpButtonVisibilityStatus(
        tradePeriodButtonVisibilityStatus.COMMIT_BUTTON_VISIBILITY_STATUS_DISABLE,
      );
    }
  }, [isCompleteTradingButtonEnabled]);

  const handleCommit = async () => {
    setCompleteTradingPeriod(false);
    setCompleteTradingPeriodSubmissionInProgress(true);
    const itemID = returnItemID();
    delete AXIOS_INSTANCE.defaults.headers.common["fadcode"];
    saveTpDiscrepancy({
      data: createDiscreapancyForUnknownVariance(saveTpDiscrepnacyRequestParams(), itemID),
    });
    await isSuccess;
  };
  const handleCommitSuccessPopup = () => {
    setShowCommitSuccessPopup(false);
    navigate("/taskManagement");
  };

  return (
    <div className="TradingPeriod">
      {completeTradingPeriodSubmissionInProgress && <Loader />}
      <HighLevelView
        enableCompleteTradingPeriodButton={enableCompleteTradingPeriodButton}
        fromHistory={fromHistory}
        fromDiscrepancyAccount={fromDiscrepancyAccount}
        selectedRow={selectedRow}
        isAllAcountingLocationsCompleted={enableCompleteTradingPeriodButton}
      />
      <DetailView
        isTradingPeriodCompleted={isTradingPeriodCompleted}
        fromHistory={fromHistory}
        fromDiscrepancyAccount={fromDiscrepancyAccount}
        selectedRow={selectedRow}
      />
      {completeTradingPeriod && (
        <CommitTpBp
          content={TRADING_PERIOD_POPUP_DATA}
          isPasswordValidation={false}
          popupType="TP"
          onCancelCommit={() => setCompleteTradingPeriod(false)}
          onCommit={handleCommit}
        />
      )}
      {!fromHistory && !fromDiscrepancyAccount && (
        <div className="flex w-full justify-end mb-16 px-10">
          {ctpButtonVisibilityStatus ===
            tradePeriodButtonVisibilityStatus.COMMIT_BUTTON_VISIBILITY_STATUS_SHOW && (
            <button
              disabled={!isCompleteTradingButtonEnabled && !isTradingPeriodCompleted}
              className={`completeButton ml-2 ${
                isCompleteTradingButtonEnabled && !isTradingPeriodCompleted
                  ? "enable-btn text-white"
                  : "disable-btn opacity-70 cursor-not-allowed"
              }`}
              onClick={() => setCompleteTradingPeriod(true)}>
              Complete Trading Period
            </button>
          )}
          {ctpButtonVisibilityStatus ===
            tradePeriodButtonVisibilityStatus.COMMIT_BUTTON_VISIBILITY_STATUS_DISABLE && (
            <button
              disabled
              className="completeButton ml-2 text-white disable-btn opacity-70 cursor-not-allowed">
              Complete Trading Period
            </button>
          )}
        </div>
      )}
      {showCommitSuccessPopup && (
        <CommitSuccessPopup title="Trading Period Committed" onSuccess={handleCommitSuccessPopup} />
      )}
    </div>
  );
};
