import Loader from "@bbo/components/Loader";
import Pagination from "@bbo/components/table/pagination";
import { PAGINATION_SIZE } from "@bbo/components/table/tableConstants";
import { BurglaryRobbery } from "@bbo/features/CurrencyCount/BurglaryRobbery";
import { InputSelect } from "@bbo/features/CurrencyCount/InputSelect";
import { SearchedCurrencyTile } from "@bbo/features/CurrencyCount/SearchedCurrencyTile";
import { UncountedCurrencyPopup } from "@bbo/features/CurrencyCount/UncountedCurrencyPopup";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { BottomNavigation, Box, Grid, Paper } from "@material-ui/core";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BurglaryRobberyCompleteButton } from "./BurglaryRobberyCompleteButton";
import "./currencyCountScreen.css";
import GenerateCountTypes from "./GenerateCountTypes";
import GenerateFullCurrencies from "./GenerateFullCurrencies";
import GenerateTopOnHandCurrencies from "./GenerateTopOnHandCurrencies";
import { useCompletedCurrencyCountCheck } from "./useCompletedCurrencyCountCheck";
import { useCurrencyCount } from "./useCurrencyCount";

export interface ICurrencyData {
  ordercode: string;
  name: string;
  balance?: number;
}
export interface ICurrency {
  currencys?: ICurrencyData[];
  name?: string;
}
export const CurrencyCountScreen = () => {
  const {
    stage,
    selected,
    handleChange,
    handleBackPressed,
    data,
    isLoading,
    onHandCurrencies,
    fullRange,
    allCurrencies,
    setFullCountValue,
    setOnHandCountValue,
    onHandCount,
    fullRangeCount,
    totalPageCount,
    fnOpenDenominationLadder,
    onPaginationClick,
    currentPage,
  } = useCurrencyCount();

  const { countedCurrencies } = useAppSelector((root) => root.countedCurrencies);
  const [searchedForCurrency, setSearchedForCurrency] = useState<null | ICurrencyData>(null);
  const [uncountedCurrencyWarningPopup, setUncountedCurrencyWarningPopup] = useState(false);
  const navigate = useNavigate();
  const { completedCurrencyCountCheck } = useCompletedCurrencyCountCheck();

  const { currencys = null, name } = useMemo(
    () => ({ ...selected, currencys: selected.currencys }),
    [selected],
  );
  const currentRows = useMemo(
    () => allCurrencies?.slice((currentPage - 1) * 15, currentPage * 15),
    [currentPage, allCurrencies],
  );
  const onHandCurr = currentRows?.filter(
    (item) => item?.balance !== 0 || item?.ordercode === "EUR" || item?.ordercode === "USD",
  );
  const fullCurr = currentRows?.filter(
    (item) => item?.balance === 0 && item?.ordercode !== "EUR" && item?.ordercode !== "USD",
  );

  if (isLoading) {
    return <Loader data-testid="loader" />;
  }

  const handleCompleteCount = () => {
    if (completedCurrencyCountCheck(selected, countedCurrencies)) {
      navigate("/currencyCountReview", {
        state: { iSelected: selected },
      });
    } else {
      setUncountedCurrencyWarningPopup(true);
    }
  };
  return (
    <Grid container md={12} spacing={2} style={{ margin: "auto" }} data-testid="grid-container">
      <Grid item md={3} className="left-navigation">
        <Grid item md={12} className="div-button">
          <i className="fa fa-angle-left fa-lg back-icon" aria-hidden="true"></i>
          <button
            className="button-count ml-2 text-2xl font-bold"
            onClick={handleBackPressed}
            data-testid="back-button">
            Back
          </button>
        </Grid>
        <h3 className="header-h3">
          {`${!selected.id ? "Select Currency Count" : "Currency Count In-Progress"} `}
        </h3>
        {Object.keys(selected).length !== 0 ? (
          <Box className="nav-selected">{name}</Box>
        ) : (
          <GenerateCountTypes data={data || null} handleChange={handleChange} />
        )}
      </Grid>
      {stage === "tiles" && (
        <Grid item md={8} className="right-div">
          {/* renders all the currency tiles */}
          {currentRows?.length > 0 && (
            <Grid container md={12} spacing={2}>
              <Grid item md={12} key={`inputSelect`}>
                {selected?.name !== "Euros & Dollars" && (
                  <InputSelect
                    fullCurrencyList={data && data.currencyCount[1]}
                    setSearchedForCurrency={setSearchedForCurrency}
                  />
                )}
              </Grid>
              <Grid container md={12} spacing={2}>
                {searchedForCurrency ? (
                  <SearchedCurrencyTile
                    fullRange={fullRange}
                    searchedForCurrency={searchedForCurrency}
                    fnOpenDenominationLadder={fnOpenDenominationLadder}
                    countedCurrencies={countedCurrencies}
                  />
                ) : (
                  <>
                    <GenerateTopOnHandCurrencies
                      onHandCurr={onHandCurr}
                      currentPage={currentPage}
                      onHandCount={onHandCount}
                      fnOpenDenominationLadder={fnOpenDenominationLadder}
                      onHandCurrencies={onHandCurrencies}
                      setOnHandCountValue={setOnHandCountValue}
                    />
                    <GenerateFullCurrencies
                      fullCurr={fullCurr}
                      currentPage={currentPage}
                      fullRangeCount={fullRangeCount}
                      fnOpenDenominationLadder={fnOpenDenominationLadder}
                      fullRange={fullRange}
                      setFullCountValue={setFullCountValue}
                      searchedForCurrency={searchedForCurrency}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          )}
          {selected.id === "BR" && <BurglaryRobbery />}
          {/* renders pagination */}
          {currencys && (
            <Box>
              <BottomNavigation className="box-page">
                <Pagination
                  className="pagination-bar flex justify-center"
                  currentPage={currentPage}
                  totalCount={totalPageCount}
                  pageSize={PAGINATION_SIZE}
                  onPageChange={onPaginationClick}
                />
              </BottomNavigation>
            </Box>
          )}
        </Grid>
      )}
      {/* Default footer right at the bottom of the page for non-burglary robbery */}
      {stage === "tiles" && selected.id !== "BR" && (
        <Paper component="footer" square variant="outlined">
          <div className="butn">
            <button className="button1">Suspend</button>
            <button className="button2">Cancel</button>
            {countedCurrencies.length > 0 && (
              <button className="button3" onClick={() => handleCompleteCount()}>
                Complete
              </button>
            )}
          </div>
        </Paper>
      )}

      {/* Renders only a complete button specifically for burglary and robbery */}
      {selected.id === "BR" && <BurglaryRobberyCompleteButton burglaryState={false} />}

      {/* Uncounted currencies pop up warning  */}
      {uncountedCurrencyWarningPopup && (
        <UncountedCurrencyPopup
          fnOpenDenominationLadder={fnOpenDenominationLadder}
          selected={selected}
        />
      )}
    </Grid>
  );
};
