/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

PO Transactions

PO Transactions Viewer

Cash Drawer Association API
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, UseMutationOptions, MutationFunction } from "@tanstack/react-query";
import type { Response, TransferError, TransferCore } from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary This endpoint is used to create transfer transaction
 */
export const usePostTransferTransactionHook = () => {
  const postTransferTransaction = useCustomInstance<Response>();

  return (transferCore: TransferCore) => {
    return postTransferTransaction({
      url: `/transfer/v1/transaction`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: transferCore,
    });
  };
};

export type PostTransferTransactionMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof usePostTransferTransactionHook>>
>;
export type PostTransferTransactionMutationBody = TransferCore;
export type PostTransferTransactionMutationError = ErrorType<TransferError | void>;

export const usePostTransferTransaction = <
  TError = ErrorType<TransferError | void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof usePostTransferTransactionHook>>,
    TError,
    { data: TransferCore },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const postTransferTransaction = usePostTransferTransactionHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof usePostTransferTransactionHook>>,
    { data: TransferCore }
  > = (props) => {
    const { data } = props || {};

    return postTransferTransaction(data);
  };

  return useMutation<
    AsyncReturnType<typeof postTransferTransaction>,
    TError,
    { data: TransferCore },
    TContext
  >(mutationFn, mutationOptions);
};
