import AdhocCashBalancing from "@bbo/screens/AdhocCashBalancing";
import CashBalanceHistory from "@bbo/screens/CashBalanceHistory";
import CashMovementHistory from "@bbo/screens/CashMovementHistory";
import Login from "@bbo/screens/Login";
import PageNotFound from "@bbo/screens/PageNotFound";
import PaidInOut from "@bbo/screens/PaidInOut";
import PouchPreparation from "@bbo/screens/PouchPreparation";
import PouchPreparationHistory from "@bbo/screens/PouchPreparationHistory";
import TaskManagement from "@bbo/screens/TaskManagement";
import TransferIn from "@bbo/screens/TransferIn";
import TransferOut from "@bbo/screens/Transferout";
import { Navigate, Route, Routes, useLocation, useSearchParams } from "react-router-dom";

import { AuthRoutes } from "@bbo/features";
import { useAWSConfig, useHubListener } from "@bbo/lib/hooks";
import BalancePeriod from "@bbo/screens/BalancePeriod";
import BranchSummary from "@bbo/screens/BranchSummary";
import DiscrepancyAccountPage from "@bbo/screens/DiscrepancyAccountPage";
import MailDespatch from "@bbo/screens/MailDespatch";
import ManageCashDrawers from "@bbo/screens/ManageCashDrawer";
import RecordKnownPage from "@bbo/screens/RecordKnownVariance";
import BranchOverviewStockScreen from "@bbo/screens/StockBranchOverview";
import StockCheck from "@bbo/screens/StockCheckScreen";
import StockCount from "@bbo/screens/StockCount";
import StockCountReviewScreen from "@bbo/screens/StockCountReview";
import TpBpHistory from "@bbo/screens/TpBpHistory";
import TradingPeriod from "@bbo/screens/TradingPeriod";
import { Auth } from "aws-amplify";
import BookingDetailsScreen from "./screens/BookingDetails";
import BranchSelectionPage from "./screens/BranchSelectionPage";
import CurrencyCount from "./screens/CurrencyCount";
import CurrencyCountReview from "./screens/CurrencyCountReview";
import CurrencyCountSummary from "./screens/CurrencyCountSummary";
import ForexDenominationLadder from "./screens/DenominationLadder";
import ForexOverview from "./screens/ForexBranchOverview";
import SalesSummaryScreen from "./screens/SalesSummary";
import TransactionLog from "./screens/TransactionLog";
import { setValueInStorage } from "./utils/util";

const App = () => {
  const { isLoggedIn } = useHubListener();
  const { authConfig, loadingConfig } = useAWSConfig();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const fadcodeInURL = searchParams.get("fadcode");

  const navigatedFromCT = searchParams.get("navigatedFromCT");
  if (navigatedFromCT) {
    setValueInStorage("navigatedFromCT", navigatedFromCT);
  }
  if (fadcodeInURL) {
    setValueInStorage("fadcode", fadcodeInURL);
  }
  if (loadingConfig) {
    return null;
  }

  Auth.configure(authConfig);

  return (
    <div data-testid="app" className="flex h-screen overflow-hidden">
      <div
        className={
          location.pathname === "/branchSelection"
            ? "relative flex flex-1 overflow-y-auto containerTop loginBackground"
            : `relative flex flex-col flex-1 overflow-y-auto containerTop ${
                isLoggedIn ? "marginTop10" : "loginBackground"
              }`
        }>
        <Routes>
          <Route element={<AuthRoutes />}>
            <Route path="/taskManagement" element={<TaskManagement />} />
            <Route path="/branchSelection" element={<BranchSelectionPage />} />
            <Route path="/PaidIn" element={<PaidInOut />} />
            <Route path="/PaidOut" element={<PaidInOut />} />
            <Route path="/transferIn" element={<TransferIn />} />
            <Route path="/home" element={<AdhocCashBalancing />} />
            <Route path="/transferOut" element={<TransferOut />} />
            <Route path="/branchOverview" element={<BranchSummary />} />
            <Route path="/cashBalance" element={<AdhocCashBalancing />} />
            <Route path="/pouchPreperation" element={<PouchPreparation />} />
            <Route path="/manageCashDrawer" element={<ManageCashDrawers />} />
            <Route path="/RecordKnownVariance" element={<RecordKnownPage />} />
            <Route path="/cashBalancesHistory" element={<CashBalanceHistory />} />
            <Route path="/balancePeriod" element={<BalancePeriod />} />
            <Route path="/tradingPeriod" element={<TradingPeriod />} />
            <Route path="/cashMovementHistory" element={<CashMovementHistory />} />
            <Route path="/discrepancyAccount" element={<DiscrepancyAccountPage />} />
            <Route path="/mailDespatch/:branchId/:nodeId" element={<MailDespatch />} />
            <Route path="/pouchPreparationHistory" element={<PouchPreparationHistory />} />
            <Route path="/stockCheck" element={<StockCheck />} />
            <Route path="/BalanceTradingPeriodHistory" element={<TpBpHistory />} />
            <Route path="/stockCount" element={<StockCount />} />
            <Route path="/branchOverviewStock" element={<BranchOverviewStockScreen />} />
            <Route path="/StockCountReview" element={<StockCountReviewScreen />} />
            <Route path="/branchOverviewForex" element={<ForexOverview />} />
            <Route path="/currencyCountSummary" element={<CurrencyCountSummary />} />
            <Route path="/TransactionLog" element={<TransactionLog />} />
            <Route path="/currencyCount" element={<CurrencyCount />} />
            <Route path="/currencyCountDenominationLadder" element={<ForexDenominationLadder />} />
            <Route path="/manualBookInStock" element={<BookingDetailsScreen />} />
            <Route path="/currencyCountReview" element={<CurrencyCountReview />} />
            <Route path="/salesSummary" element={<SalesSummaryScreen />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/" element={<Navigate to="/taskManagement" replace />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
