import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { All } from "./All";
import { AwaitingBranchAction } from "./AwaitingBranchAction";
import { AwaitingCentralBranch } from "./AwaitingCentralBranch";
import {
  ALL,
  AWAITING_BRANCH_ACTION,
  AWAITING_CENTRAL_SUPPORT,
  HISTORY,
  SETTLED,
  SUMMARY,
} from "./constant";
import { History } from "./History";
import { Settled } from "./Settled";
import { Summary } from "./Summary";

type Props = {
  selectedTab: any;
  rowLength?: number;
};

export const DiscrepancyOverview = ({ selectedTab }: Props) => {
  const discrepancyRecord = useAppSelector((root) => root.DiscrepancyAccount);

  const tabLabels = [
    { value: ALL, valueLength: discrepancyRecord.history.length || 0 },
    {
      value: AWAITING_BRANCH_ACTION,
      valueLength: discrepancyRecord.waitingBranchAction.length || 0,
    },
    {
      value: AWAITING_CENTRAL_SUPPORT,
      valueLength: discrepancyRecord.centralSupport.length || 0,
    },
    { value: SETTLED, valueLength: discrepancyRecord.settled.length || 0 },
  ];
  switch (selectedTab) {
    case ALL:
      return <All rows={discrepancyRecord.history || []} />;

    case AWAITING_BRANCH_ACTION:
      return (
        <AwaitingBranchAction
          rows={discrepancyRecord.waitingBranchAction || []}
          hideSettleButton={false}
        />
      );

    case AWAITING_CENTRAL_SUPPORT:
      return (
        <AwaitingCentralBranch
          rows={discrepancyRecord.centralSupport || []}
          hideSettleButton={false}
        />
      );

    case SETTLED:
      return <Settled rows={discrepancyRecord.settled} hideSettleButton={true} />;

    case HISTORY:
      return <History rows={discrepancyRecord.history} />;

    case SUMMARY:
      return <Summary labels={tabLabels} />;

    default:
      return null;
  }
};
