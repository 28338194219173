import {
  usePostCashTransfer,
  useGetDiscrepancyData,
  usePutDiscrepancyData,
  usePostTransferTransaction,
} from "@bbo/api/generator";
import { AXIOS_INSTANCE } from "@bbo/api/generator/mutator/useCustomInstance";
import { useAppDispatch, useAppSelector } from "@bbo/lib";
import { setDiscrepancyAccountDetails } from "@bbo/lib/reduxStore/discrepancyAccount.slice";
import { discrepancyMovementSettleToCash, updateDiscrepancyStatus } from "@bbo/types/TransferTypes";
import {
  discrepancyMovementSettleToCashParams,
  updateDiscrepancyParams,
  TRANSACTION_MODE,
  TRANSACTION_ITEM_ID,
  updateTCParamsForIn,
} from "@bbo/utils/transferPayload";
import { useEffect } from "react";
import { settleTCToCash, updateTCParams } from "../../utils/transferPayload";
import { getUUID } from "@bbo/utils/util";
import { BMK, CDF, CPA, CPD, DMB, IN, SETTLED, SP } from "./constant";

export const useDiscrepancyAccount = () => {
  const {
    data: DiscrepancyResponse,
    status: discrepancyStatus,
    refetch,
  } = useGetDiscrepancyData({ query: { queryKey: [getUUID] } });
  const { username, fadCode, smartid } = useAppSelector((root) => root.auth.authUserData);
  const dispatch = useAppDispatch();
  const branchDetails = useAppSelector((root) => root.branchDetails);

  useEffect(() => {
    dispatch(setDiscrepancyAccountDetails(DiscrepancyResponse));
  }, [DiscrepancyResponse, dispatch]);

  const isStatus = discrepancyStatus !== "success";

  const settleToCashDiscrepancyRequestParams = (data): discrepancyMovementSettleToCash => {
    return {
      discrepencyUpdateDetails: [data],
      ...data,
      userID: smartid,
      userName: username,
      fadCode: fadCode,
      branchName: fadCode,
      branchDetails: branchDetails,
      action: "Update",
    };
  };

  const updateDiscrepancyRequestParams = (data): updateDiscrepancyStatus => {
    return {
      discrepencyUpdateDetails: [data],
      userID: smartid,
      userName: username,
      fadCode: fadCode,
      branchName: fadCode,
      action: "Update",
      branchDetails: branchDetails,
    };
  };

  const {
    mutate: PutDiscrepancyApi,
    data: PutDiscrepancyData,
    status: DiscrepancyStatus,
  } = usePutDiscrepancyData({
    mutation: {
      onSuccess: () => {
        setTimeout(() => {
          refetch();
        }, 1000);
      },
    },
  });
  const { mutate: saveCashDetails } = usePostCashTransfer();
  const { mutate: settleToCashDiscrepancy } = usePostTransferTransaction();

  const { mutate: updateDiscrepancyStatus } = usePostTransferTransaction({
    mutation: {
      onSuccess: () => {
        AXIOS_INSTANCE.defaults.headers.common["fadcode"] = branchDetails.fadcode;
        setTimeout(() => {
          refetch();
        }, 1000);
      },
    },
  });

  const isDiscrepancyStatus = DiscrepancyStatus || "";

  const handleTCSettleToCash = (data) => {
    let transactionMode = 0;
    let itemID = "";
    let itemIDIn = " ";
    if (data.varianceBalance < 0) {
      transactionMode = TRANSACTION_MODE.TC_SETTLE_TO_CASH; //29
      itemID = TRANSACTION_ITEM_ID.TC_SETTLE; //9574
      itemIDIn = TRANSACTION_ITEM_ID.CASH; //1
    } else {
      transactionMode = TRANSACTION_MODE.TC_SETTLE_TO_CASH; //29
      itemID = TRANSACTION_ITEM_ID.TC_SURPLUS_SETTLEMENT; //8570
      itemIDIn = TRANSACTION_ITEM_ID.CASH; //1
    }
    if (data.status === SETTLED) {
      delete AXIOS_INSTANCE.defaults.headers.common["fadcode"];
      settleToCashDiscrepancy({
        data: settleTCToCash(
          settleToCashDiscrepancyRequestParams(data),
          transactionMode,
          itemID,
          itemIDIn,
        ),
      });
    } else {
      PutDiscrepancyApi({ data: data });
    }
  };

  const handleTCForSPBranch = (data) => {
    let transactionMode = 0;
    let itemID = "";
    let itemIDIn = " ";

    if (data.varianceBalance < 0) {
      transactionMode = TRANSACTION_MODE.TC_SETTLE_TO_NOMINEE; //32
      itemID = TRANSACTION_ITEM_ID.TC_SETTLE; //9574
      itemIDIn = TRANSACTION_ITEM_ID.POUCH_DESPATCH_SHORTAGE; //50818
    } else {
      transactionMode = TRANSACTION_MODE.TC_SETTLE_TO_NOMINEE; //32
      itemID = TRANSACTION_ITEM_ID.TC_SURPLUS_SETTLEMENT; //8570
      itemIDIn = TRANSACTION_ITEM_ID.MULTIPLE_SETTLED; //50917
    }
    if (data.status === SETTLED) {
      delete AXIOS_INSTANCE.defaults.headers.common["fadcode"];
      updateDiscrepancyStatus({
        data: updateTCParamsForIn(
          updateDiscrepancyRequestParams(data),
          transactionMode,
          itemID,
          itemIDIn,
        ),
      });
    } else {
      PutDiscrepancyApi({ data: data });
    }
  };
  const hanldeTCForDMB = (data) => {
    let transactionMode = 0;
    let itemID = "";
    let itemIDIn = " ";
    switch (data.itemID) {
      case TRANSACTION_ITEM_ID.TC_SETTLE:
        transactionMode = TRANSACTION_MODE.TC_SETTLE_T0_PL; //31
        itemID = TRANSACTION_ITEM_ID.TC_SETTLE; //9574
        itemIDIn = TRANSACTION_ITEM_ID.SETTLE_TO_PL; //6292
        break;
      case TRANSACTION_ITEM_ID.TC_SURPLUS_SETTLEMENT:
        transactionMode = TRANSACTION_MODE.TC_SETTLE_T0_PL; //31
        itemID = TRANSACTION_ITEM_ID.TC_SURPLUS_SETTLEMENT; //8570
        itemIDIn = TRANSACTION_ITEM_ID.SETTLE_TO_PL; //6292
        break;
      case TRANSACTION_ITEM_ID.TC_CASH_PA:
        if (data.varianceBalance < 0) {
          transactionMode = TRANSACTION_MODE.TC_SETTLE_T0_PL; //31
          itemID = TRANSACTION_ITEM_ID.TC_CASH_PA; //9243
          itemIDIn = TRANSACTION_ITEM_ID.SETTLE_TO_PL; //6292
        } else {
          transactionMode = TRANSACTION_MODE.TC_SETTLE_T0_PL; //31
          itemID = TRANSACTION_ITEM_ID.SETTLE_TO_PL; //6292
          itemIDIn = TRANSACTION_ITEM_ID.TC_CASH_PA; //9243
        }
    }
    if (data.status === SETTLED) {
      delete AXIOS_INSTANCE.defaults.headers.common["fadcode"];
      updateDiscrepancyStatus({
        data: updateTCParams(
          updateDiscrepancyRequestParams(data),
          transactionMode,
          itemID,
          itemIDIn,
        ),
      });
    } else {
      PutDiscrepancyApi({ data: data });
    }
  };
  const handleTCFlow = (data) => {
    let transactionMode = 0;
    let itemID = "";
    let itemIDIn = " ";

    switch (branchDetails.branch_type) {
      case SP:
        handleTCForSPBranch(data);
        break;
      case DMB:
        hanldeTCForDMB(data);
        break;
      case IN:
        if (data.varianceBalance < 0) {
          transactionMode = TRANSACTION_MODE.TC_SETTLE_TO_CASH; //29
          itemID = TRANSACTION_ITEM_ID.TC_SETTLE; //9574
          itemIDIn = TRANSACTION_ITEM_ID.CASH; //1
        } else {
          transactionMode = TRANSACTION_MODE.TC_SETTLE_TO_CASH; //29
          itemID = TRANSACTION_ITEM_ID.TC_SURPLUS_SETTLEMENT; //8570
          itemIDIn = TRANSACTION_ITEM_ID.CASH; //1
        }
        break;
    }
    if (branchDetails.branch_type === IN && data.status === SETTLED) {
      delete AXIOS_INSTANCE.defaults.headers.common["fadcode"];
      updateDiscrepancyStatus({
        data: updateTCParams(
          updateDiscrepancyRequestParams(data),
          transactionMode,
          itemID,
          itemIDIn,
        ),
      });
    } else {
      PutDiscrepancyApi({ data: data });
    }
  };

  const handleUnknownvariance = (data) => {
    let itemID = "";
    let itemIDIn = "1";
    switch (branchDetails.branch_type) {
      case DMB:
        if (data.varianceBalance > 0) {
          itemID = TRANSACTION_ITEM_ID.TP_BP_BALANCING_SURPLUS; //50832
          itemIDIn = TRANSACTION_ITEM_ID.SETTLE_TO_PL; //6292
        } else {
          itemID = TRANSACTION_ITEM_ID.TP_BP_BALANCING_SHORTAGE; //50833
          itemIDIn = TRANSACTION_ITEM_ID.SETTLE_TO_PL; //6292
        }
        break;
      case IN:
        if (data.varianceBalance > 0) {
          itemID = TRANSACTION_ITEM_ID.TP_BP_BALANCING_SURPLUS; //50832
          itemIDIn = "1";
        } else {
          itemID = TRANSACTION_ITEM_ID.TP_BP_BALANCING_SHORTAGE; //50833
          itemIDIn = "1";
        }
        break;
      case SP:
        if (data.varianceBalance > 0) {
          itemID = TRANSACTION_ITEM_ID.SETTLED_TO_NOMINEE; //50919
          itemIDIn = TRANSACTION_ITEM_ID.MULTIPLE_SETTLED; //50917
        } else {
          itemID = TRANSACTION_ITEM_ID.MULTIPLE_SETTLED; //50917
          itemIDIn = TRANSACTION_ITEM_ID.SETTLED_TO_NOMINEE; //50919
        }
        break;
    }
    if (data.status === SETTLED) {
      delete AXIOS_INSTANCE.defaults.headers.common["fadcode"];
      updateDiscrepancyStatus({
        data: updateDiscrepancyParams(updateDiscrepancyRequestParams(data), itemID, itemIDIn),
      });
    } else {
      PutDiscrepancyApi({ data: data });
    }
  };

  const handleDMB = (data) => {
    let itemID = "";
    const itemIDIn = TRANSACTION_ITEM_ID.SETTLE_TO_PL;

    switch (data.reason.title) {
      case CPD:
        if (data.varianceBalance < 0) {
          itemID = TRANSACTION_ITEM_ID.POUCH_DESPATCH_SHORTAGE; //50818
        } else {
          itemID = TRANSACTION_ITEM_ID.POUCH_DESPATCH_SURPLUS; //50817
        }
        break;
      case CPA:
        if (data.varianceBalance < 0) {
          itemID = TRANSACTION_ITEM_ID.POUCH_ACCEPTANCE_SHORTAGE; //50812
        } else {
          itemID = TRANSACTION_ITEM_ID.POUCH_ACCEPTANCE_SURPLUS; //50811
        }
        break;
      case BMK:
        if (data.varianceBalance < 0) {
          itemID = TRANSACTION_ITEM_ID.BANKING_MISS_KEY_LOSS; //50824
        } else {
          itemID = TRANSACTION_ITEM_ID.BANKING_MISS_KEY_GAIN; //50823
        }
        break;
      case CDF:
        itemID = TRANSACTION_ITEM_ID.DEBIT_CREDIT_FAILURE_LOSS; //50826
        break;
    }
    if (data.status === SETTLED) {
      delete AXIOS_INSTANCE.defaults.headers.common["fadcode"];
      updateDiscrepancyStatus({
        data: updateDiscrepancyParams(updateDiscrepancyRequestParams(data), itemID, itemIDIn),
      });
    } else {
      PutDiscrepancyApi({ data: data });
    }
  };
  const handleKnownVariance = (data) => {
    let itemID = "";
    let itemIDIn = "1";
    switch (branchDetails.branch_type) {
      case DMB:
        handleDMB(data);
        break;
      case SP:
        itemID = TRANSACTION_ITEM_ID.SETTLED_TO_NOMINEE; //50919
        itemIDIn = TRANSACTION_ITEM_ID.MULTIPLE_SETTLED; //50917
        break;
      case IN:
        if (data.varianceBalance < 0) {
          itemID = TRANSACTION_ITEM_ID.MULTIPLE_SETTLED; // 50917
          itemIDIn = TRANSACTION_ITEM_ID.SETTLE_TO_ACCOUNT; //50918
        } else {
          itemID = TRANSACTION_ITEM_ID.SETTLE_TO_ACCOUNT; // 50918
          itemIDIn = TRANSACTION_ITEM_ID.MULTIPLE_SETTLED; //50917
        }

        break;
    }
    if (branchDetails.branch_type !== DMB && data.status === SETTLED) {
      delete AXIOS_INSTANCE.defaults.headers.common["fadcode"];
      updateDiscrepancyStatus({
        data: updateDiscrepancyParams(updateDiscrepancyRequestParams(data), itemID, itemIDIn),
      });
    } else if (branchDetails.branch_type !== DMB) {
      PutDiscrepancyApi({ data: data });
    }
  };
  const saveCashDetailsApi = (data) => {
    if (["Transaction Correction", "Transaction Corrections"].includes(data[0].reason.title)) {
      handleTCSettleToCash(data);
    }
    delete AXIOS_INSTANCE.defaults.headers.common["fadcode"];
    settleToCashDiscrepancy({
      data: discrepancyMovementSettleToCashParams(settleToCashDiscrepancyRequestParams(data)),
    });
  };

  const DiscrepancyApi = (data) => {
    if (["Transaction Correction", "Transaction Corrections"].includes(data[0].reason.title)) {
      handleTCFlow(data[0]);
    } else if (["TP", "BP"].includes(data[0].reason.title)) {
      handleUnknownvariance(data[0]);
    } else {
      handleKnownVariance(data[0]);
    }
  };

  return { isDiscrepancyStatus, isStatus, DiscrepancyApi, saveCashDetailsApi };
};
