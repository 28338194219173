import { useGetPouchesInformation } from "@bbo/api/generator";
import Loader from "@bbo/components/Loader";
import PouchPopupModel from "@bbo/components/layout/PouchPopupModel";
import TableView from "@bbo/components/table";
import { POUCH_PREPARATION_HISTORY_COLUMN } from "@bbo/components/table/tableConstants";
import { CURRENCY_ITEM_ID } from "@bbo/constants/currencyItemID";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { PouchPreparationPopup } from "@bbo/features/PouchLadder/PouchPreparationPopup";
import { PrintIcon, ReprintIcon } from "@bbo/features/PouchLadder/SvgIcon";
import { useAppSelector } from "@bbo/lib";
import { useReceiptPrinter } from "@bbo/lib/hooks/useReceiptPrinter";
import { useGlobalState } from "@bbo/lib/state";
import { PouchDetail } from "@bbo/types";
import { PouchPreparationHistoryTemplate } from "@bbo/utils/ReceiptPrinterTemplates/PouchPreparationHistory";
import { PouchPreparationSummaryTemplate } from "@bbo/utils/ReceiptPrinterTemplates/PouchPreparationSummary";
import { amountUnSignedPound } from "@bbo/utils/currencyFormatter";
import {
  currentDate,
  currentTime,
  getOnlyDate,
  getTime,
  getdateFormat,
} from "@bbo/utils/dateTimeFormatter";
import { removeEmptyLinesFromAddress } from "@bbo/utils/receiptHelper";
import { formattedQuantity } from "@bbo/utils/util";
import React, { useEffect, useState } from "react";

type PrintDataInterface = {
  data: PouchDetail;
  rowIndex: number;
};

const PouchPreparationHistory = () => {
  const [param, setparam] = useState<any>({});
  const { data: pouchPreparationHistory, isLoading } = useGetPouchesInformation("1", param);
  const [PopupData, setPopupData] = useState({ showModal: false });
  const [printCondition, setprintCondition] = useState("printConfirm");
  const [openReprintConfirmationPopup, setOpenReprintConfirmationPopup] = useState<boolean>(false);
  const [openSuccessfulPrintConfirmationPopup, setOpenSuccessfulPrintConfirmationPopup] =
    useState<boolean>(false);
  const [printdata, setPrintData] = useState<PrintDataInterface>(null);
  const [cashdate] = useGlobalState("dateCashbalance");
  const [loader] = React.useState<boolean>(false);
  const [GlobalUSerName] = useGlobalState("userName");
  const { setTitle } = useTitle();
  const branchDetails = useAppSelector((root) => root.branchDetails);
  const { username, fadCode, smartid } = useAppSelector((root) => root.auth.authUserData);
  const receiptPrinterService = useReceiptPrinter();

  useEffect(() => {
    setTitle("Pouch History");
  }, [setTitle]);

  useEffect(() => {
    if (cashdate.todate) {
      setparam({
        to_date: getdateFormat(cashdate.todate),
        from_date: getdateFormat(cashdate.from),
      });
    }
  }, [cashdate.todate]);

  //Click row show details
  const handleRowClicked = (params) => {
    setPopupData({ ...params, showModal: true });
    setPrintData({ ...params });
  };

  // List Item Function bind the data denominationData
  const listItems = () => {
    const denominationData = printdata.data.denominationLadder?.map((data, index) => {
      return {
        Currency: CURRENCY_ITEM_ID[data.itemID],
        Quantity: printdata.data?.pouchBagType?.includes("Bag")
          ? printdata.data?.pouchBagType?.includes("Part Bag")
            ? formattedQuantity(data.quantity) + "" + data.looseCoin
            : formattedQuantity(data.quantity)
          : data.looseCoin !== " " && data.quantity === 0
          ? data.looseCoin
          : formattedQuantity(data.quantity),
        Value:
          data.value !== 0 && data.valueInPence === 0
            ? amountUnSignedPound(data.value)
            : amountUnSignedPound(data.valueInPence),
      };
    });
    return denominationData;
  };

  //Pouch Edit Details Function bind the data pouchEditDetails
  const pouchEditDetails = () => {
    const denominationData = printdata.data.pouchEditDetails?.map((editData, index) => {
      const denominationLadder = editData.denominationLadder?.map((data) => {
        return {
          Currency: CURRENCY_ITEM_ID[data.itemID],
          Quantity: editData.pouchBagType.includes("Bag")
            ? editData?.pouchBagType?.includes("Part Bag")
              ? formattedQuantity(data.quantity) + "" + data.looseCoin
              : formattedQuantity(data.quantity)
            : data.looseCoin !== " " && data.quantity === 0
            ? data.looseCoin
            : formattedQuantity(data.quantity),
          Value:
            data.value !== 0 && data.valueInPence === 0
              ? amountUnSignedPound(data.value)
              : amountUnSignedPound(data.valueInPence),
        };
      });
      if (editData?.preparedBySmartID)
        return {
          preparedBySmartID: editData?.preparedBySmartID,
          preparedByUserID: editData?.preparedByUserID,
          editDate: getOnlyDate(editData.date),
          editTime: getTime(editData.date),
          TotalValue: amountUnSignedPound(editData.totalPouchValue),
          list_items: denominationLadder,
        };
    });
    const newdenominationData = denominationData.filter((data) => data !== undefined);
    return newdenominationData;
  };
  // POUCH PREPARATION Receipt
  const printConfirmReceipt = () => {
    const branchAddressClean = removeEmptyLinesFromAddress(branchDetails.branch_address);
    receiptPrinterService.printReceipt({
      template: PouchPreparationHistoryTemplate,
      context: {
        title: "POUCH PREPARATION DUPLICATE",
        fadcode: branchDetails.fadcode,
        branchName: branchDetails.branch_name,
        addressLine1: branchAddressClean[0],
        addressLine2: branchAddressClean[1],
        addressLine3: branchAddressClean[2],
        postcode: branchAddressClean[branchAddressClean.length - 1],
        currentDate: currentDate(),
        currentTime: currentTime(),
        Location: "Safe",
        createdByUserName: printdata?.data.preparedByUserID || username,
        createdBySmartID: printdata?.data.preparedBySmartID || smartid,
        dateTimeSystem: getOnlyDate(printdata.data.date) + " " + getTime(printdata.data.date),
        pouchID: printdata?.data.pouchID,
        pouchType: printdata?.data.pouchTypeName,
        list_items: listItems(),
        TotalValue: amountUnSignedPound(printdata?.data.totalPouchValue),
      },
    });
  };
  //POUCH PREPARATION SUMMARY Receipt
  const printSummaryReceipt = () => {
    const branchAddressClean = removeEmptyLinesFromAddress(branchDetails.branch_address);
    receiptPrinterService.printReceipt({
      template: PouchPreparationSummaryTemplate,
      context: {
        title: "POUCH PREPARATION SUMMARY",
        fadcode: branchDetails.fadcode,
        branchName: branchDetails.branch_name,
        addressLine1: branchAddressClean[0],
        addressLine2: branchAddressClean[1],
        addressLine3: branchAddressClean[2],
        postcode: branchAddressClean[branchAddressClean.length - 1],
        currentDate: currentDate(),
        currentTime: currentTime(),
        Location: "Safe",
        createdByUserName: printdata?.data.preparedByUserID || username,
        createdBySmartID: printdata?.data.preparedBySmartID || smartid,
        dateTimeSystem: getOnlyDate(printdata.data.date) + " " + getTime(printdata.data.date),
        pouchID: printdata?.data.pouchID,
        pouchType: printdata?.data.pouchTypeName,
        list_items: listItems(),
        pouchEditDetails: pouchEditDetails(),
        TotalValue: amountUnSignedPound(printdata?.data.totalPouchValue),
      },
    });
  };

  const onPrintConfirmation = () => {
    setOpenSuccessfulPrintConfirmationPopup(true);
    setPopupData({ showModal: false });
    setprintCondition("printConfirm");
    printConfirmReceipt();
  };
  const onPrintSummary = () => {
    setOpenSuccessfulPrintConfirmationPopup(true);
    setPopupData({ showModal: false });
    setprintCondition("printSummary");
    printSummaryReceipt();
  };

  const handleReprint = () => {
    setOpenSuccessfulPrintConfirmationPopup(false);
    setOpenReprintConfirmationPopup(true);
  };

  const reload = () => {
    setOpenSuccessfulPrintConfirmationPopup(false);
    setOpenReprintConfirmationPopup(false);
  };

  const handleReprintSuccess = () => {
    setOpenSuccessfulPrintConfirmationPopup(false);
  };

  const handleResendReprint = () => {
    if (printCondition === "printConfirm") {
      printConfirmReceipt();
    } else {
      printSummaryReceipt();
    }
    setOpenReprintConfirmationPopup(false);
  };

  return (
    <>
      <>
        {isLoading ? <Loader /> : loader && <Loader />}
        <div className="w-full transferOut-height">
          <div className="p-4 lg:w-full h-full relative dynamictable">
            <TableView
              columns={POUCH_PREPARATION_HISTORY_COLUMN}
              rows={pouchPreparationHistory}
              showResetFilter
              onRowClicked={handleRowClicked}
            />
            <PouchPopupModel
              data={PopupData}
              onPrintConfirmation={onPrintConfirmation}
              onPrintSummary={onPrintSummary}
            />
          </div>
        </div>
        {openSuccessfulPrintConfirmationPopup && (
          <PouchPreparationPopup
            headerIcon={<PrintIcon />}
            popupType="confirmSuccessfulPrint"
            messageBody="Print Successful."
            labelCancel="Cancel"
            labelReprint="Reprint"
            onCancel={reload}
            labelSuccess="Confirm Successful Print"
            onSuccess={handleReprintSuccess}
            OnReprint={handleReprint}
          />
        )}
        {openReprintConfirmationPopup && (
          <PouchPreparationPopup
            headerIcon={<ReprintIcon />}
            popupType="reprintConfirmationPopup"
            messageBody="Print Unsuccessful."
            labelCancel="Cancel"
            onCancel={reload}
            labelSuccess="Reprint"
            onSuccess={handleResendReprint}
          />
        )}
        ;
      </>
    </>
  );
};

export default PouchPreparationHistory;
