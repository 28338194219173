import { featureFlags, VARIANCE_SUMMARY } from "@bbo/constants";
import { useFeatureFlag } from "@bbo/lib/hooks/useFeatureFlag";
import { amountPound } from "@bbo/utils/currencyFormatter";

type Props = {
  tabLabels: string[];
  varianceAmount: number;
  selected: string;
  onSelectTab: (params) => void;
};

const HeaderTabs = ({ varianceAmount, tabLabels, selected, onSelectTab }: Props) => {
  const [branchVarianceFeatureFlag] = useFeatureFlag(featureFlags.shouldShowBranchVariance);
  const handleTabClick = (value) => {
    onSelectTab({ name: value });
  };

  return (
    <div className="horizontal-tab-list">
      <ul>
        {tabLabels.map((value) => (
          <li
            key={value}
            className={`tab-listview${selected === value ? " active" : ""}`}
            onClick={() => handleTabClick(value)}>
            {value}
          </li>
        ))}
      </ul>
      <ul>
        {branchVarianceFeatureFlag && (
          <li
            className={`tab-listview${selected === VARIANCE_SUMMARY ? " active" : ""}`}
            onClick={() => handleTabClick(VARIANCE_SUMMARY)}>
            {VARIANCE_SUMMARY} ({amountPound(varianceAmount)})
          </li>
        )}
      </ul>
    </div>
  );
};

export default HeaderTabs;
