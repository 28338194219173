import { CashDrawerData, CashManagementsafeDetails } from "@bbo/api/generator";
import ProgressBar from "@bbo/components/ProgressBar";
import { useAppSelector, useGlobalState } from "@bbo/lib";
import { setSelectedTask } from "@bbo/lib/reduxStore/selectedTask.slice";
import { amountSignedPound, currencyStyle } from "@bbo/utils/currencyFormatter";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AMBERCODE, GREENCODE, REDCODE } from "../constants";
import "./index.css";
import TradingPeriodAccordion from "./TradingPeriodAccordion";

interface highLevelViewProps {
  isAllAcountingLocationsCompleted?: (val: boolean) => void;
  enableCompleteTradingPeriodButton?: (val: boolean) => void;
  fromHistory?: boolean;
  selectedRow?: any;
  fromDiscrepancyAccount?: boolean;
}

// Remove this type when CashManagementsafeDetails and CashDrawerData both have the same shape
type CashlistItem = Partial<CashManagementsafeDetails & CashDrawerData>;

export const HighLevelView = ({
  fromHistory,
  selectedRow,
  fromDiscrepancyAccount,
  isAllAcountingLocationsCompleted,
  enableCompleteTradingPeriodButton,
}: highLevelViewProps) => {
  const [loadData, setloadData] = React.useState<any>();

  const [percentage, setPercentage] = React.useState<number>();
  const [initialLength, setInitialLength] = React.useState<number>();
  const [finalLength, setFinalLength] = React.useState<number>();
  const [VarianceData, setVarianceData] = React.useState<any>();
  const cashlist = useAppSelector((root) => root.BpTp);
  const navigate = useNavigate();
  const [, setrouteState] = useGlobalState("routeState");
  const [, setPopupData] = useGlobalState("PopupData");
  const [, setTotalCashVariance] = useGlobalState("totalCashVariance");
  const dispatch = useDispatch();

  useEffect(() => {
    if (cashlist.accountingLocation === "") {
      return;
    }
    let loadData: CashlistItem[] = [cashlist.safe, ...cashlist.cashDrawer];
    let VarianceData = 0;

    setInitialLength(loadData.length);
    loadData = loadData.filter((item, index) => {
      if (item.entity === "safe") {
        if (item?.tradingPeriodDateTime) {
          return null;
        }
        return item;
      } else {
        if (item?.itemDetails?.tradingPeriodDateTime) {
          return null;
        }
        return item;
      }
    });
    setloadData(loadData);
    setFinalLength(loadData.length);
    if (loadData?.length === 0) {
      isAllAcountingLocationsCompleted(true);
    }

    if (fromHistory || fromDiscrepancyAccount) {
      setVarianceData(selectedRow?.cash.totalVariance);
    } else {
      cashlist.cashDrawer?.map((item) => {
        VarianceData = VarianceData + item.itemDetails.toDateVariance;
      });
      VarianceData = VarianceData + cashlist.safe.toDateVariance;
      setVarianceData(VarianceData);
      setTotalCashVariance(VarianceData);
    }
  }, [cashlist, selectedRow]);

  useEffect(() => {
    if (fromHistory || fromDiscrepancyAccount) {
      setPercentage(100);
    } else {
      let Percentage = Math.floor((finalLength / initialLength) * 100);
      Percentage = 100 - Percentage;
      if (Percentage) {
        setPercentage(Math.floor(Percentage));
      }
    }
  }, [finalLength, initialLength, percentage]);
  const CompleteBalance = (data, index) => {
    const mData = {
      head: data.entityName,
      data: data as any,
      index: index || "",
    };
    dispatch(
      setSelectedTask({
        fadcode: mData.data?.fadcode,
        entity: mData.data?.entity,
        entityID: mData.data?.entityID,
        entityName: mData.data?.entityName,
        itemDetails: mData.data?.ItemDetails,
      }),
    );
    setPopupData(mData);
    setrouteState({
      RouteState:
        location.pathname === "/balancePeriod"
          ? "BalancePeriodCashBalance"
          : "TradingPeriodCashBalance",
    });
    navigate("/cashBalance", { replace: true });
  };

  const CashContentDetail = (color) =>
    loadData?.length ? (
      <>
        <div className="mt-2 ">
          <span className="dot mr-2" style={color}></span>
          <span className="subTitle">Locations Pending</span>
        </div>
        <div className="ContentDetailAlign">
          <table>
            <tbody>
              {loadData?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="accordian-title">{item.entityName}</td>
                    <td
                      className="linkBtnHighLevel text-right"
                      onClick={() => CompleteBalance(item, index)}>
                      Complete Balance
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    ) : null;
  return (
    <div className="w-full px-10 py-4">
      <div className="flex justify-center">
        <h2 className="testSizeSubHead mb-6">Branch View</h2>
      </div>
      <div className="w-full balanceTpTable overflow-y-auto">
        <table className="table-fixed dynamictable w-full border-collapse">
          <thead>
            <tr className="h-14">
              <th className="text-left w-1/5 text-lg font-bold textColor">Commodity</th>
              <th className="text-left w-4/6 text-lg font-bold textColor">Progress</th>
              <th className="text-left width-15 text-lg font-bold textColor">Variance</th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-20">
              <td className="sideTitle">Cash</td>
              <td>
                <TradingPeriodAccordion
                  title={
                    <ProgressBar
                      bgcolor={`${
                        percentage === 100 ? GREENCODE : percentage > 0 ? AMBERCODE : REDCODE
                      }`}
                      progress={percentage}
                      reason={`${
                        percentage === 100
                          ? "Completed"
                          : percentage > 0
                          ? "In Progress"
                          : "Awaiting Action"
                      }`}
                    />
                  }
                  content={
                    fromHistory || fromDiscrepancyAccount
                      ? null
                      : CashContentDetail({
                          backgroundColor: percentage === 100 ? GREENCODE : AMBERCODE,
                        })
                  }
                />
              </td>
              <td className={`valueDot ${currencyStyle(VarianceData)}`}>
                {amountSignedPound(VarianceData)}
              </td>
            </tr>
            <tr>
              <td className="sideTitle">Forex</td>
              <td>
                <TradingPeriodAccordion
                  title={<ProgressBar bgcolor={GREENCODE} progress="100" reason="Completed" />}
                  content={null}
                />
              </td>
              <td className="valueDot greenLabel">+£300.00</td>
            </tr>
            <tr>
              <td className="sideTitle">Stock</td>
              <td>
                <TradingPeriodAccordion
                  title={<ProgressBar bgcolor={GREENCODE} progress="100" reason="Completed" />}
                  content={null}
                />
              </td>
              <td className="valueDot greenLabel">+£300.00</td>
            </tr>
            <tr>
              <td className="sideTitle">Obsolete Stock</td>
              <td>
                <TradingPeriodAccordion
                  title={<ProgressBar bgcolor={GREENCODE} progress="100" reason="Completed" />}
                  content={null}
                />
              </td>
              <td className="valueDot greenLabel">+£300.00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
