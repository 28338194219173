import { useGetBPTPList } from "@bbo/api/generator/endpoints/branch-balancing-details";
import { useGetCashTransactionDetails } from "@bbo/api/generator/endpoints/cash-management-api";
import Loader from "@bbo/components/Loader";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { BalancePeriodScreen } from "@bbo/features/BalancePeriod";
import { useAppSelector } from "@bbo/lib";
import { setBpTpDetails } from "@bbo/lib/reduxStore/BpTp.slice";
import { getOnlyDateDiscrepancy } from "@bbo/utils/dateTimeFormatter";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const BalancePeriod = () => {
  const { fadCode } = useAppSelector((root) => root.auth.authUserData);
  const { data: cashData, status: CashStatus } = useGetCashTransactionDetails("1");
  const [param, setparam] = useState<string>();
  const location = useLocation();
  const { setTitle } = useTitle();
  const [fromHistory, setFromHistory] = useState<boolean>(false);
  const [fromDiscrepancyAccount, setFromDiscrepancyAccount] = useState<boolean>(false);
  const selectedTpBpHistoryRow = localStorage.getItem("selectedTpBpHistoryRow");
  const [balancingId, setBalancingId] = useState<string>("");
  const [bpTpCommitttedData, setBpTpCommitttedData] = useState<any>();
  const dispatch = useDispatch();

  const titleSelector = () => {
    return `${JSON.parse(selectedTpBpHistoryRow)?.operationType} ${getOnlyDateDiscrepancy(
      JSON.parse(selectedTpBpHistoryRow)?.dateTime,
    )} - History`;
  };
  const { data: lastCommittedData, status: lastCommittedDataStatus } = useGetBPTPList(param);
  useEffect(() => {
    if (typeof balancingId === "string" && balancingId.length > 0) {
      setparam(fadCode);
    }
  }, [balancingId]);

  useEffect(() => {
    if (lastCommittedDataStatus === "success") {
      lastCommittedData.filter((item) => {
        if (item.name === location.state["balancingId"]) {
          setTitle(`${item?.operationType} ${getOnlyDateDiscrepancy(item?.dateTime)} - History`);
          setBpTpCommitttedData(item);
        }
      });
    }
  }, [lastCommittedData, lastCommittedDataStatus]);

  useEffect(() => {
    if (location?.state !== null) {
      const from = location.state["from"];
      if (from) {
        if (from === "TpBpHistory") {
          setFromHistory(true);
          setTitle(titleSelector());
        } else if (from === "discrepancyAccount") {
          setBalancingId(location.state["balancingId"]);
          setFromDiscrepancyAccount(true);
          setTitle(titleSelector());
        }
      }
    } else {
      setFromHistory(false);
      setFromDiscrepancyAccount(false);
      setTitle("Balance Period");
    }
  }, [location]);
  useEffect(() => {
    dispatch(setBpTpDetails(cashData));
  }, [cashData]);

  return (
    <>
      {CashStatus !== "success" ? (
        <Loader />
      ) : (
        <BalancePeriodScreen
          fromHistory={fromHistory}
          fromDiscrepancyAccount={fromDiscrepancyAccount}
          selectedRow={
            fromDiscrepancyAccount ? bpTpCommitttedData : JSON.parse(selectedTpBpHistoryRow)
          }
        />
      )}
    </>
  );
};

export default BalancePeriod;
