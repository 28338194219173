/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

PO Transactions

PO Transactions Viewer

Cash Drawer Association API
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { ForexCurrencyData, BadRequest } from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Get on-boarding entities data
 * @summary get forex branch count summary
 */
export const useGetForexCountSummaryHook = () => {
  const getForexCountSummary = useCustomInstance<ForexCurrencyData>();

  return (branchId: string, entityId: string) => {
    return getForexCountSummary({
      url: `/forex-management/forex-count-summary/${branchId}/${entityId}`,
      method: "get",
    });
  };
};

export const getGetForexCountSummaryQueryKey = (branchId: string, entityId: string) => [
  `/forex-management/forex-count-summary/${branchId}/${entityId}`,
];

export type GetForexCountSummaryQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetForexCountSummaryHook>>
>;
export type GetForexCountSummaryQueryError = ErrorType<BadRequest | void>;

export const useGetForexCountSummary = <
  TData = AsyncReturnType<ReturnType<typeof useGetForexCountSummaryHook>>,
  TError = ErrorType<BadRequest | void>,
>(
  branchId: string,
  entityId: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetForexCountSummaryHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetForexCountSummaryQueryKey(branchId, entityId);

  const getForexCountSummary = useGetForexCountSummaryHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetForexCountSummaryHook>>
  > = () => getForexCountSummary(branchId, entityId);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetForexCountSummaryHook>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(branchId && entityId), ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};
