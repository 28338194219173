import {
  AtmDetails,
  CashDrawerData,
  CashManagementData,
  CashManagementsafeDetails,
  CounterTerminalData,
} from "@bbo/api/generator";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// The logic around this type needs fixing. Functionality should not depend on accountLocation defaulting to a string
export interface CashSlice extends Omit<CashManagementData, "accountingLocation"> {
  accountingLocation: string[] | string;
  varianceSummary?: CashDrawerData[];
  VarianceAmt?: number;
}

const initialState: CashSlice = {
  atm: {
    actionList: [],
    fadcode: "",
    entity: "",
    entityID: "",
    entityName: "",
    balance: 0,
    lastBalancedDateTime: 0,
    lastVariance: 0,
    toDateVariance: 0,
    balancePeriodDateTime: undefined,
    flowEntityStatus: undefined,
    flowType: undefined,
    ItemDetails: undefined,
    smartID: undefined,
    tradingPeriodDateTime: undefined,
    transferAmt: undefined,
    transferAmtDate: undefined,
    userName: undefined,
  },
  cashDrawer: [],
  counterTerminal: [],
  safe: {
    actionList: [],
    fadcode: "",
    cashDrawer: [],
    entity: "",
    entityID: "",
    entityName: "",
    balance: 0,
    lastBalancedDateTime: 0,
    lastVariance: 0,
    toDateVariance: 0,
    transferAmt: 0,
    transferAmtDate: 0,
    flowType: undefined,
    ItemDetails: undefined,
    balancePeriodDateTime: undefined,
    flowEntityStatus: undefined,
    tradingPeriodDateTime: undefined,
  },
  totalVariance: null,
  varianceSummary: [],
  accountingLocation: "",
};

export const CashDetails = createSlice({
  name: "CashDetails",
  initialState,
  reducers: {
    setCashDetails: (state, action: PayloadAction<CashSlice>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setCashDetails } = CashDetails.actions;

export default CashDetails.reducer;
