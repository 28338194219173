import Accordion from "@bbo/components/Accordion";
import CashMovementsModal from "@bbo/components/layout/cashMovement-Popup";
import Popup from "@bbo/components/layout/Popup";
import { BalanceCheck } from "@bbo/features/AdhocBalance/BalanceCheck";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { useAppSelector } from "@bbo/lib";
import { useGlobalState } from "@bbo/lib/state";
import "@bbo/screens/Index.css";
import { amountInPence } from "@bbo/utils/currencyFormatter";
import { getbalanceDate } from "@bbo/utils/dateTimeFormatter";
import { getValueFromStorage } from "@bbo/utils/util";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Ladder } from "./ladder";
import { PreparedPouch } from "./preparedPouch";
import { SuspendPopup } from "./suspendPopUp";
import { useAdhocBalance } from "./useAdhocBalance";
import UserActivitiesTable from "./userActivitiesTable";

interface TpBpType {
  operationType: string;
  date: string;
}

const Dashboard = () => {
  const navigate = useNavigate();
  const [adhocbalance] = useGlobalState("adhocbalance");

  const [cashBalanceDetails] = useGlobalState("cashBalance");
  const [, setVariance] = useGlobalState("adhocData");
  const [, setTotal] = useGlobalState("totalCash");
  const [showSuspendPopUp, setShowSuspendPopUp] = useState<boolean>(false);
  const [cashdetail, setcashdetail] = useGlobalState("cashDetailsFprAdhocBalance");
  const [inputQ, setinputQ] = useState<boolean>(true);
  const [inputV, setinputV] = useState<boolean>(true);
  const unUseNote = "Unusable Notes";
  const unUseCoin = "Unusable Coins";
  const [Isbalancecheck, setIsbalancecheck] = useGlobalState("Isbalancecheck");
  const [ResetPopup, setResetPopup] = useState<boolean>(false);
  const [Submitted, setSubmitted] = useState<boolean>(true);
  const [, setfocus] = useState<number>(1);
  const [, setTPRecount] = useGlobalState("TPRecount");
  const [modalData, setModalData] = useState({ showModal: false });
  const [TPRecount] = useGlobalState("TPRecount");
  const [routeState] = useGlobalState("routeState");
  const selectedTask = useAppSelector((root) => root.selectedTask);
  const { setTitle, setIsBackButtonActive } = useTitle();
  const { statusRes, setStatusRes } = useAdhocBalance();
  useEffect(() => {
    if (routeState.RouteState === null) {
      navigate("/branchOverview");
    }
  }, []);

  useEffect(() => {
    if (adhocbalance.isSuspendedPouch) {
      setShowSuspendPopUp(true);
    }
  }, [adhocbalance]);

  // debugger

  useEffect(() => {
    return () => {
      setIsbalancecheck({ Isbalancecheck: false });
    };
  }, []);

  useEffect(() => {
    const titleSelector = () => {
      return `${getValueFromStorage<TpBpType>("selectedTpBpHistoryRow").operationType} ${
        getValueFromStorage<TpBpType>("selectedTpBpHistoryRow").date
      }  ${selectedTask.entityName} - Cash Balance History`;
    };
    if (routeState.RouteState === "TpBpHistory") {
      setTitle(titleSelector());
    } else {
      setTitle(`Cash Balance | ${selectedTask?.entityName}`);
    }
    setIsBackButtonActive(false);
    return () => {
      setIsBackButtonActive(true);
    };
  }, [routeState.RouteState, selectedTask?.entityName, setTitle, setIsBackButtonActive]);

  const submitNext = () => {
    if (statusRes === "") {
      setStatusRes("Incomplete");
    }

    let count = 0;
    const totalCashBySystem: number = adhocbalance.entityData[0]?.itemDetails?.systemValue;
    cashdetail.map((item) => {
      if (item.btnDisable === false)
        if (item.alertCss !== "alertCss" && item.errorMsg === "" && item.QerrorMsg === "") {
          count++;
        } else {
          count--;
        }

      return 0;
    });

    const denominationArr = cashdetail
      .filter((item) => {
        if (item.value !== null) {
          return {};
        }
      })
      .map((item, Index) => {
        return {
          denomination:
            item.Cash === unUseNote || item.Cash === unUseCoin
              ? item.Cash
              : item.Cash + item.currency,
          index: Index,
          looseCoin: parseInt(item.Quantity),
          value: amountInPence(item.value.replace("£", "")),
          itemID: item.itemID,
        };
      });
    if (count > 0 && !cashdetail.some((item) => item.alertCss === "alertCss" && Submitted)) {
      setIsbalancecheck({ Isbalancecheck: true });
    }

    let totalCashByInput = cashdetail.reduce(
      (total, value) => total + Number(value.value.replace("£", "")),
      0,
    );
    totalCashByInput = amountInPence(totalCashByInput);

    setTotal({ totalCash: totalCashByInput });
    const currentToDateVariance = totalCashByInput - totalCashBySystem;
    setVariance({
      variance: currentToDateVariance - adhocbalance.entityData[0]?.itemDetails?.toDateVariance, // last variance
      balance: totalCashByInput, // current value of ladder
      currentTDV: currentToDateVariance,
      denomination: denominationArr, // denomination ladder value
    });
    setTPRecount({ TPRecount: false });
  };

  const ResetPopupFlag = () => {
    setResetPopup(true);
  };
  const resetFocus = useRef<HTMLInputElement>();
  const nextFocus = useRef<HTMLInputElement>();

  const submitReset = (e) => {
    if (resetFocus.current) {
      resetFocus.current.focus();
    }
    if (e.target.value === "Yes") {
      setinputQ(!inputQ);
      setinputV(!inputV);
      setfocus(1);
      const updateValue = [...cashdetail];
      cashdetail.map((item, index) => {
        updateValue[index].value = "";
        updateValue[index].Quantity = "";
        updateValue[index].alertCss = "normalCss";
        updateValue[index].QalertCss = "normalCss";
        updateValue[index].errorMsg = "";
        updateValue[index].QerrorMsg = "";
        updateValue[index].btnDisable = true;
        return 0;
      });
      setcashdetail(updateValue);
      setResetPopup(false);
    } else {
      setResetPopup(false);

      if (nextFocus.current) {
        nextFocus.current.focus();
      }
    }
    setTPRecount({ TPRecount: false });
  };
  const UserActivitiesTableData =
    cashBalanceDetails.cashBalanceDetails &&
    cashBalanceDetails?.cashBalanceDetails[0]
      /**
       * @remarks - Changed associatedEntityID to entityName as nothing else seemed to fit (from the actual return type)
       PopupData.data.associatedEntityID
      */
      ?.filter((items) =>
        selectedTask.entityName
          ? selectedTask.entityName
          : selectedTask.entityID === items.sourceentityID || selectedTask.entityName
          ? selectedTask.entityName
          : selectedTask.entityID === items.destinationentityID,
      )
      .map((items) => {
        {
          const log = {
            userName: items.userName,
            operationType: items.operationType,
            amount: items.amount,
            Link: "Details",
            ...items,
          };
          return log;
        }
      });

  const userActivitiesData =
    cashBalanceDetails?.userActivitiesData &&
    cashBalanceDetails?.userActivitiesData.map((items) => {
      {
        const log = {
          user_name: items.username,
          operationType: items.status,
          amount: getbalanceDate(items.dateTime),
        };
        return log;
      }
    });
  const handleCellClicked = (params) => {
    setModalData({ ...params, showModal: true });
  };

  const handleCellClickedCallback = useCallback(handleCellClicked, []);

  const accordionData = [
    selectedTask?.entity === "safe" ? { title: "Pouches in Safe", content: <PreparedPouch /> } : {},
    {
      title: "Activities between Balances",
      content: (
        <UserActivitiesTable
          UserData={[...UserActivitiesTableData, ...userActivitiesData]}
          handleCellClickedCallback={handleCellClickedCallback}
        />
      ),
    },
  ];

  const handleBackButton = () => {
    if (routeState.RouteState === "cashBalance") {
      navigate("../taskManagement", { replace: true });
    } else if (routeState.RouteState === "TradingPeriodCashBalance") {
      navigate("../tradingPeriod", { replace: true });
    } else if (routeState.RouteState === "BalancePeriodCashBalance") {
      navigate("../balancePeriod", { replace: true });
    } else if (routeState.RouteState === "TpBpHistory") {
      navigate("../BalanceTradingPeriodHistory", { replace: true });
    } else navigate(-1);
  };

  const inputCash = (
    <div
      className={`w-full items-start grid grid-cols-1 
        lg:grid-cols-2 gap-5`}>
      <div className="grid grid-cols-1 gap-4 pl-4 pt-4">
        {accordionData?.map(({ title, content }, index) => (
          <Accordion
            title={title}
            content={content}
            key={index}
            feature={"adhocBalance"}
            onToggle={() => {
              return true;
            }}
          />
        ))}
      </div>
      {Isbalancecheck.Isbalancecheck ? (
        <BalanceCheck />
      ) : (
        <Ladder
          submitNext={(Submitted) => {
            setSubmitted(Submitted);
            submitNext();
          }}
          ResetPopupFlag={() => ResetPopupFlag()}
          cashDetails={cashdetail}
          inputQ={inputQ}
          inputV={inputV}
          nextFocus={nextFocus}
          ResetPopup={ResetPopup}
        />
      )}
    </div>
  );

  return routeState.RouteState === null ? (
    <></>
  ) : (
    <>
      <div className="subheading font-24 font-bold leading-7 lg:w-screen">
        <div className="width-70 float-left">
          <button
            onClick={() => {
              handleBackButton();
            }}>
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      <div className="containerMain">
        <div className="w-full items-start mainContainerShadow transferOut-height overflow-hidden">
          <div className="flex flex-col lg:flex-row mb-6 w-full rounded shadow overflow-y-auto cashExchangeHeight justify-center">
            {showSuspendPopUp && <SuspendPopup onSuccess={() => setShowSuspendPopUp(false)} />}
            {ResetPopup && <Popup submitReset={submitReset} resetFocus={resetFocus} />}
            {inputCash}
            <CashMovementsModal data={modalData} />
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
