import { CASH_DRAWERS, COUNTERS, SAFE } from "@bbo/constants";
import CashDrawerList from "../BranchOverview/CashDrawerList";
import CounterList from "../BranchOverview/CounterList";
import SafeItem from "../BranchOverview/SafeItem";

interface IProps {
  stockData;
  isStockData;
}

export const StockAccountingOverview = ({ stockData, isStockData }: IProps) => {
  return (
    <div data-testid="overview-testid">
      <CounterList header={COUNTERS} items={stockData?.counterTerminal} isStockData={isStockData} />
      <hr className="border-gray-200 border-bottom-none mb-5 w-full" />
      <div className="w-full accounting-card block float-left h-56 overflow-x-auto whitespace-nowrap">
        <SafeItem header={SAFE} data={stockData?.safe} isStockData={isStockData} />
      </div>
      <hr className="border-gray-200 border-bottom-none mb-5 w-full" />
      <CashDrawerList
        header={CASH_DRAWERS}
        items={stockData?.cashDrawer}
        isStockData={isStockData}
      />
    </div>
  );
};
