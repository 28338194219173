/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

PO Transactions

PO Transactions Viewer

Cash Drawer Association API
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { CurrencyCount, InternalServerError } from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Get currency count for a branch
 * @summary This endpoint will get the currency counts list on a branch
 */
export const useGetCurrencyCountListHook = () => {
  const getCurrencyCountList = useCustomInstance<CurrencyCount | void>();

  return (fadcode: string) => {
    return getCurrencyCountList({
      url: `/forex-management/currencycount/${fadcode}`,
      method: "get",
    });
  };
};

export const getGetCurrencyCountListQueryKey = (fadcode: string) => [
  `/forex-management/currencycount/${fadcode}`,
];

export type GetCurrencyCountListQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetCurrencyCountListHook>>
>;
export type GetCurrencyCountListQueryError = ErrorType<InternalServerError>;

export const useGetCurrencyCountList = <
  TData = AsyncReturnType<ReturnType<typeof useGetCurrencyCountListHook>>,
  TError = ErrorType<InternalServerError>,
>(
  fadcode: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetCurrencyCountListHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetCurrencyCountListQueryKey(fadcode);

  const getCurrencyCountList = useGetCurrencyCountListHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetCurrencyCountListHook>>
  > = () => getCurrencyCountList(fadcode);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetCurrencyCountListHook>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!fadcode, ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};
