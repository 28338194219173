import { useGlobalState } from "@bbo/lib/state";
import { formatDate, getOnlyDateFormat } from "@bbo/utils/dateTimeFormatter";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import "@material-ui/core/styles";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import makeStyles from "@material-ui/styles/makeStyles";
import { useEffect, useState } from "react";
import "./index.css";
interface iProps {
  name?: string;
  activeFilters?: any[];
  onChange?: (params: any) => void;
  dateRange?: number;
}

const fromMinDate = () => {
  const dateTime = new Date();
  dateTime.setFullYear(dateTime.getFullYear() - 6);
  return dateTime.getTime();
};

const toMinDate = (fromDate) => {
  let dateTime;
  if (fromDate) {
    dateTime = new Date(fromDate);
  } else {
    dateTime = new Date();
    dateTime.setFullYear(dateTime.getFullYear() - 6);
  }
  return dateTime.getTime();
};

const toMaxDate = (fromDate) => {
  let dateTime;
  if (fromDate) {
    dateTime = new Date(fromDate);
    dateTime.setDate(dateTime.getDate() + 42);
    const today = new Date();
    if (dateTime.getDate() > today.getDate()) {
      //Disabled feature date
      dateTime.setTime(today.getTime());
    }
  } else {
    dateTime = new Date();
  }
  return dateTime.getTime();
};

const useStyles = makeStyles({
  root: {
    "& .MuiFormLabel-root": {
      color: "#222",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#444444",
    },
    "&:focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#444444",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#444444",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#444444",
      border: "1px solid #444444",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#222",
    },
  },
  dateRangeWithoutTime: {
    "& MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl":
      {
        borderColor: "#444444",
        border: "1px solid red",
      },
  },
});

export const DateRange = (props: iProps) => {
  const [fromDate, handleFromDateChange] = useState(null);
  const [toDate, handleToDateChange] = useState(null);
  const [isInvalid] = useState(false);
  const [, setcashdate] = useGlobalState("dateCashbalance");
  useEffect(() => {
    if (props.activeFilters.length === 0) {
      handleFromDateChange(null); // reset filter
      handleToDateChange(null); // reset filter
      setcashdate({ from: null, todate: null });
    }
  }, [props.activeFilters]);
  const classes = useStyles();
  useEffect(() => {
    if (fromDate != null && Date.parse(fromDate) > Date.parse(toDate)) {
      handleToDateChange(null);
      if (props.onChange) {
        props.onChange({
          field: props.name,
          filter: "datetime",
          from: "",
          to: "",
        });
      }
    } else if (toDate != null && Date.parse(fromDate) <= Date.parse(toDate)) {
      if (props.onChange) {
        props.onChange({
          field: props.name,
          filter: "datetime",
          from: formatDate(fromDate),
          to: formatDate(toDate),
        });
      }
    }
    setcashdate({
      from: fromDate,
      todate: toDate,
    });
  }, [fromDate, toDate]);

  return (
    <div className="po-date-range" id="id-po-date-picker">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="po-date-picker">
          <div className="innerContainer">
            <DateTimePicker
              label="From"
              inputVariant="outlined"
              ampm={true}
              value={fromDate}
              className={classes.root}
              error={isInvalid}
              minDate={fromMinDate()}
              maxDate={new Date()}
              format="dd/MM/yyyy HH"
              openTo="date"
              onChange={handleFromDateChange}
              autoOk
              views={["date", "hours"]}
            />
            <i className="far fa-calendar-alt"></i>
          </div>
          <div className="innerContainer">
            <DateTimePicker
              label="To"
              inputVariant="outlined"
              ampm={true}
              value={toDate}
              error={isInvalid}
              className={classes.root}
              minDate={toMinDate(fromDate)}
              maxDate={toMaxDate(fromDate)}
              format="dd/MM/yyyy HH"
              onChange={handleToDateChange}
              autoOk={false}
            />
            <i className="far fa-calendar-alt"></i>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export const TimeRange = (props: iProps) => {
  const [selectedFromTime, handleFromTime] = useState(null);
  const [selectedToTime, handleToTime] = useState(null);
  const [, setTransactionLogParam] = useGlobalState("transactionLogParam");
  const classes = useStyles();
  const { onChange, name, activeFilters } = props;
  useEffect(() => {
    if (selectedFromTime && Date.parse(selectedFromTime) <= Date.parse(selectedToTime)) {
      const hoursFrom = new Date(selectedFromTime).getHours();
      const hoursTo = new Date(selectedToTime).getHours();
      if (onChange) {
        onChange({
          field: name,
          filter: "setValueTime",
          from: ("0" + hoursFrom).slice(-2) + ":" + new Date(selectedFromTime).getMinutes(),
          to: ("0" + hoursTo).slice(-2) + ":" + new Date(selectedToTime).getMinutes(),
        });
      }
      setTransactionLogParam({
        from: new Date(selectedFromTime).getHours(),
        fromMin: new Date(selectedFromTime).getMinutes(),
        to: new Date(selectedToTime).getHours(),
        toMin: new Date(selectedToTime).getMinutes(),
      });
    }
  }, [selectedFromTime, selectedToTime]);
  useEffect(() => {
    if (!activeFilters.length) {
      handleFromTime(null);
      handleToTime(null);
      setTransactionLogParam({
        from: null,
        to: null,
        toMin: null,
        fromMin: null,
      });
    }
  }, [activeFilters]);
  return (
    <div className="po-date-range" id="id-po-date-picker">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="po-date-picker">
          <div className="innerContainer">
            <TimePicker
              ampm={false}
              openTo="hours"
              views={["hours", "minutes"]}
              format="HH:mm"
              label="From"
              value={selectedFromTime}
              className={classes.root}
              inputVariant="outlined"
              onChange={handleFromTime}
            />
            <i className="far fa-clock"></i>
          </div>
          <div className="innerContainer">
            <TimePicker
              ampm={false}
              openTo="hours"
              views={["hours", "minutes"]}
              format="HH:mm"
              label="To"
              value={selectedToTime}
              disabled={!selectedFromTime}
              className={classes.root}
              onChange={handleToTime}
              inputVariant="outlined"
            />
            <i className="far fa-clock"></i>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
};
export const DateCalender = (props: iProps) => {
  const { onChange, name, activeFilters } = props;
  const [selectedDate, handleDateChange] = useState(null);
  const classes = useStyles();
  const [, setTransactionLogParamDate] = useGlobalState("transactionLogParamDate");
  useEffect(() => {
    if (selectedDate) {
      if (onChange) {
        onChange({
          field: name,
          filter: "DateCalender",
          date: getOnlyDateFormat(selectedDate),
        });
      }
      setTransactionLogParamDate({ date: selectedDate });
    }
  }, [selectedDate]);
  useEffect(() => {
    if (!activeFilters.length) {
      handleDateChange(null);
      setTransactionLogParamDate({
        date: null,
      });
    }
  }, [activeFilters]);
  return (
    <div className="po-date-range" id="id-po-date-picker">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="po-date-picker">
          <div className="innerContainer">
            <DatePicker
              label="Select Date"
              value={selectedDate}
              onChange={handleDateChange}
              className={classes.root}
              animateYearScrolling
              inputVariant="outlined"
              format="dd/MM/yyyy"
            />
            <i className="far fa-calendar-alt"></i>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export const DateRangeWithoutTime = (props: iProps) => {
  const [fromDate, handleFromDateChange] = useState(null);
  const [toDate, handleToDateChange] = useState(null);
  const [, setcashdate] = useGlobalState("dateCashbalance");

  useEffect(() => {
    if (props.activeFilters.length === 0) {
      handleFromDateChange(null); // reset filter
      handleToDateChange(null); // reset filter
      setcashdate({ from: null, todate: null });
    }
  }, [props.activeFilters]);

  const classes = useStyles();

  useEffect(() => {
    if (fromDate !== null && toDate !== null) {
      if (Date.parse(fromDate) > Date.parse(toDate)) {
        handleToDateChange(null);
        if (props.onChange) {
          props.onChange({
            field: props.name,
            filter: "datetime",
            from: "",
            to: "",
          });
        }
      } else {
        if (props.onChange) {
          props.onChange({
            field: props.name,
            filter: "datetime",
            from: formatDate(fromDate),
            to: formatDate(toDate),
          });
        }
      }
    } else if (fromDate !== null && toDate === null) {
      if (props.onChange) {
        props.onChange({
          field: props.name,
          filter: "datetime",
          from: formatDate(fromDate),
          to: formatDate(fromDate),
        });
      }
    }

    setcashdate({ from: fromDate, todate: toDate || fromDate });
  }, [fromDate, toDate]);

  return (
    <div className="po-date-range" id="id-po-date-picker">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="po-date-picker">
          <div className="innerContainer">
            <DatePicker
              variant="inline"
              inputVariant="outlined"
              className={classes.dateRangeWithoutTime}
              label="From"
              format="dd/MM/yyyy"
              value={fromDate}
              onChange={handleFromDateChange}
              allowKeyboardControl={true}
              autoOk={true}
              minDate={fromMinDate()}
              disableFuture
            />
            <i className="far fa-calendar-alt"></i>
          </div>
          <div className="innerContainer">
            <DatePicker
              variant="inline"
              inputVariant="outlined"
              className={classes.dateRangeWithoutTime}
              format="dd/MM/yyyy"
              label="To"
              value={toDate}
              allowKeyboardControl={true}
              autoOk={true}
              onChange={handleToDateChange}
              minDate={toMinDate(fromDate)}
              disableFuture
            />
            <i className="far fa-calendar-alt"></i>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
};
