export const CURRENCY_ITEM_ID = {
  356: "£100 Notes",
  655: "£50 Notes",
  656: "£20 Notes",
  657: "£10  Notes",
  658: "£5 Notes",
  659: "£1 Notes",
  357: "£2 Coin",
  660: "£1 Coin",
  661: "50p Coin",
  662: "1p Coin",
  663: "2p Coin",
  2189: "5p Coin",
  2190: "10p Coin",
  2191: "20p Coin",
  3328: "£2 Coin Full Bag",
  3329: "£1 Coin Full Bag",
  3330: "50p Coin Full Bag",
  3331: "20p Coin Full Bag",
  3332: "10p Coin Full Bag",
  3333: "5p Coin Full Bag",
  3334: "2p Coin Full Bag",
  3335: "1p Coin Full Bag",
  3336: "£2 Partial Coin Bag",
  3337: "£1 Partial Coin Bag",
  3338: "50p Partial Coin Bag",
  3339: "20p Partial Coin Bag",
  3340: "10p Partial Coin Bag",
  3341: "5p Partial Coin Bag",
  3342: "2p Partial Coin Bag",
  3343: "1p Partial Coin Bag",
  3344: "Unusable Coin £5",
  3345: "Unusable Coin £2",
  3346: "Unusable Coin £1",
  3347: "Unusable Coin 50p",
  3348: "Unusable Coin 20p",
  3349: "Unusable Coin 10p",
  3350: "Unusable Coin 5p",
  3351: "Unusable Coin 2p",
  3352: "Unusable Coin 1p",
};
