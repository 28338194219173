import TableView from "@bbo/components/table";
import { BRANCH_DISCREPENCY_SUMMARY_COLUMN } from "@bbo/components/table/tableConstants";
import { Column } from "@bbo/components/table/types";
import { DISCREPANCY_STATUS } from "@bbo/constants";
import { useAppSelector } from "@bbo/lib";
import { useGlobalState } from "@bbo/lib/state";
import { DiscrepencyHistory } from "@bbo/openapi/api";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import {
  BACK_OFFICE,
  COMMENT,
  DISCREPANCY_SETTLEMENT,
  IN_BRANCH_TYPE,
  PAID_IN,
  PAID_OUT,
  SETTLED,
  SETTLE_CASH,
} from "./constant";
import DisputePopUp from "./DisputePopUp";
import HighLevelPopUp from "./HighLevelPopUp";
import SettleMentPopUp from "./SettleMentPopUp";
import { useDiscrepancyAccount } from "./useDiscrepancyAccount";
// import Summary from "./Summary"
interface iProps {
  rows: any[];
  hideSettleButton?: boolean;
}

export const All = (props: iProps) => {
  const [, setDetailedViewModel] = useGlobalState("DetailedViewModel");
  const [showPopUP, setShowPopUp] = useState<boolean>(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const [selectValue] = useGlobalState("selectValue");
  const [selectedRows, setSelectedRows] = useState([]);
  const [apiChange, setApiChange] = useGlobalState("apiChange");
  const [totalVariance, setTotalVariance] = useState<number>(0);
  const [selectedRowDetails, setSelectedRowDetails] = useState([]);
  const [disputePopUp, showDisputePopUp] = useState<boolean>(false);
  const [showDispute, setShowDispute] = useState<boolean>(false);
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const [comment, setComment] = React.useState<string>("");
  const [, setShowSettleDispute] = useGlobalState("showSettleDispute");
  const [globalCurrentRows] = useGlobalState("currentRows");
  const [rows, setRows] = useState<DiscrepencyHistory[]>([]);
  const branchDetails = useAppSelector((root) => root.branchDetails);
  const { isDiscrepancyStatus, DiscrepancyApi, saveCashDetailsApi } = useDiscrepancyAccount();

  useEffect(() => {
    setCheckedRows([]);
    if (props.rows) {
      const modifiedRow = props?.rows.map((item) => {
        return {
          ...item,
          reasonType: item.reason.title,
        };
      });
      setRows(modifiedRow);
    }
  }, [props.rows]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCellClicked = (params: {
    field: string;
    column: Column;
    data: unknown;
    rowIndex: number;
    columnIndex: number;
  }) => {
    setDetailedViewModel({ params: params, showModel: false });
    setShowSettleDispute({ showSettleDisputeButton: true });
  };

  const handleSelectAll = (params: { checked: boolean; data: any }) => {
    if (params.checked) {
      setCheckedRows(params.data?.map((v, index) => index));
    } else {
      setCheckedRows([]);
    }
  };

  const handleRowSelection = (params: { checked: boolean; data: any; rowIndex: number }) => {
    if (params.checked) {
      if (
        params.data?.reason.id === DISCREPANCY_STATUS.TC &&
        params?.data.statusCode === DISCREPANCY_STATUS.AWAITING_CODE
      ) {
        setShowHelp(false);
        setShowDispute(true);
      } else if (
        (params.data?.reason.id === DISCREPANCY_STATUS.TP ||
          params.data?.reason.id === DISCREPANCY_STATUS.BP) &&
        params?.data.statusCode === DISCREPANCY_STATUS.AWAITING_CODE
      ) {
        setShowDispute(false);
        setShowHelp(true);
      } else if (
        params.data?.reason.id !== DISCREPANCY_STATUS.TC &&
        params?.data.statusCode === DISCREPANCY_STATUS.HELP_CODE
      ) {
        setShowHelp(false);
        setShowDispute(true);
      } else if (params?.data.statusCode === DISCREPANCY_STATUS.DISPUTE_CODE) {
        setShowHelp(false);
        setShowDispute(false);
      }
      setCheckedRows((item) => [...item, params?.rowIndex]);
    } else {
      setCheckedRows((item) => {
        const position = item.indexOf(params.rowIndex);
        if (position !== -1) {
          return [...item.slice(0, position), ...item.slice(position + 1)];
        }
      });
    }
  };
  const saveCashRequestParams =
    selectValue?.data === "null"
      ? null
      : {
          transfer_at: BACK_OFFICE,
          transfer_amount: totalVariance > 0 ? totalVariance : -totalVariance,
          operation_type: totalVariance > 0 ? PAID_OUT : PAID_IN,
          reason_code: DISCREPANCY_SETTLEMENT,
          accountReferenceID: selectedRows[0]?.accountReferenceID,
          clientReference: selectedRows[0]?.clientReference,
          TCReference: selectedRows[0]?.TCReference,
          comments: COMMENT,
          reason: selectedRows[0]?.reason?.title,
          discrepencyDataID: selectedRows[0]?.discrepencyDataID,
          varianceBalance: selectedRows[0]?.varianceBalance,
          status: SETTLED,
          itemID: selectedRows[0]?.itemID,
          ...(totalVariance > 0
            ? {
                source_entity_type: selectValue?.data?.type,
                source_entityID: selectValue?.data?.value,
              }
            : {
                destination_entity_type: selectValue?.data?.type,
                destination_entityID: selectValue?.data?.value,
              }),
        };

  const handleCashDetails = () => {
    saveCashDetailsApi(saveCashRequestParams);
  };
  const callbackHandleUpdate = () => {
    if (selectValue.ShowSelect === SETTLE_CASH) {
      handleCashDetails();
    } else DiscrepancyApi(selectedRows);
    setShowPopUp(false);
  };
  useLayoutEffect(() => {
    if (isDiscrepancyStatus === "success") {
      setDetailedViewModel({ showModel: true });
      setShowPopUp(false);
    }
  }, [isDiscrepancyStatus]);

  const handleSettle = (value: string) => {
    const selectedRow = [];
    const mValue = {};
    globalCurrentRows.map((v, index) => {
      return checkedRows.filter((indexValue) => {
        if (indexValue === index && v?.status !== SETTLED) {
          return selectedRow.push(v);
        }
      });
    });
    let status: string;
    switch (value) {
      case "help":
        showDisputePopUp(true);
        status = DISCREPANCY_STATUS.HELP_REQUESTED;
        break;
      case "settled":
        showDisputePopUp(false);
        status = DISCREPANCY_STATUS.SETTLED;
        break;
      case "dispute":
        showDisputePopUp(true);
        status = DISCREPANCY_STATUS.DISPUTE_RAISED;
        break;
      default:
        status = "";
        break;
    }
    const requestPayLoad = selectedRow.map((item) => {
      mValue["discrepencyDataID"] = item?.discrepencyDataID;
      mValue["status"] = status;
      mValue["comment"] = comment;
      mValue["TCReference"] = item?.TCReference;
      mValue["accountReferenceID"] = item?.accountReferenceID;
      mValue["clientReference"] = item?.clientReference;
      mValue["reason"] = item?.reason;
      mValue["varianceBalance"] = item?.varianceBalance;
      mValue["itemID"] = item?.itemID;
      return { ...mValue };
    });
    setSelectedRows(requestPayLoad);
    const TotalVariance = selectedRow.reduce(
      (total, value) => total + Number(value?.entity?.itemDetails?.toDateVariance),
      0,
    );
    setTotalVariance(TotalVariance);
    setShowPopUp(true);
    setSelectedRowDetails(selectedRow);
  };
  const handleCellClickedCallback = useCallback(handleCellClicked, []);
  const rowSelectionCallback = useCallback(handleRowSelection, []);
  const handleSelectionAllCallback = useCallback(handleSelectAll, []);
  const renderAddonBtn = checkedRows && checkedRows.length > 0 && (
    <div className="flex w-1/3 justify-end mr-20">
      {checkedRows.length === 1 && showHelp && (
        <button
          onClick={() => handleSettle("help")}
          className="brandWhiteBtn mr-2 font-bold RightalignBtn">
          Help
        </button>
      )}
      {checkedRows.length === 1 && showDispute && (
        <button
          onClick={() => handleSettle("dispute")}
          className="brandWhiteBtn mr-2 font-bold RightalignBtn">
          Dispute
        </button>
      )}
      <button
        className="accounting-button-discrepancy ml-2 RightalignBtn"
        onClick={() => handleSettle("settled")}>
        Settle
      </button>
    </div>
  );
  const handleComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setComment(value);
  };
  return (
    <div className="w-full transferOut-height">
      <div className="p-4 w-full h-full relative dynamictable hideDiv">
        <TableView
          columns={BRANCH_DISCREPENCY_SUMMARY_COLUMN}
          rows={rows}
          onRowSelection={rowSelectionCallback}
          onCellClicked={handleCellClickedCallback}
          onSelectAll={handleSelectionAllCallback}
          isDiscrpencyTable={true}
          renderAddonBtn={renderAddonBtn}
        />
        {showPopUP && branchDetails.branch_type !== IN_BRANCH_TYPE && !disputePopUp && (
          <HighLevelPopUp
            selectedRow={selectedRowDetails}
            totalAmount={totalVariance}
            onSuccess={() => callbackHandleUpdate()}
            onCancel={() => setShowPopUp(false)}
          />
        )}
        {showPopUP && branchDetails.branch_type === IN_BRANCH_TYPE && !disputePopUp && (
          <SettleMentPopUp
            selectedRow={selectedRowDetails}
            totalAmount={totalVariance}
            onSuccess={() => callbackHandleUpdate()}
            onCancel={() => setShowPopUp(false)}
          />
        )}
        {showPopUP && disputePopUp && (
          <DisputePopUp
            comment={comment}
            handleComment={handleComment}
            onSuccess={() => callbackHandleUpdate()}
            onCancel={() => {
              setComment("");
              setShowPopUp(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
