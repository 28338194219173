export const RightSideView = ({
  disable,
  warningFlag,
  invaildLimitFlag,
  invalidFund,
  enterAmount,
  handleValue,
  selectedAmount,
  handleCashDetails,
}) => {
  return (
    <div className={`transferIn-right-side bg-white pt-6 pl-8 ${disable ? "" : "diableBtnCss"}`}>
      <h2 className="heading-3 font-bold mb-5">Cash Transfer Amount</h2>
      <div className="h-4/5 flex w-full">
        <div className="grid grid-cols-1 gap-12 w-full">
          <div className={`flex items-center ${disable ? "" : "diableBtnCss"}`}>
            {/* <div className='grid grid-cols-3 gap-6'>
          {btnAmount}
        </div> */}
            <div className="transfer-out-section w-full flex flex-col justify-center items-center mt-5">
              {warningFlag && !invaildLimitFlag && !invalidFund && (
                <h2 className="heading w-full text-center warningMsg">
                  Warning: Transfer limit has been surpassed
                </h2>
              )}
              {invaildLimitFlag && !invalidFund && (
                <h2 className="heading w-full text-center errorMsg">
                  This transaction will not be permitted{" "}
                  <i
                    className="fas fa-info-circle errorMsg cursor-pointer"
                    title="Transaction above £26000.00 will not be permitted."></i>
                </h2>
              )}
              {invalidFund && (
                <h2 className="heading w-full text-center errorMsg">
                  This cash Drawer has insufficient fund to transfer
                </h2>
              )}
              <div
                className={`justify-center flex-col cursor-pointer items-center flex block w-full ${
                  disable ? "" : "diableBtnCss"
                }`}>
                <>
                  {" "}
                  <h2 className="text-lg font-bold mb-5">Enter Amount</h2>
                  <input
                    className={`brandWhiteBtn w-full mb-4 ${disable ? "" : "diableBtnCss"}`}
                    disabled={!disable}
                    pattern="[0-9]"
                    onKeyDown={handleValue}
                    onChange={(e) => {
                      enterAmount(e);
                    }}
                    value={selectedAmount}
                  />
                  <button
                    className={`w-10/12 accountingBtnNoHover  ${
                      disable ? "accounting-button" : "diableBtnCss"
                    }`}
                    disabled={invaildLimitFlag || !selectedAmount || selectedAmount === "£"}
                    onClick={() => {
                      handleCashDetails();
                    }}>
                    Submit
                  </button>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
