import PopupConfirm from "@bbo/components/Alert";
import Loader from "@bbo/components/Loader";
import { useAppSelector } from "@bbo/lib";
import { useNavigate } from "react-router-dom";
import { useTransferout } from "./useTransferOut";

import PrintConfirm from "@bbo/components/Printconfirm/index";
import { LeftSideView } from "./LeftSideView";
import { MiddleView } from "./MiddleView";
import { RightSideView } from "./RightSideView";

const TransferOutPage = () => {
  const {
    transferDateTime,
    transferAmount,
    selectOption,
    activeHeadingName,
    listItems,
    setisDrawers,
    isDrawers,
    handleCashDetails,
    clearFlag,
    invalidFund,
    invaildLimitFlag,
    warningFlag,
    disable,
    transferListIsLoading,
    onSelectHandleChange,
    selectView,
    ReceiptPrint,
    setclearFlag,
    selectedAmount,
    enterAmount,
    handleValue,
    printData,
    setPrintData,
  } = useTransferout();

  const selectedTask = useAppSelector((root) => root.selectedTask);
  const navigate = useNavigate();

  return (
    <>
      {transferListIsLoading ? (
        <Loader />
      ) : (
        <>
          <div className="subheading font-24 font-bold leading-7 lg:w-screen">
            <div className="width-70 float-left">
              <button
                onClick={() => {
                  navigate("/branchOverview");
                }}>
                <i className="fas fa-chevron-left"></i> Back
              </button>
            </div>
          </div>
          <div className="containerMain">
            <div className="lg:w-full transferOut-height overflow-y-auto grid grid-cols-1 gap-5 lg:grid-cols-3 lg:gap-10">
              <LeftSideView transferAmount={transferAmount} transferDateTime={transferDateTime} />
              <MiddleView
                activeHeadingName={activeHeadingName}
                isDrawers={isDrawers}
                listItems={listItems}
                onSelectHandleChange
                selectOption={selectOption}
                selectView={selectView}
                setisDrawers={setisDrawers}
              />
              <RightSideView
                disable={disable}
                enterAmount={enterAmount}
                handleCashDetails={handleCashDetails}
                handleValue={handleValue}
                invaildLimitFlag={invaildLimitFlag}
                invalidFund={invalidFund}
                selectedAmount={selectedAmount}
                warningFlag={warningFlag}
              />
              <>
                {printData && (
                  <PrintConfirm
                    onCancel={() => {
                      setclearFlag(false);
                      setPrintData(false);
                      navigate("/branchOverview");
                    }}
                    onReprint={() => {
                      ReceiptPrint("TRANSFER OUT DUPLICATE");
                    }}
                    onSuccess={() => {
                      setPrintData(false);
                      navigate("../branchOverview", {
                        replace: true,
                        state: {
                          showLimit: selectedTask.associatedEntityType === "cash_drawer",
                        },
                      });
                    }}
                  />
                )}
                {clearFlag && !printData && (
                  <PopupConfirm
                    header="Cash Transfer out is successful!"
                    labelCancel="Close"
                    labelSuccess="Print Receipt"
                    tickicon={true}
                    onCancel={() => {
                      navigate("../branchOverview", {
                        replace: true,
                      });
                      setclearFlag(false);
                    }}
                    onSuccess={() => {
                      ReceiptPrint("TRANSFER OUT");

                      setPrintData(true);
                    }}
                  />
                )}
              </>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TransferOutPage;
