import { useGetBusinessRulesByBranchId } from "@bbo/api/generator";
import link from "@bbo/assets/images/link.svg";
import userCheck from "@bbo/assets/images/user-check.svg";
import PopupConfirm from "@bbo/components/Alert";
import { useAppSelector } from "@bbo/lib";
import useBranchApi from "@bbo/lib/hooks/useBranchLogsApi";
import useBussinesRules from "@bbo/lib/hooks/useBussinesRules";
import useMenuApi from "@bbo/lib/hooks/useMenuApi";
import { setSelectedTask } from "@bbo/lib/reduxStore/selectedTask.slice";
import { useGlobalState } from "@bbo/lib/state";
import { AccountPopupData } from "@bbo/types/CashOverviewResponse";
import { amountSignedPound, currencyStyle } from "@bbo/utils/currencyFormatter";
import { formatDateYearMonthDay } from "@bbo/utils/dateTimeFormatter";
import { deepLink, isDeviceMobile, setValueInStorage } from "@bbo/utils/util";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  header: string;
  data: AccountPopupData;
  onClose: () => void;
};

export const ActionList = ({ data, header, onClose }: Props) => {
  const { fadCode } = useAppSelector((root) => root.auth.authUserData);
  const [, setruleList] = useGlobalState("BusinesRule");
  const [, setReasonCode] = useGlobalState("reasonCode");
  const [, setPopupData] = useGlobalState("PopupData");
  const [, setLastBalanceInLogs] = useGlobalState("lastBalanceInLogs");
  const [, setrouteState] = useGlobalState("routeState");
  const [userName] = useGlobalState("userName");
  const [URL, setURl] = useState("");
  const [operationType, setoperationType] = useState("");
  const [isMobile] = useState(isDeviceMobile());
  const [, setMaxLimitCounter] = useGlobalState("maxLimitCounter");
  const { branch_type } = useAppSelector((root) => root.branchDetails);
  const dispatch = useDispatch();
  const [pouchPopup, setPouchPopup] = React.useState({
    isPopupPouch: false,
    message: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { last_varience, last_countdate, lastbalancedLabel, orderRows } = useMemo(() => {
    let last_varience = "Last Variance";
    let last_countdate = "To Date Variance";
    let lastbalancedLabel = "Last Balance Date";
    let orderRows = ["currentVariance", "toDateVariance", "lastBalancedDateTime"];
    if (location.pathname === "/branchOverviewForex") {
      last_varience = "Last Count Variance";
      last_countdate = "To Date Variance";
      lastbalancedLabel = "Last Count Date";
      orderRows = ["currentVariance", "lastBalancedDateTime", "toDateVariance"];
    }

    return { last_countdate, last_varience, orderRows, lastbalancedLabel };
  }, [location.pathname]);
  const orderedTable = useMemo(
    () =>
      orderRows.map((row) => {
        const { currentVariance = 0, toDateVariance, lastBalancedDateTime } = data;
        switch (row) {
          case "currentVariance":
            return (
              <tr className={currencyStyle(currentVariance)}>
                <td>{last_varience}</td>
                <td>
                  <b>{amountSignedPound(currentVariance)}</b>
                </td>
              </tr>
            );
          case "toDateVariance":
            return (
              <tr>
                <td>{last_countdate}</td>
                <td>
                  <b>{amountSignedPound(toDateVariance)}</b>
                </td>
              </tr>
            );
          case "lastBalancedDateTime":
            return (
              <tr>
                <td>{lastbalancedLabel}</td>
                <td>
                  <b>{formatDateYearMonthDay(lastBalancedDateTime)}</b>
                </td>
              </tr>
            );
          default:
            break;
        }
      }),
    [data, last_countdate, last_varience, lastbalancedLabel, orderRows],
  );
  const [{ data: counterData }] = useBussinesRules("getBusinessRulesByBranchId", "1", "", "GET");
  const { data: counterDataLimits, isLoading } = useGetBusinessRulesByBranchId(fadCode, {
    rule_type: "transfer_out",
    location: "",
    entity_id: data?.entityID,
  });

  let request;
  if (data.associatedEntityID) {
    request = {
      branchId: 1,
      operationType: operationType,
      entityID: data.associatedEntityID,
    };
  } else {
    request = {
      branchId: 1,
      operationType: operationType,
      entityID: data.entityID,
    };
  }

  const [{ data: reasonCodeList, status: reasonCodeListStatus }, getReasonCodeApi] = useMenuApi(
    "",
    { operationType: operationType, branchType: branch_type },
    "",
    "GET",
  );
  const [{ data: getLogsData, status: getLogsStatus }, getLogs] = useBranchApi(
    "",
    {
      fadcode: data?.fadcode,
      entity: data?.associatedEntityType,
      entityID: data?.associatedEntityID,
      status: "continue_cash_transfer",
    },
    "",
    "GET",
  );
  const ApiCounterCall = async (url) => {
    getLogs("getLogsDetails");
    if (url === "/transferOut") {
      setoperationType("transfer_out");
      setrouteState({ RouteState: "transfer_out" });

      navigate(url);
      // getReasonCodeApi("getReasonCodeList");
    } else if (url === "/transferIn") {
      setoperationType("transfer_in");
      setrouteState({ RouteState: "transfer_in" });

      navigate(url);
    } else if (url === "/paidIn") {
      setoperationType("paid_in");
      setrouteState({ RouteState: "paid_in" });
      getReasonCodeApi("getReasonCodeList");
      setURl(url);
      await reasonCodeList;
    } else if (url === "/paidOut") {
      setoperationType("paid_out");
      setrouteState({ RouteState: "paid_out" });
      getReasonCodeApi("getReasonCodeList");
      setURl(url);
      await reasonCodeList;
    } else if (url === "currency_cnt_sum_1") {
      setoperationType(url);
      setrouteState({ RouteState: url });
      setURl("/currencyCountSummary");
      navigate("/currencyCountSummary");
      getReasonCodeApi("getReasonCodeList");
    } else if (url === "currency_cnt_1") {
      setoperationType(url);
      setrouteState({ RouteState: url });
      setURl("/currencyCount");
      navigate("/currencyCount");
      getReasonCodeApi("getReasonCodeList");
    } else {
      navigate(url);

      setrouteState({ RouteState: url });
    }
  };

  useLayoutEffect(() => {
    if (reasonCodeList !== typeof undefined && reasonCodeListStatus === "fetched") {
      let reasonCodes = reasonCodeList;
      reasonCodes = reasonCodes.filter((obj) => obj.code !== "DS");
      setReasonCode([...reasonCodes]);

      if (operationType === "paid_out" || operationType === "paid_in") {
        navigate(URL);
      }
    }
  }, [URL, navigate, operationType, reasonCodeList, reasonCodeListStatus, setReasonCode]);

  useLayoutEffect(() => {
    if (getLogsData !== typeof undefined && getLogsStatus === "fetched") {
      if (getLogsData.length !== 0) {
        setLastBalanceInLogs(getLogsData[0]?.entity.itemDetails?.balance);
        navigate(URL);
      } else setLastBalanceInLogs(0);
    }
  }, [URL, getLogsData, getLogsStatus, navigate, reasonCodeListStatus, setLastBalanceInLogs]);

  useEffect(() => {
    if (counterDataLimits !== undefined && counterDataLimits?.length !== 0) {
      setMaxLimitCounter(counterDataLimits[0]?.permittedLimit);
    } else setMaxLimitCounter(0);
  }, [counterDataLimits, isLoading, setMaxLimitCounter]);

  useEffect(() => {
    setruleList([...counterData]);
  }, [counterData, setruleList]);

  const handleAction = (params, index = 0) => {
    let mHeader;
    if (data.entityID) {
      mHeader = data.entityName;
    } else {
      mHeader = header;
    }
    const mData = {
      head: mHeader,
      data: data as any,
      index: index || "",
    };

    setPopupData(mData);
    setValueInStorage("entityID", mData.data?.entityID);

    dispatch(
      setSelectedTask({
        fadcode: mData.data?.fadcode,
        entity: mData.data?.entity,
        entityID: mData.data?.entityID,
        entityName: mData.data?.entityName,
        itemDetails: mData.data?.itemDetails,
        associatedEntityName: mData.data?.associatedEntityName,
        associatedEntityType: mData.data?.associatedEntityType,
        associatedEntityID: mData.data?.associatedEntityID,
      }),
    );

    if (params.url || params.id) {
      ApiCounterCall(params.url || params.id);
    }
  };

  const buttonStyle = (index) => {
    if (data.actionList.length % 2 !== 0 && data.actionList.length === index + 1) {
      return "branchOverviewButton font-bold col-span-2";
    }
    return "branchOverviewButton font-bold";
  };

  /**
   * Dispatch or Acceptance button action handling
   * @param params function button to perform Pouch Dispatch or Pouch Acceptance
   * @param index key index
   * @returns
   */
  const renderPouchAction = (params, index) => {
    if (isMobile) {
      const refreshToken = localStorage.getItem(
        `CognitoIdentityServiceProvider.3bug83m4vi0j030qikm703k87n.${userName?.username}.refreshToken`,
      );

      const CT_DEEP_LINK =
        params.id === "pouch_despatch_1"
          ? deepLink.pouchDispatch(refreshToken)
          : deepLink.pouchAcceptance(refreshToken);

      return (
        <a key={index} className="branchOverviewButton font-bold" href={CT_DEEP_LINK}>
          {params.name}
        </a>
      );
    }

    return (
      <button
        key={index}
        className="branchOverviewButton font-bold"
        onClick={() => {
          if (location.pathname !== "/branchOverviewForex") {
            setPouchPopup({
              isPopupPouch: true,
              message: `${params.name} is only available via the Counter Terminal App.`,
            });
          }
        }}>
        {params.name}
      </button>
    );
  };

  const ActionButtons = () => {
    // eslint-disable-next-line no-debugger
    return (
      <>
        {data.actionList?.map((item, index) => {
          if (item.id === "pouch_acc_1" || item.id === "pouch_despatch_1") {
            return renderPouchAction(item, index);
          }

          return (
            <button
              key={index}
              className={buttonStyle(index)}
              onClick={() => {
                if (
                  location.pathname !== "/branchOverviewForex" ||
                  item.name === "Currency Count Summary" ||
                  item.name === "Currency Count"
                ) {
                  handleAction(item, index);
                }
              }}>
              {item.name}
            </button>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div className="justify-center bg-opacity-75 transition-opacity bg-gray-500 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="p-6 relative flex flex-col w-full cursor-pointer  bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between">
              <h3 className="heading-5 font-bold text-color">
                <span className="capitalize">{data.entityName}</span>
                {data?.associatedEntityName && <img src={link} className="mx-2 inline" />}
                {data?.associatedEntityName && <span>{data?.associatedEntityName}</span>}
              </h3>
              <button
                className="ml-auto border-0 text-black opacity-1 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={onClose}>
                <span className="text-black opacity-1 w-6 text-3xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {data?.associatedEntityName && (
              <div className="second-heading h-10 truncate">
                <img src={userCheck} className="mr-2" /> <span>{data?.userFullName}</span>
              </div>
            )}
            <hr className="border-gray-200 border-bottom-none my-3.5" />
            {/*body*/}
            <div className="relativeflex-auto">
              <div className="w-full accounting-card">
                <div className="float-left w-full">
                  <div className="accounting-content">
                    <table className="w-full border-none">
                      {/* <tbody>
                        <tr className={currencyStyle(data?.currentVariance || 0)}>
                          <td>Last Variance</td>
                          <td>
                            <b>{amountSignedPound(data?.currentVariance)}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>To Date Variance</td>
                          <td>
                            <b>{amountSignedPound(data?.toDateVariance)}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Last Balance Date</td>
                          <td>
                            <b>{formatDateYearMonthDay(data?.lastBalancedDateTime)}</b>
                          </td>
                        </tr>
                      </tbody> */}
                      <tbody>{orderedTable}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <ActionButtons />
            </div>
            {pouchPopup.isPopupPouch && (
              <PopupConfirm
                header={pouchPopup.message || ""}
                labelSuccess="Close"
                tickicon={false}
                onSuccess={() => setPouchPopup({ isPopupPouch: false, message: "" })}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
