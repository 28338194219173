/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

PO Transactions

PO Transactions Viewer

Cash Drawer Association API
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { StockCount, InternalServerError } from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Get stock count for a branch
 * @summary This endpoint will get the stock counts list on a branch
 */
export const useGetStockCountListHook = () => {
  const getStockCountList = useCustomInstance<StockCount | void>();

  return (fadcode: string) => {
    return getStockCountList({ url: `/stock-management/stockcount/${fadcode}`, method: "get" });
  };
};

export const getGetStockCountListQueryKey = (fadcode: string) => [
  `/stock-management/stockcount/${fadcode}`,
];

export type GetStockCountListQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetStockCountListHook>>
>;
export type GetStockCountListQueryError = ErrorType<InternalServerError>;

export const useGetStockCountList = <
  TData = AsyncReturnType<ReturnType<typeof useGetStockCountListHook>>,
  TError = ErrorType<InternalServerError>,
>(
  fadcode: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetStockCountListHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetStockCountListQueryKey(fadcode);

  const getStockCountList = useGetStockCountListHook();

  const queryFn: QueryFunction<AsyncReturnType<ReturnType<typeof useGetStockCountListHook>>> = () =>
    getStockCountList(fadcode);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetStockCountListHook>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!fadcode, ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};
