import { useGlobalState } from "@bbo/lib/state";
import { amountSignedPound, currencyStyle } from "@bbo/utils/currencyFormatter";
import UserAvatar from "../../assets/images/varinace.png";
import { useAdhocBalance } from "./useAdhocBalance";

export const BalanceCheck = () => {
  const [moveDiscrepancyButton] = useGlobalState("moveDiscrepancyButton");

  const {
    adhocData,
    PopupData,
    maxLimitCounter,
    rollOver,
    total,
    navigate,
    routeState,
    handleTransferOut,
    handleNext,
    setRollOver,
    setIsRecount,
    handleSaveVariance,
    setStatusRes,
    resetLadderValue,
  } = useAdhocBalance();

  const renderFunction = () => {
    if (adhocData.variance === 0) {
      return (
        <>
          <div className="balanceborder mx-auto flex flex-col items-center justify-center varianceBox">
            <i className="fas fa-check text-5xl paidTickColor bg-white"></i>
            <p className="balance-check" data-cy="BalanceCheck">
              Balance Completed
            </p>
            <br />
            {PopupData.data.entityID !== "safe_1" &&
            maxLimitCounter !== 0 &&
            total.totalCash >= maxLimitCounter ? (
              <>
                <small className="w-full flex flex-nowrap text-center pt-5 text-lg">
                  <p>Cash drawer limit exceeded. Please transfer excess cash to the Safe.</p>
                </small>

                <div className="mx-auto mt-6 flex items-center ">
                  <button
                    className="inline-flex bg-white-600 justify-center py-4 px-8
    border shadow-sm text-sm font-medium mr-3
    rounded-md brandWhiteBtn"
                    onClick={() => handleNext()}
                    data-cy="NextBtn">
                    Continue
                  </button>
                  <button
                    className="inline-flex bg-red-600 justify-center py-4 px-8
    border border-transparent shadow-sm text-sm font-medium mr-3
    rounded-md text-white brandRedBtn"
                    onClick={() => handleTransferOut()}
                    data-cy="NextBtn">
                    Transfer
                  </button>
                </div>
              </>
            ) : (
              <>
                <small className="w-full text-center pt-5 text-lg">
                  <p className="displayNone">Within the cash drawer Limit</p>
                </small>
                <div className="mx-auto flex items-center justify-end">
                  <button
                    className="inline-flex bg-red-600 justify-center py-2 px-4
border border-transparent shadow-sm text-sm font-medium mr-2
rounded-md text-white brandRedBtn"
                    onClick={() => handleNext()}
                    data-cy="NextBtn">
                    Next
                  </button>
                </div>
              </>
            )}
          </div>
        </>
      );
    } else
      return (
        <>
          <div className="balanceborder mx-auto flex flex-col items-center justify-center varianceBox">
            <div className="text-center">
              <img className="mb-4 h-16 w-16" src={UserAvatar} />
            </div>
            <div className="balance-check text-center" data-cy="BalanceCheck">
              There is a variance of
              <div className={currencyStyle(adhocData.variance)}>
                {amountSignedPound(adhocData.variance)}
              </div>
            </div>
          </div>
          <div className="mx-auto flex items-center justify-center mt-2 ">
            <button
              className="inline-flex justify-center text-black font-bold p-2 mr-4 rounded brand_button resetBtn"
              onClick={() => {
                if (routeState.RouteState === "TradingPeriodCashBalance") {
                  navigate("../tradingPeriod", { replace: true });
                  // window.location.reload()
                } else if (routeState.RouteState === "BalancePeriodCashBalance") {
                  navigate("../balancePeriod", { replace: true });
                  // window.location.reload()
                } else navigate("../branchOverview", { replace: true });
                resetLadderValue();
              }}>
              Cancel
            </button>
            <button
              className="inline-flex justify-center text-black font-bold p-2 mr-4 rounded brand_button resetBtn"
              onClick={() => {
                setStatusRes("Recount");
                handleSaveVariance();
              }}
              data-cy="RecountBtn">
              Recount
            </button>
            {moveDiscrepancyButton.moveDiscrepancyButton && (
              <button
                className="inline-flex justify-center text-black font-bold p-2 mr-4 rounded brand_button resetBtn"
                data-cy="RolloverBtn">
                Move to Discrepancy Account
              </button>
            )}
            <button
              className="inline-flex bg-red-600 justify-center py-2 px-4
    border border-transparent shadow-sm text-sm font-medium 
    rounded-md text-white brandRedBtn"
              onClick={() => {
                setIsRecount({ isRecount: false });
                setRollOver(true);

                setStatusRes("Accepted");
                handleSaveVariance();
              }}
              data-cy="RolloverBtn">
              Complete
            </button>
          </div>
        </>
      );
  };

  const Rollover = (
    <>
      <div className="balanceborder text-2xl mx-auto mb-8 flex flex-col flex-nowrap items-center justify-center varianceBox">
        <i className="fas fa-check text-5xl paidTickColor bg-white"></i>
        <div className="balance-check text-center flex-nowrap" data-cy="BalanceCheck">
          <div data-cy="BalanceCheckPlus">
            Balance completed with a variance of
            <div className={currencyStyle(adhocData.variance)}>
              {amountSignedPound(adhocData.variance)}
            </div>
          </div>
        </div>{" "}
        {PopupData.data.entityID !== "safe_1" &&
        maxLimitCounter !== 0 &&
        total.totalCash >= maxLimitCounter ? (
          <>
            <small className="w-full flex flex-nowrap text-center pt-5 text-lg">
              <p>Cash drawer limit exceeded. Please transfer excess cash to the Safe.</p>
            </small>

            <div className="mx-auto mt-6 flex items-center ">
              <button
                className="inline-flex bg-white-600 justify-center py-4 px-8
    border shadow-sm text-sm font-medium mr-3
    rounded-md brandWhiteBtn"
                onClick={() => handleNext()}
                data-cy="NextBtn">
                Continue
              </button>
              <button
                className="inline-flex bg-red-600 justify-center py-4 px-8
    border border-transparent shadow-sm text-sm font-medium mr-3
    rounded-md text-white brandRedBtn"
                onClick={() => handleTransferOut()}
                data-cy="NextBtn">
                Transfer
              </button>
            </div>
          </>
        ) : (
          <>
            <small className="w-full text-center pt-5 text-lg">
              <p className="displayNone">Within the cash drawer Limit</p>
            </small>
            <div className="mx-auto flex items-center justify-end">
              <button
                className="inline-flex bg-red-600 justify-center py-2 px-4
border border-transparent shadow-sm text-sm font-medium mr-2
rounded-md text-white brandRedBtn"
                onClick={() => handleNext()}
                data-cy="NextBtn">
                Next
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
  return (
    <>
      <div className="newAlignCss">{rollOver ? Rollover : renderFunction()}</div>
    </>
  );
};
