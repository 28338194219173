import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import React, { useEffect } from "react";
import HeaderTabs from "./HeaderTabs";
import "./index.css";
import { TradingDetailViewOverview } from "./TradingDetailViewOverview";

interface detailViewProps {
  isCompleteTradingButtonEnabled?: boolean;
  isTradingPeriodCompleted?: boolean;
  fromHistory?: boolean;
  selectedRow?: any;
  fromDiscrepancyAccount?: boolean;
}

export const DetailView = ({
  isTradingPeriodCompleted,
  fromHistory,
  selectedRow,
  fromDiscrepancyAccount,
}: detailViewProps) => {
  const [selectedTab, setSelectedTab] = React.useState<string>();
  const cashlist = useAppSelector((root) => root.BpTp);
  const [selectedValue, setSelectedTaValue] = React.useState<any>();
  const [loadData, setloadData] = React.useState<any>();
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const maxRecordsPerPage = 8;
  const [currentCashLists, setCurrentCashLists] = React.useState<any>();
  const [totalPages, setTotalPages] = React.useState<number>();

  useEffect(() => {
    if (fromHistory || fromDiscrepancyAccount) {
      setSelectedTaValue(selectedRow.cash.accountingLocations[0]);
    } else setSelectedTaValue(cashlist.safe);
  }, [cashlist.safe]);

  const handleSelectTab = (params) => {
    if (fromHistory || fromDiscrepancyAccount) {
      const loadData = [...selectedRow.cash.accountingLocations];
      let value;
      loadData?.map((item) => {
        if (item.entityName === params.name) {
          value = item;
        }
      });
      setSelectedTaValue(value);
    } else {
      setSelectedTab(params.name);
      const loadData = [cashlist.safe, ...cashlist.cashDrawer];
      let value;
      loadData?.map((item) => {
        if (item.entityName === params.name) {
          value = item;
        }
      });
      setSelectedTaValue(value);
    }
  };

  useEffect(() => {
    if (cashlist && !fromHistory && !fromDiscrepancyAccount) {
      const indexOfLastCashList = currentPage * maxRecordsPerPage;
      const indexOfFirstCashList = indexOfLastCashList - maxRecordsPerPage;
      const loadData = [cashlist.safe, ...cashlist.cashDrawer];
      const headingName = loadData?.map((item) => {
        return item.entityName;
      });
      setloadData(headingName);
      setSelectedTab(headingName[0]);
      const currentCashList = headingName.slice(indexOfFirstCashList, indexOfLastCashList);
      setCurrentCashLists(currentCashList);
      const calculateTotalPage = Math.ceil(headingName.length / maxRecordsPerPage);
      setTotalPages(calculateTotalPage);
    } else if (fromHistory || fromDiscrepancyAccount) {
      const indexOfLastCashList = currentPage * maxRecordsPerPage;
      const indexOfFirstCashList = indexOfLastCashList - maxRecordsPerPage;
      const loadData = [...selectedRow.cash.accountingLocations];
      const headingName = loadData?.map((item) => {
        return item.entityName;
      });

      setloadData(headingName);
      setSelectedTab(headingName[0]);
      const currentCashList = headingName.slice(indexOfFirstCashList, indexOfLastCashList);
      setCurrentCashLists(currentCashList);
      const calculateTotalPage = Math.ceil(headingName.length / maxRecordsPerPage);
      setTotalPages(calculateTotalPage);
    }
  }, [cashlist, currentPage]);

  const loadNextData = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
  };
  const loadPreviousData = () => {
    const previousPage = currentPage - 1;
    setCurrentPage(previousPage);
  };

  return (
    <div className="w-full px-10 mt-6">
      <div className="flex justify-center">
        <h2 className="testSizeSubHead text-black">Detail View</h2>
      </div>
      <div className="w-full detailViewHeader overflow-y-auto">
        {loadData && (
          <HeaderTabs
            tabLabels={currentCashLists}
            selected={selectedTab}
            varianceAmount={selectedRow?.varianceAmount}
            onSelectTab={handleSelectTab}
            loadNextData={loadNextData}
            loadPreviousData={loadPreviousData}
            totalPages={totalPages}
            currentPage={currentPage}
          />
        )}
        <TradingDetailViewOverview
          selectedTab={selectedTab}
          selectedValue={selectedValue ? selectedValue : cashlist.safe}
          isTradingPeriodCompleted={isTradingPeriodCompleted}
          fromHistory={fromHistory}
          fromDiscrepancyAccount={fromDiscrepancyAccount}
        />
        <div className="w-full pt-1 pb-10 mb-10">
          <div className="w-full complete-trading-period-wrapper overflow-y-auto"></div>
        </div>
      </div>
    </div>
  );
};
