import * as Constants from "@bbo/constants/ForexConstants";
import CashDrawerList from "../../BranchOverview/CashDrawerList";
import Counters from "../../BranchOverview/CounterList";
import Safe from "../../BranchOverview/SafeItem";

export interface IOverviewProps {
  forex;
}

export default function Overview({ forex }: IOverviewProps) {
  return (
    <div data-testid="overview-testid">
      <Counters items={forex.counterTerminal} header={Constants.COUNTERS} />
      <hr className="border-black border-bottom-none mb-5 w-full" />
      <div className="w-full accounting-card block float-left h-56 overflow-x-auto whitespace-nowrap">
        <Safe header={Constants.SAFE} data={forex.safe} />
      </div>
      <hr className="border-black border-bottom-none mb-5 w-full" />
      <CashDrawerList header={Constants.CASH_DRAWERS} items={forex.cashDrawer} />
    </div>
  );
}
