import { useGlobalState } from "@bbo/lib/state";
import { getOnlyDate, getonlyTime } from "@bbo/utils/dateTimeFormatter";

/**
 * Alert/Popup view with
 * @param props
 * @returns
 */

interface iProps {
  data?: any;
  CommitFlag?: boolean;
  Show?: boolean;
}

export const SystemGeneratedDetails = (props: iProps) => {
  const [KnownVarianceAPI] = useGlobalState("KnownVarianceAPI");

  return (
    <>
      <h2 className="mainHeadingRecord mb-1"> System Generated Details</h2>
      <div className="DetailView">
        {props.Show && (
          <table className="w-full border-none">
            <tbody className="SystemTable">
              {KnownVarianceAPI?.Success?.systemDetails?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="width51">
                      <b>{item.label} -</b>
                    </td>
                    <td className="width49">
                      {item.label === "Date"
                        ? getOnlyDate(item.value)
                        : item.label === "Time"
                        ? getonlyTime(item.value)
                        : item.value}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};
