import useStockCountProductDescApi from "@bbo/lib/hooks/useStockCountProductDescApi";
import { useGlobalState } from "@bbo/lib/state";
import { getValueFromStorage, setValueInStorage } from "@bbo/utils/util";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import BarcodeScanForm from "./BarcodeScanForm";
import { ConfirmationPopUpBody } from "./ConfirmationPopUpBody";
import { CountTypes } from "./CountTypes";
import "./index.css";

import {
  useGetBranchData,
  useGetStockCountList,
  useGetStockManagementStockCountSuspend,
  usePutStockManagementStockCountSuspend,
  usePutStockManagementStockCountStatus,
} from "@bbo/api/generator";
import Loader from "@bbo/components/Loader";
import { Popup } from "@bbo/components/Popup";
import { useReceiptPrinter } from "@bbo/lib/hooks/useReceiptPrinter";
import { currentDate, currentTime } from "@bbo/utils/dateTimeFormatter";
import { StockCountProductPrintReceiptTemplate } from "@bbo/utils/ReceiptPrinterTemplates/StockCountProducts";
import {
  calculateVariance,
  convertCountedProductToStockItem,
  covertSuspendedStockProducts,
  getUpdatedCountedProducts,
  persistStockCountCompleted,
} from "../StockCountReview/stockCountReviewHelpers";
import { ProductDescription } from "./ProductDescription";
import { WarningPopUp } from "./WarningPopUp";
import { amountInPence } from "@bbo/utils/currencyFormatter";

// stock count interface
interface iStockCount {
  selectedCountType: string;
  isProductLoaded: boolean;
  searchedProduct: string;
  grapevineReferenceNumber: string;
}

export interface CountedProduct {
  description: string;
  name: string;
  itemID: string;
  quantity: number;
  value?: number;
  ean?: string;
  state?: string;
  barcode: string;
  countGroup: string;
  enteredQuantity: number | string;
  additionalQuantity: number | string;
  productImage?: string;
  packSize?: string;
  varianceQuantity?: string | number;
  varianceAmount?: string | number;
  systemQuantity?: string | number;
}

interface IPrintResponse {
  [key: string]: CountedProduct;
}
export interface IEntityData {
  entityID?: string;
  entityName?: string;
  fadcode?: string;
  entity?: string;
}
interface IProps {
  entityData: IEntityData;
}

const initialState = {
  selectedCountType: "",
  isProductLoaded: false,
  searchedProduct: "",
  grapevineReferenceNumber: "",
};

export const StockCount = ({ entityData }: IProps) => {
  const navigate = useNavigate();
  const [state, setState] = useState<iStockCount>(initialState);
  const [productDetailsAtAlCountGroup, setProductDetailsAtAlCountGroup] =
    useState<CountedProduct>(null);
  const [countTypeSelection, setCountTypeSelection] = useGlobalState("COUNTTYPE");
  const [countedProducts, setCountedProducts] = useState<CountedProduct[]>([]);
  const [initialCall, setInitialCall] = useState<boolean>(true);
  const [showPopUP, setShowPopUp] = useState<boolean>(false);
  const [showActionPopUP, setShowActionPopUp] = useState<boolean>(false);
  const [isCountedProduct, setIsCountedProduct] = useState<boolean>(false);
  const [, setBarCodeFormEnable] = useGlobalState("BarCodeFormEnable");
  const [, setCountTypeTitleShow] = useGlobalState("CountTypeTitleShow");
  const receiptPrinterService = useReceiptPrinter();
  const fadCode = getValueFromStorage<string>("fadcode");
  const [isCountSuspended, setIsCountSuspended] = useState<boolean>(false);
  const [, setIsResume] = useState<boolean>(false);
  const [showCompletedPopup, setShowCompletedPopup] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useGlobalState("isUserLoggedIn");

  const { data: stockCount } = useGetStockCountList(fadCode);

  const [
    {
      data: _productDetailsResponse,
      status: responseStatus,
      error: responseError,
      statusCode: responseStatusCode,
    },
    getProductDetailsAtAlCountGroup,
    refresh,
  ] = useStockCountProductDescApi(
    "",
    {
      entityID: entityData?.entityID,
      fadcode: entityData?.fadcode, // GlobalUSerName.attributes["custom:branch_id"],
      countGroup: state.selectedCountType,
      searchedProduct: state.searchedProduct,
    },
    "",
    "GET",
  );

  const { data: branchData } = useGetBranchData(fadCode);

  const { data: suspendedStocks, isLoading: isFetchingSuspended } =
    useGetStockManagementStockCountSuspend({
      entityID: entityData?.entityID,
    });

  useEffect(() => {
    if (!(suspendedStocks && suspendedStocks.status === "suspended" && stockCount)) {
      return;
    }
    if (suspendedStocks?.stockItems) {
      setIsCountSuspended(true);
      handleCountType(suspendedStocks?.selectedCountGroup);
      setCountTypeTitleShow({ countTypeTitleShow: false });
      setCountedProducts(
        Object.values(suspendedStocks?.stockItems).map((item) => ({
          ...item,
          name: item.name || "",
          itemID: item.itemID || "",
          quantity: item.quantity || 0,
          barcode: item.barcode || "",
          stockCountGroup: item.stockCountGroup,
          description: item.description,
          countGroup: item.stockCountGroup,
          enteredQuantity: item.quantity,
          additionalQuantity: 0,
        })),
      );
      return () => {
        // Cleanup
        setIsCountSuspended(false);
        setCountTypeTitleShow({ countTypeTitleShow: true });
        setCountedProducts([]);
        handleCountType("");
      };
    }
  }, [suspendedStocks, setCountTypeTitleShow]);

  const locationData = {
    location: entityData?.fadcode,
    locationAccount: entityData?.entity,
    locationAccountID: entityData?.entityID,
    locationAccountName: entityData?.entityName,
  };

  const {
    mutate: storeActivity,
    isSuccess,
    error,
    isError,
    isLoading,
  } = usePutStockManagementStockCountStatus();

  const handleMutation = (updatedCountedProducts: CountedProduct[], status: string) => {
    const total = updatedCountedProducts?.reduce(
      (total, value) => total + Number(value?.enteredQuantity) - value?.quantity,
      0,
    );

    storeActivity({
      status,
      data: updatedCountedProducts.map((item) => {
        const { varianceAmount } = calculateVariance(item);
        return {
          accountingLocation: locationData.location,
          accountingLocationID: locationData.locationAccountID,
          accountinglocationName: locationData.locationAccountName,
          barcode: item.barcode,
          countGroup: item.countGroup,
          currentVariance: varianceAmount,
          description: item.description,
          ean: item.ean,
          fadcode: entityData.fadcode,
          itemID: item.itemID,
          name: item.name,
          packSize: item.packSize,
          quantity: Number(item.enteredQuantity),
          status,
          totalVariance: amountInPence(total / 100),
          unitPrice: item.value,
          value: item.value,
        };
      }),
    });
  };

  const {
    mutate: stockCountSuspend,
    isSuccess: onSuccess,
    isError: onError,
  } = usePutStockManagementStockCountSuspend();

  const handleSuspendMutation = (products: CountedProduct[]) => {
    stockCountSuspend({
      data: {
        ...locationData,
        fadcode: fadCode,
        status: "suspended",
        selectedCountGroup: state.selectedCountType,
        stockItems: products.reduce(
          (acc, product) => ({ ...acc, [product.barcode]: covertSuspendedStockProducts(product) }),
          {},
        ),
      },
    });
  };

  const triggerPrint = () => {
    fetchProductDetails();
  };

  const getSerialized = (data: IPrintResponse): IPrintResponse => {
    if (data) {
      Object.keys(data).forEach((key, i) => {
        data[key]["index"] = ++i;
      });
      return data;
    }
    return {};
  };

  useEffect(() => {
    if (!state.searchedProduct && _productDetailsResponse && branchData) {
      receiptPrinterService.printReceipt({
        template: StockCountProductPrintReceiptTemplate,
        context: {
          title: "STOCK COUNT LIST",
          fadcode: fadCode,
          currentDate: currentDate(),
          currentTime: currentTime(),
          sessionID: "6-80125",
          branch_name: branchData?.branch_name,
          branch_address: branchData?.branch_address,
          products: getSerialized(_productDetailsResponse),
        },
      });
    }
  }, [_productDetailsResponse, branchData]);

  const productDetailsResponse = useMemo(
    () =>
      _productDetailsResponse
        ? (Object.values(_productDetailsResponse)[0] as CountedProduct)
        : null,
    [_productDetailsResponse],
  );

  const [popupData, setPopupData] = useState({
    actions: [],
  });

  const [actionData, setActionPopupData] = useState({
    actions: [],
  });
  const popUpAction = (): void => {
    const actions = [
      {
        name: "Ok",
        handleAction: () => {
          setShowActionPopUp(false);
        },
        style: "popUp-button red-button mt-8 flex-initial ml-4",
      },
    ];

    setActionPopupData({
      ...actionData,
      actions,
    });
  };

  const fetchProductDetails = async () => {
    getProductDetailsAtAlCountGroup("getProductDetailsAtAlCountGroup");

    await productDetailsResponse;

    setInitialCall(false);
    if (!initialCall) {
      refresh();
    }
  };
  useLayoutEffect(() => {
    if (responseError) {
      popUpAction();
      setShowActionPopUp(true);
    }
  }, [productDetailsResponse, responseError, responseStatus]);

  const checkStatus = (product: CountedProduct) => {
    const updatedCountedProducts = getUpdatedCountedProducts(product, countedProducts);
    const actions = [
      {
        name: `No`,
        handleAction: () => {
          setShowPopUp(false);
        },
        style: "popUp-button white-button mt-8 flex-initial mr-4",
      },
      {
        name: "Yes",
        handleAction: () => {
          handleMutation(updatedCountedProducts, "incomplete");
          setShowPopUp(false);
        },
        style: "popUp-button red-button mt-8 flex-initial ml-4",
      },
    ];

    setPopupData({
      ...popupData,
      actions,
    });
  };

  const handleInput = (value: string, isGRNInput: boolean) => {
    if (!value) {
      return;
    }
    if (isGRNInput) {
      setState({
        ...state,
        grapevineReferenceNumber: value,
      });
      return;
    } else {
      setState({
        ...state,
        searchedProduct: value,
      });
    }

    const productMatch = countedProducts.find((item) => item.itemID === value);

    if (productMatch) {
      setIsCountedProduct(true);
      checkStatus(productMatch);
      setShowPopUp(true);
      setProductDetailsAtAlCountGroup(productMatch);
    } else {
      fetchProductDetails();
      setIsCountedProduct(false);
    }
  };

  useEffect(() => {
    if (productDetailsResponse && state.searchedProduct) {
      setProductDetailsAtAlCountGroup(productDetailsResponse);
    }
  }, [productDetailsResponse]);

  useEffect(() => {
    setBarCodeFormEnable({
      barCodeFormEnable: true,
    });
  }, [setBarCodeFormEnable]);

  useEffect(() => {
    persistStockCountCompleted(false);
    return () => {
      setIsCountSuspended(false);
      setCountedProducts([]);
    };
  }, []);

  const handleCountType = (countType: string): void => {
    setState({
      ...state,
      selectedCountType: countType,
    });
  };

  const handleCountedProduct = (isCancel: boolean, product?: CountedProduct): void => {
    const updatedCountedProducts = getUpdatedCountedProducts(product, countedProducts);
    setCountedProducts(updatedCountedProducts);

    if (isCancel) {
      handleMutation(updatedCountedProducts, "incomplete");
      setCountedProducts([]);
      setState({ ...state, selectedCountType: "" });
      setBarCodeFormEnable({
        barCodeFormEnable: true,
      });
      setCountTypeTitleShow({ countTypeTitleShow: true });
    } else {
      handleMutation(updatedCountedProducts, "incomplete");
    }

    setProductDetailsAtAlCountGroup(null);
  };

  const handleComplete = () => {
    const formattedData = countedProducts.map((item) => {
      const { varianceAmount, varianceQuantity } = calculateVariance(item);
      const mValue = {};
      mValue["productName"] = item?.name;
      mValue["packSize"] = item?.packSize;
      mValue["itemCode"] = item?.itemID;
      mValue["productEan"] = item?.ean;
      mValue["systemQuantity"] = item?.quantity;
      mValue["enteredQuantity"] = Number(item?.enteredQuantity);
      mValue["varianceQuantity"] = varianceQuantity;
      mValue["varianceAmount"] = varianceAmount;
      return {
        ...item,
        ...mValue,
      };
    });
    const stockCountData = {
      countType: state.selectedCountType,
      data: formattedData,
      grapevineReferenceNumber: state.grapevineReferenceNumber,
    };
    setValueInStorage("stockProductData", stockCountData);
    navigate("../StockCountReview");
  };

  const handleCancelCountedProduct = () => {
    setProductDetailsAtAlCountGroup(null);
  };

  const handleSuspend = async () => {
    await handleSuspendMutation(countedProducts);
    handleMutation(countedProducts, "incomplete");
  };

  useEffect(() => {
    if (onSuccess) {
      setIsCountSuspended(true);
      setProductDetailsAtAlCountGroup(null);
      setCountTypeTitleShow({ countTypeTitleShow: false });
    }
  }, [onSuccess]);

  useEffect(() => {
    if (!isUserLoggedIn.IsLoggedIn) {
      handleSuspend();
    }
  }, [isUserLoggedIn.IsLoggedIn]);

  const handleResume = () => {
    setIsResume(true);
    setIsCountSuspended(false);
    setBarCodeFormEnable({
      barCodeFormEnable: false,
    });
  };
  const handleCancelPopUp = (flag: boolean) => {
    setShowCompletedPopup(flag);
  };

  return (
    <>
      {isFetchingSuspended ? <Loader /> : null}
      <div className="grid grid-cols-4 gap-1">
        <div className="leftSectionBG">
          <CountTypes
            countType={
              suspendedStocks ? suspendedStocks?.["selectedCountGroup"] : state.selectedCountType
            }
            scannedProducts={countedProducts}
            handleCountSelect={handleCountType}
            triggerPrint={triggerPrint}
            isCountSuspended={isCountSuspended}
            handleResume={handleResume}
            stockCount={stockCount}
          />
        </div>
        <div className="col-span-3 bg-color-stock body-panel">
          <div className="mb-12 mr-10 actionPanel">
            {countedProducts?.length && !isCountSuspended ? (
              <button
                type="button"
                className="action-label-blue mr-8"
                onClick={() => handleSuspend()}>
                Suspend
              </button>
            ) : null}
            <button
              type="button"
              className="action-label-blue"
              onClick={() => handleCancelPopUp(true)}>
              Cancel Count
            </button>
          </div>
          {!productDetailsAtAlCountGroup ? (
            <div className="flex-1">
              <BarcodeScanForm
                handleInput={handleInput}
                statusCode={responseStatusCode}
                countType={state.selectedCountType}
                grnRefNum={state.grapevineReferenceNumber}
              />
            </div>
          ) : (
            <div className="p-10 flex-1">
              <ProductDescription
                countType={state.selectedCountType}
                productDetailsResponse={productDetailsAtAlCountGroup}
                handleCountedProduct={handleCountedProduct}
                handleCancel={handleCancelCountedProduct}
                isCountedProduct={isCountedProduct}
                showCompletedPopup={showCompletedPopup}
                handleCancelPopUp={handleCancelPopUp}
              />
            </div>
          )}
          <div className="flex w-full justify-center top-border-light p-3 sticky-panel bg-white">
            <button
              className="action-button button-red"
              disabled={!countedProducts.length && !state.grapevineReferenceNumber}
              onClick={() => handleComplete()}>
              Complete
            </button>
          </div>
        </div>
        {showPopUP && (
          <Popup body={<ConfirmationPopUpBody />} hideCrossBtn={true} actions={popupData.actions} />
        )}
        {showActionPopUP && (
          <Popup body={<WarningPopUp />} hideCrossBtn={true} actions={actionData.actions} />
        )}
      </div>
    </>
  );
};
