import { useGetBranchData, usePostTransferTransaction } from "@bbo/api/generator";
import { AXIOS_INSTANCE } from "@bbo/api/generator/mutator/useCustomInstance";
import PopupConfirm from "@bbo/components/Alert";
import PaidInOutButton from "@bbo/components/Buttons/PaidInOutButton";
import AmountInput from "@bbo/components/Input/AmountInput";
import PrintConfirm from "@bbo/components/Printconfirm/index";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { BACK_OFFICE } from "@bbo/features/DiscrepancyAccount/constant";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { useReceiptPrinter } from "@bbo/lib/hooks/useReceiptPrinter";
import { useGlobalState } from "@bbo/lib/state";
import { CashPaidInOut } from "@bbo/types/TransferTypes";
import { PaidInOutTemplate } from "@bbo/utils/ReceiptPrinterTemplates/PaidInOut";
import { amountFormatter, inputInPence } from "@bbo/utils/currencyFormatter";
import { currentDate, currentTime } from "@bbo/utils/dateTimeFormatter";
import { removeEmptyLinesFromAddress } from "@bbo/utils/receiptHelper";
import { getStockUnitIdentifier, paidInParams, paidOutParams } from "@bbo/utils/transferPayload";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Index.css";

const PaidInOut = () => {
  const navigate = useNavigate();
  const [PopupData] = useGlobalState("PopupData");
  // const [userName] = useGlobalState("userName");
  const [disable, setdisable] = useState<boolean>(false);
  const [activeHeadingName, setactiveHeadingName] = useState<string>();
  const [ActiveId, setActiveId] = useState<string>();
  const [, setEntityType] = useState<string>();
  const [selectedAmount, setselectedAmount] = useState<string>("£0.00");
  const [warningFlag, setwarningFlag] = useState<boolean>(false);
  const [invaildFlag, setInvaildFlag] = useState<boolean>(false);
  const [invalidLimitFlag, setinvaildLimitFlag] = useState<boolean>(false);
  const [invalidFund, setinvalidFund] = useState<boolean>(false);
  const [clearFlag, setclearFlag] = useState<boolean>(false);
  const [initialCall, setInitialCall] = useState<boolean>(true);
  const [ruleList] = useGlobalState("BusinesRule");
  const [comment, setComment] = useState<string>(" ");
  const [Length, setLength] = useState<number>(0);
  const [reasonCode] = useGlobalState("reasonCode");
  const [routeState] = useGlobalState("routeState");
  const [printData, setPrintData] = useState<boolean>(false);
  const txtAreaComment = useRef(null);
  const { setTitle } = useTitle();
  const selectedTask = useAppSelector((root) => root.selectedTask);
  const branchDetails = useAppSelector((root) => root.branchDetails);
  const { fadCode, username } = useAppSelector((root) => root.auth.authUserData);
  const receiptPrinterService = useReceiptPrinter();
  useEffect(() => {
    if (routeState.RouteState === null) {
      navigate("/branchOverview");
    }
  }, []);

  useEffect(() => {
    const selectedRule =
      routeState.RouteState === null
        ? {}
        : ruleList.filter((item) => {
            if (PopupData?.data.entityID === item.entityID) {
              return item;
            }
          });

    if (parseInt(selectedRule[0]?.permittedLimit) < parseInt(selectedAmount.substring(1))) {
      setwarningFlag(true);
    } else {
      setwarningFlag(false);
    }

    if (parseInt(selectedRule[0]?.maximumLimit) < parseInt(selectedAmount.substring(1))) {
      setinvaildLimitFlag(true);
    } else {
      setinvaildLimitFlag(false);
    }
  });
  const { data: branchData } = useGetBranchData(fadCode);

  const selectOption = (reason) => {
    setdisable(true);
    setactiveHeadingName(reason.title);
    setActiveId(reason.code);
    setEntityType(reason);
    setselectedAmount("£0.00");
    setclearFlag(false);
    setinvalidFund(false);
    if (txtAreaComment) {
      txtAreaComment.current.focus();
    }
  };

  const operationType = routeState ? routeState.RouteState : null;

  const btnCounterView = reasonCode.map((reason, index) => {
    if (reason.title !== "Obsolete Stock") {
      return (
        <PaidInOutButton
          key={index}
          disabled={clearFlag}
          className={`transfer-out-tiles heading font-bold ${
            reason.title === activeHeadingName ? "active-counter" : ""
          } `}
          onClick={() => {
            selectOption(reason);
          }}
          text={reason.title}
        />
      );
    }
  });

  const onPrintClick = (title: string) => {
    const branchAddressClean = removeEmptyLinesFromAddress(branchDetails.branch_address);
    receiptPrinterService.printReceipt({
      template: PaidInOutTemplate,
      context: {
        title: title,
        fadcode: branchDetails.fadcode,
        branchName: branchDetails.branch_name,
        addressLine1: branchAddressClean[0],
        addressLine2: branchAddressClean[1],
        addressLine3: branchAddressClean[2],
        postcode: branchAddressClean[branchAddressClean.length - 1],
        currentDate: currentDate(),
        currentTime: currentTime(),
        amount: selectedAmount,
        branchType: branchData.branch_type,
        reasonCode: activeHeadingName,
        accountingLocation: PopupData.data.entity === "safe" ? "safe" : PopupData.data.entityName,
      },
    });
  };

  const enterAmount = (amount) => {
    const rx_live = /^[0-9,£\b]+(\.\d{0,2})?$/;
    setselectedAmount(amountFormatter(amount));
    setInvaildFlag(true);
    if (rx_live.test(amount)) {
      setselectedAmount(amountFormatter(amount));
      setInvaildFlag(true);
    } else {
      if (amount.length === 0) {
        setselectedAmount("");
      } else {
        setselectedAmount(selectedAmount);
        setInvaildFlag(true);
      }
    }
  };

  const handleComment = (e) => {
    const value = e.target.value;

    if (value.length <= 120) {
      setLength(value.trim().length);
      setComment(value);
    }
  };

  const saveCashRequestParams = (): CashPaidInOut => {
    return {
      transferAmount: inputInPence(selectedAmount),
      fadcode: PopupData.data.fadcode,
      userName: username,
      reasonCode: ActiveId,
      comments: comment,
      transferAt: BACK_OFFICE,
      operationType: operationType,
      stockunitIdentifier: PopupData?.data.entity
        ? getStockUnitIdentifier(PopupData?.data.entity, PopupData?.data.sequenceID)
        : getStockUnitIdentifier(
            PopupData?.data.associatedEntityType,
            Number(PopupData?.data.entityID),
          ),
      ...(operationType === "paid_in"
        ? {
            destinationEntityType: PopupData?.data?.associatedEntityID
              ? PopupData?.data?.associatedEntityType
              : PopupData?.data.entity,
            destinationentityID: PopupData?.data?.associatedEntityID
              ? PopupData?.data?.associatedEntityID
              : PopupData.data.entityID,
          }
        : {
            sourceEntityType: PopupData?.data?.associatedEntityID
              ? PopupData?.data?.associatedEntityType
              : PopupData?.data.entity,
            sourceentityID: PopupData?.data?.associatedEntityID
              ? PopupData?.data?.associatedEntityID
              : PopupData.data.entityID,
          }),
    };
  };

  // const { mutate: saveCashResponsee, isSuccess, isError } = usePostCashTransfer();
  const { mutate: postTransferOut, isSuccess, isError } = usePostTransferTransaction();

  const handleCashDetails = () => {
    let BalanceCheck = true;
    let balance;
    if (PopupData?.data?.entity === "safe") {
      balance = PopupData?.data?.balance;
    } else {
      balance = PopupData?.data?.itemDetails.balance;
    }
    if (Number(balance) < inputInPence(selectedAmount) && operationType !== "paid_in") {
      BalanceCheck = false;
      setinvalidFund(true);
      setwarningFlag(false);
    } else {
      BalanceCheck = true;
      setinvalidFund(false);
      setwarningFlag(true);
    }

    if (invaildFlag && BalanceCheck) {
      delete AXIOS_INSTANCE.defaults.headers.common["fadcode"];

      operationType === "paid_in"
        ? postTransferOut({ data: paidInParams(saveCashRequestParams()) })
        : postTransferOut({ data: paidOutParams(saveCashRequestParams()) });
      // saveCashResponsee({ data: saveCashRequestParams });
    }
  };
  useEffect(() => {
    if (isError) {
      setinvalidFund(true);
      setwarningFlag(false);
      setclearFlag(false);
    } else if (isSuccess) {
      setclearFlag(true);
    }
    AXIOS_INSTANCE.defaults.headers.common["fadcode"] = fadCode;
  }, [isSuccess, isError]);

  const PaidIn = () => {
    setTitle("Paid In");

    if (PopupData) {
      return (
        <>
          <div className="containerMain">
            <div className="w-full items-start transferOut-height overflow-y-auto grid grid-cols-1 lg:grid-cols-2 gap-12">
              <div className={`pt-6 pl-8 ${!clearFlag ? "" : "diableBtnCss"}`}>
                <h2 className="heading-3 font-bold mb-5">Select Reason Code / Label</h2>

                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                  <div className="grid grid-cols-1 gap-4">{btnCounterView}</div>
                  <div></div>
                </div>
                <div className="float-left mt-14 w-full">
                  <p className="heading text-color">
                    <b>Please provide a comment for your records</b>
                  </p>

                  <textarea
                    ref={txtAreaComment}
                    disabled={clearFlag}
                    className="paidTextArea w-full textArea"
                    rows={5}
                    value={comment}
                    onChange={handleComment}
                    placeholder="Add comments"></textarea>
                  <p className="text-right">{Length}/120</p>
                </div>
              </div>
              <div
                className={`pt-6 pl-8 flex flex-col bg-white transferIn-right-side h-full ${
                  disable ? "" : "diableBtnCss"
                }`}>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                  <h2 className="heading-3 font-bold mb-5">Paid In Amount</h2>
                </div>
                <div className="flex justify-center items-center h-full">
                  <div
                    className={`${disable ? "lg:w-4/6 w-full" : "diableBtnCss lg:w-4/6 w-full"}`}>
                    {!clearFlag ? (
                      <>
                        <div className="transfer-out-section w-full flex flex-col justify-center items-center mt-5">
                          {warningFlag && !invalidLimitFlag && (
                            <h2 className="heading w-full text-center warningMsg">
                              Warning: Transfer limit has been surpassed
                            </h2>
                          )}
                          {invalidLimitFlag && (
                            <h2 className="heading w-full text-center errorMsg">
                              This transaction will not be permitted
                              <i
                                className="fas fa-info-circle errorMsg cursor-pointer"
                                title="Transaction above £26000.00 will not be permitted."></i>
                            </h2>
                          )}
                          {invalidFund && (
                            <h2 className="heading w-full text-center errorMsg">
                              This cash Drawer has insufficient fund to transfer
                            </h2>
                          )}
                          <div
                            className={`justify-center flex-col cursor-pointer items-center flex block w-full ${
                              disable ? "" : "diableBtnCss"
                            }`}></div>{" "}
                          <h2 className="text-lg font-bold mb-5">Enter Amount</h2>
                          <AmountInput
                            selectAmount={selectedAmount}
                            disabled={disable}
                            handleChange={enterAmount}
                          />
                          <button
                            className={`accountingBtnNoHover ${
                              disable ? "accounting-button" : "diableBtnCss"
                            } btn-submit-cash`}
                            disabled={
                              invalidLimitFlag ||
                              !selectedAmount ||
                              selectedAmount === "£" ||
                              selectedAmount === "£0.00"
                            }
                            onClick={() => {
                              handleCashDetails();
                            }}>
                            Submit
                          </button>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {printData && (
            <PrintConfirm
              onCancel={() => {
                setPrintData(false);
                navigate("/branchOverview");
              }}
              onReprint={() => {
                onPrintClick("PAID IN DUPLICATE");
              }}
              onSuccess={() => {
                setPrintData(false);
                navigate("../branchOverview", {
                  replace: true,
                  state: { showLimit: PopupData?.data?.associatedEntityType === "cash_drawer" },
                });
              }}
            />
          )}
          {clearFlag && (
            <PopupConfirm
              header="Paid In Transaction is successful!"
              labelCancel="Close"
              labelSuccess="Print Receipt"
              tickicon={true}
              onCancel={() => {
                setclearFlag(false);
                navigate("/branchOverview");
              }}
              onSuccess={() => {
                setclearFlag(false);
                setPrintData(true);
                onPrintClick("PAID IN");
              }}
            />
          )}
        </>
      );
    }
  };

  const PaidOut = () => {
    setTitle("Paid Out");

    if (PopupData) {
      return (
        <>
          <div className="containerMain">
            <div className="transferOut-height overflow-y-auto grid grid-cols-1 lg:grid-cols-2 gap-12">
              <div className={`pt-6 pl-8  ${!clearFlag ? "" : "diableBtnCss"}`}>
                <h2 className="heading-3 font-bold mb-5">Select Reason Code / Label</h2>
                <div className="grid grid-cols-2 gap-4 pb-4 pr-4">{btnCounterView}</div>
                <div className="float-left mt-10 w-full">
                  <p className="heading text-color">
                    <b>Please provide a comment for your records</b>
                  </p>
                  <textarea
                    ref={txtAreaComment}
                    disabled={clearFlag}
                    className="paidTextArea w-full textArea"
                    rows={5}
                    value={comment}
                    onChange={handleComment}
                    placeholder="Add comments"></textarea>
                  <p className="text-right">{Length}/120</p>
                </div>
              </div>
              <div
                className={`pt-6 pl-8 flex flex-col bg-white transferIn-right-side h-full ${
                  disable ? "" : "diableBtnCss"
                }`}>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <h2 className="heading-3 font-bold mb-5">Paid Out Amount</h2>
                </div>
                <div className="flex justify-center items-center h-full">
                  <div
                    className={`${disable ? "lg:w-4/6 w-full" : "diableBtnCss lg:w-4/6 w-full"}`}>
                    {!clearFlag ? (
                      <>
                        <div className="transfer-out-section w-full flex flex-col justify-center items-center">
                          {warningFlag && !invalidLimitFlag && (
                            <h2 className="heading w-full text-center warningMsg">
                              Warning: Transfer limit has been surpassed
                            </h2>
                          )}
                          {invalidLimitFlag && (
                            <h2 className="heading w-full text-center errorMsg">
                              This transaction will not be permitted
                              <i
                                className="fas fa-info-circle errorMsg cursor-pointer"
                                title="Transaction above £26000.00 will not be permitted."></i>
                            </h2>
                          )}
                          {invalidFund && (
                            <h2 className="heading w-full text-center errorMsg">
                              This cash Drawer has insufficient fund to transfer
                            </h2>
                          )}
                          <div
                            className={`justify-center flex-col cursor-pointer items-center flex block w-full ${
                              disable ? "" : "diableBtnCss"
                            }`}></div>{" "}
                          <h2 className="text-lg font-bold mb-5">Enter Amount</h2>
                          <AmountInput
                            selectAmount={selectedAmount}
                            disabled={disable}
                            handleChange={enterAmount}
                          />
                          <button
                            className={`accountingBtnNoHover ${
                              disable ? "accounting-button" : "diableBtnCss"
                            } btn-submit-cash`}
                            disabled={
                              invalidLimitFlag ||
                              !selectedAmount ||
                              selectedAmount === "£" ||
                              selectedAmount === "£0.00"
                            }
                            onClick={() => {
                              handleCashDetails();
                            }}>
                            Submit
                          </button>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {printData && (
            <PrintConfirm
              onCancel={() => {
                setPrintData(false);
                navigate("/branchOverview");
              }}
              onReprint={() => {
                onPrintClick("PAID OUT DUPLICATE");
              }}
              onSuccess={() => {
                setPrintData(false);
                navigate("/branchOverview");
              }}
            />
          )}
          {clearFlag && (
            <PopupConfirm
              header="Paid Out Transaction is successful!"
              labelCancel="Close"
              labelSuccess="Print Receipt"
              tickicon={true}
              onCancel={() => {
                setclearFlag(false);
                navigate("/branchOverview");
              }}
              onSuccess={() => {
                setclearFlag(false);
                setPrintData(true);
                onPrintClick("PAID OUT");
              }}
            />
          )}
        </>
      );
    }
  };

  return (
    <>
      {routeState.RouteState === null ? <></> : operationType !== "paid_in" ? PaidOut() : PaidIn()}
    </>
  );
};

export default PaidInOut;
