/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

PO Transactions

PO Transactions Viewer

Cash Drawer Association API
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  Transactions,
  ErrorResp,
  GetTransactionsParams,
  ItemCounts,
  GetItemCountsParams,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Get Transactions
 */
export const useGetTransactionsHook = () => {
  const getTransactions = useCustomInstance<Transactions>();

  return (branch: string, from: number, to: number, params?: GetTransactionsParams) => {
    return getTransactions({
      url: `/transactionviewer/v1/branch/${branch}/from/${from}/to/${to}/transactions`,
      method: "get",
      params,
    });
  };
};

export const getGetTransactionsQueryKey = (
  branch: string,
  from: number,
  to: number,
  params?: GetTransactionsParams,
) => [
  `/transactionviewer/v1/branch/${branch}/from/${from}/to/${to}/transactions`,
  ...(params ? [params] : []),
];

export type GetTransactionsQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetTransactionsHook>>
>;
export type GetTransactionsQueryError = ErrorType<ErrorResp>;

export const useGetTransactions = <
  TData = AsyncReturnType<ReturnType<typeof useGetTransactionsHook>>,
  TError = ErrorType<ErrorResp>,
>(
  branch: string,
  from: number,
  to: number,
  params?: GetTransactionsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetTransactionsHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetTransactionsQueryKey(branch, from, to, params);

  const getTransactions = useGetTransactionsHook();

  const queryFn: QueryFunction<AsyncReturnType<ReturnType<typeof useGetTransactionsHook>>> = () =>
    getTransactions(branch, from, to, params);

  const query = useQuery<AsyncReturnType<ReturnType<typeof useGetTransactionsHook>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!(branch && from && to), ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary Get Item Counts
 */
export const useGetItemCountsHook = () => {
  const getItemCounts = useCustomInstance<ItemCounts>();

  return (branch: string, from: number, to: number, params?: GetItemCountsParams) => {
    return getItemCounts({
      url: `/transactionviewer/v1/branch/${branch}/from/${from}/to/${to}/itemCount`,
      method: "get",
      params,
    });
  };
};

export const getGetItemCountsQueryKey = (
  branch: string,
  from: number,
  to: number,
  params?: GetItemCountsParams,
) => [
  `/transactionviewer/v1/branch/${branch}/from/${from}/to/${to}/itemCount`,
  ...(params ? [params] : []),
];

export type GetItemCountsQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetItemCountsHook>>
>;
export type GetItemCountsQueryError = ErrorType<ErrorResp>;

export const useGetItemCounts = <
  TData = AsyncReturnType<ReturnType<typeof useGetItemCountsHook>>,
  TError = ErrorType<ErrorResp>,
>(
  branch: string,
  from: number,
  to: number,
  params?: GetItemCountsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetItemCountsHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetItemCountsQueryKey(branch, from, to, params);

  const getItemCounts = useGetItemCountsHook();

  const queryFn: QueryFunction<AsyncReturnType<ReturnType<typeof useGetItemCountsHook>>> = () =>
    getItemCounts(branch, from, to, params);

  const query = useQuery<AsyncReturnType<ReturnType<typeof useGetItemCountsHook>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!(branch && from && to), ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};
