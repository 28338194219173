import { useGetBusinessRulesByBranchId } from "@bbo/api/generator";
import { useGetList } from "@bbo/api/generator/endpoints/cash-management-api";
import { usePostTransferTransaction } from "@bbo/api/generator/endpoints/transfer-ap-i";
import { AXIOS_INSTANCE } from "@bbo/api/generator/mutator/useCustomInstance";
import PopupConfirm from "@bbo/components/Alert";
import Loader from "@bbo/components/Loader";
import PrintConfirm from "@bbo/components/Printconfirm/index";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { BACK_OFFICE } from "@bbo/features/DiscrepancyAccount/constant";
import { useAppSelector } from "@bbo/lib";
import { useReceiptPrinter } from "@bbo/lib/hooks/useReceiptPrinter";
import { useGlobalState } from "@bbo/lib/state";
import { CashTransfer } from "@bbo/types/TransferTypes";
import { amountFormatter, amountUnSignedPound, inputInPence } from "@bbo/utils/currencyFormatter";
import { currentDate, currentTime, getTransferDateTime } from "@bbo/utils/dateTimeFormatter";
import { removeEmptyLinesFromAddress } from "@bbo/utils/receiptHelper";
import { TransferInTemplate } from "@bbo/utils/ReceiptPrinterTemplates/TransferInTemplate";
import { getStockUnitIdentifier, transferInParams } from "@bbo/utils/transferPayload";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const TransferInPage = () => {
  const navigate = useNavigate();
  const [PopupData] = useGlobalState("PopupData");
  const [disable, setdisable] = React.useState<boolean>(false);
  const [activeHeadingName, setactiveHeadingName] = React.useState<string>();
  const [ActiveId, setActiveId] = React.useState<string>();
  const [transferType] = React.useState<string>("transfer_in");
  const { userName } = useAppSelector((root) => root.userName);
  const [EntityType, setEntityType] = React.useState<string>();
  const receiptPrinterService = useReceiptPrinter();
  const [sequenceID, setSequenceID] = React.useState<number>(0);
  const [selectedAmount, setselectedAmount] = React.useState<string>("");
  const [transferAmount, setTransferAmount] = React.useState<number>(0);
  const [transferDateTime, setTransferDateTime] = React.useState<number>(0);
  const branchDetails = useAppSelector((root) => root.branchDetails);
  const [nextSelect, setnextSelect] = React.useState<boolean>(true);
  const [warningFlag, setwarningFlag] = React.useState<boolean>(false);
  const [invaildFlag, setInvaildFlag] = React.useState<boolean>(false);
  const [invaildLimitFlag, setinvaildLimitFlag] = React.useState<boolean>(false);
  const [invalidFund, setinvalidFund] = React.useState<boolean>(false);
  const [clearFlag, setclearFlag] = React.useState<boolean>(false);
  const [alldata, setalldata] = React.useState<any>();
  const [isDrawers, setisDrawers] = React.useState<boolean>(false);
  const [routeState] = useGlobalState("routeState");
  const { fadCode } = useAppSelector((root) => root.auth.authUserData);
  const [printData, setPrintData] = React.useState<boolean>(false);
  const { setTitle, setIsBackButtonActive } = useTitle();
  const selectedTask = useAppSelector((root) => root.selectedTask);
  const { data: businessRules } = useGetBusinessRulesByBranchId(fadCode);

  const { data: transferListData, isLoading: transferListIsLoading } = useGetList(
    "1",
    "transfer_in",
    {
      entityID: selectedTask.entityID,
    },
  );

  useEffect(() => {
    if (routeState.RouteState === null) {
      navigate("/branchOverview");
    }
  }, []);
  useEffect(() => {
    if (transferListIsLoading) {
      return;
    }

    const selectedRule = businessRules.filter((item) => {
      if (transferType === item.ruleType) {
        return item;
      }
    });
    if (parseInt(`${selectedRule[0]?.permittedLimit}`) < parseInt(selectedAmount.substring(1))) {
      setwarningFlag(true);
    } else {
      setwarningFlag(false);
    }

    if (parseInt(`${selectedRule[0]?.maximumLimit}`) < parseInt(selectedAmount.substring(1))) {
      setinvaildLimitFlag(true);
    } else {
      setinvaildLimitFlag(false);
    }
  }, [
    alldata?.associatedCounterTerminalID,
    businessRules,
    selectedAmount,
    transferListIsLoading,
    transferType,
  ]);

  const selectOption = (heading, Id, entityType, data) => {
    setalldata(data);
    setnextSelect(false);
    setdisable(true);
    setactiveHeadingName(heading);
    setActiveId(Id);
    setEntityType(entityType);

    setSequenceID(data.sequenceID);
    setselectedAmount("");
    setclearFlag(false);
    setinvalidFund(false);
  };

  const listItems =
    transferListData && isDrawers
      ? transferListData?.unassociatedCashDrawer
      : transferListData?.associatedCashDrawer;

  const ButtonCounterView = () => (
    <>
      {routeState.RouteState === null
        ? null
        : listItems?.map((headingName) => {
            if (
              PopupData?.data.associatedCounterTerminalID !== headingName.entityID &&
              headingName.entityID !== PopupData.data.entityID
            ) {
              return (
                <tr
                  key={headingName.entityID}
                  className={`transferbutton heading ${
                    headingName.associatedCounterTerminal === activeHeadingName
                      ? "transferButtonActive"
                      : ""
                  } `}
                  onClick={() => {
                    selectOption(
                      headingName.associatedCounterTerminal,
                      headingName.entityID,
                      headingName.entity,
                      headingName,
                    );
                  }}>
                  <td>{headingName.associatedCounterTerminal}</td>
                </tr>
              );
            }
          })}
    </>
  );

  const detailViewBox = () => {
    return (
      <div className="w-full CashTransfer lg:h-full">
        <table className="w-full table-fixed transfer-out-table h-full border-none">
          <tbody>
            {" "}
            {PopupData.data.entity === "safe" ? (
              <>
                {alldata.associatedCounterTerminalID && (
                  <tr>
                    <td>
                      <p className="heading">Terminal</p>
                      <p className="text-2xl font-bold">{alldata.associatedCounterTerminal}</p>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <p className="heading">User Logged In</p>
                    <p className="text-2xl font-bold break-words">{userName}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="heading">
                      {alldata.associatedCounterTerminalID && "Attached"} Cash Drawer
                    </p>
                    {alldata.associatedCounterTerminalID !== "" ? (
                      <p className="text-2xl font-bold">{alldata.entityName}</p>
                    ) : (
                      <p className="text-2xl font-bold">{activeHeadingName}</p>
                    )}
                  </td>
                </tr>
              </>
            ) : (
              <>
                {" "}
                {PopupData?.data.associatedCounterTerminal && (
                  <tr>
                    <td>
                      <p className="heading">Terminal</p>
                      <p className="text-2xl font-bold">
                        {PopupData.data.associatedCounterTerminal}
                      </p>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <p className="heading">User Logged In</p>
                    <p className="text-2xl font-bold break-words">{userName}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="heading">
                      {PopupData.data.associatedCounterTerminal && "Attached"} Cash Drawer
                    </p>
                    {PopupData.data.associatedCounterTerminal === "" ? (
                      <p className="text-2xl font-bold">{PopupData.data.entityName}</p>
                    ) : (
                      <p className="text-2xl font-bold">{activeHeadingName}</p>
                    )}
                  </td>
                </tr>
              </>
            )}
            {PopupData.data.entity === "safe" ? (
              <>
                <tr>
                  <td>
                    <p className="heading">Last Transferred Amount</p>
                    <p className="text-2xl font-bold">
                      {amountUnSignedPound(transferAmount || alldata?.itemDetails.transferAmt)}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="heading">Last Transferred Date & Time</p>
                    <p className="text-2xl font-bold">
                      {getTransferDateTime(
                        transferDateTime || alldata?.itemDetails.transferAmtDate,
                      )}
                    </p>
                  </td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td>
                    <p className="heading">Last Transferred Amount</p>
                    <p className="text-2xl font-bold">
                      {amountUnSignedPound(
                        transferAmount || PopupData?.data.itemDetails.transferAmt,
                      )}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="heading">Last Transferred Date & Time</p>
                    <p className="text-2xl font-bold">
                      {getTransferDateTime(
                        transferDateTime || PopupData?.data.itemDetails.transferAmtDate,
                      )}
                    </p>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  function handleValue(event) {
    if (
      event.which !== 9 &&
      event.which !== 8 &&
      event.which !== 0 &&
      (event.which < 48 || event.which > 57) &&
      event.which !== 190
    ) {
      event.preventDefault();
    }
  }

  const enterAmount = (e) => {
    setinvalidFund(false);
    e.preventDefault();
    const amount = e.target.value;
    const rx_live = /^[0-9,£\b]+(\.\d{0,2})?$/;
    setselectedAmount(amountFormatter(amount));
    setInvaildFlag(true);
    if (rx_live.test(amount)) {
      setselectedAmount(amountFormatter(amount));
      setInvaildFlag(true);
    } else {
      if (amount.length === 0) {
        setselectedAmount("");
      } else {
        setselectedAmount(selectedAmount);
        setInvaildFlag(true);
      }
    }
  };

  const saveCashRequestParams = (): CashTransfer => {
    return {
      sourceEntityType: EntityType || PopupData?.data.entity,
      sourceEntityID: ActiveId || PopupData?.data.entityID,
      destinationEntityType: "safe",
      destinationEntityID: PopupData?.data.entityID,
      transferAmount: inputInPence(selectedAmount),
      fadcode: PopupData.data.fadcode,
      userName: userName,
      operationType: "transfer_in",
      transferAt: BACK_OFFICE,
      stockunitIdentifier: getStockUnitIdentifier(selectedTask.entity, selectedTask.sequenceID),
      stockunitIdentifierDest: getStockUnitIdentifier(EntityType, sequenceID),
    };
  };

  const { mutate: postTransferIn, isSuccess, isError } = usePostTransferTransaction();

  const handleCashDetails = async () => {
    let BalanceCheck = true;
    const balance = alldata.itemDetails.balance;

    if (Number(balance) < inputInPence(selectedAmount)) {
      BalanceCheck = false;
      setinvalidFund(true);
      setwarningFlag(false);
      setclearFlag(false);
    } else {
      BalanceCheck = true;
      setinvalidFund(false);
      setwarningFlag(true);
      setclearFlag(true);
    }
    if (invaildFlag && BalanceCheck) {
      delete AXIOS_INSTANCE.defaults.headers.common["fadcode"];
      postTransferIn({ data: transferInParams(saveCashRequestParams()) });
    }
  };

  const backFunction = () => {
    if (nextSelect) {
      navigate("/branchOverview");
    } else {
      setnextSelect(true);
    }
  };

  useEffect(() => {
    if (PopupData?.data.entity === "safe") {
      setTitle("Cash Transfer In");
    } else if (
      PopupData?.data.associatedCounterTerminalID === "" ||
      // eslint-disable-next-line no-unsafe-optional-chaining
      !("associatedCounterTerminalID" in PopupData?.data)
    ) {
      setTitle("Cash Transfer Out");
      setdisable(true);
    }
    setIsBackButtonActive(false);
    return () => {
      setIsBackButtonActive(true);
    };
  }, [PopupData?.data, setTitle]);

  useEffect(() => {
    if (isError) {
      setwarningFlag(false);
      setinvalidFund(true);
      setclearFlag(false);
    } else if (isSuccess) {
      setTransferAmount(inputInPence(selectedAmount));
      setTransferDateTime(Math.floor(Date.now() / 1000));
      setclearFlag(true);
      setinvalidFund(false);
    }

    AXIOS_INSTANCE.defaults.headers.common["fadcode"] = fadCode;
  }, [isSuccess, isError]);

  const ReceiptPrint = (title: string) => {
    const branchAddressClean = removeEmptyLinesFromAddress(branchDetails.branch_address);
    receiptPrinterService.printReceipt({
      template: TransferInTemplate,
      context: {
        title: title,
        fadcode: fadCode,
        branchName: branchDetails.branch_name,
        addressLine1: branchAddressClean[0],
        addressLine2: branchAddressClean[1],
        addressLine3: branchAddressClean[2],
        postcode: branchAddressClean[branchAddressClean.length - 1],
        currentDate: currentDate(),
        currentTime: currentTime(),
        toAccountingLocation: activeHeadingName.split("(")[0],
        fromAccountingLocation: selectedTask.entityName.split("(")[0],
        amount: selectedAmount,
      },
    });
  };

  /**
   * @remarks - temporary solution to prevent app from
   * crashing until we implement a global loading solution
   */

  return (
    <>
      {routeState.RouteState === null ? (
        <></>
      ) : transferListIsLoading ? (
        <Loader />
      ) : (
        PopupData && (
          <>
            <div className="subheading font-24 font-bold leading-7 lg:w-screen">
              <div className="width-70 float-left">
                <button onClick={backFunction}>
                  <i className="fas fa-chevron-left"></i> Back
                </button>
              </div>
            </div>
            <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-12 transferOut-height overflow-y-auto">
              <div className="pt-8 pl-8">
                <h2 className="heading-3 font-bold mb-5">Cash Transfer From</h2>
                {PopupData?.data.associatedCounterTerminalID !== "" && nextSelect && (
                  <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 pb-4 pr-4">
                    <button
                      className={`${
                        isDrawers ? "text-lg text-color font-semibold" : "transferIntabs"
                      }`}
                      onClick={() => setisDrawers(false)}>
                      Counters
                    </button>
                    <button
                      className={`${
                        isDrawers ? "transferIntabs" : "text-lg text-color font-semibold"
                      }`}
                      onClick={() => setisDrawers(true)}>
                      Cash Drawers
                    </button>
                  </div>
                )}
                {PopupData?.data.associatedCounterTerminalID !== "" ? (
                  <div
                    className={`overflow-y-auto  ${
                      nextSelect ? "transferIn-left-side" : "height-500"
                    }`}>
                    {nextSelect ? (
                      <table className="w-full h-full table-border">
                        <tbody>
                          <ButtonCounterView />
                        </tbody>
                      </table>
                    ) : (
                      detailViewBox()
                    )}
                  </div>
                ) : (
                  detailViewBox()
                )}
              </div>
              <div
                className={`transferIn-right-side bg-white pt-8 pl-8 ${
                  disable ? "" : "diableBtnCss"
                }`}>
                <h2 className="heading-3 font-bold">Cash Transfer to the Safe</h2>
                <div
                  className={`flex justify-center items-center height-94 ${
                    PopupData.data.associatedCounterTerminalID !== "" ? "" : "lg:h-full"
                  }`}>
                  <div
                    className={`${disable ? "lg:w-4/6 w-full" : "diableBtnCss lg:w-4/6 w-full"}`}>
                    <div className="transfer-out-section w-full flex flex-col justify-center items-center">
                      {warningFlag && !invaildLimitFlag && (
                        <h2 className="heading w-full text-center warningMsg">
                          Warning: Transfer limit has been surpassed
                        </h2>
                      )}
                      {invaildLimitFlag && (
                        <h2 className="heading w-full text-center errorMsg">
                          This transaction will not be permitted
                          <i
                            className="fas fa-info-circle errorMsg cursor-pointer"
                            title="Transaction above £26000.00 will not be permitted."></i>
                        </h2>
                      )}
                      {invalidFund && (
                        <h2 className="heading w-full text-center errorMsg">
                          This has insufficient fund to transfer
                        </h2>
                      )}
                      <div
                        className={`justify-center flex-col cursor-pointer items-center flex block w-full ${
                          disable ? "" : "diableBtnCss"
                        }`}>
                        <>
                          {" "}
                          <h2 className="text-lg font-bold mb-5">Enter Amount</h2>
                          <input
                            className={`brandWhiteBtn w-full mb-4 ${disable ? "" : "diableBtnCss"}`}
                            disabled={!disable}
                            pattern="[0-9]"
                            onKeyDown={handleValue}
                            onChange={(e) => {
                              enterAmount(e);
                            }}
                            value={selectedAmount}
                          />
                          <button
                            className={`w-10/12 accountingBtnNoHover ${
                              disable ? "accounting-button" : "diableBtnCss"
                            }`}
                            disabled={invaildLimitFlag || !selectedAmount || selectedAmount === "£"}
                            onClick={() => {
                              handleCashDetails();
                            }}>
                            Submit
                          </button>
                        </>
                        {printData && (
                          <PrintConfirm
                            onCancel={() => {
                              setclearFlag(false);
                              setPrintData(false);
                              navigate("/branchOverview");
                            }}
                            onReprint={() => {
                              ReceiptPrint("TRANSFER IN DUPLICATE");
                            }}
                            onSuccess={() => {
                              setPrintData(false);
                              navigate("../branchOverview", {
                                replace: true,
                                state: {
                                  showLimit: selectedTask.associatedEntityType === "cash_drawer",
                                },
                              });
                            }}
                          />
                        )}
                        {clearFlag && !printData && (
                          <PopupConfirm
                            header="Cash Transfer in is successful!"
                            labelCancel="Close"
                            labelSuccess="Print Receipt"
                            tickicon={true}
                            onCancel={() => {
                              navigate("../branchOverview", {
                                replace: true,
                              });
                              setclearFlag(false);
                            }}
                            onSuccess={() => {
                              ReceiptPrint("TRANSFER IN");

                              setPrintData(true);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      )}
    </>
  );
};

export default TransferInPage;
