/**
 * utility for date time calculation such as sum to date or substract to date etc.
 */

/**
 * DateTime Calcualtion

 */
export const substractNumberOfDaysFromDate = (numberOfDays: number, date: Date): Date => {
  const dt = new Date(date);
  dt.setDate(dt.getDate() - numberOfDays);
  return dt;
};
