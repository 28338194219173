import useDiscrepancyApi from "@bbo/lib/hooks/useDiscrepancyApi";
import { useGlobalState } from "@bbo/lib/state";
import { amountPound } from "@bbo/utils/currencyFormatter";
import { formatDateYearMonthDay } from "@bbo/utils/dateTimeFormatter";
import React, { useEffect, useState } from "react";
import "../index.css";

export default function MoveVarianceSummaryPopup(props) {
  const [MoveVariancePopup, setMoveVariancePopup] = useGlobalState("MoveVariancePopup");
  const [disConfimBtn, setdisConfimBtn] = useState(true);
  const [apiChange, setapiChange] = useGlobalState("apiChange");

  useEffect(() => {
    setMoveVariancePopup({ BranchVariancePopup: props?.data.MoveDiscrepancy });
  }, [props.data]);

  useEffect(() => {
    if (props.data.selectedRow.length === 1) {
      setdisConfimBtn(false);
    } else {
      setdisConfimBtn(true);
    }
  }, []);

  const saveDiscrepancyDetailsRequestParma = props?.data.selectedRow;

  const [{ data: addPouch1Response }, saveDiscrepancy] = useDiscrepancyApi(
    "",
    saveDiscrepancyDetailsRequestParma,
    "",
    "POST",
    "",
  );

  const confirmBtn = async () => {
    saveDiscrepancy("postDiscrepancyData");

    await addPouch1Response;
    //  setdisConfimBtn(true);
    setMoveVariancePopup({ BranchVariancePopup: false });
    setapiChange({ apiChange: !apiChange });
  };

  // on Scroll active confirm button
  const handleScroll = (e) => {
    if (Math.round(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight) {
      setdisConfimBtn(false);
    } else setdisConfimBtn(true);
  };

  const varianceSummary = (
    <div style={{ height: "340px", overflow: "scroll" }} onScroll={handleScroll}>
      {props?.data.selectedRow.map((value, index) => {
        return (
          <React.Fragment key={index}>
            {props?.data.selectedRow && (
              <div className="popupHead ">
                <div className="flex items-center justify-between   popupTextSize">
                  <div>Variance(s) moved to Discrepancy</div>
                  <div
                    className={
                      parseInt(value.toDateVariance) === 0
                        ? ""
                        : parseInt(value.toDateVariance) < 0
                        ? "redLabel whitespace-nowrap"
                        : "greenLabel whitespace-nowrap"
                    }>
                    <b>{amountPound(value.toDateVariance)}</b>
                  </div>
                </div>
                <div className="flex items-center justify-between popupTextSize">
                  <div>Variance Date</div>
                  <div>
                    <b>{formatDateYearMonthDay(value.lastBalancedDateTime)}</b>
                  </div>
                </div>
              </div>
            )}

            <div className="flex items-center justify-between mt-4">
              <h2 className="testSizePopup">Reason for Variance</h2>
            </div>
            <div className="flex items-center justify-left my-3">
              <div className="textPopupReason">
                {value.reason.title}
                {value.reason?.subReason?.title ? " - " + value.reason?.subReason?.title : null}
                {value.reason?.subReason?.filterReason?.title
                  ? " - " + value.reason?.subReason?.filterReason?.title
                  : null}
              </div>
            </div>
            <div className="flex items-center justify-between ">
              <div className="textPopupReason mb-4">Please provide comments for your records.</div>
            </div>
            <hr className="border-black border-bottom-none mb-5 w-full"></hr>
          </React.Fragment>
        );
      })}
    </div>
  );

  function getSum(total, num) {
    return total + num;
  }
  const totalVariance = props?.data.selectedRow
    .map((value) => {
      return parseInt(value.toDateVariance);
    })
    .reduce(getSum, 0);

  return (
    <>
      {MoveVariancePopup && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-2 mx-auto max-w-xl">
              <div className="border-0  shadow-lg relative height-25 width-35 overflow-y-auto flex flex-col w-full bg-white outline-none focus:outline-none p-8 popupContainer	">
                <>
                  <div className="flex items-center justify-between">
                    <h2 className="testSizePopupHeading  my-6" data-cy="HeadingMoveToVariance">
                      You are moving {props?.data.selectedRow.length} variances with a total value
                      of{" "}
                      <u
                        className={
                          parseInt(totalVariance) === 0
                            ? ""
                            : parseInt(totalVariance) < 0
                            ? "redLabel"
                            : "greenLabel"
                        }>
                        {amountPound(totalVariance)}
                      </u>{" "}
                      to the Branch Discrepancy Account
                    </h2>
                  </div>
                </>
                <div className="varianceSummary"> {varianceSummary}</div>

                <>
                  {" "}
                  <div className="flex justify-left pt-2">
                    <button
                      className="inline-flex hover justify-center text-black font-bold p-2 rounded resetBtn mr-2 completeBtnPopup"
                      onClick={() => setMoveVariancePopup({ BranchVariancePopup: false })}
                      data-cy="CancelMove">
                      Cancel
                    </button>
                    <button
                      className="accounting-button ml-1 completeBtnPopup"
                      onClick={() => confirmBtn()}
                      disabled={disConfimBtn}
                      data-cy="CompleteMove">
                      Complete
                    </button>
                  </div>
                </>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
}
