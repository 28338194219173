import { IDenomination } from "./ForexDenomination";
import ForexLaddarRow from "./ForexLadderRow";
interface Props {
  row: IDenomination;
  position: number;
  code: string;
  onChange: (e: Event, rowdata: IDenomination) => void;
  onBlur: (rowdata: IDenomination) => void;
}

const DenominationLadder = ({ row, position, onChange, code, onBlur }: Props) => {
  if (
    ((row?.cash === "100.00" || row?.cash === "200.00") && code === "EUR") ||
    (row?.cash === "100.00" && code === "USD")
  ) {
    return (
      <ForexLaddarRow
        key={position}
        item={row}
        index={position}
        onChange={onChange}
        code={code}
        onBlur={onBlur}
      />
    );
  }
  return (
    <ForexLaddarRow key={position} item={row} index={position} onChange={onChange} code={code} />
  );
};
export default DenominationLadder;
