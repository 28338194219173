/**
 * Utility for Date Time Formatting
 */

/**
 * Date formatter function
 * @param timestamp Unix timestamp number
 * @returns date as DD MMMM YYYY
 */
export const getDate = (timestamp: number): string => {
  const date = new Date(timestamp * 1000);

  return (
    date.toLocaleString("en-GB", { day: "2-digit", timeZone: "UTC" }) +
    " " +
    date.toLocaleString("en-GB", { month: "long", timeZone: "UTC" }) +
    " " +
    date.toLocaleString("en-GB", { year: "numeric", timeZone: "UTC" })
  );
};

/**
 * Format Year-Month-Day
 * @param timestamp Unix timestamp number
 * @returns date as yyyy-mm-dd
 */
export const formatDateYearMonthDay = (timestamp: number): string => {
  const date = new Date(timestamp * 1000);

  return (
    date.toLocaleString("en-GB", { day: "2-digit", timeZone: "UTC" }) +
    "/" +
    date.toLocaleString("en-GB", { month: "2-digit", timeZone: "UTC" }) +
    "/" +
    date.toLocaleDateString("en-GB", { year: "numeric", timeZone: "UTC" })
  );
};

/**
 * Format Year-Month-Day
 * @param timestamp Unix timestamp number
 * @returns date as yyyy-mm-dd hh:mm
 */
export const getDateTime = (timestamp: number): string => {
  const dateTime = new Date(timestamp * 1000);

  return (
    dateTime.toLocaleDateString("en-GB", { day: "2-digit", timeZone: "UTC" }) +
    "/" +
    dateTime.toLocaleString("en-GB", { month: "2-digit", timeZone: "UTC" }) +
    "/" +
    dateTime.toLocaleString("en-GB", { year: "numeric", timeZone: "UTC" }) +
    " " +
    dateTime.toLocaleString("en-GB", { hour: "2-digit", timeZone: "UTC" }) +
    ":" +
    parseInt(
      dateTime.toLocaleString("en-GB", { minute: "2-digit", timeZone: "UTC" }),
    ).toLocaleString("en-GB", {
      minimumIntegerDigits: 2,
    }) +
    " "
  );
};

export const getOnlyDateTime = (timestamp: number): string => {
  const dateTime = new Date(timestamp * 1000);

  return (
    dateTime.toLocaleDateString("en-GB", { day: "2-digit", timeZone: "UTC" }) +
    "-" +
    dateTime.toLocaleString("en-GB", { month: "2-digit", timeZone: "UTC" }) +
    "-" +
    dateTime.toLocaleString("en-GB", { year: "numeric", timeZone: "UTC" }) +
    " " +
    dateTime.toLocaleString("en-GB", { hour: "2-digit", timeZone: "UTC" }) +
    ":" +
    parseInt(
      dateTime.toLocaleString("en-GB", { minute: "2-digit", timeZone: "UTC" }),
    ).toLocaleString("en-GB", {
      minimumIntegerDigits: 2,
    }) +
    " "
  );
};
export const getonlyTime = (timestamp: number): string => {
  const dateTime = new Date(timestamp * 1000);

  return (
    dateTime.toLocaleString("en-GB", { hour: "2-digit", timeZone: "UTC" }) +
    ":" +
    parseInt(
      dateTime.toLocaleString("en-GB", { minute: "2-digit", timeZone: "UTC" }),
    ).toLocaleString("en-GB", {
      minimumIntegerDigits: 2,
    })
  );
};

export const getOnlyDate = (dateString: number): string => {
  const date = new Date(dateString * 1000);

  return (
    date.toLocaleDateString("en-GB", { day: "2-digit", timeZone: "UTC" }) +
    "-" +
    date.toLocaleString("en-GB", { month: "2-digit", timeZone: "UTC" }) +
    "-" +
    date.toLocaleString("en-GB", { year: "numeric", timeZone: "UTC" })
  );
};
export const getOnlyDateDiscrepancy = (dateString: number): string => {
  const date = new Date(dateString * 1000);

  return (
    date.toLocaleDateString("en-GB", { day: "2-digit", timeZone: "UTC" }) +
    "/" +
    date.toLocaleString("en-GB", { month: "2-digit", timeZone: "UTC" }) +
    "/" +
    date.toLocaleString("en-GB", { year: "numeric", timeZone: "UTC" })
  );
};

export const getTransferDateTime = (timeStinrg: number): string => {
  if (timeStinrg !== 0) {
    const dateTime = new Date(timeStinrg * 1000);
    const hours = dateTime.getHours();
    const ampm = hours >= 12 ? "pm" : "am";
    return (
      dateTime.toLocaleDateString("en-GB", { day: "2-digit", timeZone: "UTC" }) +
      "-" +
      dateTime.toLocaleString("en-GB", { month: "2-digit", timeZone: "UTC" }) +
      "-" +
      dateTime.toLocaleString("en-GB", { year: "numeric", timeZone: "UTC" }) +
      " & " +
      dateTime.toLocaleString("en-GB", { hour: "2-digit", timeZone: "UTC" }) +
      ":" +
      parseInt(
        dateTime.toLocaleString("en-GB", { minute: "2-digit", timeZone: "UTC" }),
      ).toLocaleString("en-GB", {
        minimumIntegerDigits: 2,
      }) +
      " " +
      ampm
    );
  }
};
export const getonlyDateTime = (timeStinrg: number): string => {
  if (timeStinrg !== 0) {
    const dateTime = new Date(timeStinrg * 1000);
    const hours = dateTime.getHours();
    const ampm = hours >= 12 ? "pm" : "am";
    return (
      dateTime.toLocaleDateString("en-GB", { day: "2-digit", timeZone: "UTC" }) +
      "-" +
      dateTime.toLocaleString("en-GB", { month: "2-digit", timeZone: "UTC" }) +
      "-" +
      dateTime.toLocaleString("en-GB", { year: "numeric", timeZone: "UTC" }) +
      " " +
      dateTime.toLocaleString("en-GB", { hour: "2-digit", timeZone: "UTC" }) +
      ":" +
      parseInt(
        dateTime.toLocaleString("en-GB", { minute: "2-digit", timeZone: "UTC" }),
      ).toLocaleString("en-GB", {
        minimumIntegerDigits: 2,
      }) +
      ampm
    );
  }
};

export const getdateFormat = (date: Date): string => {
  const dateTime = new Date(date);
  return (
    dateTime.toLocaleDateString("en-GB", { year: "numeric", timeZone: "UTC" }) +
    "-" +
    dateTime.toLocaleString("en-GB", { month: "2-digit", timeZone: "UTC" }) +
    "-" +
    dateTime.toLocaleString("en-GB", { day: "2-digit", timeZone: "UTC" })
  );
};

export const getOnlyDateFormat = (date: Date): string => {
  const dateTime = new Date(date);
  return (
    dateTime.toLocaleDateString("en-GB", { day: "2-digit", timeZone: "UTC" }) +
    "/" +
    dateTime.toLocaleString("en-GB", { month: "2-digit", timeZone: "UTC" }) +
    "/" +
    dateTime.toLocaleString("en-GB", { year: "numeric", timeZone: "UTC" })
  );
};

export const getdateStringFormat = (date: number): string => {
  const dateTime = new Date(date * 1000);
  return (
    dateTime.toLocaleString("en-GB", { month: "2-digit", timeZone: "UTC" }) +
    "-" +
    dateTime.toLocaleString("en-GB", { day: "2-digit", timeZone: "UTC" }) +
    "-" +
    dateTime.toLocaleDateString("en-GB", { year: "numeric", timeZone: "UTC" })
  );
};

export const getUnixTimeStamp = (date: Date): number => {
  return Math.floor(new Date(date).getTime() / 1000);
};

export const getbalanceDate = (time: number) => {
  const dateTime = new Date(time * 1000);
  return (
    dateTime.toLocaleDateString("en-GB", { day: "2-digit", timeZone: "UTC" }) +
    "-" +
    dateTime.toLocaleString("en-GB", { month: "2-digit", timeZone: "UTC" }) +
    "-" +
    dateTime.toLocaleString("en-GB", { year: "numeric", timeZone: "UTC" }) +
    " " +
    dateTime.toLocaleString("en-GB", { hour: "2-digit", timeZone: "UTC" }) +
    ":" +
    parseInt(
      dateTime.toLocaleString("en-GB", { minute: "2-digit", timeZone: "UTC" }),
    ).toLocaleString("en-GB", {
      minimumIntegerDigits: 2,
    })
  );
};

export const formatDate = (date: string): string => {
  const dateTime = new Date(date);
  return (
    dateTime.toLocaleDateString("en-GB", { day: "2-digit", timeZone: "UTC" }) +
    "-" +
    dateTime.toLocaleString("en-GB", { month: "2-digit", timeZone: "UTC" }) +
    "-" +
    dateTime.toLocaleString("en-GB", { year: "numeric", timeZone: "UTC" }) +
    " " +
    dateTime.toLocaleString("en-GB", { hour: "2-digit", timeZone: "UTC" }) +
    ":" +
    parseInt(
      dateTime.toLocaleString("en-GB", { minute: "2-digit", timeZone: "UTC" }),
    ).toLocaleString("en-GB", {
      minimumIntegerDigits: 2,
    })
  );
};

export const getTime = (timeStinrg: number): string => {
  const dateTime = new Date(timeStinrg * 1000);

  return (
    dateTime.toLocaleString("en-GB", { hour: "2-digit", timeZone: "UTC" }) +
    ":" +
    parseInt(
      dateTime.toLocaleString("en-GB", { minute: "2-digit", timeZone: "UTC" }),
    ).toLocaleString("en-GB", {
      minimumIntegerDigits: 2,
    })
  );
};

export const getHoursOnly = (timeStinrg: number): number => {
  const dateTime = new Date(timeStinrg * 1000);
  const hour = dateTime.toLocaleString("en-GB", { hour: "2-digit", timeZone: "UTC" });
  return Number(hour);
};

export const convertUnixTimeStampToDate = (unixTime: number): Date => {
  return new Date(unixTime * 1000);
};
export const getTimeForTpBpHistory = (timeStinrg) => {
  const dateTime = new Date(timeStinrg * 1000);
  const hours = dateTime.toLocaleString("en-GB", { hour: "2-digit", timeZone: "UTC" });
  const ampm = Number(hours) >= 12 ? "pm" : "am";
  return (
    hours +
    ":" +
    parseInt(
      dateTime.toLocaleString("en-GB", { minute: "2-digit", timeZone: "UTC" }),
    ).toLocaleString("en-GB", {
      minimumIntegerDigits: 2,
    }) +
    " " +
    ampm
  );
};

export const currentDate = () => {
  const date = new Date();
  return date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
};

export const currentDateDDMMYYYY = () => {
  const date = new Date();
  return date.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" });
};

export const currentTime = () => {
  const date = new Date();
  return date.getHours() + ":" + date.getMinutes();
};

export const newTransferDateTime = (timeStinrg: number): string => {
  if (timeStinrg !== 0) {
    const dateTime = new Date(timeStinrg * 1000);
    const hours = dateTime.getHours();
    const ampm = hours >= 12 ? "pm" : "am";
    return (
      dateTime.toLocaleDateString("en-GB", { day: "2-digit", timeZone: "UTC" }) +
      "-" +
      dateTime.toLocaleString("en-GB", { month: "2-digit", timeZone: "UTC" }) +
      "-" +
      dateTime.toLocaleString("en-GB", { year: "numeric", timeZone: "UTC" }) +
      " " +
      dateTime.toLocaleString("en-GB", { hour: "2-digit", timeZone: "UTC" }) +
      ":" +
      parseInt(
        dateTime.toLocaleString("en-GB", { minute: "2-digit", timeZone: "UTC" }),
      ).toLocaleString("en-GB", {
        minimumIntegerDigits: 2,
      }) +
      " " +
      ampm
    );
  }
};
export const currentDateTime = () => {
  const dateTime = new Date();
  const hours = dateTime.getHours();
  const ampm = hours >= 12 ? "pm" : "am";
  return (
    dateTime.toLocaleDateString("en-GB", { day: "2-digit", timeZone: "UTC" }) +
    "-" +
    dateTime.toLocaleString("en-GB", { month: "2-digit", timeZone: "UTC" }) +
    "-" +
    dateTime.toLocaleString("en-GB", { year: "numeric", timeZone: "UTC" }) +
    " " +
    dateTime.toLocaleString("en-GB", { hour: "2-digit", timeZone: "UTC" }) +
    ":" +
    parseInt(
      dateTime.toLocaleString("en-GB", { minute: "2-digit", timeZone: "UTC" }),
    ).toLocaleString("en-GB", {
      minimumIntegerDigits: 2,
    }) +
    " " +
    ampm
  );
};

export const currentOnlyTime = () => {
  const date = new Date();
  return (
    date.toLocaleString("en-GB", { hour: "2-digit" }) +
    ":" +
    (date.getMinutes() < 10 ? "0" : "") +
    date.getMinutes()

    // date.toLocaleString("en-GB", { minute: "2-digit" })
  );
};
