import { amountSignedPound, currencyStyle } from "@bbo/utils/currencyFormatter";
import { formatDateYearMonthDay } from "@bbo/utils/dateTimeFormatter";

interface IAssociatedInformation {
  data: { currentVariance: number; lastBalancedDateTime: number; toDateVariance: number };
}

export const StockCardData = ({ data }: IAssociatedInformation) => {
  return (
    <table className="w-full">
      <tbody className="counterPad">
        <tr className={currencyStyle(data?.currentVariance)}>
          <td>Last Count Variance</td>
          <td>
            <b>{amountSignedPound(data?.currentVariance)}</b>
          </td>
        </tr>
        <tr>
          <td>Last Count Date</td>
          <td>
            <b>{formatDateYearMonthDay(data?.lastBalancedDateTime)}</b>
          </td>
        </tr>
        <tr>
          <td>To Date Variance</td>
          <td>
            <b>{amountSignedPound(data?.toDateVariance)}</b>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
