import { useEffect, useState } from "react";
import downArrow from "../../assets/images/triDOWN.png";
import upArrow from "../../assets/images/triUP.png";
import "./index.css";

// interface TradingPeriodAccordionProps {
//   title?: React.ReactNode;
//   content?: React.ReactNode;
// }

export default function TradingPeriodAccordion({ title, content }) {
  const [isActive, setIsActive] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [title?.props?.progress]);
  return (
    title && (
      <div className="TPColor">
        <div className="accordion-title">
          <div className="accordionTitleName ">
            {title}
            {content && (
              <label data-testid="expand" onClick={() => setIsActive(!isActive)}>
                {!isActive ? (
                  <img className="pt-3 ml-2" src={downArrow} width="20" height="20" alt="User" />
                ) : (
                  <img className="pt-3 ml-2" src={upArrow} width="20" height="20" alt="User" />
                )}
              </label>
            )}
          </div>{" "}
        </div>
        {isActive && content && <div className="TPContent">{content}</div>}
      </div>
    )
  );
}
