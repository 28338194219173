import { BRANCH_OVERVIEW_TABS } from "@bbo/constants";
import useVarianceApi from "@bbo/lib/hooks/useVarianceApi";
import { useGlobalState } from "@bbo/lib/state";
import React, { useEffect, useState } from "react";

import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import useCashApi from "@bbo/lib/hooks/useCashApi";
import { setCashDetails } from "@bbo/lib/reduxStore/cash.slice";
import { amountPound } from "@bbo/utils/currencyFormatter";
import { useDispatch } from "react-redux";
import { AWAITING_BRANCH_ACTION } from "../DiscrepancyAccount/constant";
import { useGetReasonCodeList } from "@bbo/api/generator";

export const useBranchOverview = () => {
  const [tabLabels] = useState(BRANCH_OVERVIEW_TABS);
  const [selectedTab, setSelectedTab] = useState(BRANCH_OVERVIEW_TABS[0]);
  const { fadCode } = useAppSelector((root) => root.auth.authUserData);

  const [{ data: cashData, status: cashDataIsLoading }] = useCashApi(
    "getCashTransactionDetails",
    "1",
    "",
    "GET",
  );
  //   const {
  //     data: cashData,
  //     status: cashDataIsLoading,
  //     refetch,
  //   } = useGetCashTransactionDetails(fadCode);

  const dispatch = useDispatch();

  const { data: reasonCodeList } = useGetReasonCodeList("discrepancy");

  const [{ data: VarianceData, status: VarianceDataIsLoading }, getVarianceApi, refresh] =
    useVarianceApi("", "1", "", "GET");

  const [, setreasonCode] = useGlobalState("reasonCodeList");
  const [Variance, setVariance] = React.useState<number>(0);
  const handleSelectTab = (params) => {
    setSelectedTab(params.name);
  };

  useEffect(() => {
    // Below comment is for Branch Variance Summary which is false when turn on then it will work
    if (cashData && VarianceData) {
      const varianceList = [];

      let varianceIn = 0;
      const mData = VarianceData?.map((result) => {
        varianceIn = varianceIn + result.toDateVariance;
        return {
          entity: result.entity,
          entityID: result.entityID,
          entityName: result.entityName,
          toDateVariance: result.toDateVariance,
          toDateVarianceDisplay: amountPound(result.toDateVariance),
          variance_amount: result.toDateVariance, // Key added due to record know variance
          status: AWAITING_BRANCH_ACTION, // Key added due to record know variance
          lastBalancedDateTime: result.lastBalancedDateTime,
          reason: result.reason,
          comment: result.comment,
        };
      });

      setVariance(varianceIn);
      cashData["varianceSummary"] = varianceList;

      dispatch(
        setCashDetails({
          ...cashData,
          varianceSummary: mData,
          VarianceAmt: Variance,
        }),
      );
    } else {
      dispatch(setCashDetails(cashData));
    }
  }, [cashData, VarianceData, Variance]);

  useEffect(() => {
    setreasonCode(reasonCodeList);
  }, [reasonCodeList, setreasonCode]);

  return {
    handleSelectTab,
    selectedTab,
    tabLabels,
    VarianceDataIsLoading,
    cashDataIsLoading,
    cashData,
  };
};
