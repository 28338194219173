import Loader from "@bbo/components/Loader";
import { Balance_PERIOD_POPUP_DATA, DISCREPANCY_STATUS } from "@bbo/constants";
import { CommitTpBp } from "@bbo/features/CommitTpBp";
import { useAppSelector, useGlobalState } from "@bbo/lib";
import useDiscrepancyApi from "@bbo/lib/hooks/useDiscrepancyApi";
import { inputInPence } from "@bbo/utils/currencyFormatter";
import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommitSuccessPopup from "../CommitTpBp/CommitSuccessPopup";
import { HighLevelView } from "../BalancePeriod/HighLevelView";
import { DetailView } from "./DetailView";
import { AXIOS_INSTANCE } from "@bbo/api/generator/mutator/useCustomInstance";
import {
  TRANSACTION_ITEM_ID,
  createDiscreapancyForUnknownVariance,
} from "@bbo/utils/transferPayload";
import { usePostTransferTransaction } from "@bbo/api/generator";
import { createBpTpDiscrepancy } from "../../types/TransferTypes";

export enum balancePeriodButtonVisibilityStatus {
  COMMIT_BUTTON_VISIBILITY_STATUS_HIDE,
  COMMIT_BUTTON_VISIBILITY_STATUS_DISABLE,
  COMMIT_BUTTON_VISIBILITY_STATUS_SHOW,
}
interface BalancePeriodScreenProps {
  fromHistory?: boolean;
  selectedRow?: any;
  fromDiscrepancyAccount?: boolean;
}

export const BalancePeriodScreen = ({
  fromHistory,
  fromDiscrepancyAccount,
  selectedRow,
}: BalancePeriodScreenProps) => {
  const navigate = useNavigate();
  const [totalCashVariance] = useGlobalState("totalCashVariance");
  const [varianceAmount, setVarianceAmount] = useState<string>();
  const [completeBalancePeriod, setCompleteBalancePeriod] = useState(false);
  const [isBalancePeriodButtonEnabled, setIsBalancePeriodButtonEnabled] = useState<boolean>(false);
  const [showCommitSuccessPopup, setShowCommitSuccessPopup] = useState<boolean>(false);
  const [completeBalancePeriodSubmissionInProgress, setCompleteBalancePeriodSubmissionInProgress] =
    useState<boolean>(false);

  const [cbpButtonVisibilityStatus, setCbpButtonVisibilityStatus] =
    useState<balancePeriodButtonVisibilityStatus>(
      balancePeriodButtonVisibilityStatus.COMMIT_BUTTON_VISIBILITY_STATUS_HIDE,
    );
  const { username, fadCode, smartid } = useAppSelector((root) => root.auth.authUserData);
  const branchDetails = useAppSelector((root) => root.branchDetails);

  useLayoutEffect(() => {
    if (isBalancePeriodButtonEnabled) {
      setCbpButtonVisibilityStatus(
        balancePeriodButtonVisibilityStatus.COMMIT_BUTTON_VISIBILITY_STATUS_SHOW,
      );
    } else {
      setCbpButtonVisibilityStatus(
        balancePeriodButtonVisibilityStatus.COMMIT_BUTTON_VISIBILITY_STATUS_DISABLE,
      );
    }
  }, [isBalancePeriodButtonEnabled]);

  const handleCommitSuccessPopup = () => {
    setShowCommitSuccessPopup(false);
    navigate("/taskManagement");
  };

  const saveDiscrepancyDetailsRequestParma = [
    {
      comment: "",
      reason: {
        code: "BP",
        id: "BP",
        title: "BP",
        subReason: {
          filterReason: {},
        },
      },
      entity: "",
      entityID: "",
      status: DISCREPANCY_STATUS.AWAITING_BRANCH_ACTION,
      varianceAmount: inputInPence(varianceAmount),
    },
  ];
  const saveTpDiscrepnacyRequestParams = (): createBpTpDiscrepancy => {
    return {
      comment: "",
      entity: "",
      entityID: "",
      reason: {
        code: "BP",
        id: "BP",
        subReason: {
          filterReason: {},
        },
        title: "TP",
      },
      status: DISCREPANCY_STATUS.AWAITING_BRANCH_ACTION,
      varianceAmount: inputInPence(varianceAmount),
      userName: username,
      action: "Create",
      fadcode: fadCode,
      branchName: fadCode,
      userID: smartid,
      branchDetails: branchDetails,
    };
  };
  const enableBalancePeriodButton = (val) => {
    setIsBalancePeriodButtonEnabled(val);
  };
  const returnItemID = () => {
    if (inputInPence(varianceAmount) > 0) {
      return TRANSACTION_ITEM_ID.TP_BP_BALANCING_SURPLUS; //50832
    } else {
      return TRANSACTION_ITEM_ID.TP_BP_BALANCING_SHORTAGE; //50833
    }
  };

  const { mutate: saveTpDiscrepancy, isSuccess } = usePostTransferTransaction({
    mutation: {
      onSuccess: () => {
        AXIOS_INSTANCE.defaults.headers.common["fadcode"] = branchDetails.fadcode;
      },
    },
  });

  const handleCommit = async () => {
    setCompleteBalancePeriod(false);
    setCompleteBalancePeriodSubmissionInProgress(true);
    const itemID = returnItemID();
    delete AXIOS_INSTANCE.defaults.headers.common["fadcode"];
    saveTpDiscrepancy({
      data: createDiscreapancyForUnknownVariance(saveTpDiscrepnacyRequestParams(), itemID),
    });
    await isSuccess;
  };
  useLayoutEffect(() => {
    setVarianceAmount(totalCashVariance.toString());
  }, [totalCashVariance]);

  useEffect(() => {
    if (isSuccess) {
      setCompleteBalancePeriodSubmissionInProgress(false);
      setShowCommitSuccessPopup(true);
    }
  }, [isSuccess]);
  return (
    <div className="balancePeriod">
      {completeBalancePeriodSubmissionInProgress && <Loader />}
      <HighLevelView
        isAllAcountingLocationsCompleted={enableBalancePeriodButton}
        fromHistory={fromHistory}
        fromDiscrepancyAccount={fromDiscrepancyAccount}
        selectedRow={selectedRow}
      />
      <DetailView
        fromHistory={fromHistory}
        fromDiscrepancyAccount={fromDiscrepancyAccount}
        selectedRow={selectedRow}
      />
      {completeBalancePeriod && (
        <CommitTpBp
          content={Balance_PERIOD_POPUP_DATA}
          isPasswordValidation={false}
          popupType="BP"
          onCancelCommit={() => setCompleteBalancePeriod(false)}
          onCommit={handleCommit}
        />
      )}
      {!fromHistory && !fromDiscrepancyAccount && (
        <div className="flex w-full justify-end mb-16 px-10">
          {cbpButtonVisibilityStatus ===
            balancePeriodButtonVisibilityStatus.COMMIT_BUTTON_VISIBILITY_STATUS_SHOW && (
            <button className="completeButton ml-2" onClick={() => setCompleteBalancePeriod(true)}>
              Complete Balance Period
            </button>
          )}
          {cbpButtonVisibilityStatus ===
            balancePeriodButtonVisibilityStatus.COMMIT_BUTTON_VISIBILITY_STATUS_DISABLE && (
            <button disabled className="completeButton ml-2">
              Complete Balance Period
            </button>
          )}
        </div>
      )}
      {showCommitSuccessPopup && (
        <CommitSuccessPopup title="Balance Period Committed" onSuccess={handleCommitSuccessPopup} />
      )}
    </div>
  );
};
