/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

PO Transactions

PO Transactions Viewer

Cash Drawer Association API
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, UseMutationOptions, MutationFunction } from "@tanstack/react-query";
import type { Error } from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * This endpoint is used to remove specific entity
 * @summary removing the cash drawer from the branch.
 */
export const useRemoveEntityHook = () => {
  const removeEntity = useCustomInstance<void>();

  return (entityType: string) => {
    return removeEntity({ url: `/adhoc_balances/${entityType}`, method: "delete" });
  };
};

export type RemoveEntityMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useRemoveEntityHook>>
>;

export type RemoveEntityMutationError = ErrorType<Error>;

export const useRemoveEntity = <TError = ErrorType<Error>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof useRemoveEntityHook>>,
    TError,
    { entityType: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const removeEntity = useRemoveEntityHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof useRemoveEntityHook>>,
    { entityType: string }
  > = (props) => {
    const { entityType } = props || {};

    return removeEntity(entityType);
  };

  return useMutation<
    AsyncReturnType<typeof removeEntity>,
    TError,
    { entityType: string },
    TContext
  >(mutationFn, mutationOptions);
};
