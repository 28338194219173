import "./index.css";
import { TradingDetailSafeOverview } from "./TradingDetailSafeOverview";

type Props = {
  selectedTab: string;
  selectedValue: any;
  isTradingPeriodCompleted?: boolean;
  fromHistory?: boolean;
  fromDiscrepancyAccount?: boolean;
};

export const TradingDetailViewOverview = ({
  selectedTab,
  selectedValue,
  isTradingPeriodCompleted,
  fromHistory,
  fromDiscrepancyAccount,
}: Props) => {
  switch (selectedTab) {
    case "Safe 1":
      return (
        <TradingDetailSafeOverview
          selectedValue={selectedValue}
          isTradingPeriodCompleted={isTradingPeriodCompleted}
          fromHistory={fromHistory}
          fromDiscrepancyAccount={fromDiscrepancyAccount}
        />
      );

    default:
      return (
        <TradingDetailSafeOverview
          selectedValue={selectedValue}
          fromHistory={fromHistory}
          fromDiscrepancyAccount={fromDiscrepancyAccount}
        />
      );
  }
};
