import {
  usePostOnboardEntity,
  useRemoveEntity,
  useGetCashTransactionDetails,
} from "@bbo/api/generator";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { useDissociationApi } from "@bbo/lib/hooks/useDissociation";
import { useGlobalState } from "@bbo/lib/state";
import { PrintCashDrawerData } from "@bbo/types/CashDrawer";
import {
  LabelAvailablePrinters,
  LabelPrinterClient,
  setup as setupDeviceService,
  SupportedServices,
} from "postoffice-peripheral-management-service";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";

export const useManageCashDrawer = () => {
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [cashDrawer, setCashDrawer] = useState<any>({});
  const [reprint, setReprint] = useState<boolean>(false);
  const [printData, setPrintData] = useState<PrintCashDrawerData>(null);
  const [warnningLabel, setWarningLable] = useState<string>("");
  const [initialAddCall, setInitialAddCall] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [activity, setActivity] = useState<string>("");
  const [addedCashDrawer, setAddedCashDrawer] = useState<string>("");
  const [balanceCheck, setBalanceCheck] = useState<boolean>(false);
  const [, setgetNewCashDrawer] = useGlobalState("getNewCashDrawer");
  const { fadCode } = useAppSelector((root) => root.auth.authUserData);
  const {
    data: cashDrawers,
    status: isCashDrawLoading,
    refetch,
  } = useGetCashTransactionDetails(fadCode || "2314010");
  const { isCtAccess } = useAppSelector((root) => root.counterTerminalAccess);

  const ManageCashDrawerRequestParma = useMemo(
    () => ({
      balance: 0,
      entity: "cash_drawer",
      entityType: "accounting_location",
      operationType: "onboarding",
    }),
    [],
  );

  const labelPrinterService = setupDeviceService({
    deviceServerHost: process.env.REACT_APP_POL_DEVICE_SERVER_HOST,
    disconnectAfterResponse: true,
  });

  const labelPrinterClient = labelPrinterService.buildClient(SupportedServices.LabelPrinter, {
    useMock: process.env.REACT_APP_POL_DEVICE_SERVER_SIMULATED,
  }) as LabelPrinterClient;

  const zplString = ({ barcode, title }: { barcode: string; title: string }) =>
    `^XA
    ^MMT
    ^PW1200
    ^LL1800
    ^LS0
    ^BY6,3,341^FT844,1566^BCB,,Y,N
    ^FH^FD>;${barcode}^FS
    ^FT406,1799^A0B,106,106^FB1799,1,27,C^FH^CI28^FD${title}^FS^CI27
    ^MMC,N
    ^XZ`;

  const DissociateCashDrawerReq = {
    entityType: cashDrawer.associatedEntityType,
    entityID: cashDrawer.associatedEntityID,
    associatedEntityType: "counter_terminal",
    associatedEntityID: cashDrawer.entityID,
    operationType: "association",
    fadcode: cashDrawer.fadcode,
  };

  const {
    data: addCashDrawerResponse,
    mutate: addPoucheResponse,
    status: addCashDrawerStatus,
    error: addCashDrawerError,
  } = usePostOnboardEntity();

  const AddCashDrawer = useCallback(() => {
    addPoucheResponse({ entityType: "cash_drawer", data: ManageCashDrawerRequestParma });
  }, [ManageCashDrawerRequestParma, addPoucheResponse]);

  const {
    mutate: RemoveCashDrawer,
    data: removeCashDrawerResponse,
    status: removeCashDrawerStatus,
    isError: removeCashDrawerError,
  } = useRemoveEntity();

  const [
    {
      data: dissociateCashDrawerResponse,
      status: dissociateDrawerStatus,
      error: dissociateCashDrawerError,
    },
    DissociateCashDrawer,
    dissociaterefresh,
  ] = useDissociationApi("", "", "POST", "", DissociateCashDrawerReq);

  const handleCashDrawer = (counterData) => {
    setCashDrawer({ ...counterData });
    setShowPopUp(true);
  };

  const callBackHandlePopup = () => {
    setShowPopUp(false);
    setReprint(true);
  };

  const addCashDrawer = async () => {
    AddCashDrawer();
    setIsLoading(true);
    await addCashDrawerResponse;

    setActivity("Added");
  };
  const removeCashDrawer = async () => {
    RemoveCashDrawer({
      entityType: cashDrawers?.safe?.cashDrawer[cashDrawers?.safe?.cashDrawer.length - 1]?.entityID,
    });
  };

  const extractCashDrawerNumber = (cashDrawer: string): number => {
    const cashDrawerNumber = cashDrawer.split(" ");

    return Number(cashDrawerNumber[cashDrawerNumber.length - 1]);
  };
  const validateLastCashDrawer = (): boolean => {
    const cashDrawersInCounter = cashDrawers.counterTerminal.map((counters) => {
      return extractCashDrawerNumber(counters.associatedEntityName);
    });
    const lastCashDrawerInSafe = extractCashDrawerNumber(
      cashDrawers?.safe?.cashDrawer[cashDrawers?.safe?.cashDrawer.length - 1]?.entityName,
    );
    return cashDrawersInCounter.includes(lastCashDrawerInSafe + 1);
  };
  const unAssociateCD = () => {
    if (
      validateLastCashDrawer() ||
      cashDrawers.cashDrawer[cashDrawers?.cashDrawer.length - 1].entityID !==
        cashDrawers?.safe?.cashDrawer[cashDrawers?.safe?.cashDrawer.length - 1]?.entityID
    ) {
      return true;
    } else {
      return false;
    }
  };
  const dissociateCashDrawer = async () => {
    DissociateCashDrawer("postEntityType");
    await dissociateCashDrawerResponse;
    setInitialAddCall(false);
    if (!initialAddCall) {
      dissociaterefresh();
    }
  };

  const callBackHandleReprint = () => {
    setIsLoading(true);
    if (activity === "ADD") {
      addCashDrawer();
      setReprint(false);
      setSuccessMessage(true);
    } else if (activity === "REMOVE") {
      removeCashDrawer();
      setSuccessMessage(true);
    } else if (activity === "ForcedLoggOff") {
      setReprint(false);
      dissociateCashDrawer();
      setSuccessMessage(true);
    } else {
      setReprint(false);
      setPrintData({
        title: cashDrawer.entityName ? cashDrawer.entityName : addCashDrawerResponse.entityName,
        barcode:
          cashDrawer.entityBarcode || cashDrawer.barcode || cashDrawer.associatedEntityID
            ? cashDrawer.entityBarcode || cashDrawer.barcode || cashDrawer.associatedEntityID
            : addCashDrawerResponse.barcode,
      });
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  };

  const handleActivity = (activiti: string) => {
    setActivity(activiti);
    if (activiti === "ADD") {
      setWarningLable("Are you sure you want to add a Cash Drawer?");

      setReprint(true);
    } else if (activiti === "ForcedLoggOff") {
      setWarningLable("The user will be logged off and the cash drawer disassociated");
      setReprint(true);
    } else if (activiti === "REMOVE") {
      if (
        cashDrawers?.safe?.cashDrawer[cashDrawers?.safe?.cashDrawer.length - 1]?.itemDetails
          ?.balance !== 0 ||
        unAssociateCD()
      ) {
        setBalanceCheck(true);
      } else {
        setWarningLable(
          `Are you sure you want to remove ${
            cashDrawers?.safe?.cashDrawer[cashDrawers?.safe?.cashDrawer.length - 1]?.entityName
          }?`,
        );
        setReprint(true);
      }
    }
  };
  const callBackHandelForceLog = () => {
    setShowPopUp(false);
    handleActivity("ForcedLoggOff");
  };

  useLayoutEffect(() => {
    if (addCashDrawerStatus === "success" && addCashDrawerResponse?.entityName !== undefined) {
      setAddedCashDrawer(addCashDrawerResponse?.entityName);
      setSuccessMessage(true);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCashDrawerResponse, addCashDrawerError, addCashDrawerStatus]);

  useLayoutEffect(() => {
    if (removeCashDrawerStatus === "success") {
      setAddedCashDrawer(
        cashDrawers?.safe?.cashDrawer[cashDrawers?.safe?.cashDrawer.length - 1]?.entityName,
      );
      setReprint(false);
      setSuccessMessage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeCashDrawerResponse, removeCashDrawerError, removeCashDrawerStatus]);

  useLayoutEffect(() => {
    if (dissociateCashDrawerResponse !== typeof undefined && dissociateDrawerStatus === "fetched") {
      setSuccessMessage(true);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dissociateCashDrawerResponse, dissociateCashDrawerError, dissociateDrawerStatus]);

  const printZpl = async ({ rawBarcode, title }: { rawBarcode: string; title: string }) => {
    const indexOfLastDash = rawBarcode?.lastIndexOf("-");
    const part1 = rawBarcode?.slice(0, 6);
    const part2 = rawBarcode?.slice(6, indexOfLastDash + 1);
    const part3 = rawBarcode?.slice(indexOfLastDash + 1);
    const barcode = `${part1}>6${part2}>5${part3}`;
    try {
      const result = await labelPrinterClient.print({
        label: zplString({ barcode, title }),
        printer: LabelAvailablePrinters.RoyalMail,
      });
      return Promise.resolve(result);
    } catch (e) {
      return Promise.reject(e);
    }
  };
  return {
    cashDrawers,
    cashDrawer,
    setReprint,
    setAddedCashDrawer,
    setWarningLable,
    setActivity,
    reprint,
    warnningLabel,
    callBackHandlePopup,
    setBalanceCheck,
    setPrintData,
    balanceCheck,
    callBackHandleReprint,
    setSuccessMessage,
    setgetNewCashDrawer,
    refetch,
    addedCashDrawer,
    activity,
    isLoading,
    successMessage,
    printZpl,
    isCtAccess,
    addCashDrawerResponse,
    callBackHandelForceLog,
    showPopUp,
    handleActivity,
    handleCashDrawer,
    printData,
    isCashDrawLoading,
    unAssociateCD,
  };
};
