import {
  ATM,
  BRANCH_OVERVIEW,
  CASH_DRAWERS,
  COUNTERS,
  featureFlags,
  SAFE,
  VARIANCE_SUMMARY,
} from "@bbo/constants";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { useFeatureFlag } from "@bbo/lib/hooks/useFeatureFlag";
import { BranchVarianceSummary } from "../BranchVarianceSummary";
import AtmItem from "./AtmItem";
import CashDrawerList from "./CashDrawerList";
import CounterList from "./CounterList";
import { Overview } from "./Overview";
import SafeItem from "./SafeItem";

type Props = {
  selectedTab: string;
};

export const AccountingOverview = ({ selectedTab }: Props) => {
  const cashList = useAppSelector((root) => root.cashDetails);

  const [branchVarianceFeatureFlag] = useFeatureFlag(featureFlags.shouldShowBranchVariance);

  switch (selectedTab) {
    case BRANCH_OVERVIEW:
      return <Overview />;

    case COUNTERS:
      return <CounterList header={selectedTab} items={cashList.counterTerminal} />;

    case CASH_DRAWERS:
      return <CashDrawerList header={selectedTab} items={cashList.cashDrawer} showAll={true} />;

    case SAFE:
      return (
        <>
          <SafeItem data={cashList.safe} header={selectedTab} />
          <CashDrawerList header={selectedTab} items={cashList.cashDrawer} showAll={false} />
        </>
      );

    case ATM:
      return <AtmItem data={cashList.atm} header={selectedTab} />;

    case VARIANCE_SUMMARY:
      return branchVarianceFeatureFlag && <BranchVarianceSummary rows={cashList.varianceSummary} />;

    default:
      return null;
  }
};
