export const PAGINATION_SIZE = 7;

/**
 * Table  constant
 */
export const CASH_BALANCE_HISTORY_COLUMN = [
  {
    headerName: "Accounting Locations",
    field: "entityName",
    filter: "searchFilter",
    type: "string",
  },
  {
    headerName: "User Name",
    field: "userName",
    filter: "searchFilter",
    type: "string",
  },
  {
    headerName: "Smart ID",
    field: "smartID",
    filter: "searchFilter",
    type: "string",
  },
  {
    headerName: "Date and Time Range",
    field: "dateTime",
    filter: "datetime",
    type: "datetime",
    cellClass: "cell-center",
  },
  {
    headerName: "Variance",
    field: "currentVariance",
    filter: "amountRange",
    type: "currencyPence",
    cellClass: (params) => {
      if (params.data) {
        if (params.data.entity.itemDetails.currentVariance < 0) {
          return "cell-amount-negative float-right";
        }
        return "cell-amount-positive float-right";
      }
      return "float-right";
    },
  },
  {
    headerName: "Status",
    field: "entity.status",
    filter: "searchFilter",
    type: "string",
  },
];
export const CASH_MOVEMENT_HISTORY_COLUMN = [
  {
    headerName: "User Name",
    field: "userName",
    filter: "dropdown",
    type: "string",
  },
  {
    headerName: "Smart ID",
    field: "smartID",
    filter: "dropdown",
    type: "string",
  },
  {
    headerName: "Transaction Type",
    field: "operationType",
    filter: "searchFilter",
    type: "string",
    enableSelectAll: true,
  },
  {
    headerName: "Date and Time Range",
    field: "dateTime",
    filter: "datetime",
    cellClass: "cell-center",
    type: "datetime",
  },
  {
    headerName: "Transaction Location",
    field: "transferAt",
    filter: "dropdown",
    type: "string",
  },
  {
    headerName: "From",
    field: "sourceEntityName",
    filter: "dropdown",
    type: "string",
  },
  {
    headerName: "To",
    field: "destinationEntityName",
    filter: "dropdown",
    type: "string",
  },
  {
    headerName: "Amount",
    field: "transferAmount",
    filter: "amountRange",
    type: "currencyPence",
    cellClass: (params) => {
      return "float-right";
    },
  },
];

export const POUCH_PREPARATION_HISTORY_COLUMN = [
  {
    headerName: "Pouch ID",
    field: "pouchID",
    filter: "dropdown",
    type: "string",
  },
  {
    headerName: "Pouch Type",
    field: "pouchTypeName",
    filter: "dropdown",
    type: "string",
  },
  {
    headerName: "Prepared by User Name",
    field: "preparedByUserID",
    filter: "dropdown",
    type: "string",
  },
  {
    headerName: "Prepared by Smart ID",
    field: "preparedBySmartID",
    filter: "dropdown",
    type: "string",
  },

  {
    headerName: "Date and Time Range",
    field: "date",
    filter: "datetime",
    cellClass: "cell-center",
    type: "string",
  },
  {
    headerName: "Value",
    field: "totalPouchValue",
    filter: "dropdown",
    type: "currency",
    cellClass: (params) => {
      return "float-right text-right";
    },
  },
  {
    headerName: "Status",
    field: "status",
    filter: "dropdown",
    type: "string",
  },
];

//Branch variance summary table column definition
export const BRANCH_VARIANCE_SUMMARY_COLUMN = [
  {
    headerName: "Select All",
    field: "checkbox",
    type: "string",
    width: 140,
    suppressFilter: true,
    suppressSorting: true,
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  {
    headerName: "Accounting Location",
    field: "entityName",
    type: "string",
    suppressFilter: true,
    suppressSorting: true,
  },
  {
    headerName: "Variance",
    field: "toDateVarianceDisplay",
    type: "currency",
    suppressFilter: true,
    suppressSorting: true,
    cellClass: (params) => {
      if (params.data && params.data[params.field]) {
        if (params.data[params.field].indexOf("-") !== -1) {
          return "cell-amount-negative";
        }
        return "cell-amount-positive";
      }
      return "";
    },
  },
  {
    headerName: "Reason",
    field: "reason.title",
    type: "string",
    suppressFilter: true,
    suppressSorting: true,
  },
  {
    headerName: "",
    field: "btn-change-row",
    filter: "dropdown",
    type: "string",
    width: 140,
    suppressFilter: true,
    suppressSorting: true,
    cellClickable: true,
    cellRenderer: `<span style="color:#2B60DE;">change</span>`,
  },
];

export const BRANCH_DISCREPENCY_SUMMARY_COLUMN = [
  {
    headerName: "",
    field: "checkbox",
    type: "string",
    width: 140,
    checkboxSelection: true,
    filter: "checkBox",
    suppressSorting: true,
    cellClickable: false,
  },
  {
    headerName: "Date",
    field: "dateTime",
    type: "string",
    filter: "datetime",
    cellClickable: true,
  },
  {
    headerName: "Discrepancy Record Type",
    field: "discrepencyType",
    type: "string",
    filter: "dropdown",
    cellClickable: true,
  },
  {
    headerName: "Reason Code",
    field: "reasonType",
    type: "string",
    filter: "dropdown",
    cellClickable: true,
  },
  {
    headerName: "Discrepancy Value",
    field: "varianceBalance",
    filter: "amountRange",
    type: "currencyPence",
    cellClickable: true,
    cellClass: (params) => {
      if (params.data && params.data[params.field]) {
        if (params.data[params.field] < 0) {
          return "cell-amount-negative float-right";
        }
        return "cell-amount-positive float-right";
      }
      return "float-right";
    },
  },
  {
    headerName: "Status",
    field: "status",
    type: "string",
    filter: "dropdown",
    cellClickable: true,
  },
];

export const BRANCH_DISCREPENCY_SUMMARY_SETTLED_COLUMN = [
  {
    headerName: "Date",
    field: "dateTime",
    type: "string",
    filter: "datetime",
    cellClickable: true,
  },
  {
    headerName: "Discrepancy Record Type",
    field: "discrepencyType",
    type: "string",
    filter: "dropdown",
    cellClickable: true,
  },
  {
    headerName: "Reason Code",
    field: "reasonType",
    type: "string",
    filter: "dropdown",
    cellClickable: true,
  },
  {
    headerName: "Discrepancy Value",
    field: "varianceBalance",
    filter: "amountRange",
    type: "currencyPence",
    cellClickable: true,
    cellClass: (params) => {
      if (params.data && params.data[params.field]) {
        if (params.data[params.field] < 0) {
          return "cell-amount-negative float-right";
        }
        return "cell-amount-positive float-right";
      }
      return "float-right";
    },
  },
  {
    headerName: "Status",
    field: "status",
    type: "string",
    filter: "dropdown",
    cellClickable: true,
  },
];

export const BRANCH_DISCREPENCY_HISTORY_COLUMN = [
  {
    headerName: "Date",
    field: "dateTime",
    type: "string",
    filter: "datetime",
    suppressSorting: true,
    cellClickable: true,
  },
  {
    headerName: "Discrepancy Record Type",
    field: "discrepencyType",
    filter: "dropdown",
    type: "string",
    suppressSorting: true,
    cellClickable: true,
  },
  {
    headerName: "Reason Code",
    field: "reasonType",
    type: "string",
    filter: "dropdown",
    suppressSorting: true,
    cellClickable: true,
  },
  {
    headerName: "Discrepancy Value",
    field: "varianceBalance",
    filter: "amountRange",
    type: "currencyPence",
    suppressSorting: true,
    cellClickable: true,
    cellClass: (params) => {
      if (params.data && params.data[params.field]) {
        if (params.data[params.field] < 0) {
          return "cell-amount-negative float-right";
        }
        return "cell-amount-positive float-right";
      }
      return "float-right";
    },
  },
  {
    headerName: "Status",
    field: "status",
    type: "string",
    filter: "dropdown",
    suppressSorting: true,
    cellClickable: true,
  },
];

export const USER_ACTIVITIES_TABLE_COLUMN = [
  {
    headerName: "User Name",
    field: "userName",
    type: "string",
    suppressSorting: true,
    cellClass: "tableTextSize",
  },
  {
    headerName: "Activity",
    field: "operationType",
    type: "string",
    suppressSorting: true,
    cellClass: "tableTextSize",
  },
  {
    headerName: "Details",
    field: "amount",
    type: "string",
    suppressSorting: true,
    cellClass: "tableTextSize",
  },
  {
    headerName: "Link to",
    field: "Link",
    type: "string",
    suppressSorting: true,
    cellClickable: true,
    cellClass: (params) => {
      if (params.data[params.field] === "Details") {
        return `linkTag tableTextSize`;
      }
    },

    // cellClass: (params) => {

    // },
  },
];

export const TP_BP_HISTORY_COLUMN = [
  {
    headerName: "BP / TP Number",
    field: "name",
    type: "string",
    suppressSorting: true,
    cellClickable: true,
    cellClass: "tableTextSize",
  },
  {
    headerName: "Date",
    field: "dateTime",
    filter: "datetime",
    type: "datetime",
    cellClickable: true,
    suppressSorting: true,
    cellClass: "cell-center",
  },
  {
    headerName: "Username",
    field: "createdByUserName",
    filter: "searchFilter",
    cellClickable: true,
    suppressSorting: true,
    type: "string",
  },
  {
    headerName: "Smart ID",
    field: "createdBySmartID",
    filter: "searchFilter",
    cellClickable: true,
    suppressSorting: true,
    type: "string",
  },
  {
    headerName: "Discrepancy Value",
    field: "varianceAmount",
    filter: "amountRange",
    type: "currencyPence",
    suppressSorting: true,
    cellClickable: false,
    cellClass: (params) => {
      if (params.data && params.data[params.field]) {
        if (params.data[params.field] < 0) {
          return "cell-amount-negative";
        }
        return "cell-amount-positive";
      }
      return "text-right";
    },
  },
];
export const STOCK_REVIEW_COLUMN = [
  {
    headerName: "Product name",
    field: "productName",
    type: "string",
    suppressSorting: true,
    cellClass: "tableTextSize",
  },
  {
    headerName: "Product EAN",
    field: "productEan",
    type: "string",
    suppressSorting: true,
    cellClass: "tableTextSize",
  },
  {
    headerName: "System quantity",
    field: "systemQuantity",
    type: "string",
    suppressSorting: true,
    cellClass: "tableTextSize",
  },
  {
    headerName: "Entered quantity",
    field: "enteredQuantity",
    type: "string",
    inputBox: true,
    suppressSorting: true,
    cellClickable: true,
    cellClass: "tableTextSize",
  },
  {
    headerName: "Variance quantity",
    field: "varianceQuantity",
    type: "string",
    dynamicQuantity: true,
    cellClass: "tableTextSize",
  },
  {
    headerName: "Variance value",
    field: "varianceAmount",
    type: "currencyPence",
    dynamicVariance: true,
    cellClass: (params) => {
      if (params.data && params.data[params.field]) {
        if (params.data[params.field] < 0) {
          return "cell-amount-negative";
        }
        return "cell-amount-positive";
      }
      return "text-right";
    },
  },
];

export const TRANSACTION_LOG_COLUMN = [
  {
    headerName: "Date",
    field: "TransactionStartTime",
    cellClass: "cell-center",
    type: "string",
    suppressSorting: true,
  },
  {
    headerName: "Time Range",
    field: "TransactionStartTime",
    cellClass: "cell-center",
    type: "string",
    suppressSorting: true,
  },
  {
    headerName: "Terminal ID",
    field: "nodeID",
    type: "string",
    suppressSorting: true,
    cellClass: "tableTextSize",
  },
  {
    headerName: "Transaction ID",
    field: "TransactionID",
    width: 350,
    filter: "InputSearch",
    type: "string",
    suppressSorting: true,
  },
  {
    headerName: "Smart ID",
    field: "basketOpenedBy",
    type: "string",
    suppressSorting: true,
    cellClass: "tableTextSize",
  },
  {
    headerName: "Product Description ",
    field: "itemDescription",
    filter: "InputSearch",
    type: "string",
    suppressSorting: true,
  },
  {
    headerName: "Value",
    field: "valueInPence",
    type: "string",
    suppressSorting: true,
    cellClass: (params) => {
      return "tableTextSize text-right float-right";
    },
  },
];
