import { useGetBranchData, useGetEntityData } from "@bbo/api/generator";
import CashBalanceDetailPopup from "@bbo/components/layout/PopupScreen/cashBalanceDetailPopup";
import { Popup } from "@bbo/components/Popup";
import TableView from "@bbo/components/table";
import Loader from "@bbo/components/Loader";
import { CASH_BALANCE_HISTORY_COLUMN } from "@bbo/components/table/tableConstants";
import { getFilters } from "@bbo/components/table/utility";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { useReceiptPrinter } from "@bbo/lib/hooks/useReceiptPrinter";
import { useGlobalState } from "@bbo/lib/state";
import { amountPound } from "@bbo/utils/currencyFormatter";
import {
  currentDateDDMMYYYY,
  currentTime,
  getbalanceDate,
  getonlyDateTime,
  getUnixTimeStamp,
} from "@bbo/utils/dateTimeFormatter";
import { CashBalancingHistory } from "@bbo/utils/ReceiptPrinterTemplates/CashBalancingHistory";
import { getValueFromStorage } from "@bbo/utils/util";
import { useEffect, useState } from "react";
import {
  convertDenomination,
  Denomination,
  removeEmptyLinesFromAddress,
} from "../utils/receiptHelper";
import { useAppSelector } from "@bbo/lib";

const CashBalanceHistory = () => {
  const [param, setparam] = useState<any>({});
  const [cashRowSelected, setCashRowSelected] = useState(null);
  const { data: cashBalanceHistory, isLoading } = useGetEntityData("AccountingLocation", param);
  const [cashdate] = useGlobalState("dateCashbalance");
  const [isDateRangeSelected, setIsDateRangeSelected] = useState<boolean>(false);
  const [modalData, setModalData] = useState({ showModal: false });
  const { setTitle } = useTitle();
  const receiptPrinterService = useReceiptPrinter();
  const [showPopup, setShowPopup] = useState(false);
  const fadCode = getValueFromStorage<string>("fadcode");
  const branchDetails = useAppSelector((root) => root.branchDetails);
  const [cashBalanceHistoryData, setCashBalanceHistoryData] = useState([]);
  const [loader] = useState<boolean>(false);

  useEffect(() => {
    setTitle("Cash Balances History");
  }, [setTitle]);

  useEffect(() => {
    if (!isLoading) {
      const cashBalanceHistoryUpdated = cashBalanceHistory?.history?.map((item) => {
        const details = item?.entity?.itemDetails;
        const mValue = {};
        if (details && details?.lastBalancedDateTime) {
          mValue["dateTime"] = item?.entity?.itemDetails?.lastBalancedDateTime;
        }
        if (details) {
          mValue["currentVariance"] = item?.entity?.itemDetails?.currentVariance;
          mValue["entityName"] = item?.entity?.entityName;
          mValue["userName"] = item?.entity?.userName;
          mValue["smartID"] = item?.entity?.smartID;
          mValue["status"] = item?.entity?.status;
        }

        return {
          ...item,
          ...mValue,
        };
      });
      setCashBalanceHistoryData(cashBalanceHistoryUpdated);
    }
  }, [isLoading, cashBalanceHistory]);

  useEffect(() => {
    if (cashdate.todate) {
      setIsDateRangeSelected(true);
      setparam({
        toDate: getUnixTimeStamp(cashdate.todate),
        fromDate: getUnixTimeStamp(cashdate.from),
      });
    } else {
      setIsDateRangeSelected(false);
    }
  }, [cashdate.todate]);

  const handleRowClicked = (params) => {
    setShowPopup(true);
    setCashRowSelected(params);
    setModalData({ ...params, showModal: true });
  };
  const actions = [
    {
      name: "Cancel",
      handleAction: () => {
        setShowPopup(false);
      },
      style: "popupBtn border p-3 flex-auto mr-1",
    },
    {
      name: "Print",
      handleAction: () => {
        const branchAddressClean = removeEmptyLinesFromAddress(branchDetails.branch_address);
        setShowPopup(false);
        receiptPrinterService.printReceipt({
          template: CashBalancingHistory,
          context: {
            title: "Cash Balance History",
            branchName: branchDetails.branch_name,
            addressLine1: branchAddressClean[0],
            addressLine2: branchAddressClean[1],
            addressLine3: branchAddressClean[2],
            postcode: branchAddressClean[branchAddressClean.length - 1],
            fadcode: branchDetails.fadcode,
            currentDate: currentDateDDMMYYYY(),
            currentTime: currentTime(),
            accountingLocation: cashRowSelected.data.entity.entityName,
            userName: cashRowSelected.data.entity.userName,
            smartId: cashRowSelected.data.entity.smartID,
            dateAndTime: getonlyDateTime(
              cashRowSelected.data.entity.itemDetails.lastBalancedDateTime,
            ),
            Variance: amountPound(cashRowSelected.data.entity.itemDetails.currentVariance),
            Status: cashRowSelected.data.entity.status,
            denominationLadder: convertDenomination(
              cashRowSelected.data.entity?.itemDetails?.denominationLadder as Denomination[],
            ),
            preparedPouches: cashRowSelected.data?.preparedPouches,
            suspendedPouches: cashRowSelected.data?.suspendedPouches,
            activitiesBetweenBalances: cashRowSelected.data?.transferActivities,
          },
        });
      },
      style: "accounting-button border p-3 text-white flex-auto ml-1",
    },
  ];
  return (
    <>
      <>
        {isLoading ? <Loader /> : loader && <Loader />}
        <div className="w-full transferOut-height">
          <div className="p-4 w-full h-full relative dynamictable">
            <TableView
              showResetFilter
              columns={CASH_BALANCE_HISTORY_COLUMN}
              rows={cashBalanceHistoryData}
              onRowClicked={handleRowClicked}
              isDateRangeSelected={isDateRangeSelected}
            />
            {showPopup && (
              <Popup
                titleHeader="Cash Balance History"
                title=" - Details"
                body={<CashBalanceDetailPopup data={modalData} />}
                actions={actions}
                onClose={() => {
                  setShowPopup(false);
                }}
              />
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default CashBalanceHistory;
