import { amountPound, currencyStyle } from "@bbo/utils/currencyFormatter";
import { getTimeForTpBpHistory } from "@bbo/utils/dateTimeFormatter";
const TpBpDetailView = (props) => {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const { createdByUserName, createdBySmartID, date, dateTime, varianceAmount } = props?.data?.data;

  return (
    <>
      <div className="flex items-center justify-center rounded-b">
        <table className="w-full BalanceTable detailViewTable">
          <tbody className="bg-white divide-y divide-gray-200">
            <tr>
              <td colSpan={2}>
                <b>User Name</b>
              </td>
              <td colSpan={3}>{createdByUserName}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>Smart ID</b>
              </td>
              <td colSpan={3}>{createdBySmartID}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>Date & Time</b>
              </td>
              <td colSpan={3}>
                {date} {getTimeForTpBpHistory(dateTime)}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>Commodity Value</b>
              </td>
              <td colSpan={3}>{amountPound(varianceAmount)}</td>
            </tr>
            <tr className="subHead">
              <td>
                <b>Commodity</b>
              </td>
              <td></td>
              <td></td>
              <td className="text-right">
                <b>Value</b>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>Cash</b>
              </td>
              <td className="text-right" colSpan={3}>
                <div className={currencyStyle(varianceAmount)}>{amountPound(varianceAmount)}</div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>Forex</b>
              </td>
              <td className="text-right" colSpan={3}>
                {amountPound(0)}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>Stock</b>
              </td>
              <td className="text-right" colSpan={3}>
                {amountPound(0)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TpBpDetailView;
