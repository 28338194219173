import link from "@bbo/assets/images/link.svg";
import userCheck from "@bbo/assets/images/user-check.svg";
import { useState } from "react";
import { StockCardData } from "../StockBranchOverview/StockCardData/StockCardData";
import { ActionList } from "./ActionList";
import { useTableList } from "./useTableList";
import { CounterTerminalData } from "@bbo/api/generator";

type Props = {
  header: string;
  data: CounterTerminalData;
  index: number;
  isStockData?: boolean;
};

const CounterItem = ({ data, index, isStockData, ...rest }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const { orderedTable } = useTableList(data.itemDetails ?? {});
  const handleClose = () => {
    setShowModal(false);
  };
  const actionData = { ...data, ...data.itemDetails };

  return (
    <>
      {" "}
      <div
        className="main widthAccounting mr-8 inline-block"
        key={index}
        onClick={() => setShowModal(true)}>
        <div className="heading">
          <span>{data?.entityName}</span>
          <img src={link} className="mx-2" />
          <span>{data?.associatedEntityName}</span>
        </div>
        <hr className="border-gray-200 border-bottom-none" />
        <div className="second-heading h-10 truncate">
          <img src={userCheck} className="mr-2" /> <span>{data?.userFullName}</span>
        </div>
        <hr className="border-gray-200 border-bottom-none" />
        <div className="third-heading">
          {isStockData ? (
            <StockCardData data={data?.itemDetails} />
          ) : (
            <table className="w-full">
              <tbody className="counterPad">{orderedTable}</tbody>
            </table>
          )}
        </div>
      </div>
      {showModal && <ActionList data={actionData} header={rest.header} onClose={handleClose} />}
    </>
  );
};

export default CounterItem;
