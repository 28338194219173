import { useGetStockBranchOverview } from "@bbo/api/generator";
import Loader from "@bbo/components/Loader";
import { Tab } from "@bbo/components/Tab/Tab";
import { CASH_DRAWERS, COUNTERS, SAFE } from "@bbo/constants";
import CashDrawerList from "@bbo/features/BranchOverview/CashDrawerList";
import CounterList from "@bbo/features/BranchOverview/CounterList";
import SafeItem from "@bbo/features/BranchOverview/SafeItem";
import { useGlobalState } from "@bbo/lib";
import { getValueFromStorage } from "@bbo/utils/util";
import { StockAccountingOverview } from "../StockAccountingOverview";

export const StockBranchOverviewTab = () => {
  const [GlobalUSerName] = useGlobalState("userName");

  const { data: stockData, isLoading: isStockLoading } = useGetStockBranchOverview(
    getValueFromStorage("fadcode"),
  );
  if (isStockLoading) {
    return <Loader data-testid="loader" />;
  }
  return (
    <Tab id={"StockBranchOverviewTab"}>
      <Tab.TitleList>
        <Tab.Title data-testid="tab-0">Branch Overview</Tab.Title>
        <Tab.Title data-testid="tab-1">{COUNTERS}</Tab.Title>
        <Tab.Title data-testid="tab-2">{SAFE}</Tab.Title>
        <Tab.Title data-testid="tab-3">{CASH_DRAWERS}</Tab.Title>
      </Tab.TitleList>
      <Tab.Panel className="w-full bg-white">
        <Tab.PanelContent>
          <Tab.PanelData>
            <div className="p-8">
              <StockAccountingOverview stockData={stockData} isStockData={true} />
            </div>
          </Tab.PanelData>
          <Tab.PanelData>
            <div className="p-8">
              <CounterList
                items={stockData["counterTerminal"]}
                header={COUNTERS}
                data-testid="counter-id"
                isStockData={true}
              />
            </div>
          </Tab.PanelData>
          <Tab.PanelData>
            <div className="p-8">
              <SafeItem data={stockData["safe"]} header={SAFE} isStockData={true} />
              <CashDrawerList
                items={stockData["cashDrawer"]}
                header={CASH_DRAWERS}
                showAll={false}
                isStockData={true}
              />
            </div>
          </Tab.PanelData>
          <Tab.PanelData>
            <div className="p-8">
              <CashDrawerList
                items={stockData["cashDrawer"]}
                header={CASH_DRAWERS}
                showAll={true}
                isStockData={true}
              />
            </div>
          </Tab.PanelData>
        </Tab.PanelContent>
      </Tab.Panel>
    </Tab>
  );
};
