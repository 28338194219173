import { useGlobalState } from "@bbo/lib/state";
import "@bbo/screens/Index.css";
import { useEffect, useState } from "react";
import LaddarRow from "./laddreRow";

interface iProps {
  submitNext?: (Submitted) => void;
  ResetPopupFlag?: () => void;
  cashDetails?: any;
  inputQ?: boolean;
  inputV?: boolean;
  nextFocus?: any;
  ResetPopup?: boolean;
}

export const Ladder = (props: iProps) => {
  const [diabledFlags, setDiabledFlags] = useState({
    inputQ: false,
    inputV: false,
  });
  const [cashdetail, setcashdetail] = useGlobalState("cashDetailsFprAdhocBalance");
  const [graysubmit, setgraysubmit] = useState<string>("next-background");
  const [Submitted, setSubmitted] = useState<boolean>(true);
  const [isReadonlyMode] = useGlobalState("isReadonlyMode");

  useEffect(() => {
    if (isReadonlyMode.isReadonlyMode) {
      setDiabledFlags({ inputQ: true, inputV: true });
    } else setDiabledFlags({ inputQ: false, inputV: false });
  }, [isReadonlyMode]);
  useEffect(() => {
    setDiabledFlags({ inputQ: false, inputV: false });
  }, [props.inputQ, props.inputV]);
  useEffect(() => {
    let count = 0;
    cashdetail.map((item) => {
      if (item.btnDisable === false) {
        count++;
      }
      if (
        !isReadonlyMode.isReadonlyMode &&
        count > 0 &&
        !cashdetail.some((item) => item.alertCss === "alertCss")
      ) {
        if (!props.ResetPopup) {
          setSubmitted(false);
        }
        setgraysubmit("active-next");
      } else {
        setSubmitted(true);
        setgraysubmit("next-background");
      }
      return 0;
    });
  });

  const onChange = (params, index, flags) => {
    setcashdetail((list) => {
      list[index] = { ...params };
      return [...list];
    });
    if (flags !== "inputQ") {
      setDiabledFlags({ inputQ: true, inputV: false });
    } else {
      setDiabledFlags({ inputQ: false, inputV: true });
    }
  };

  const submitNext = (Submitted) => {
    if (props.submitNext) {
      props.submitNext(Submitted);
    }
  };
  const ResetPopupFlag = (e) => {
    e.preventDefault();
    if (props.ResetPopupFlag) {
      props.ResetPopupFlag();
    }
  };
  return (
    <div className="pt-4 bg-white boxShadow  ">
      <h2 className="heading-4 font-bold mt-4 pl-4">
        Cash Balances - Enter Cash in Quantity or Value
      </h2>
      <form>
        <>
          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-3 md:gap-4">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0"></div>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-3">
                <div className="overflow-y-auto  sm:rounded-md">
                  <div className="pb-5 pl-4">
                    <table className="breakdownTable table-fixed border-collapse">
                      <thead className="adhoc-head-background mb-2">
                        <tr>
                          <th className="heading">
                            <b>Denomination</b>
                          </th>
                          <th></th>
                          <th className="heading">
                            <b>Quantity</b>
                          </th>
                          <th></th>
                          <th className="heading">
                            <b>Value</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {cashdetail?.map((row, position) => (
                          <LaddarRow
                            key={position}
                            item={row}
                            index={position}
                            diabledFlags={diabledFlags}
                            onChange={onChange}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 py-4 boxLadderShadow buttomBtnFix items-center justify-between lg:justify-center flex ">
            {/* {alertMsg} */}{" "}
            {!isReadonlyMode.isReadonlyMode && !Submitted && (
              <button
                data-cy="resetBtn"
                type="submit"
                className="inline-flex justify-center text-black font-bold rounded reset-btn"
                onClick={ResetPopupFlag}>
                Reset
              </button>
            )}
            <button
              data-cy="nextBtn"
              type="submit"
              disabled={Submitted}
              ref={props.nextFocus}
              className={"inline-flex justify-center ml-6 font-bold rounded " + graysubmit}
              onClick={() => submitNext(Submitted)}>
              Next
            </button>
          </div>
        </>
      </form>
    </div>
  );
};
