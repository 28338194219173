import { FC } from "react";

interface RouteStateProps {
  RouteState?: string;
}
export interface ListItemProps {
  entityName: string;
  entityID?: string;
  entity?: string;
  sequenceID?: string;
  associatedCounterTerminal?: string;
}
export interface AppProps {
  routeState?: RouteStateProps;
  listItems?: [ListItemProps];
  activeHeadingName?: string;
  selectOption?: (param1: string, param2: string, param3: string, param4: string) => void;
}

enum CONSTANTS {
  TRANS_BUTTON_ACTIVE = "transferButtonActive",
  COMMON_HEADER = "transferbutton heading",
  EMPTY = "",
}
const ButtonCounterView: FC<AppProps> = ({
  routeState,
  listItems,
  activeHeadingName,
  selectOption,
}: AppProps) => (
  <>
    {listItems?.map((headingName, index) =>
      headingName.associatedCounterTerminal ? (
        <tr
          key={index}
          className={`${CONSTANTS.COMMON_HEADER} ${
            headingName.associatedCounterTerminal === activeHeadingName
              ? CONSTANTS.TRANS_BUTTON_ACTIVE
              : CONSTANTS.EMPTY
          } `}
          onClick={() => {
            selectOption(
              headingName.associatedCounterTerminal,
              headingName.entityID,
              headingName.entity,
              headingName.sequenceID,
            );
          }}>
          <td>{headingName.associatedCounterTerminal}</td>
        </tr>
      ) : (
        <tr
          key={index}
          className={`${CONSTANTS.COMMON_HEADER} ${
            headingName.entityName === activeHeadingName
              ? CONSTANTS.TRANS_BUTTON_ACTIVE
              : CONSTANTS.EMPTY
          } `}
          onClick={() => {
            selectOption(
              headingName.entityName,
              headingName.entityID,
              headingName.entity,
              headingName.sequenceID,
            );
          }}>
          <td>{headingName.entityName}</td>
        </tr>
      ),
    )}
  </>
);
export default ButtonCounterView;
