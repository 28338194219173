import "@bbo/components/layout/index.css";
import Loader from "@bbo/components/Loader";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { AccountingOverview } from "./AccountingOverview";
import HeaderTabs from "./HeaderTabs";
import { useBranchOverview } from "./useBranchOverview";

export const BranchOverview = () => {
  const { handleSelectTab, selectedTab, tabLabels, VarianceDataIsLoading, cashDataIsLoading } =
    useBranchOverview();

  const CashList = useAppSelector((root) => root.cashDetails);

  return (
    <>
      {cashDataIsLoading !== "error" &&
        cashDataIsLoading !== "fetched" &&
        VarianceDataIsLoading !== "fetched" && <Loader />}
      <HeaderTabs
        tabLabels={tabLabels}
        selected={selectedTab}
        varianceAmount={CashList.VarianceAmt}
        onSelectTab={handleSelectTab}
      />
      <AccountingOverview selectedTab={selectedTab} />
    </>
  );
};
