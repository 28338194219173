import { combineReducers, configureStore, PreloadedState } from "@reduxjs/toolkit";
import authReducer from "./auth.slice";
import BpTpReducer from "./BpTp.slice";
import branchDetailReducer from "./branchDetais.slice";
import cashReducer from "./cash.slice";
import countedCurrenciesReducer from "./countedCurrencies.slice";
import counterTerminalAccessReducer from "./counterTerminalAccess.slice";
import DiscrepancyAccountReducer from "./discrepancyAccount.slice";
import HistoryReducer from "./history.slice";
import paginationReducer from "./pagination.slice";
import selectedTaskReducer from "./selectedTask.slice";
import userNameReducer from "./userName.slice";

const reducer = combineReducers({
  counterTerminalAccess: counterTerminalAccessReducer,
  selectedTask: selectedTaskReducer,
  auth: authReducer,
  userName: userNameReducer,
  cashDetails: cashReducer,
  BpTp: BpTpReducer,
  pagination: paginationReducer,
  History: HistoryReducer,
  countedCurrencies: countedCurrenciesReducer,
  DiscrepancyAccount: DiscrepancyAccountReducer,
  branchDetails: branchDetailReducer,
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: reducer,
    preloadedState,
  });
}
export const store = configureStore({
  reducer,
});

export type RootState = ReturnType<typeof reducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
