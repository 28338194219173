/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

PO Transactions

PO Transactions Viewer

Cash Drawer Association API
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  EntityDataPouchStatus,
  CashManagementApiResponse,
  CashBalanceRequestPayload,
  CashBalanceDetails,
  GetEntityDataParams,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Get entity_data.
 * @summary This endpoint is used to create and item post balancing updates
 */
export const useCashManagementgetEntityDataByBranchIdHook = () => {
  const cashManagementgetEntityDataByBranchId = useCustomInstance<EntityDataPouchStatus>();

  return (branchId: string, entity: string, entityID: string) => {
    return cashManagementgetEntityDataByBranchId({
      url: `/cash_management/adhoc-balances/${branchId}/${entity}/${entityID}`,
      method: "get",
    });
  };
};

export const getCashManagementgetEntityDataByBranchIdQueryKey = (
  branchId: string,
  entity: string,
  entityID: string,
) => [`/cash_management/adhoc-balances/${branchId}/${entity}/${entityID}`];

export type CashManagementgetEntityDataByBranchIdQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useCashManagementgetEntityDataByBranchIdHook>>
>;
export type CashManagementgetEntityDataByBranchIdQueryError = ErrorType<void>;

export const useCashManagementgetEntityDataByBranchId = <
  TData = AsyncReturnType<ReturnType<typeof useCashManagementgetEntityDataByBranchIdHook>>,
  TError = ErrorType<void>,
>(
  branchId: string,
  entity: string,
  entityID: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useCashManagementgetEntityDataByBranchIdHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getCashManagementgetEntityDataByBranchIdQueryKey(branchId, entity, entityID);

  const cashManagementgetEntityDataByBranchId = useCashManagementgetEntityDataByBranchIdHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useCashManagementgetEntityDataByBranchIdHook>>
  > = () => cashManagementgetEntityDataByBranchId(branchId, entity, entityID);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useCashManagementgetEntityDataByBranchIdHook>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(branchId && entity && entityID), ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary This endpoint is used to create and item post balancing updates
 */
export const usePostEntityDataHook = () => {
  const postEntityData = useCustomInstance<void>();

  return (entityUid: string, cashBalanceRequestPayload: CashBalanceRequestPayload) => {
    return postEntityData({
      url: `/cash_management/adhoc-balances/entity/${entityUid}`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: cashBalanceRequestPayload,
    });
  };
};

export type PostEntityDataMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof usePostEntityDataHook>>
>;
export type PostEntityDataMutationBody = CashBalanceRequestPayload;
export type PostEntityDataMutationError = ErrorType<CashManagementApiResponse>;

export const usePostEntityData = <
  TError = ErrorType<CashManagementApiResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof usePostEntityDataHook>>,
    TError,
    { entityUid: string; data: CashBalanceRequestPayload },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const postEntityData = usePostEntityDataHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof usePostEntityDataHook>>,
    { entityUid: string; data: CashBalanceRequestPayload }
  > = (props) => {
    const { entityUid, data } = props || {};

    return postEntityData(entityUid, data);
  };

  return useMutation<
    AsyncReturnType<typeof postEntityData>,
    TError,
    { entityUid: string; data: CashBalanceRequestPayload },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Get entity_data.
 * @summary This endpoint is used to get entity data based on entity_type
 */
export const useGetEntityDataHook = () => {
  const getEntityData = useCustomInstance<CashBalanceDetails>();

  return (entityType: string, params?: GetEntityDataParams) => {
    return getEntityData({
      url: `/cash_management/adhoc-balances/${entityType}`,
      method: "get",
      params,
    });
  };
};

export const getGetEntityDataQueryKey = (entityType: string, params?: GetEntityDataParams) => [
  `/cash_management/adhoc-balances/${entityType}`,
  ...(params ? [params] : []),
];

export type GetEntityDataQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetEntityDataHook>>
>;
export type GetEntityDataQueryError = ErrorType<void>;

export const useGetEntityData = <
  TData = AsyncReturnType<ReturnType<typeof useGetEntityDataHook>>,
  TError = ErrorType<void>,
>(
  entityType: string,
  params?: GetEntityDataParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetEntityDataHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetEntityDataQueryKey(entityType, params);

  const getEntityData = useGetEntityDataHook();

  const queryFn: QueryFunction<AsyncReturnType<ReturnType<typeof useGetEntityDataHook>>> = () =>
    getEntityData(entityType, params);

  const query = useQuery<AsyncReturnType<ReturnType<typeof useGetEntityDataHook>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!entityType, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};
