import useCurrencyCountRowHook from "./useCurrencyCountRowHook";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./CurrencyCountRowStyles";

const CurrencyCountRow = ({ item, varianceType }) => {
  const { data, getStyles } = useCurrencyCountRowHook(item, varianceType);
  const classes = useStyles();

  return (
    <tr>
      {Object.keys(data).map((key, index) => {
        if (key !== "varianceType") {
          return (
            <td className={getStyles(key, classes)} key={index + key}>
              <Typography>{data[key]}</Typography>
            </td>
          );
        }
      })}
    </tr>
  );
};

export default CurrencyCountRow;
