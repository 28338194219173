/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

PO Transactions

PO Transactions Viewer

Cash Drawer Association API
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, UseMutationOptions, MutationFunction } from "@tanstack/react-query";
import type {
  VarianceReportResponse,
  CashManagementApiResponse,
  VarianceReportFields,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary This endpoint is used to get Variance report of branch
 */
export const usePutCashManagementVarianceReportHook = () => {
  const putCashManagementVarianceReport = useCustomInstance<VarianceReportResponse[]>();

  return (varianceReportFields: VarianceReportFields) => {
    return putCashManagementVarianceReport({
      url: `/cash_management/variance_report`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: varianceReportFields,
    });
  };
};

export type PutCashManagementVarianceReportMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof usePutCashManagementVarianceReportHook>>
>;
export type PutCashManagementVarianceReportMutationBody = VarianceReportFields;
export type PutCashManagementVarianceReportMutationError =
  ErrorType<CashManagementApiResponse | void>;

export const usePutCashManagementVarianceReport = <
  TError = ErrorType<CashManagementApiResponse | void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof usePutCashManagementVarianceReportHook>>,
    TError,
    { data: VarianceReportFields },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const putCashManagementVarianceReport = usePutCashManagementVarianceReportHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof usePutCashManagementVarianceReportHook>>,
    { data: VarianceReportFields }
  > = (props) => {
    const { data } = props || {};

    return putCashManagementVarianceReport(data);
  };

  return useMutation<
    AsyncReturnType<typeof putCashManagementVarianceReport>,
    TError,
    { data: VarianceReportFields },
    TContext
  >(mutationFn, mutationOptions);
};
