/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

PO Transactions

PO Transactions Viewer

Cash Drawer Association API
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, UseMutationOptions, MutationFunction } from "@tanstack/react-query";
import type { OnBoardingCashDrawerResponse, Error, OnBoardingEntityCashDrawers } from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * This endpoint is used to item entry
 * @summary Used for the On Boarding the Cash Drawers.
 */
export const usePostOnboardEntityHook = () => {
  const postOnboardEntity = useCustomInstance<OnBoardingCashDrawerResponse>();

  return (entityType: string, onBoardingEntityCashDrawers: OnBoardingEntityCashDrawers) => {
    return postOnboardEntity({
      url: `/adhoc_balances/${entityType}`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: onBoardingEntityCashDrawers,
    });
  };
};

export type PostOnboardEntityMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof usePostOnboardEntityHook>>
>;
export type PostOnboardEntityMutationBody = OnBoardingEntityCashDrawers;
export type PostOnboardEntityMutationError = ErrorType<void | Error>;

export const usePostOnboardEntity = <
  TError = ErrorType<void | Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof usePostOnboardEntityHook>>,
    TError,
    { entityType: string; data: OnBoardingEntityCashDrawers },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const postOnboardEntity = usePostOnboardEntityHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof usePostOnboardEntityHook>>,
    { entityType: string; data: OnBoardingEntityCashDrawers }
  > = (props) => {
    const { entityType, data } = props || {};

    return postOnboardEntity(entityType, data);
  };

  return useMutation<
    AsyncReturnType<typeof postOnboardEntity>,
    TError,
    { entityType: string; data: OnBoardingEntityCashDrawers },
    TContext
  >(mutationFn, mutationOptions);
};
