/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

PO Transactions

PO Transactions Viewer

Cash Drawer Association API
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { CashDrawerData, CashManagementApiResponse } from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Get list of BP/TP.
 * @summary get Adhoc BalanceHistory
 */
export const useAdhocBalanceHistoryHook = () => {
  const adhocBalanceHistory = useCustomInstance<CashDrawerData[]>();

  return (fadcode: string, entity: string, entityID: string, timestamp: number) => {
    return adhocBalanceHistory({
      url: `/cash_management/adhoc-balance-history/${fadcode}/${entity}/${entityID}/${timestamp}`,
      method: "get",
    });
  };
};

export const getAdhocBalanceHistoryQueryKey = (
  fadcode: string,
  entity: string,
  entityID: string,
  timestamp: number,
) => [`/cash_management/adhoc-balance-history/${fadcode}/${entity}/${entityID}/${timestamp}`];

export type AdhocBalanceHistoryQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useAdhocBalanceHistoryHook>>
>;
export type AdhocBalanceHistoryQueryError = ErrorType<CashManagementApiResponse | void>;

export const useAdhocBalanceHistory = <
  TData = AsyncReturnType<ReturnType<typeof useAdhocBalanceHistoryHook>>,
  TError = ErrorType<CashManagementApiResponse | void>,
>(
  fadcode: string,
  entity: string,
  entityID: string,
  timestamp: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useAdhocBalanceHistoryHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getAdhocBalanceHistoryQueryKey(fadcode, entity, entityID, timestamp);

  const adhocBalanceHistory = useAdhocBalanceHistoryHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useAdhocBalanceHistoryHook>>
  > = () => adhocBalanceHistory(fadcode, entity, entityID, timestamp);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useAdhocBalanceHistoryHook>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(fadcode && entity && entityID && timestamp),
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};
