import { usePostEntityData } from "@bbo/api/generator/endpoints/adhoc-balancing-ap-i";
import { useGetList } from "@bbo/api/generator/endpoints/cash-management-api";
import { usePutTaskManagementTask } from "@bbo/api/generator/endpoints/task-management-api";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { useGlobalState } from "@bbo/lib/state";
import { getUnixTimeStamp } from "@bbo/utils/dateTimeFormatter";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

interface requestData {
  branchId?: string;
  operationType?: string;
  entityID?: string;
}

export const useAdhocBalance = () => {
  const navigate = useNavigate();
  const [adhocData] = useGlobalState("adhocData");
  const [, setIsRecount] = useGlobalState("isRecount");
  const [total] = useGlobalState("totalCash");
  const [rollOver, setRollOver] = useState(false);
  const [adhocbalance, setAdhocBalance] = useGlobalState("adhocbalance");
  const [taskFlow, setTaskFlow] = useState("");
  const [statusRes, setStatusRes] = useState("");
  const [, setIsbalancecheck] = useGlobalState("Isbalancecheck");
  const [cashdetail, setcashdetail] = useGlobalState("cashDetailsFprAdhocBalance");
  const [maxLimitCounter, setMaxLimitCounter] = useGlobalState("maxLimitCounter");
  const [PopupData] = useGlobalState("PopupData");
  const [routeState] = useGlobalState("routeState");
  const [cashBalance] = useGlobalState("cashBalance");
  const selectedTask = useAppSelector((root) => root.selectedTask);
  const { fadCode, username } = useAppSelector((root) => root.auth.authUserData);

  const [initialCall, setInitialCall] = useState(true);
  const [initialCallSecond, setInitialCallSecond] = useState(true);

  const emptyAdhocBalance = {
    entityData: [],
    isSuspendedPouch: false,
    preparedPouches: [],
    suspendedBy: "",
    suspendedPouches: [],
  };
  useEffect(() => {
    if (selectedTask.task === "Cash Balance") {
      setTaskFlow("cash Balance Task");
    } else if (routeState.RouteState === "TradingPeriodCashBalance") {
      setTaskFlow("TP Balance");
    }
    if (routeState.RouteState === "BalancePeriodCashBalance") {
      setTaskFlow("BP Balance");
    } else setTaskFlow("Adhoc Balance");
  }, [routeState.RouteState, selectedTask]);
  const updateRequestParams = useMemo(
    () => ({
      status: "Completed",
      task: selectedTask?.task,
      taskID: selectedTask?.taskID,
      level: selectedTask?.level,
      trigger: selectedTask?.trigger,
      run_date_time: getUnixTimeStamp(new Date()),
      completionDateTime: getUnixTimeStamp(new Date()),
      fadcode: fadCode,
      user: username,
      entityID: selectedTask?.entityID,
      entity: selectedTask?.entity,
      role: selectedTask?.role,
      taskType: selectedTask?.taskType,
      entityName: selectedTask?.entityName,
    }),
    [adhocbalance.entityData, fadCode, selectedTask?.task, selectedTask?.taskID, username],
  );

  const saveVarianceRequestParams = useMemo(
    () => ({
      createdBy: "", // Was coming from here but this is not a valid property: adhocbalance.entityData[0]?.createdBy
      entity: adhocbalance.entityData[0]?.entity,
      entityID: adhocbalance.entityData[0]?.entityID,
      operationType: "cash_balancing",
      entityType: adhocbalance.entityData[0]?.entityType,
      entityName: adhocbalance.entityData[0]?.entityName,
      status: statusRes,
      branchEntityTypeIdTimestamp: adhocbalance.entityData[0]?.branchEntityTypeIDTimestamp,
      fadcode: adhocbalance.entityData[0]?.fadcode,
      flowType: taskFlow,
      flowEntityStatus: "ReCount",
      sequenceID: adhocbalance.entityData[0]?.sequenceID,
      itemDetails:
        routeState.RouteState === "TradingPeriodCashBalance"
          ? {
              lastBalancedDateTime: adhocbalance.entityData[0]?.itemDetails?.lastBalancedDateTime,
              tradingPeriodDateTime: Math.floor(Date.now() / 1000),
              currentVariance: adhocData.variance, // last variance
              balance: adhocData.balance, // current value of ladder
              toDateVariance: adhocData.currentTDV, // currentTDV
              denominationLadder: adhocData.denomination, // denomination ladder value
              balancePeriodDateTime: 0,
            }
          : routeState.RouteState === "BalancePeriodCashBalance"
          ? {
              lastBalancedDateTime: adhocbalance.entityData[0]?.itemDetails?.lastBalancedDateTime,
              balancePeriodDateTime: Math.floor(Date.now() / 1000),
              currentVariance: adhocData.variance, // last variance
              balance: adhocData.balance, // current value of ladder
              toDateVariance: adhocData.currentTDV, // currentTDV
              denominationLadder: adhocData.denomination, // denomination ladder value
              tradingPeriodDateTime: 0,
            }
          : {
              lastBalancedDateTime: adhocbalance.entityData[0]?.itemDetails?.lastBalancedDateTime,
              currentVariance: adhocData.variance, // last variance
              balance: adhocData.balance, // current value of ladder
              toDateVariance: adhocData.currentTDV, // currentTDV
              denominationLadder: adhocData.denomination, // denomination ladder value
              tradingPeriodDateTime: 0,
              balancePeriodDateTime: 0,
            },
      preparedPouches: adhocbalance.preparedPouches,
      suspendedPouches: adhocbalance.suspendedPouches,
      transferActivities: [],
    }),
    [
      adhocData.balance,
      adhocData.currentTDV,
      adhocData.denomination,
      adhocData.variance,
      adhocbalance.entityData,
      adhocbalance.preparedPouches,
      adhocbalance.suspendedPouches,
      cashBalance.cashBalanceDetails,
      routeState.RouteState,
      statusRes,
      taskFlow,
    ],
  );

  const transferListApiRequest = useMemo(
    () => ({
      branchId: 1,
      operationType: "transfer_out",
      entityID: PopupData?.data.entityID,
    }),
    [PopupData?.data.entityID],
  );

  const resetLadderValue = () => {
    setAdhocBalance({ ...emptyAdhocBalance });
    const updateValue = [...cashdetail];
    cashdetail.map((item, index) => {
      updateValue[index].value = "";
      updateValue[index].Quantity = "";
      updateValue[index].alertCss = "normalCss";
      updateValue[index].QalertCss = "normalCss";
      updateValue[index].errorMsg = "";
      updateValue[index].QerrorMsg = "";
      updateValue[index].btnDisable = true;
      return 0;
    });
    setcashdetail(updateValue);
  };
  const handleNext = () => {
    setRollOver(false);
    setIsbalancecheck({ Isbalancecheck: false });
    resetLadderValue();
    if (routeState.RouteState === "TradingPeriodCashBalance") {
      navigate("../tradingPeriod", { replace: true });
    } else if (routeState.RouteState === "BalancePeriodCashBalance") {
      navigate("../balancePeriod", { replace: true });
    } else if (routeState.RouteState.includes("cashBalance")) {
      navigate("../branchOverview", { replace: true });
    } else navigate("../taskManagement", { replace: true });
  };

  const [res, setres] = useState<requestData>();
  const {
    data: CounterList,
    status: CounterListStatus,
    error: CounterListError,
  } = useGetList(res?.branchId, res?.operationType, { entityID: res?.entityID });

  const {
    mutate: updateTaskStatusCall,
    data: updateTaskResponse,
    status: updateTaskStatus,
    error: updateTaskError,
  } = usePutTaskManagementTask();
  const {
    mutate: saveVarianceCall,
    data: saveVarianceResponse,
    status: saveVarianceStatus,
    error: saveVarianceError,
  } = usePostEntityData();

  const TransferListApiCall = useCallback(
    async (res) => {
      setres(res);
      await CounterList;
    },
    [CounterList],
  );
  const updateTaskStatusApi = useCallback(
    async (res) => {
      updateTaskStatusCall({ data: res });
      await updateTaskResponse;
    },
    [updateTaskResponse, updateTaskStatusCall],
  );
  const saveVarianceCallApi = useCallback(
    async (res2) => {
      saveVarianceCall({ entityUid: adhocbalance.entityData[0]?.entityID, data: res2 });
      await updateTaskResponse;
    },
    [adhocbalance.entityData, saveVarianceCall, updateTaskResponse],
  );
  const handleTaskUpdate = useCallback(() => {
    if (initialCallSecond) {
      updateTaskStatusApi(updateRequestParams);
      setInitialCallSecond(false);
    }
  }, [initialCallSecond, updateRequestParams, updateTaskStatusApi]);

  const handleSaveVariance = useCallback(() => {
    if (statusRes !== "") saveVarianceCallApi(saveVarianceRequestParams);
  }, [saveVarianceCallApi, saveVarianceRequestParams, statusRes]);

  const handleTransferOut = useCallback(() => {
    TransferListApiCall(transferListApiRequest);
  }, [TransferListApiCall, transferListApiRequest]);

  useEffect(() => {
    if (adhocData.variance === 0) {
      setStatusRes("Accepted");
    }
    handleSaveVariance();
  }, [adhocData.variance, handleSaveVariance]);

  useLayoutEffect(() => {
    if (saveVarianceStatus === "success") {
      if (
        selectedTask?.task === "Cash Balance" &&
        (adhocData.variance === 0 || statusRes === "Accepted")
      ) {
        handleTaskUpdate();
      } else if (statusRes === "Recount") {
        setIsbalancecheck({ Isbalancecheck: false });
      } else if (statusRes === "Incomplete") {
        setRollOver(false);
      } else if (adhocData.variance === 0) {
        setRollOver(false);
        setIsbalancecheck({ Isbalancecheck: false });
        navigate("../branchOverview", { replace: true });
      } else {
        setRollOver(true);
      }
    }
  }, [
    adhocData.variance,
    handleTaskUpdate,
    navigate,
    saveVarianceStatus,
    selectedTask?.task,
    setIsbalancecheck,
    statusRes,
  ]);
  useLayoutEffect(() => {
    if (CounterListStatus === "success") {
      navigate("../transferOut", {
        replace: true,
        state: { to: "safe_1", from: res?.entityID },
      });
    }
  }, [CounterListStatus, navigate, res?.entityID]);

  return {
    adhocData,
    PopupData,
    maxLimitCounter,
    rollOver,
    total,
    statusRes,
    navigate,
    routeState,
    handleTransferOut,
    handleNext,
    handleSaveVariance,
    setRollOver,
    setIsRecount,
    resetLadderValue,
    setStatusRes,
  };
};
