import { DiscrepencyHistory } from "@bbo/api/generator";
import TableView from "@bbo/components/table";
import { BRANCH_DISCREPENCY_HISTORY_COLUMN } from "@bbo/components/table/tableConstants";
import { Column } from "@bbo/components/table/types";
import { useGlobalState } from "@bbo/lib/state";
import { useCallback, useEffect, useState } from "react";
interface iProps {
  rows: any[];
}

export const History = (props: iProps) => {
  const [, setDetailedViewModel] = useGlobalState("DetailedViewModel");
  const [rows, setRows] = useState<DiscrepencyHistory[]>([]);
  useEffect(() => {
    if (props.rows) {
      const modifiedRow = props?.rows.map((item) => {
        return {
          ...item,
          reasonType: item.reason.title,
        };
      });
      setRows(modifiedRow);
    }
  }, [props.rows]);
  const handleCellClicked = (params: {
    field: string;
    column: Column;
    data: unknown;
    rowIndex: number;
    columnIndex: number;
  }) => {
    setDetailedViewModel({ params: params, showModel: false });
  };
  const handleCellClickedCallback = useCallback(handleCellClicked, []);
  return (
    <>
      <div className="w-full transferOut-height">
        <div className="p-4 w-full h-full relative dynamictable hideDiv">
          <TableView
            columns={BRANCH_DISCREPENCY_HISTORY_COLUMN}
            rows={rows}
            onCellClicked={handleCellClickedCallback}
            isDiscrpencyTable={true}
          />
        </div>
      </div>
    </>
  );
};
