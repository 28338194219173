import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { Divider, Grid } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { LoadMore } from "./MoreTile";
import "./currencyCountScreen.css";

interface ICurrencyData {
  ordercode: string;
  name: string;
  balance?: number;
}

interface GenerateFullCurrenciesProps {
  fullCurr?: ICurrencyData[];
  currentPage?: number;
  fullRangeCount?: number;
  fnOpenDenominationLadder?: (code: string, name: string) => void;
  fullRange?: ICurrencyData[];
  setFullCountValue?: (count: number) => void;
  searchedForCurrency: ICurrencyData | null;
}
const GenerateFullCurrencies = ({
  fullCurr,
  currentPage,
  fullRangeCount,
  fnOpenDenominationLadder,
  fullRange,
  setFullCountValue,
  searchedForCurrency,
}: GenerateFullCurrenciesProps) => {
  const { countedCurrencies } = useAppSelector((root) => root.countedCurrencies);

  return (
    <>
      {fullCurr?.length > 0 &&
        fullCurr?.length < 18 &&
        fullCurr[fullCurr.length - 1]?.ordercode !== fullRange[fullRange.length - 1]?.ordercode && (
          <Grid item md={12} key={`full--${1}`}>
            <Divider className="divider" />
            <span className="count-part">{"Full Currencies"}</span>
          </Grid>
        )}
      {fullCurr?.length > 0 &&
        fullCurr?.length === 18 &&
        fullCurr[0].ordercode === fullRange[0]?.ordercode && (
          <Grid item md={12} key={`full--${1}`}>
            <Divider className="divider" />
            <span className="count-part">{"Full Currencies"}</span>
          </Grid>
        )}
      {fullCurr?.map((item, index) => {
        if (item.name !== "more") {
          return (
            <Grid item md={4} key={`full-${item.ordercode}`} className="count-part-content">
              <button
                className="grid-box"
                onClick={() => {
                  fnOpenDenominationLadder(item.ordercode, item.name);
                }}>
                <span className="count-part-content">{item.name}</span>
              </button>
              {countedCurrencies.includes(item.ordercode) && (
                <div className="tick-container">
                  <CheckCircleIcon className="tick" fontSize="inherit" />
                </div>
              )}
            </Grid>
          );
        }
        if (item.name === "more") {
          return (
            <Grid item md={4} key={`cur-${index}`}>
              <LoadMore count={fullRangeCount} items={fullRange} setCountfn={setFullCountValue} />
            </Grid>
          );
        }
      })}
    </>
  );
};
export default GenerateFullCurrencies;
