import ForexLaddarRow from "./ForexLadderRow";
import Loader from "@bbo/components/Loader";
import { Grid } from "@material-ui/core";
import { ICurrency } from "./Icurrency";
import "./forexladder.css";
import AlertDialog from "@bbo/components/Dialog";
import DenominationLadder from "./DenominationLadder";
import { useForexDenomination } from "./useForexDenomination";

interface IProps {
  code?: string;
  name?: string;
  iSelected?: ICurrency;
}
export interface IDenomination {
  cash?: string;
  cashType?: string;
  currency?: string;
  quantity?: number;
  value?: number;
  changeKey?: string;
  errorMsg?: string;
  alertCss?: string;
  QalertCss?: string;
  QerrorMsg?: string;
}

const ForexDenomination = ({ code, name, iSelected }: IProps) => {
  const {
    open,
    denominationState,
    isConfirmEnabled,
    popupTitle,
    handleClickOpen,
    handleClose,
    handleCurrencyChange,
    totalData,
    data,
    isLoading,
    fnBack,
    handleSubmit,
  } = useForexDenomination(code, iSelected);
  if (isLoading) {
    return <Loader />;
  }

  if (!isLoading && !data) {
    return (
      <p className="typography">
        No denomination exist for the {name} ({code})
      </p>
    );
  }
  return (
    <>
      <div>
        <Grid container>
          <Grid item md={6}>
            <Grid item md={12} className="div-button">
              <i className="fa fa-angle-left fa-lg back-icon" aria-hidden="true"></i>
              <button className="button-count ml-2 text-2xl font-bold" onClick={fnBack}>
                Back
              </button>
            </Grid>
            <div className="centered">
              <p className="typography">
                Enter denomination by quantity, value or total value in the text boxes on the
                right-hand side.
              </p>
            </div>
          </Grid>
          <Grid item md={6} className="right-navigation">
            <div className="right-denomination">
              <div className="right-denominationdiv">
                <h2 className="heading-4 font-bold mt-4 pl-4">{`${name} (${code}) Denomination`}</h2>
                <table className="breakdownTable table-fixed border-collapse">
                  <thead className="adhoc-head-background mb-2">
                    <tr>
                      <th className="heading">
                        <b>Denomination</b>
                      </th>
                      <th></th>
                      <th className="heading">
                        <b>Quantity</b>
                      </th>
                      <th></th>
                      <th className="heading">
                        <b>Value</b>
                      </th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200">
                    {denominationState?.map((row, position) => {
                      return (
                        <DenominationLadder
                          row={row}
                          position={position}
                          onChange={handleCurrencyChange}
                          onBlur={handleClickOpen}
                          code={code}
                          key={`L-${position}`}
                        />
                      );
                    })}
                    {code === "USD" || code === "EUR" ? (
                      <ForexLaddarRow
                        item={totalData}
                        onChange={handleCurrencyChange}
                        code={code}
                        onBlur={handleClickOpen}
                      />
                    ) : (
                      <ForexLaddarRow
                        item={totalData}
                        onChange={handleCurrencyChange}
                        code={code}
                      />
                    )}
                    <tr>
                      <td className="footeralign" colSpan={5}></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="footer1">
                <button
                  className={isConfirmEnabled ? "submit-button-disable" : "submit-button"}
                  disabled={isConfirmEnabled}
                  onClick={handleSubmit}>
                  Confirm
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div>
        <AlertDialog
          isVisible={open}
          handleClose={handleClose}
          modalText={popupTitle}
          buttons={[{ title: "Continue", onPress: handleClose }]}
        />
      </div>
    </>
  );
};
export default ForexDenomination;
