// import { useGlobalState } from "@bbo/lib/state";
import { DiscrepencyHistory } from "@bbo/api/generator";
import TableView from "@bbo/components/table";
import { BRANCH_DISCREPENCY_SUMMARY_SETTLED_COLUMN } from "@bbo/components/table/tableConstants";
import { Column } from "@bbo/components/table/types";
import { useGlobalState } from "@bbo/lib/state";
import { useCallback, useEffect, useState } from "react";

// import Summary from "./Summary"
interface iProps {
  rows: any[];
  hideSettleButton?: boolean;
}

export const Settled = (props: iProps) => {
  const [, setDetailedViewModel] = useGlobalState("DetailedViewModel");

  const [rows, setRows] = useState<DiscrepencyHistory[]>([]);
  useEffect(() => {
    if (props.rows) {
      const modifiedRow = props?.rows.map((item) => {
        return {
          ...item,
          reasonType: item.reason.title,
        };
      });
      setRows(modifiedRow);
    }
  }, [props.rows]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCellClicked = (params: {
    field: string;
    column: Column;
    data: unknown;
    rowIndex: number;
    columnIndex: number;
  }) => {
    setDetailedViewModel({ params: params, showModel: false });
  };

  const handleCellClickedCallback = useCallback(handleCellClicked, []);

  return (
    <div className="w-full transferOut-height">
      <div className="p-4 w-full h-full relative dynamictable hideDiv">
        <TableView
          columns={BRANCH_DISCREPENCY_SUMMARY_SETTLED_COLUMN}
          rows={rows}
          isDiscrpencyTable={true}
          onCellClicked={handleCellClickedCallback}
        />
      </div>
    </div>
  );
};
