/**
 * Alert/Popup view with
 * @param props
 * @returns
 */

export interface iProps {
  title?: string;
  header?: string;
  content?: string;
  labelCancel?: string;
  labelSuccess?: string;
  logoIcon?: string;
  renderlinkLabel?: string;
  onSuccess?: () => void;
  onCancel?: () => void;
  onRenderLink?: () => void;
  tickicon: boolean;
}

const PopupConfirm = ({
  title,
  header,
  content,
  labelCancel,
  labelSuccess,
  logoIcon,
  renderlinkLabel,
  onSuccess,
  onCancel,
  onRenderLink,
  tickicon,
}: iProps) => {
  const arrayHeadertile = [
    "Pouch Barcode Failed to Scan",
    "this pouch is already cancelled",
    "invalid pouch barcode",
  ];
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4	">
            <div className="relative p-4 flex-auto text-center">
              {title && (
                <h2 className="my-4 popup-content-msg font-bold ">
                  {title && <b>{`${title}`}</b>}
                </h2>
              )}
              {tickicon && <i className="fas fa-check text-5xl paidTickColor bg-white"></i>}
              {arrayHeadertile.includes(header) && (
                <i className="fas fa-times paidCrossColor text-5xl bg-white"></i>
              )}
              {logoIcon && <i className="fas fa-times paidCrossColor text-5xl bg-white"></i>}
              <h2 className="my-4 popup-content-msg">{`${
                header || "Pouch Preperation is successfull!"
              }`}</h2>
              {header === "Pouch Barcode Failed to Scan" && <h2>Cancel or Scan Pouch again.</h2>}
              {content && <h3>{content}</h3>}
            </div>

            {/*footer*/}

            <div className="flex items-center justify-center">
              {labelCancel && (
                <button
                  className="popUp-button white-button mt-8 flex-initial mr-4"
                  type="button"
                  value="No"
                  data-cy="popupNo"
                  onClick={onCancel}>
                  {`${labelCancel}`}
                </button>
              )}
              {renderlinkLabel && (
                <button type="button" className="link-button" onClick={onRenderLink}>
                  {`${renderlinkLabel}`}
                </button>
              )}
              <button
                className="popUp-button red-button mt-8 flex-initial ml-4"
                type="button"
                value="Yes"
                data-cy="popupYes"
                onClick={onSuccess}>
                {`${labelSuccess || "Print Receipt"}`}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default PopupConfirm;
