import { ReactNode, useEffect, useState } from "react";

interface iProps {
  id?: string;
  name: string;
  items: any[];
  getvalue: any;
  children?: ReactNode;
  onChange?: (params: any) => any;
}

const MultiDropDown = (props: iProps) => {
  const [selectedValue, setSelectedValue] = useState<any>();
  const [selectedfirstValue, setSelectedfirstValue] = useState<any>();
  const [selectedSecondValue, setSelectedSecondValue] = useState<any>();

  const [tierListSecond, settierListSecond] = useState<any>();
  const [tierListThird, settierListThird] = useState<any>();
  const [tierlist, settierlist] = useState<any>();
  const [, setsavelist] = useState<any>();
  const [Flag, setFlag] = useState<boolean>(false);

  useEffect(() => {
    setsavelist(props?.getvalue);
    settierlist(props.items);
  }, []);

  useEffect(() => {
    if (props?.getvalue?.title && tierlist) {
      const ListSecond = tierlist.filter((value) => {
        if (props?.getvalue?.title === value.title) {
          return value;
        }
      });
      settierListSecond(ListSecond[0].sub_reasons);

      if (props?.getvalue?.sub_reason.title && ListSecond[0].sub_reasons) {
        const name = props?.getvalue?.sub_reason.title;
        const ListThird = ListSecond[0].sub_reasons.filter((value) => {
          if (name === value.title) {
            return value;
          }
        });
        settierListThird(ListThird[0].filter_reasons);
      }
    }
  }, [tierlist]);

  useEffect(() => {
    if (selectedValue || Flag)
      if (props.onChange) {
        props.onChange({
          reason: {
            id: selectedValue?.id || props?.getvalue?.id,
            title: selectedValue?.title || props?.getvalue?.title,
            code: selectedValue?.code || props?.getvalue?.code,
            sub_reason: {
              id: selectedfirstValue?.id || props?.getvalue?.sub_reason.id,
              title: selectedfirstValue?.title || props?.getvalue?.sub_reason.title,
              code: selectedfirstValue?.code || props?.getvalue?.sub_reason.code,
              filter_reason: {
                id: selectedSecondValue?.id || props?.getvalue?.sub_reason.filter_reason.id,
                title:
                  selectedSecondValue?.title || props?.getvalue?.sub_reason.filter_reason.title,
                code: selectedSecondValue?.code || props?.getvalue?.sub_reason.filter_reason.code,
              },
            },
          },
          selectedValue: selectedValue?.title || props?.getvalue?.title,
          selectedfirstValue: selectedfirstValue?.title || props?.getvalue?.sub_reason.title,
          selectedSecondValue:
            selectedSecondValue?.title || props?.getvalue?.sub_reason.filter_reason.title,
          Flag: Flag,
        });
      }
  }, [selectedValue, selectedfirstValue, selectedSecondValue, Flag]);

  const renderItems = (list) => {
    if (list && list.length > 0) {
      return list.map((value, index) => (
        <option className="dropDownCss" key={index} value={value.title} data-cy={value.id}>
          {value.title}
        </option>
      ));
    }
    return null;
  };

  // const renderSelect = (list) => {
  //      return       (<option className="dropDownCss" key={'default-1'} value={"list"}>{list}</option>)}

  const handleChange = (e, listNumber) => {
    setFlag(true);
    const selectValue = e.target.value;
    if (listNumber === "one") {
      settierListSecond(false);
      settierListThird(false);
      setSelectedfirstValue(false);
      setSelectedValue(false);
      setSelectedSecondValue(false);
      const ListSecond = tierlist.filter((value) => {
        if (selectValue === value.title) {
          return value;
        }
      });

      settierListSecond(ListSecond[0].sub_reasons);
      setSelectedValue({
        id: ListSecond[0].ID,
        title: ListSecond[0].title,
        code: ListSecond[0].code,
      });
    }
    if (listNumber === "second") {
      settierListThird(false);
      setSelectedSecondValue(false);
      const ListThird = tierListSecond.filter((value) => {
        if (selectValue === value.title) {
          return value;
        }
      });
      settierListThird(ListThird[0].filter_reasons);
      setSelectedfirstValue({
        id: ListThird[0].id,
        title: ListThird[0].title,
        code: ListThird[0].code,
      });
    }
    if (listNumber === "third") {
      const lastlist = tierListThird.filter((value) => {
        if (selectValue === value.title) {
          return value;
        }
      });
      setSelectedSecondValue({
        id: lastlist[0].id,
        title: lastlist[0].title,
        code: lastlist[0].code,
      });
    }
  };

  const handleClick = () => {
    setFlag(true);
  };
  return (
    <div className=" items-center justify-center py-4 dropDownWidth80 rounded-b">
      {
        <>
          {" "}
          <select
            name={props.name || "dropdown"}
            className="filterDropdown dropDownCss dropDownWidth80"
            value={selectedValue?.title || props?.getvalue?.title}
            onChange={(e) => handleChange(e, "one")}
            onClick={() => handleClick()}
            data-cy="Tier1">
            {/* {renderSelect(savelist)} */}
            {renderItems(tierlist)}
          </select>
          {tierListSecond && (
            <select
              name={props.name || "dropdown"}
              className="filterDropdown dropDownCss mt-4"
              value={selectedfirstValue?.title || props?.getvalue?.sub_reason.title}
              onChange={(e) => handleChange(e, "second")}
              onClick={() => handleClick()}
              data-cy="Tier2">
              {/* {renderSelect()} */}
              {renderItems(tierListSecond)}
            </select>
          )}
          {tierListThird && (
            <select
              name={props.name || "dropdown"}
              className="filterDropdown dropDownCss mt-4"
              value={selectedSecondValue?.title || props?.getvalue?.sub_reason.filter_reason.title}
              onChange={(e) => handleChange(e, "third")}
              onClick={() => handleClick()}
              data-cy="Tier3">
              {/* {renderSelect()} */}
              {renderItems(tierListThird)}
            </select>
          )}
        </>
      }
    </div>
  );
};

export default MultiDropDown;
