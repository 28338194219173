/**
 * Status constants
 * Discrepancy movement status
 * Record a Know variance status
 */
export const DISCREPANCY_STATUS = {
  AWAITING_BRANCH_ACTION: "Awaiting Branch Action",
  AWAITING_CENTRAL_SUPPORT: "Awaiting Central Support",
  HELP_REQUESTED: "Help Requested",
  DISPUTE_RAISED: "Dispute Raised",
  SETTLED: "Settled",
  AWAITING_CODE: "AwaitingBA_1",
  HELP_CODE: "HelpR_1",
  DISPUTE_CODE: "DisputeR_1",
  SETTLED_CODE: "Settled_1",
  TP: "TP",
  BP: "BP",
  TC: "TC",
  CASH_POUCH_DESPATCH: "csc_pd_cc",
  CASH_POUCH_ACCEPTANCE: "csc_pa_cc",
};
