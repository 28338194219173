import { useGlobalState } from "@bbo/lib/state";
import { getHoursOnly, getOnlyDateDiscrepancy, getonlyTime } from "@bbo/utils/dateTimeFormatter";
import { ReactNode, useCallback, useEffect, useState } from "react";
import Loader from "../Loader";
import ColumnHeader from "./columnHeader";
import { dd_mm_yyyy_hh_mm_comparator } from "./comparator";
import FilterNode from "./filterNode";
import LabelUperLeft from "./labelUperLeft";
import Pagination from "./pagination";
import PrintLogs from "./printLogs";
import ResultShown from "./resultShown";
import RowNode from "./rowNode";
import "./style.css";
import { PAGINATION_SIZE } from "./tableConstants";
import {
  Column,
  TableCallbackParams,
  TableCellCallbackParams,
  TableRowCallbackParams,
} from "./types";

interface TableProps<T> {
  paginationSize?: number;
  columns: Column[];
  rows: T[];
  renderAddonBtn?: any;
  children?: ReactNode;
  onRowClicked?: (params: TableRowCallbackParams<T>) => void;
  onCellClicked?: (params: TableCellCallbackParams<T>) => void;
  onRowSelection?: (params: TableRowCallbackParams<T>) => void;
  onSelectAll?: (params: TableCallbackParams<T>) => void;
  onCellChange?: (params: TableCellCallbackParams<T>) => void;
  handleSetPaginationData?: (currentPage, lastPage) => void;
  uperLeftTitle?: string;
  showResetFilter?: boolean;
  checkboxSelection?: boolean;
  isDateRangeSelected?: boolean;
  isDiscrpencyTable?: boolean;
  isStockReview?: boolean;
  min?: string;
  hideSortIcon?: boolean;
  TransactionType?: T[];
  showPrint?: boolean;
  onPrintClick?: (params) => void;
  invokingPage?: string;
  isLoading?: boolean;
  handleTotal?: (totQuantity: number, totValue: number) => void;
  smartSearch?: boolean;
}

const TableView = <T,>(props: TableProps<T>) => {
  const [rows, setRows] = useState(props.rows);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedRows, setCheckedRows] = useState([false]);
  const [currentTableData, setCurrentTableRows] = useState<T[]>([]);
  const [globalActiveFilters, setGlobalActiveFilters] = useGlobalState("activeFilters");
  const [, setGlobalCurrentPage] = useGlobalState("curentPage");
  const [, setGlobalCurrentRows] = useGlobalState("currentRows");

  const [inputTransaction, setInputTransaction] = useState<boolean>(true);
  const [inputSearch, setInputSearch] = useState<boolean>(true);

  const paginationSize = props.paginationSize ? props.paginationSize : PAGINATION_SIZE;
  useEffect(() => {
    setRows(props.rows);
    if (props.checkboxSelection || props.isDiscrpencyTable) {
      setCheckedRows(props.rows?.map(() => false));
    }
  }, [props.checkboxSelection, props.isDiscrpencyTable, props.rows]);

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * paginationSize;
    const lastPageIndex = firstPageIndex + paginationSize;
    setCurrentTableRows(rows?.slice(firstPageIndex, lastPageIndex));
    setGlobalCurrentRows(rows);
  }, [currentPage, paginationSize, rows, setGlobalCurrentRows]);

  const handleHeaderSelection = (params) => {
    setCheckedRows((list) => list?.map(() => params.checked));
    if (props.onSelectAll) {
      props.onSelectAll(params);
    }
  };

  const handleRowSelection = (params) => {
    const firstPageIndex = (currentPage - 1) * paginationSize;
    const position = firstPageIndex + params.rowIndex;
    setCheckedRows((list) =>
      list.map((value, index) => {
        return position === index ? params.checked : value;
      }),
    );

    if (props.onRowSelection) {
      props.onRowSelection(params);
    }
  };

  const handleSortChanged = (params) => {
    const firstPageIndex = (currentPage - 1) * paginationSize;
    const lastPageIndex = firstPageIndex + paginationSize;
    setCurrentTableRows(params.rows.slice(firstPageIndex, lastPageIndex));
  };

  const headerSelectionCallback = useCallback(handleHeaderSelection, []);
  const rowSelectionCallback = useCallback(handleRowSelection, []);
  const sortChangedCallback = useCallback(handleSortChanged, []);

  const renderColumn = (cols: Column[]) => {
    if (cols && cols.length > 0) {
      return cols.map((col, index) => (
        <ColumnHeader
          key={index}
          column={col}
          class={col.headingClass}
          rows={rows}
          columnIndex={index}
          checkedList={checkedRows}
          onHeaderSelection={headerSelectionCallback}
          onSortChanged={sortChangedCallback}
          hideSortIcon={props.hideSortIcon}
        />
      ));
    }
    return null;
  };

  const renderRows = (cols: Column[] = [], dataRows: T[] = []) => {
    if (dataRows && dataRows.length > 0) {
      const pageIndex = (currentPage - 1) * paginationSize;
      return dataRows.map((row, index) => {
        const rowIndex = pageIndex + index;
        return (
          <RowNode
            key={rowIndex}
            columns={cols}
            data={row}
            rowIndex={rowIndex}
            isSelected={checkedRows[rowIndex]}
            onRowClicked={props.onRowClicked}
            onCellClicked={props.onCellClicked}
            onRowSelection={rowSelectionCallback}
            onCellChange={props.onCellChange}
            isStockReview={props.isStockReview}
            min={props.min}
          />
        );
      });
    }
    if (props?.isLoading) {
      return (
        <tr className="noRecords">
          <td colSpan={cols.length}>
            <Loader />
          </td>
        </tr>
      );
    }
    return props.isDateRangeSelected ? (
      <tr className="noRecords">
        <td colSpan={6}>No matching records found.</td>
      </tr>
    ) : (
      <tr className="noRecordFound">
        <td colSpan={cols.length}>No Results Found</td>
      </tr>
    );
  };

  const onFilterChanged = (params: any) => {
    let mFilter = null;
    if (params.filter === "datetime") {
      mFilter = params.to !== "" ? [params] : [];
    } else if (params.filter === "searchFilter") {
      mFilter = params.items && params.items.length > 0 ? [params] : [];
    } else if (params.filter === "InputSearch") {
      mFilter = params.items && params.items.length > 0 ? [params] : [];

      if (!params.items.length) {
        setInputTransaction(false);
        setRows([]);
      } else {
        setInputTransaction(true);
      }
    } else {
      mFilter = params.value !== " " ? [params] : [];
    }
    const index = globalActiveFilters.findIndex((item) => item.field === params.field);
    if (index >= 0) {
      setGlobalActiveFilters([
        ...globalActiveFilters.slice(0, index),
        ...mFilter,
        ...globalActiveFilters.slice(index + 1),
      ]);
    } else if (mFilter.length > 0) {
      setGlobalActiveFilters((items) => [...items, ...mFilter]);
    }
  };
  const setFilters = useCallback(() => {
    let list = [];
    if (globalActiveFilters && globalActiveFilters.length > 0 && props.rows) {
      list = props?.rows?.filter((item) => {
        return globalActiveFilters.every((data) => {
          if (data.filter === "dropdown") {
            return data.value === item[data.field];
          } else if (data.filter === "searchFilter") {
            const mValue = item[data.field];
            return data.items.indexOf(mValue) !== -1;
          } else if (data.filter === "InputSearch") {
            const mValue = item[data.field];
            return data.items.indexOf(mValue) !== -1;
          } else if (data.filter === "datetime") {
            const from = dd_mm_yyyy_hh_mm_comparator(item[data.field], data.from);
            const to = dd_mm_yyyy_hh_mm_comparator(data.to, item[data.field]);
            return from && to;
          } else if (data.filter === "amountRange" && (data.from === "" || data.to === "")) {
            return props.rows;
          } else if (data.filter === "amountRange" && data.from !== "" && data.to !== "") {
            const amount = item[data.field];
            return data.from <= amount && amount <= data.to;
          } else if (data.filter === "DateCalender") {
            return data.date === getOnlyDateDiscrepancy(item[data.field]);
          } else if (data.filter === "timerange") {
            return (
              getHoursOnly(item[data.field]) >= new Date(data.from).getHours() &&
              getHoursOnly(item[data.field]) <= new Date(data.to).getHours()
            );
          } else if (data.filter === "setValueTime") {
            const newdate = getonlyTime(item[data.field]);
            return newdate >= data.from && newdate <= data.to;
          }
        });
      });
    } else {
      list = props.rows;
    }
    if (props?.handleTotal) {
      const totalQuantity = list.reduce((n, { quantity }) => n + quantity, 0);
      const totalValue = list.reduce((n, { value }) => n + value, 0);
      props.handleTotal(totalQuantity, totalValue);
    }
    if (inputTransaction) setRows(list);
    setGlobalCurrentRows(list);
    //Reset pagination on filter change
    setGlobalCurrentPage(1);
    setCurrentPage(1);
  }, [
    globalActiveFilters,
    inputSearch,
    inputTransaction,
    props.rows,
    setGlobalCurrentPage,
    setGlobalCurrentRows,
  ]);
  useEffect(() => {
    setGlobalActiveFilters([]);
  }, []);

  useEffect(() => {
    setFilters();
  }, [globalActiveFilters, setFilters]);

  const resetFilter = () => {
    setGlobalActiveFilters([]);
    setRows(props.rows);
  };
  const PrintReceipt = () => {
    if (props.onPrintClick) {
      props.onPrintClick(currentTableData);
    }
  };
  return (
    <div className="ui-table-view">
      <LabelUperLeft
        title={props.uperLeftTitle}
        showResetFilter={props.showResetFilter}
        onResetAllFilters={resetFilter}
      />
      {!props.isDiscrpencyTable && !props.paginationSize && props.paginationSize !== 0 && (
        <ResultShown
          currentPage={currentPage}
          totalCount={rows?.length}
          pageSize={PAGINATION_SIZE}
          currentPageSize={currentTableData?.length}
        />
      )}
      {props.showPrint && (
        <PrintLogs
          onSuccess={PrintReceipt}
          invokingPage={props?.invokingPage}
          noRecords={!(rows.length > 0)}
        />
      )}
      <div
        className={`${
          props.isStockReview
            ? "w-full stockDynamictableHeight overflow-y-auto"
            : props?.invokingPage === "Sales"
            ? "w-full overflow-y-auto"
            : "w-full dynamictableHeight overflow-y-auto"
        }`}>
        <table className="table-fixed dynamictable newTheme w-full border-collapse">
          <thead className={`${props.isStockReview ? "bottom-border" : ""}`}>
            <tr>{renderColumn(props.columns)}</tr>
          </thead>
          <tbody>
            {!props.isStockReview && (
              <FilterNode
                columns={props.columns}
                rows={props.rows}
                secondSearch={rows}
                TransactionType={props.TransactionType}
                filters={globalActiveFilters}
                checkedList={checkedRows}
                onHeaderSelection={headerSelectionCallback}
                onFilterChanged={onFilterChanged}
                isDateRangeSelected={props.isDateRangeSelected}
                smartSearch={props?.smartSearch || false}
              />
            )}

            {renderRows(props.columns, currentTableData)}
          </tbody>
        </table>
      </div>
      <div className="font-24 flex font-bold leading-7 fixed bottom-2 items-center full-width">
        <div className="w-full float-right">
          <Pagination
            className="pagination-bar flex justify-center"
            currentPage={currentPage}
            totalCount={rows?.length}
            pageSize={paginationSize}
            onPageChange={(page) => {
              setCurrentPage(page);
              setGlobalCurrentPage(page);
            }}
            handleSetPaginationData={props?.handleSetPaginationData}
          />
        </div>
        {props.renderAddonBtn}
      </div>
    </div>
  );
};

export default TableView;
