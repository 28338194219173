import { memo, useEffect, useRef, useState } from "react";
import "./index.css";
import { Column } from "./types";

/**
 *Result page row count shown of the table component
 * @param props
 * @returns
 */
interface iProps {
  id?: string;
  name: string;
  column: Column;
  columnIndex: number;
  rows: unknown[];
  activeFilters?: any[];
  onChange?: (params: any) => any;
  TransactionType?: any[];
  secondSearch: unknown[];
  smartSearch?: boolean;
}

const InputSearch = (props: iProps) => {
  const [show, setShow] = useState(false);
  const [items, setItems] = useState([]);
  const [inputError, setInputError] = useState<string>("");
  const [checkedValues, setCheckedValues] = useState({});
  const [inputValue, setInputValue] = useState<string>("");
  const refDrawer = useRef(null);
  const transactionIdMaxLength = 14;

  useEffect(() => {
    if (props.activeFilters.length === 0) {
      setCheckedValues({});
    }
  }, [props.activeFilters]);

  useEffect(() => {
    if (props.rows) {
      const checks = {};
      let mList = [];
      props.rows.map((row) => {
        const searchInput = row[props.column.field] ? row[props.column.field] : "";
        if (mList.indexOf(searchInput) === -1) {
          mList = [...mList, searchInput];
          checks[searchInput] = false;
        }
      });

      setCheckedValues(checks);
      setItems(mList);
    }
  }, [props.column.field, props.rows, props.secondSearch]);

  useEffect(() => {
    let selection = [];
    for (const key in checkedValues) {
      if (checkedValues[key]) {
        selection = [...selection, key];
      }
    }
  }, [checkedValues]);

  /**
   * Checkbox event listener
   * @param e EventListener on change input
   */

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let searchInput;
    if (!props?.smartSearch) {
      searchInput = e.target.value ? e.target.value.trim() : "";
    } else {
      searchInput = e.target.value ? e.target.value : "";
    }
    let mList = [];
    if (props.name === "basketID") {
      searchInput.replace(/[^\d-]/g, "");
    }
    if (!props?.smartSearch) {
      searchInput.length > transactionIdMaxLength
        ? setInputError("Incorrect format please try again")
        : setInputError("");
    } else {
      setInputError("");
    }
    if (props?.smartSearch) {
      mList = items.filter((item) => {
        let compareValue = item || "";
        const searchData = searchInput.toLowerCase();
        compareValue = compareValue.toString().toLowerCase();
        return compareValue.startsWith(searchData);
      });
    } else {
      mList = items.filter((item) => {
        let compareValue = item || "";
        compareValue = compareValue.toString();
        return compareValue.indexOf(searchInput) !== -1;
      });
    }
    props.onChange({
      field: props.name,
      items: mList,
      filter: "InputSearch",
      inputValue: searchInput,
    });
    setInputValue(searchInput);
  };

  useEffect(() => {
    const handleClick = (e) => {
      if (show) {
        if (!refDrawer || (refDrawer && refDrawer.current.contains(e.target))) return;
        setShow((v) => !v);
      }
    };

    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  return (
    <div>
      <span className={`${inputError !== "" ? "incorrectFormat" : ""}`}>{inputError}</span>
      <input
        id="input-field"
        className={`${
          inputError !== ""
            ? "incorrectFormat incorrectFormatBorder filterInputSearchBox"
            : "filterInputSearchBox"
        }`}
        placeholder="Search"
        type="text"
        value={inputValue || ""}
        onChange={handleSearchChange}
      />
    </div>
  );
};
export default memo(InputSearch);
