/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

PO Transactions

PO Transactions Viewer

Cash Drawer Association API
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  VarianceReport,
  Error,
  BusinessLogicDetails,
  GetBusinessRulesByBranchIdParams,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Update Business Rule
 */
export const usePutBranchmanagementBusinessrulesFadcodeHook = () => {
  const putBranchmanagementBusinessrulesFadcode = useCustomInstance<VarianceReport[]>();

  return (fadcode: string, businessLogicDetails: BusinessLogicDetails) => {
    return putBranchmanagementBusinessrulesFadcode({
      url: `/branchmanagement/businessrules/${fadcode}`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: businessLogicDetails,
    });
  };
};

export type PutBranchmanagementBusinessrulesFadcodeMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof usePutBranchmanagementBusinessrulesFadcodeHook>>
>;
export type PutBranchmanagementBusinessrulesFadcodeMutationBody = BusinessLogicDetails;
export type PutBranchmanagementBusinessrulesFadcodeMutationError = ErrorType<Error | void>;

export const usePutBranchmanagementBusinessrulesFadcode = <
  TError = ErrorType<Error | void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof usePutBranchmanagementBusinessrulesFadcodeHook>>,
    TError,
    { fadcode: string; data: BusinessLogicDetails },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const putBranchmanagementBusinessrulesFadcode = usePutBranchmanagementBusinessrulesFadcodeHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof usePutBranchmanagementBusinessrulesFadcodeHook>>,
    { fadcode: string; data: BusinessLogicDetails }
  > = (props) => {
    const { fadcode, data } = props || {};

    return putBranchmanagementBusinessrulesFadcode(fadcode, data);
  };

  return useMutation<
    AsyncReturnType<typeof putBranchmanagementBusinessrulesFadcode>,
    TError,
    { fadcode: string; data: BusinessLogicDetails },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Get entity_data.
 * @summary This endpoint is used to create and item post balancing updates
 */
export const useGetBusinessRulesByBranchIdHook = () => {
  const getBusinessRulesByBranchId = useCustomInstance<BusinessLogicDetails[]>();

  return (branchId: string, params?: GetBusinessRulesByBranchIdParams) => {
    return getBusinessRulesByBranchId({
      url: `/branchmanagement/businessrules/getrules/${branchId}`,
      method: "get",
      params,
    });
  };
};

export const getGetBusinessRulesByBranchIdQueryKey = (
  branchId: string,
  params?: GetBusinessRulesByBranchIdParams,
) => [`/branchmanagement/businessrules/getrules/${branchId}`, ...(params ? [params] : [])];

export type GetBusinessRulesByBranchIdQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetBusinessRulesByBranchIdHook>>
>;
export type GetBusinessRulesByBranchIdQueryError = ErrorType<void>;

export const useGetBusinessRulesByBranchId = <
  TData = AsyncReturnType<ReturnType<typeof useGetBusinessRulesByBranchIdHook>>,
  TError = ErrorType<void>,
>(
  branchId: string,
  params?: GetBusinessRulesByBranchIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetBusinessRulesByBranchIdHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetBusinessRulesByBranchIdQueryKey(branchId, params);

  const getBusinessRulesByBranchId = useGetBusinessRulesByBranchIdHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetBusinessRulesByBranchIdHook>>
  > = () => getBusinessRulesByBranchId(branchId, params);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetBusinessRulesByBranchIdHook>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!branchId, ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};
