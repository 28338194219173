import { currentDateDDMMYYYY, currentTime } from "../../utils/dateTimeFormatter";
import { useCallback, useEffect } from "react";
import "./SalesSummary.css";
import { useSalesSummary } from "./useSalesSummary";
import { SalesSummaryPrintReceiptTemplate } from "@bbo/utils/ReceiptPrinterTemplates/SalesSummary";
import TableView from "@bbo/components/table";
import { cols } from "./HeaderConstants";

export const SalesSummary = () => {
  const {
    paginationData,
    setPaginationData,
    isDateRangeSelected,
    formattedData,
    userName,
    param,
    convertUnixTimetoDate,
    receiptPrinterService,
    fadCode,
    data: branchData,
    isLoading,
    totalQuantity,
    setTotals,
    totalValue,
  } = useSalesSummary();
  const handleSetPaginationData = useCallback((currentPage, lastPage) => {
    setPaginationData({ currentPage, lastPage });
  }, []);
  const daterange = convertUnixTimetoDate(param.from) + " - " + convertUnixTimetoDate(param.to);
  const str = "Whitlow Lane,Moulton,Northwich,Cheshire";
  const addres = str.split(",").join(" ");
  const onPrintClick = (params) => {
    receiptPrinterService.printReceipt({
      template: SalesSummaryPrintReceiptTemplate,
      context: {
        title: "SALES SUMMARY",
        fadCode: branchData?.fadcode || fadCode,
        branch_name: branchData?.branch_name,
        branch_address: branchData?.branch_address.split(",").join(" ") || addres,
        currentDate: currentDateDDMMYYYY(),
        currentTime: currentTime(),
        products: formattedData.ItemCounts,
        userName: userName.userName,
        smartID: userName.smartID,
        dateRange: daterange,
        totalQuantity: formattedData.totalQuantity,
        totalValue: formattedData.totalValue,
      },
    });
  };
  return (
    <div className="container1">
      <TableView
        columns={cols}
        rows={formattedData?.ItemCounts || []}
        hideSortIcon={true}
        isDiscrpencyTable={true}
        showPrint={true}
        handleSetPaginationData={handleSetPaginationData}
        isDateRangeSelected={isDateRangeSelected}
        onPrintClick={onPrintClick}
        invokingPage={"Sales"}
        isLoading={isLoading}
        handleTotal={setTotals}
        smartSearch={true}
      />
      {paginationData?.currentPage === paginationData?.lastPage && (
        <div className="total-container">
          <span className="total-children total">Total:</span>
          <span className="total-children empty"></span>
          <span className="total-children quantity">{totalQuantity}</span>
          <span className="total-children value">{totalValue}</span>
        </div>
      )}
    </div>
  );
};
