/**
 * Ladder row component
 */

import { useEffect, useState } from "react";
import { handleCashNote, updateFlags } from "../PouchLadder/utility";
// import { useGlobalState } from "@bbo/lib/state";

/**
 * Pouch Preparation screen functional component
 * @returns
 */
interface iProps {
  item?: {
    Cash: any;
    Quantity: any;
    value: any;
    cashType: string;
    currency: string;
    btnDisable: boolean;
    alertCss: string;
    QalertCss: string;
    errorMsg: string;
    QerrorMsg: string;
  };
  index?: number;
  looseBag?: boolean;
  looseCoin?: boolean;
  diabledFlags?: any;
  onChange?: (params, index, flags) => void;
}

const LaddarRow = (props: iProps) => {
  const [data, setData] = useState(props.item);
  const [focus, setfocus] = useState<number>(1);
  const [inputFlag, setInputFlag] = useState<string>("");
  const unUseNote = "Unusable Notes";
  const unUseCoin = "Unusable Coins";

  const { index, diabledFlags } = props;
  useEffect(() => {
    setData(props.item);
  }, [props.item]);

  const CashText = (cash, type) => {
    if (type === "£" && cash !== unUseNote && cash !== unUseCoin) {
      return <span className="textCash text-lg font-normal textCashalign">£{cash.toFixed(2)}</span>;
    } else if (type === "p" && cash !== unUseNote && cash !== unUseCoin) {
      return <span className="textCash text-lg font-normal textCashalign">{cash}p</span>;
    } else if (cash === unUseNote || cash === unUseCoin) {
      return <span className="textCash text-lg font-normal textCashalign">{cash}</span>;
    }
  };
  const handleValue = (event) => {
    if (
      event.which !== 9 &&
      event.which !== 8 &&
      event.which !== 0 &&
      (event.which < 48 || event.which > 57) &&
      event.which !== 190
    ) {
      event.preventDefault();
    }
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 2].focus();
      event.preventDefault();
    }
    setInputFlag("inputV");
  };
  const handleEnter = (event) => {
    if (
      event.which !== 9 &&
      event.which !== 8 &&
      event.which !== 0 &&
      event.which !== 38 &&
      event.which !== 40 &&
      (event.which < 48 || event.which > 57)
    ) {
      event.preventDefault();
    }
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      if (index === 22) {
        form.elements[index + 3].focus();
      } else {
        form.elements[index + 2].focus();
      }
      event.preventDefault();
    }
    setInputFlag("inputQ");
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    const mValue = value.replace(/[^0-9]/g, "");

    setData((params) => {
      const flags = updateFlags({ ...params, [name]: mValue }, diabledFlags);
      let data = null;
      const inputField = name !== "value" ? "Q" : "V";

      data = handleCashNote(params, name, value, inputField);
      if (data.value && inputField === "Q") {
        data.value = `£${data.value.replace("£", "")}`;
      } else {
        data.value = `£${(Number(data.value.replace("£", "")) / 100).toFixed(2)}`;
      }
      props.onChange({ ...data }, index, inputFlag);

      return data;
    });
  };

  return (
    <tr>
      <td className="text-left w-1/5">
        <label className="block text-sm font-medium text-gray-700">
          {CashText(data.Cash, data.currency)}
          {data.Cash !== unUseNote && data.Cash !== unUseCoin && (
            <span className=" textCashType text-lg font-normal"> {data.cashType}</span>
          )}
        </label>
      </td>
      <td className="width-10 text-left">
        <label className="block text-lg font-medium text-gray-700 text-center	">X</label>
      </td>
      <td className="text-left w-1/5">
        <input
          key={index}
          data-cy={data.Cash + "Q"}
          min="0"
          pattern="[0-9]"
          disabled={data.Cash === unUseNote || data.Cash === unUseCoin ? true : diabledFlags.inputQ}
          type="text"
          id="Quantity"
          placeholder="Quantity"
          name="Quantity"
          value={data.Quantity || ""}
          autoComplete="off"
          className={"px-3 py-3 w-full paidTextArea text-lg inputCash " + data.QalertCss}
          onKeyDown={handleEnter}
          onChange={handleOnChange}
        />
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {data.QerrorMsg}
        </span>
      </td>
      <td className="text-center">
        <label className="block text-lg font-medium text-gray-700">=</label>
      </td>
      <td className="text-left w-1/5">
        {" "}
        <input
          key={index}
          data-cy={data.Cash + "V"}
          min="0"
          name="value"
          id="value"
          placeholder=""
          value={data.value || "£0.00"}
          disabled={
            diabledFlags.inputV
              ? data.Cash === unUseNote || data.Cash === unUseCoin
                ? false
                : diabledFlags.inputV
              : false
          }
          autoComplete="off"
          className={"px-3 py-3 w-full paidTextArea text-lg inputCash " + data.alertCss}
          onKeyDown={handleValue}
          onChange={handleOnChange}
        />
        {data.errorMsg && (
          <>
            <div className="col-1 grid mt-2">
              <span className="font-medium tracking-wide text-red-500 text-lg font-bold">
                {data.errorMsg} <i className="fas fa-exclamation-circle text-red-500"></i>
              </span>
            </div>
          </>
        )}
      </td>
    </tr>
  );
};

export default LaddarRow;
