//ADD,REMOVE,REPRINT, and all other flows will be handled here..

/**
 * Alert/Popup view with
 * @param props
 * @returns
 */

interface iProps {
  label?: string;
  balancePopUP?: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
}

const Reprint = (props: iProps) => {
  const onSuccess = () => {
    if (props.onSuccess) {
      props.onSuccess();
    }
  };

  const onCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-xl popupmodel">
          <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4	">
            <div className="flex p-7  items-center justify-start">
              <h2 className="text-blueGray-700 text-lg leading-relaxed heading-5 font-bold text-color">
                {props?.label || "Are you sure you want to re-print the label?"}
              </h2>
            </div>

            {/*footer*/}
            <div className="justify-start p-7 rounded-b">
              {!props.balancePopUP && (
                <>
                  <button
                    className="popUp-button white-button mt-8 flex-initial mr-4"
                    type="button"
                    value="No"
                    onClick={onCancel}
                    data-cy="popupNo">
                    No
                  </button>
                  <button
                    className="popUp-button red-button mt-8 flex-initial ml-4"
                    type="button"
                    value="Yes"
                    onClick={onSuccess}
                    data-cy="popupYes">
                    Yes
                  </button>
                </>
              )}
              {props.balancePopUP && (
                <button
                  className="brandRedBtnMcd text-white active:brandRedBtnMcd font-bold text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                  type="button"
                  value="No"
                  onClick={onCancel}
                  data-cy="popupYes">
                  Close
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default Reprint;
