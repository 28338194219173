/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

PO Transactions

PO Transactions Viewer

Cash Drawer Association API
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { ReasonCode } from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Returns all reason code list for Paid In, Paid Out, and Discrepancy.
 * @summary Reason code list
 */
export const useGetReasonCodeListHook = () => {
  const getReasonCodeList = useCustomInstance<ReasonCode[]>();

  return (operationType: string, branchType?: string) => {
    return getReasonCodeList({
      url: `/menu-management/reason-code/${operationType}/${branchType}`,
      method: "get",
    });
  };
};

export const getGetReasonCodeListQueryKey = (operationType: string, branchType?: string) => [
  `/menu-management/reason-code/${operationType}/${branchType}`,
];

export type GetReasonCodeListQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetReasonCodeListHook>>
>;
export type GetReasonCodeListQueryError = ErrorType<void>;

export const useGetReasonCodeList = <
  TData = AsyncReturnType<ReturnType<typeof useGetReasonCodeListHook>>,
  TError = ErrorType<void>,
>(
  operationType: string,
  branchType?: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetReasonCodeListHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetReasonCodeListQueryKey(operationType, branchType);

  const getReasonCodeList = useGetReasonCodeListHook();

  const queryFn: QueryFunction<AsyncReturnType<ReturnType<typeof useGetReasonCodeListHook>>> = () =>
    getReasonCodeList(operationType, branchType);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetReasonCodeListHook>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(operationType && branchType), ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};
