import useVarianceApi from "@bbo/lib/hooks/useVarianceApi";
import { useGlobalState } from "@bbo/lib/state";
import { amountPound } from "@bbo/utils/currencyFormatter";
import { formatDateYearMonthDay } from "@bbo/utils/dateTimeFormatter";
import React, { useEffect, useState } from "react";
import MultiDropDown from "../../dropdown/MultiDropDown";
import "../index.css";

export default function BranchVarianceDetailPopup(props) {
  const [BranchVariancePopup, setBranchVariancePopup] = useGlobalState("BranchVariancePopup");
  const [reasonCode] = useGlobalState("reasonCodeList");
  const [, setFlag] = useState(false);
  const [disConfimBtn, setdisConfimBtn] = useState(true);
  const [comment, setComment] = React.useState<string>("");
  const [changeValueReason, setchangeValueReason] = useState<any>();
  const [apiChange, setapiChange] = useGlobalState("apiChange");

  useEffect(() => {
    setFlag(props?.data.MoveDiscrepancy);
    setBranchVariancePopup({ BranchVariancePopup: props?.data.showModal });
    if (props.data.data.comment) {
      setComment(props.data.data.comment);
    }
  }, [props.data]);

  const handleComment = (e) => {
    const value = e.target.value;
    setComment(value);
    setdisConfimBtn(false);
  };
  const saveDropDownValue = (params: any) => {
    setchangeValueReason(params.reason);
    if (params.selectedValue || params.Flag) setdisConfimBtn(false);
    else setdisConfimBtn(true);
  };
  const saveVariancesRequestParma = {
    entity_name: props.data.data.entity_name,
    to_date_variance: props.data.data.to_date_variance,
    last_balance_date_time: props.data.data.last_balanced_date_time,
    entityID: props.data.data.entityID,
    entity: props.data.data.entity,
    comment: comment || props.data.data.comment,
    reason: changeValueReason || props.data.data.reason,
  };

  const [{ data: addPouch1Response }, saveVariance] = useVarianceApi(
    "",
    saveVariancesRequestParma,
    "",
    "PUT",
    "",
  );

  const confimBtn = async () => {
    saveVariance("cashManagementVarianceSummaryPut");
    await addPouch1Response;
    setdisConfimBtn(true);
    // setMoveVariancePopup({ MoveVariancePopup: false });
    setdisConfimBtn(true);
    setBranchVariancePopup({ BranchVariancePopup: false });
    setapiChange({ apiChange: !apiChange });
  };

  return (
    <>
      {BranchVariancePopup && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-2 mx-auto max-w-xl">
              <div className="border-0  shadow-lg relative height-25 width-35 overflow-y-auto flex flex-col w-full bg-white outline-none focus:outline-none p-8 popupContainer	">
                <div className="flex items-center justify-between">
                  <h2 className="testSizePopup">Reason for Variance</h2>
                </div>
                {props?.data.showModal && (
                  <div className="popupHead my-4">
                    <div className="flex items-center justify-between   popupTextSize">
                      <div>Variance to Move</div>
                      <div
                        className={
                          parseInt(props?.data.data?.to_date_variance) === 0
                            ? ""
                            : parseInt(props?.data.data?.to_date_variance) < 0
                            ? "redLabel"
                            : "greenLabel"
                        }>
                        <b>{amountPound(props?.data.data?.to_date_variance)}</b>
                      </div>
                    </div>
                    <div className="flex items-center justify-between popupTextSize">
                      <div>Variance Date</div>
                      <div>
                        <b>{formatDateYearMonthDay(props?.data.data?.last_balanced_date_time)}</b>
                      </div>
                    </div>
                  </div>
                )}

                <>
                  <h3 className="testSizeSubHead">Select reasons for the discrepancy</h3>
                  <MultiDropDown
                    name={"Reason code"}
                    items={reasonCode}
                    getvalue={props.data.data?.reason}
                    onChange={saveDropDownValue}
                  />

                  <div className="dropDownWidth80">
                    <textarea
                      className="paidTextArea w-full textArea "
                      rows={2}
                      value={comment}
                      onChange={handleComment}
                      placeholder="Please provide comments for your records."></textarea>
                  </div>
                  <div className="flex justify-left pt-2">
                    <button
                      className="inline-flex hover justify-center text-black font-bold p-2 rounded resetBtn mr-2 completeBtnPopup"
                      onClick={() => setBranchVariancePopup({ BranchVariancePopup: false })}>
                      Cancel
                    </button>
                    <button
                      className="accounting-button ml-1 completeBtnPopup"
                      onClick={confimBtn}
                      disabled={!!disConfimBtn}>
                      Confirm
                    </button>
                  </div>
                </>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
}
