import {
  useGetLogsDetails,
  useGetBusinessRulesByBranchId,
  useAdhocBalanceHistory,
  useCashManagementgetEntityDataByBranchId,
  CashTransferHistoryData,
  useGetCashInformation,
} from "@bbo/api/generator";
import Loader from "@bbo/components/Loader";
import { LOGDETAILS } from "@bbo/constants";
import AdhocBalance from "@bbo/features/AdhocBalance/AdhocBalance";
import { useAppSelector } from "@bbo/lib";
import { useGlobalState } from "@bbo/lib/state";
import { amountInPound, amountUnSignedPound } from "@bbo/utils/currencyFormatter";
import { getbalanceDate } from "@bbo/utils/dateTimeFormatter";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Index.css";

const AdhocCashBalancing = () => {
  const navigate = useNavigate();
  const { fadCode } = useAppSelector((root) => root.auth.authUserData);
  const [TPRecount, setTPRecount] = useGlobalState("TPRecount");
  const [isReadonlyMode, setIsReadonlyMode] = useGlobalState("isReadonlyMode");
  const [adhocbalance, setAdhocBalance] = useGlobalState("adhocbalance");
  const [, setcashBalance] = useGlobalState("cashBalance");
  const [, setMaxLimitCounter] = useGlobalState("maxLimitCounter");
  const [cashdetail, setcashdetail] = useGlobalState("cashDetailsFprAdhocBalance");
  const [routeState] = useGlobalState("routeState");
  const selectedTask = useAppSelector((root) => root.selectedTask);
  const [cashMovementStatus, setCashMovementStatus] = useState(true);
  const [dateParam, setDateparam] = useState({
    fromDate: null,
    toDate: null,
  });
  interface IRequestParamTpBP {
    fadcode: string;
    entity: string;
    entityID: string;
    timestamp: number;
  }
  interface IParamsSet {
    branchId: string;
    from_date: string;
    to_date: string;
  }
  interface IUserActivitiesParams {
    fadcode: string;
    entity: string;
    entityID: string;
    status: string;
  }
  useEffect(() => {
    if (routeState.RouteState !== "TpBpHistory") {
      setIsReadonlyMode({ isReadonlyMode: false });
    }
  }, [routeState.RouteState]);

  useEffect(() => {
    if (routeState.RouteState === null) {
      navigate("/branchOverview");
      setIsReadonlyMode({ isReadonlyMode: false });
    }
  }, []);
  const entity = selectedTask.entity ? selectedTask.entity : selectedTask.associatedEntityType;
  const entityID = selectedTask.entity ? selectedTask.entityID : selectedTask.associatedEntityID;
  const { data: adhocData, status: adHocStatus } = useCashManagementgetEntityDataByBranchId(
    selectedTask.fadcode,
    entity,
    entityID,
  );
  const fromDate = adhocData && adhocData?.entityData[0].itemDetails.lastBalancedDateTime;
  const toDate = Math.floor(Date.now() / 1000);
  const [paramsSet, setParamsSet] = useState<IParamsSet>({
    branchId: null,
    from_date: "",
    to_date: "",
  });
  const { data: cashMovementHistory, status: isRecordLoaded } = useGetCashInformation(
    paramsSet.branchId,
    paramsSet,
  );
  const [requestParamTpBP, setRequestParamTpBP] = useState<IRequestParamTpBP>({
    fadcode: "",
    entity: "",
    entityID: "",
    timestamp: null,
  });
  const [userActivitiesParams, setUserActivitiesParams] = useState<IUserActivitiesParams>({
    fadcode: "",
    entity: "",
    entityID: "",
    status: "",
  });
  const { data: TpBpHistoryData, status: TpBpHistoryStatus } = useAdhocBalanceHistory(
    requestParamTpBP.fadcode,
    requestParamTpBP.entity,
    requestParamTpBP.entityID,
    requestParamTpBP.timestamp,
  );
  const { data: userActivitesData } = useGetLogsDetails(
    userActivitiesParams.fadcode,
    userActivitiesParams.entity,
    userActivitiesParams.entityID,
    userActivitiesParams.status,
    dateParam,
  );
  const dataFormatter = (data) => {
    const newdata =
      data.cashMovement?.map((item: CashTransferHistoryData) => {
        const mValue = {};
        if (item.dateTime) {
          mValue["lastBalancedDate"] = getbalanceDate(item.dateTime);
        }
        if (item) {
          mValue["amount"] = amountUnSignedPound(item.transferAmount);
        }

        return {
          ...item,
          ...mValue,
        };
      }) || [];
    return [newdata, data.actionList];
  };

  const { data: counterData } = useGetBusinessRulesByBranchId(fadCode, {
    rule_type: "transfer_out",
    location: "",
    entity_id: selectedTask?.entityID,
  });

  const resetLadderValue = () => {
    const updateValue = [...cashdetail];
    cashdetail.map((item, index) => {
      updateValue[index].value = "";
      updateValue[index].Quantity = "";
      updateValue[index].alertCss = "normalCss";
      updateValue[index].QalertCss = "normalCss";
      updateValue[index].errorMsg = "";
      updateValue[index].QerrorMsg = "";
      updateValue[index].btnDisable = true;
      return 0;
    });
  };
  useEffect(() => {
    if (userActivitesData && cashMovementHistory) {
      setcashBalance({
        cashBalanceDetails: dataFormatter(cashMovementHistory),
        userActivitiesData: userActivitesData,
      });
      setCashMovementStatus(false);
    }
  }, [cashMovementHistory, userActivitesData]);
  useEffect(() => {
    resetLadderValue();
    if (adHocStatus === "success") {
      !dateParam.fromDate &&
        setDateparam({
          fromDate: fromDate,
          toDate: toDate,
        });
      setParamsSet({
        branchId: "1",
        from_date: fromDate.toString(),
        to_date: toDate.toString(),
      });
      setUserActivitiesParams({
        fadcode: selectedTask.fadcode,
        entity: selectedTask.entity ? selectedTask.entity : selectedTask.associatedEntityType,
        entityID: selectedTask.entity ? selectedTask.entityID : selectedTask.associatedEntityID,
        status: LOGDETAILS,
      });
    }
    setAdhocBalance({ ...adhocData });
  }, [adhocData, setAdhocBalance]);
  useEffect(() => {
    if (counterData !== undefined) {
      setMaxLimitCounter(counterData[0]?.permittedLimit);
    } else setMaxLimitCounter(0);
  }, [counterData]);

  useEffect(() => {
    if (TPRecount.TPRecount && adhocbalance.entityData) {
      const updateValue = [...cashdetail];

      const getList = adhocbalance?.entityData[0]?.itemDetails.denominationLadder;

      cashdetail?.map((item, index) => {
        getList?.map((list) => {
          if (list.index === index) {
            updateValue[index].value = amountInPound(Number(list.value));
            updateValue[index].Quantity = list.looseCoin;
          }
        });
      });
      setcashdetail(updateValue);
    }
  }, [TPRecount, adhocbalance]);

  useEffect(() => {
    if (isReadonlyMode.isReadonlyMode) {
      setRequestParamTpBP({
        fadcode: fadCode,
        entity: selectedTask.entity,
        entityID: selectedTask.entityID,
        timestamp: selectedTask.run_date_time,
      });
    }
  }, [isReadonlyMode]);

  useEffect(() => {
    if (
      routeState.RouteState !== "TradingPeriodCashBalance" ||
      routeState.RouteState !== "BalancePeriodCashBalance"
    ) {
      return () => {
        setTPRecount({ TPRecount: false });
      };
    }
  }, []);

  useEffect(() => {
    if (isReadonlyMode.isReadonlyMode && TpBpHistoryData) {
      const updateValue = [...cashdetail];

      const getList = TpBpHistoryData[0]?.itemDetails?.denominationLadder;
      cashdetail?.map((item, index) => {
        getList?.map((list) => {
          if (list.index === index) {
            updateValue[index].value = amountInPound(Number(list.value));
            updateValue[index].Quantity = list.looseCoin;
          }
        });
      });
      setcashdetail(updateValue);
    }
  }, [TpBpHistoryData, TpBpHistoryStatus]);
  return (
    <div>
      {(isRecordLoaded !== "success" && isRecordLoaded !== "error") ||
      cashMovementStatus ||
      (isReadonlyMode.isReadonlyMode &&
        TpBpHistoryStatus !== "success" &&
        TpBpHistoryStatus !== "error") ? (
        <Loader />
      ) : (
        <AdhocBalance />
      )}
    </div>
  );
};
export default AdhocCashBalancing;
