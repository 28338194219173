export const TRADING_DETAIL_SAFE = "Safe";
export const CASHDRAWER1 = "Cash Drawer 1";
export const CASHDRAWER2 = "Cash Drawer 2";
export const CASHDRAWER3 = "Cash Drawer 3";

export const TRADING_DETAIL_VIEW_TABS = [
  TRADING_DETAIL_SAFE,
  CASHDRAWER1,
  CASHDRAWER2,
  CASHDRAWER3,
];

// Trading Period Popup configuration data
export const TRADING_PERIOD_POPUP_DATA = {
  initialPopup: {
    commitButton: "Complete Trading Period",
    title: "By completing this TP",
    lines: [
      "All on hand figures will be updated",
      "Any Identified variance will be moved to the Branch Discrepancy Account",
      "The branch will be moved to the next trading period.",
    ],
  },
  cancelPopup: {
    cancelNavButton: "Go back to Declaration",
    title: "By Cancelling this TP",
    lines: [
      "The branch will not be rolled into the next Trading period",
      "Unknown variances will not be moved into the Branch Discrepancy Account",
      "All accounting locations may need to be recounted",
    ],
  },
};

// Balance Period Popup configuration data
export const Balance_PERIOD_POPUP_DATA = {
  initialPopup: {
    commitButton: "Complete Balance Period",
    title: "By completing this BP",
    lines: [
      "All on hand figures will be updated",
      "Any Identified variance will be moved to the Branch Discrepancy Account",
      "The branch will be moved to the next balance period.",
    ],
  },
  cancelPopup: {
    cancelNavButton: "Back to Confirmation Page",
    title: "By Cancelling this BP",
    lines: [
      "The branch will not be rolled into the next Balancing period",
      "Unknown variances will not be moved into the Branch Discrepancy Account",
      "All accounting locations may need to be recounted",
    ],
  },
};

export const COMMIT_BUTTON_VISIBILITY_STATUS_HIDE = "hide";
export const COMMIT_BUTTON_VISIBILITY_STATUS_DISABLE = "disable";
export const COMMIT_BUTTON_VISIBILITY_STATUS_SHOW = "show";
