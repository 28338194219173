import { Tab } from "@bbo/components/Tab/Tab";
import * as Constants from "@bbo/constants/ForexConstants";
import CashDrawerList from "../BranchOverview/CashDrawerList";
import Counters from "../BranchOverview/CounterList";
import Overview from "./Sections/Overview";
import Safe from "../BranchOverview/SafeItem";
import { useGetForexBranchOverview } from "@bbo/api/generator";
import Loader from "@bbo/components/Loader";

export const ForexAccountsContainer = () => {
  const { data: forex, isLoading: isforexLoading } = useGetForexBranchOverview("1");
  if (isforexLoading) {
    return <Loader />;
  }
  return (
    <Tab id="branchOviewOverTab">
      <Tab.TitleList>
        <Tab.Title data-testid="tab-0">Branch Overview</Tab.Title>
        <Tab.Title data-testid="tab-1">Counters</Tab.Title>
        <Tab.Title data-testid="tab-2">Safe</Tab.Title>
        <Tab.Title data-testid="tab-3">Cash Drawers</Tab.Title>
      </Tab.TitleList>

      <Tab.Panel className="w-full bg-white">
        <Tab.PanelContent>
          <Tab.PanelData>
            <Overview forex={forex} />
          </Tab.PanelData>
          <Tab.PanelData>
            <Counters
              items={forex["counterTerminal"]}
              header={Constants.COUNTERS}
              data-testid="counter-id"
            />
          </Tab.PanelData>
          <Tab.PanelData>
            <Safe header={Constants.SAFE} data={forex["safe"]} />
            <CashDrawerList
              header={Constants.CASH_DRAWERS}
              items={forex["cashDrawer"]}
              showAll={false}
            />
          </Tab.PanelData>
          <Tab.PanelData>
            <CashDrawerList
              header={Constants.CASH_DRAWERS}
              items={forex["cashDrawer"]}
              showAll={true}
            />
          </Tab.PanelData>
        </Tab.PanelContent>
      </Tab.Panel>
    </Tab>
  );
};
