import BranchVarianceDetailPopup from "@bbo/components/layout/PopupScreen/BranchVarianceDetailPopup";
import MoveVarianceSummaryPopup from "@bbo/components/layout/PopupScreen/MoveVariancesummaryPopup";
import TableView from "@bbo/components/table";
import { BRANCH_VARIANCE_SUMMARY_COLUMN } from "@bbo/components/table/tableConstants";
import { useGlobalState } from "@bbo/lib/state";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
/**
 * Branch Variance summary props model
 * @param props
 * @returns
 */
interface iProps {
  rows: any[];
}

export const BranchVarianceSummary = (props: iProps) => {
  const [BranchVariancePopup, setBranchVariancePopup] = useGlobalState("BranchVariancePopup");
  const [MoveVariancePopup, setMoveVariancePopup] = useGlobalState("MoveVariancePopup");
  const [modalData, setModalData] = useState<unknown>();
  const [checkedRows, setCheckedRows] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setBranchVariancePopup({ BranchVariancePopup: false });
    setMoveVariancePopup({ BranchVariancePopup: false });
  }, []);

  useEffect(() => {
    setCheckedRows([]);
  }, [props.rows]);

  const handleCellClicked = (params) => {
    setModalData({ ...params, showModal: true });
    setBranchVariancePopup({ BranchVariancePopup: true });
  };

  const handleSelectAll = (params) => {
    if (params.checked) {
      setCheckedRows(props.rows.map((v, index) => index));
    } else {
      setCheckedRows([]);
    }
  };

  const handleRowSelection = (params) => {
    if (params.checked) {
      setCheckedRows((item) => [...item, params.rowIndex]);
    } else {
      setCheckedRows((item) => {
        const position = item.indexOf(params.rowIndex);
        if (position !== -1) {
          return [...item.slice(0, position), ...item.slice(position + 1)];
        }
      });
    }
  };

  const handleMoveDiscrepancyAccount = () => {
    const selectedRow = [];
    props.rows.map((v, index) => {
      return checkedRows.filter((indexValue) => {
        if (indexValue === index) {
          return selectedRow.push(v);
        }
      });
    });

    setMoveVariancePopup({ BranchVariancePopup: true });
    setModalData({ selectedRow, MoveDiscrepancy: true });
  };

  const handleCellClickedCallback = useCallback(handleCellClicked, []);
  const rowSelectionCallback = useCallback(handleRowSelection, []);
  const handleSelectionAllCallback = useCallback(handleSelectAll, []);

  return (
    <>
      <div className="w-full transferOut-height">
        <div
          className="leftBtnalign mr-8 my-2"
          onClick={() => {
            navigate("/discrepancyAccount");
          }}>
          {" "}
          Go to Discrepancy Account
        </div>
        <div className="p-4 lg:w-full h-full relative dynamictable varianceSummary">
          <TableView
            columns={BRANCH_VARIANCE_SUMMARY_COLUMN}
            rows={props.rows || []}
            checkboxSelection={true}
            uperLeftTitle={"Select Variance to move to Discrepancy Account"}
            onCellClicked={handleCellClickedCallback}
            onRowSelection={rowSelectionCallback}
            onSelectAll={handleSelectionAllCallback}
          />
          {BranchVariancePopup.BranchVariancePopup && modalData && (
            <BranchVarianceDetailPopup data={modalData} />
          )}
          {MoveVariancePopup.BranchVariancePopup && modalData && (
            <MoveVarianceSummaryPopup data={modalData} />
          )}

          <div className="flex items-center justify-end" style={{ marginTop: 8 }}>
            <button
              className="accounting-button"
              disabled={!!(checkedRows && checkedRows.length === 0)}
              onClick={handleMoveDiscrepancyAccount}
              data-cy="MoveToDiscrepancy">
              Move to Discrepancy Account
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
