/**
 * Pouch preparation constants
 */

import { TRANSACTION_NOTE_COIN_ITEM_ID } from "@bbo/utils/transferPayload";

/* eslint-disable prettier/prettier */
export const LIMIT_EXCEEDS = 26000;

export const NOTES_DETAIL = [
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.NOTE_50_POUND_ID,
    Cash: 50.0,
    Quantity: null,
    value: "£0.00",
    cashType: "Notes",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.NOTE_20_POUND_ID,
    Cash: 20.0,
    Quantity: null,
    value: "£0.00",
    cashType: "Notes",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.NOTE_10_POUND_ID,
    Cash: 10.0,
    Quantity: null,
    value: "£0.00",
    cashType: "Notes",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.NOTE_5_POUND_ID,
    Cash: 5.0,
    Quantity: null,
    value: "£0.00",
    cashType: "Notes",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
];

export const COINS_DETAIL = [
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_2_POUND_ID,
    Cash: 2.0,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_1_POUND_ID,
    Cash: 1.0,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_50_PENCE_ID,
    Cash: 50,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_20_PENCE_ID,
    Cash: 20,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_10_PENCE_ID,
    Cash: 10,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_5_PENCE_ID,
    Cash: 5,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_2_PENCE_ID,
    Cash: 2,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_1_PENCE_ID,
    Cash: 1,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
];

export const COINS_DETAIL_PART_BAG = [
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_POUND_PARTIAL_ID,
    Cash: 2.0,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_POUND_PARTIAL_ID,
    Cash: 1.0,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_50_PENCE_PARTIAL_ID,
    Cash: 50,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_20_PENCE_PARTIAL_ID,
    Cash: 20,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_10_PENCE_PARTIAL_ID,
    Cash: 10,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_5_PENCE_PARTIAL_ID,
    Cash: 5,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_PENCE_PARTIAL_ID,
    Cash: 2,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_PENCE_PARTIAL_ID,
    Cash: 1,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
];

export const COINS_FULL_BAG_DETAIL = [
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_POUND_FULL_ID,
    Cash: 2.0,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_POUND_FULL_ID,
    Cash: 1.0,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_50_PENCE_FULL_ID,
    Cash: 50,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_20_PENCE_FULL_ID,
    Cash: 20,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_10_PENCE_FULL_ID,
    Cash: 10,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_5_PENCE_FULL_ID,
    Cash: 5,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_PENCE_FULL_ID,
    Cash: 2,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_PENCE_FULL_ID,
    Cash: 1,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
];
