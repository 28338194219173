/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

PO Transactions

PO Transactions Viewer

Cash Drawer Association API
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { TaskData, TaskManagementApiResponse } from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Get list  of  Tasks.
 * @summary This endpoint is used to get task List data based on branch_id
 */
export const useGetTaskListHook = () => {
  const getTaskList = useCustomInstance<TaskData[]>();

  return (branchId: string) => {
    return getTaskList({ url: `/task-management/task/${branchId}`, method: "get" });
  };
};

export const getGetTaskListQueryKey = (branchId: string) => [`/task-management/task/${branchId}`];

export type GetTaskListQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetTaskListHook>>
>;
export type GetTaskListQueryError = ErrorType<void>;

export const useGetTaskList = <
  TData = AsyncReturnType<ReturnType<typeof useGetTaskListHook>>,
  TError = ErrorType<void>,
>(
  branchId: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<ReturnType<typeof useGetTaskListHook>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetTaskListQueryKey(branchId);

  const getTaskList = useGetTaskListHook();

  const queryFn: QueryFunction<AsyncReturnType<ReturnType<typeof useGetTaskListHook>>> = () =>
    getTaskList(branchId);

  const query = useQuery<AsyncReturnType<ReturnType<typeof useGetTaskListHook>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!branchId, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

/**
 * Get list of Tasks of particular Entity crossponding Task_type.
 * @summary This endpoint is used to get task List data based on branch_id and entity_id
 */
export const useGetParticularTaskHook = () => {
  const getParticularTask = useCustomInstance<TaskData[]>();

  return (branchId: string, taskType: string, entityID: string) => {
    return getParticularTask({
      url: `/task-management/task/${branchId}/${taskType}/${entityID}`,
      method: "get",
    });
  };
};

export const getGetParticularTaskQueryKey = (
  branchId: string,
  taskType: string,
  entityID: string,
) => [`/task-management/task/${branchId}/${taskType}/${entityID}`];

export type GetParticularTaskQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetParticularTaskHook>>
>;
export type GetParticularTaskQueryError = ErrorType<void>;

export const useGetParticularTask = <
  TData = AsyncReturnType<ReturnType<typeof useGetParticularTaskHook>>,
  TError = ErrorType<void>,
>(
  branchId: string,
  taskType: string,
  entityID: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetParticularTaskHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetParticularTaskQueryKey(branchId, taskType, entityID);

  const getParticularTask = useGetParticularTaskHook();

  const queryFn: QueryFunction<AsyncReturnType<ReturnType<typeof useGetParticularTaskHook>>> = () =>
    getParticularTask(branchId, taskType, entityID);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetParticularTaskHook>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(branchId && taskType && entityID), ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Get list of Tasks of particular Entity crossponding Task_type.
 * @summary This endpoint is used to get task List data based on branch_id and entity_id
 */
export const useGetTaskDetailsListHook = () => {
  const getTaskDetailsList = useCustomInstance<TaskData[]>();

  return (branchId: string, entityId: string, status: string) => {
    return getTaskDetailsList({
      url: `/task-management/taskdetails/${branchId}/${entityId}/${status}`,
      method: "get",
    });
  };
};

export const getGetTaskDetailsListQueryKey = (
  branchId: string,
  entityId: string,
  status: string,
) => [`/task-management/taskdetails/${branchId}/${entityId}/${status}`];

export type GetTaskDetailsListQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetTaskDetailsListHook>>
>;
export type GetTaskDetailsListQueryError = ErrorType<void>;

export const useGetTaskDetailsList = <
  TData = AsyncReturnType<ReturnType<typeof useGetTaskDetailsListHook>>,
  TError = ErrorType<void>,
>(
  branchId: string,
  entityId: string,
  status: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetTaskDetailsListHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetTaskDetailsListQueryKey(branchId, entityId, status);

  const getTaskDetailsList = useGetTaskDetailsListHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetTaskDetailsListHook>>
  > = () => getTaskDetailsList(branchId, entityId, status);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetTaskDetailsListHook>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(branchId && entityId && status), ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};

/**
 * This endpoint is used to task list
 * @summary This endpoint is used to update the task status
 */
export const usePutTaskManagementTaskHook = () => {
  const putTaskManagementTask = useCustomInstance<void>();

  return (taskData: TaskData) => {
    return putTaskManagementTask({
      url: `/task-management/task`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: taskData,
    });
  };
};

export type PutTaskManagementTaskMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof usePutTaskManagementTaskHook>>
>;
export type PutTaskManagementTaskMutationBody = TaskData;
export type PutTaskManagementTaskMutationError = ErrorType<TaskManagementApiResponse>;

export const usePutTaskManagementTask = <
  TError = ErrorType<TaskManagementApiResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof usePutTaskManagementTaskHook>>,
    TError,
    { data: TaskData },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const putTaskManagementTask = usePutTaskManagementTaskHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof usePutTaskManagementTaskHook>>,
    { data: TaskData }
  > = (props) => {
    const { data } = props || {};

    return putTaskManagementTask(data);
  };

  return useMutation<
    AsyncReturnType<typeof putTaskManagementTask>,
    TError,
    { data: TaskData },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * This endpoint is used to task list
 * @summary This endpoint is used to create the task status
 */
export const usePostTaskManagementTaskHook = () => {
  const postTaskManagementTask = useCustomInstance<void>();

  return (taskData: TaskData) => {
    return postTaskManagementTask({
      url: `/task-management/task`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: taskData,
    });
  };
};

export type PostTaskManagementTaskMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof usePostTaskManagementTaskHook>>
>;
export type PostTaskManagementTaskMutationBody = TaskData;
export type PostTaskManagementTaskMutationError = ErrorType<TaskManagementApiResponse>;

export const usePostTaskManagementTask = <
  TError = ErrorType<TaskManagementApiResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof usePostTaskManagementTaskHook>>,
    TError,
    { data: TaskData },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const postTaskManagementTask = usePostTaskManagementTaskHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof usePostTaskManagementTaskHook>>,
    { data: TaskData }
  > = (props) => {
    const { data } = props || {};

    return postTaskManagementTask(data);
  };

  return useMutation<
    AsyncReturnType<typeof postTaskManagementTask>,
    TError,
    { data: TaskData },
    TContext
  >(mutationFn, mutationOptions);
};
