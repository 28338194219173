import { useGetCashInformation } from "@bbo/api/generator";
import CashMovementsModal from "@bbo/components/layout/cashMovement-Popup";
import Loader from "@bbo/components/Loader";
import TableView from "@bbo/components/table";
import { CASH_MOVEMENT_HISTORY_COLUMN } from "@bbo/components/table/tableConstants";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { useGlobalState } from "@bbo/lib/state";
import { getUnixTimeStamp } from "@bbo/utils/dateTimeFormatter";
import React, { useEffect, useState } from "react";

const CashMovementHistory = () => {
  const [param, setparam] = useState<any>({});
  const { data: cashMovementHistory, isLoading } = useGetCashInformation("1", param);
  const [cashdate] = useGlobalState("dateCashbalance");
  const [modalData, setModalData] = useState({ showModal: false });
  const [loader, setloader] = React.useState<boolean>(false);
  const { setTitle } = useTitle();
  const [cashMovementHistoryData, setCashMovementHistoryData] = React.useState({});

  useEffect(() => {
    setTitle("Cash Movements History");
  }, [setTitle]);

  useEffect(() => {
    if (!isLoading) {
      setCashMovementHistoryData(cashMovementHistory);
    }
  }, [isLoading, cashMovementHistory]);

  useEffect(() => {
    if (cashdate.todate) {
      const param = {
        branchId: 1,
        fromDate: getUnixTimeStamp(cashdate.from),
        toDate: getUnixTimeStamp(cashdate.todate),
      };
      setparam(param);
    }
  }, [cashdate.todate]);

  const handleRowClicked = (params) => {
    setModalData({ ...params, showModal: true });
  };

  return (
    <>
      <>
        {isLoading ? <Loader /> : loader && <Loader />}
        <div className="w-full transferOut-height">
          <div className="p-4 lg:w-full h-full relative dynamictable">
            <TableView
              columns={CASH_MOVEMENT_HISTORY_COLUMN}
              rows={cashMovementHistoryData?.["cashMovement"]}
              showResetFilter
              TransactionType={cashMovementHistoryData?.["actionList"]}
              onRowClicked={handleRowClicked}
            />
            <CashMovementsModal data={modalData} />
          </div>
        </div>
      </>
    </>
  );
};

export default CashMovementHistory;
