import {
  CashManagementData,
  CashManagementsafeDetails,
  CashManagementitemDetails,
} from "@bbo/api/generator";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Need to check whether "ItemDetails" should instead be coming back as "itemDetails" which is currently being used
export interface BpTpStateSafe extends CashManagementsafeDetails {
  itemDetails: CashManagementitemDetails;
}

// The logic around this type needs fixing. Functionality should not depend on accountLocation defaulting to a string
export interface BpTpState extends Omit<CashManagementData, "accountingLocation"> {
  accountingLocation: string[] | string | null;
  safe: BpTpStateSafe;
}

const initialState: BpTpState = {
  atm: {
    lastVariance: null,
    toDateVariance: null,
    lastBalancedDateTime: null,
    entityID: "",
    entity: "",
    entityName: "",
    transferAmt: null,
    transferAmtDate: null,
    smartID: "",
    userName: "",
    actionList: [],
    balance: null,
    fadcode: null,
    flowEntityStatus: undefined,
    balancePeriodDateTime: undefined,
    flowType: undefined,
    tradingPeriodDateTime: undefined,
    ItemDetails: undefined,
  },
  cashDrawer: [],
  counterTerminal: [],
  safe: {
    flowEntityStatus: "",
    balancePeriodDateTime: null,
    tradingPeriodDateTime: null,
    lastBalancedDateTime: null,
    lastVariance: null,
    entityName: "",
    toDateVariance: null,
    balance: null,
    fadcode: "",
    entityID: "",
    entity: "",
    cashDrawer: [],
    transferAmt: null,
    flowType: "",
    transferAmtDate: null,
    actionList: [],
    ItemDetails: null,
    itemDetails: {
      denominationLadder: [],
      lastBalancedDateTime: null,
      balancePeriodDateTime: null,
      tradingPeriodDateTime: null,
      currentVariance: null,
      balance: null,
      toDateVariance: null,
      transactionID: "",
      transferAmt: null,
      transferAmtDate: null,
      lastTransferAmount: undefined,
    },
  },
  totalVariance: null,
  accountingLocation: "",
};

export const BpTpDetails = createSlice({
  name: "BpTpDetails",
  initialState,
  reducers: {
    setBpTpDetails: (state, action: PayloadAction<CashManagementData>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setBpTpDetails } = BpTpDetails.actions;

export default BpTpDetails.reducer;
