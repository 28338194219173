/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

PO Transactions

PO Transactions Viewer

Cash Drawer Association API
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, UseMutationOptions, MutationFunction } from "@tanstack/react-query";
import type { StockManagementApiResponse, StockCountRequest } from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary This endpoint is used to store the stock counting into dB
 */
export const usePutStockManagementStockCountStatusHook = () => {
  const putStockManagementStockCountStatus = useCustomInstance<StockManagementApiResponse>();

  return (status: string, stockCountRequest: StockCountRequest[]) => {
    return putStockManagementStockCountStatus({
      url: `/stock-management/stock/count/${status}`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: stockCountRequest,
    });
  };
};

export type PutStockManagementStockCountStatusMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof usePutStockManagementStockCountStatusHook>>
>;
export type PutStockManagementStockCountStatusMutationBody = StockCountRequest[];
export type PutStockManagementStockCountStatusMutationError = ErrorType<void>;

export const usePutStockManagementStockCountStatus = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof usePutStockManagementStockCountStatusHook>>,
    TError,
    { status: string; data: StockCountRequest[] },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const putStockManagementStockCountStatus = usePutStockManagementStockCountStatusHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof usePutStockManagementStockCountStatusHook>>,
    { status: string; data: StockCountRequest[] }
  > = (props) => {
    const { status, data } = props || {};

    return putStockManagementStockCountStatus(status, data);
  };

  return useMutation<
    AsyncReturnType<typeof putStockManagementStockCountStatus>,
    TError,
    { status: string; data: StockCountRequest[] },
    TContext
  >(mutationFn, mutationOptions);
};
