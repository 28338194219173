import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export type TaskSwitchProps = "Cash Balance" | "Transfer Out" | "Transfer In";
/**
 * @remarks - possibly incorrect data structure, needs looking at when other components are refactored
 */
export type SelectedTask = {
  completionDateTime?: number;
  entity?: string;
  entityID?: string;
  entityName?: string;
  fadcode?: string;
  level?: string;
  role?: string;
  run_date_time?: number;
  status?: string;
  task?: TaskSwitchProps;
  taskID?: string;
  taskType?: string;
  trigger?: string;
  associatedEntityType?: string;
  associatedEntityID?: string;
  user?: string;
  associatedCounterTerminalID?: string;
  transferAmt?: number;
  transferAmtDate?: number;
  sequenceID?: number;
  warningMsg?: boolean;
  associatedEntityName?: string;
  itemDetails?: {
    currentVariance?: string;
    toDateVariance?: string;
    lastBalancedDateTime?: string;
    transferAmt?: number;
    transferAmtDate?: number;
    balance?: string;
  };
};

const initialState: SelectedTask = {
  completionDateTime: 0,
  entity: "",
  entityID: "",
  entityName: "",
  fadcode: "",
  level: "",
  role: "",
  run_date_time: 0,
  sequenceID: 0,
  status: "",
  task: "Cash Balance",
  taskID: "",
  taskType: "",
  trigger: "",
  associatedEntityType: "",
  associatedEntityID: "",
  user: "",
  associatedCounterTerminalID: "",
  transferAmt: 0,
  transferAmtDate: 0,
  associatedEntityName: "",
  warningMsg: false,
  itemDetails: {
    currentVariance: "",
    toDateVariance: "",
    lastBalancedDateTime: "",
    transferAmt: 0,
    transferAmtDate: 0,
    balance: "",
  },
};

export const selectedTask = createSlice({
  name: "selectedTask",
  initialState,
  reducers: {
    setSelectedTask: (state, action: PayloadAction<SelectedTask>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setSelectedTask } = selectedTask.actions;

export default selectedTask.reducer;
