import { CashDrawerData, ForexDrawerData } from "@bbo/api/generator";
import { useState } from "react";
import CashDrawerItem from "./CashDrawerItem";

type Props = {
  header: string;
  items: (CashDrawerData | ForexDrawerData)[];
  showAll?: boolean;
  isStockData?: boolean;
};

const CashDrawerList = ({ items, isStockData, ...rest }: Props) => {
  const [count, setCount] = useState(15);
  const containerStyle = () => {
    if (rest.showAll) {
      return "grid grid-cols-1 lg:grid-cols-4 gap-7";
    }

    return "float-left h-40 overflow-x-scroll whitespace-nowrap";
  };

  const LoadMore = () => {
    if (rest.showAll && count !== items?.length && count < items?.length) {
      return (
        <div
          className="main morebtn justify-center flex-col cursor-pointer items-center flex block w-full grid-cols-4 gap-5"
          onClick={() => setCount(items?.length)}>
          <p> More</p>
          <div>
            <i className="fas fa-arrow-down mt-4"></i>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <h2 className="account-group mb-1">Cash Drawers {rest.showAll}</h2>
      <div className={`w-full accounting-card ${containerStyle()}`}>
        {items?.map((item, index) => {
          if (
            (rest.showAll && index < count) ||
            (!rest.showAll && !item?.associatedCounterTerminalID)
          ) {
            return (
              <CashDrawerItem
                key={index}
                data={item}
                index={index}
                header={rest.header}
                isStockData={isStockData}
              />
            );
          }
        })}
        <LoadMore />
      </div>
    </>
  );
};

export default CashDrawerList;
