//ADD,REMOVE,REPRINT, and all other flows will be handled here..

/**
 * Alert/Popup view with
 * @param props
 * @returns
 */

interface iProps {
  cashDrawerAdded?: string;
  flow?: string;
  onSuccess?: () => void;
  onCancel?: () => void;
}

const SuccessMessage = (props: iProps) => {
  const reprint = "Re-print";
  const onSuccess = () => {
    if (props.onSuccess) {
      props.onSuccess();
    }
  };

  const onCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const returnFlag = () => {
    if (props.flow === "REMOVE") {
      return false;
    } else if (props.flow === "ForcedLoggOff") {
      return false;
    } else return true;
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-xl popupmodel">
          <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4	">
            <div className="relative p-4 flex-auto text-center">
              <i className="fas fa-check text-5xl paidTickColor bg-white"></i>

              {props?.flow === "REMOVE" && (
                <div className=" p-4  items-center justify-start">
                  <h2 className="text-blueGray-500 text-lg leading-relaxed heading-5 font-bold text-color">
                    {` ${props?.cashDrawerAdded} has been successfully removed.`}
                  </h2>
                </div>
              )}

              {props?.flow === "ForcedLoggOff" && (
                <div className=" p-4  items-center justify-start">
                  <h2 className="text-blueGray-500 text-lg leading-relaxed heading-5 font-bold text-color">
                    Successfully logged off user and cash drawer disassociated.
                  </h2>
                </div>
              )}

              {returnFlag() && (
                <div className=" p-4  items-center justify-start">
                  <h2 className="text-blueGray-500 text-lg leading-relaxed heading-5 font-bold text-color">
                    {props?.flow === "Added"
                      ? ` ${props?.cashDrawerAdded} has been successfully added.Please check the label has printed.`
                      : `Please check the label has printed.`}
                  </h2>
                </div>
              )}
              {/*footer*/}
              <div className="justify-start p-4  rounded-b">
                {!returnFlag() && (
                  <button
                    className="brandRedBtnMcd text-white active:brandRedBtnMcd font-bold text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="button"
                    value="Yes"
                    onClick={onCancel}
                    data-cy="popupYes">
                    Close
                  </button>
                )}
                {returnFlag() && (
                  <>
                    <button
                      className="restNoBtnMcd align-center border-t border-r border-l border-b border-black border-solid bg-transparent-500 text-black active:bg-transparent-600 font-bold text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150 hover"
                      type="button"
                      value="No"
                      onClick={onSuccess}
                      data-cy="popupNo">
                      Re-print
                    </button>
                    <button
                      className="brandRedBtnMcd text-white active:brandRedBtnMcd font-bold text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                      type="button"
                      value="Yes"
                      onClick={onCancel}
                      data-cy="popupYes">
                      Confirm
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default SuccessMessage;
