import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { BranchOverview } from "@bbo/features/BranchOverview";
import { useEffect } from "react";

const BranchSummary = () => {
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Cash Management");
  }, [setTitle]);

  return (
    <>
      <>
        <div className="containerMain">
          <div className="w-full items-start transferOut-height overflow-x-auto">
            <div className="px-4 py-10 md:p-8 mx-auto w-full">
              <div>
                <BranchOverview />
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default BranchSummary;
