import { TRANSACTION_NOTE_COIN_ITEM_ID } from "@bbo/utils/transferPayload";

export const CASH_DETAILS = [
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.NOTE_50_POUND_ID,
    Cash: 50,
    Quantity: null,
    value: null,
    cashType: "Notes",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.NOTE_20_POUND_ID,
    Cash: 20,
    Quantity: null,
    value: null,
    cashType: "Notes",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.NOTE_10_POUND_ID,
    Cash: 10,
    Quantity: null,
    value: null,
    cashType: "Notes",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.NOTE_5_POUND_ID,
    Cash: 5,
    Quantity: null,
    value: null,
    cashType: "Notes",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_2_POUND_ID,
    Cash: 2,
    Quantity: null,
    value: null,
    cashType: "Coins",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_1_POUND_ID,
    Cash: 1,
    Quantity: null,
    value: null,
    cashType: "Coins",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_50_PENCE_ID,
    Cash: 50,
    Quantity: null,
    value: null,
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_20_PENCE_ID,
    Cash: 20,
    Quantity: null,
    value: null,
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_10_PENCE_ID,
    Cash: 10,
    Quantity: null,
    value: null,
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_5_PENCE_ID,
    Cash: 5,
    Quantity: null,
    value: null,
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_2_PENCE_ID,
    Cash: 2,
    Quantity: null,
    value: null,
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_1_PENCE_ID,
    Cash: 1,
    Quantity: null,
    value: null,
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.UNUSABLE_NOTES_ID,
    Cash: "Unusable Notes",
    Quantity: null,
    value: null,
    cashType: "Notes",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.UNUSABLE_COINS_ID,
    Cash: "Unusable Coins",
    Quantity: null,
    value: null,
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
];

export const defaultNotesDetail = [
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.NOTE_50_POUND_ID,
    Cash: 50,
    Quantity: null,
    value: null,
    cashType: "Notes",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.NOTE_20_POUND_ID,
    Cash: 20,
    Quantity: null,
    value: null,
    cashType: "Notes",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.NOTE_10_POUND_ID,
    Cash: 10,
    Quantity: null,
    value: null,
    cashType: "Notes",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.NOTE_5_POUND_ID,
    Cash: 5,
    Quantity: null,
    value: null,
    cashType: "Notes",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
];

export const defaultCoinDetail = [
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_2_POUND_ID,
    Cash: 2,
    Quantity: null,
    value: null,
    cashType: "Notes",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_1_POUND_ID,
    Cash: 1,
    Quantity: null,
    value: null,
    cashType: "Coins",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_50_PENCE_ID,
    Cash: 50,
    Quantity: null,
    value: null,
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_20_PENCE_ID,
    Cash: 20,
    Quantity: null,
    value: null,
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_10_PENCE_ID,
    Cash: 10,
    Quantity: null,
    value: null,
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_5_PENCE_ID,
    Cash: 5,
    Quantity: null,
    value: null,
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_2_PENCE_ID,
    Cash: 2,
    Quantity: null,
    value: null,
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.COIN_1_PENCE_ID,
    Cash: 1,
    Quantity: null,
    value: null,
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
];
