import { useGetList } from "@bbo/api/generator/endpoints/cash-management-api";
import { usePostTransferTransaction } from "@bbo/api/generator/endpoints/transfer-ap-i";
import { AXIOS_INSTANCE } from "@bbo/api/generator/mutator/useCustomInstance";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { useReceiptPrinter } from "@bbo/lib/hooks/useReceiptPrinter";
import { useGlobalState } from "@bbo/lib/state";
import { CashTransfer } from "@bbo/types/TransferTypes";
import { amountFormatter, inputInPence } from "@bbo/utils/currencyFormatter";
import { currentDate, currentTime } from "@bbo/utils/dateTimeFormatter";
import { removeEmptyLinesFromAddress } from "@bbo/utils/receiptHelper";
import { TransferOutTemplate } from "@bbo/utils/ReceiptPrinterTemplates/TransferOutTemplate";
import { getStockUnitIdentifier, transferOutParams } from "@bbo/utils/transferPayload";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BACK_OFFICE } from "../DiscrepancyAccount/constant";

export const useTransferout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedTask = useAppSelector((root) => root.selectedTask);
  const [routeState] = useGlobalState("routeState");

  const { data: transferListData, isLoading: transferListIsLoading } = useGetList(
    "1",
    "transfer_out",
    {
      entityID: selectedTask.associatedEntityID
        ? selectedTask.associatedEntityID
        : selectedTask.entityID,
    },
  );
  const { mutate: postTransferOut, isSuccess, isError } = usePostTransferTransaction();

  const postTransferOutApi = (data) => {
    postTransferOut({ data: data });
  };
  useEffect(() => {
    if (routeState.RouteState === null) {
      navigate("/branchOverview");
    }
  }, []);
  const [PopupData] = useGlobalState("PopupData");
  const [disable, setdisable] = React.useState<boolean>(false);
  const [activeHeadingName, setactiveHeadingName] = React.useState<string>(null);
  const [ActiveId, setActiveId] = React.useState<string>();
  const [EntityType, setEntityType] = React.useState<string>();
  const [selectedAmount, setselectedAmount] = React.useState<string>("");
  const [selectedRule, setselectedRule] = React.useState<any>();
  const [transferAmount, setTransferAmount] = React.useState<number>(0);

  const [printData, setPrintData] = React.useState<boolean>(false);
  const [sequenceID, setSequenceID] = React.useState<number>(0);
  const branchDetails = useAppSelector((root) => root.branchDetails);

  const [transferDateTime, setTransferDateTime] = React.useState<number>(0);
  const [warningFlag, setwarningFlag] = React.useState<boolean>(false);
  const [invaildFlag, setInvaildFlag] = React.useState<boolean>(false);
  const [invaildLimitFlag, setinvaildLimitFlag] = React.useState<boolean>(false);
  const [invalidFund, setinvalidFund] = React.useState<boolean>(false);
  const [clearFlag, setclearFlag] = React.useState<boolean>(false);
  const [initialCall, setInitialCall] = React.useState<boolean>(true);
  const [ruleList] = useGlobalState("BusinesRule");
  const [isDrawers, setisDrawers] = React.useState<boolean>(false);
  const { fadCode } = useAppSelector((root) => root.auth.authUserData);
  const { userName } = useAppSelector((root) => root.userName);

  const receiptPrinterService = useReceiptPrinter();
  //   const { data: transferListData, isLoading: transferListIsLoading } = useGetList(
  //     "1",
  //     "transfer_out",
  //     {
  //       entityID: selectedTask.associatedEntityID
  //         ? selectedTask.associatedEntityID
  //         : selectedTask.entityID,
  //     },
  //   );

  //   useEffect(() => {
  //     if (routeState.RouteState === null) {
  //       navigate("/branchOverview");
  //     }
  //   }, []);
  useEffect(() => {
    if (location.state) {
      setisDrawers(true);
      setdisable(true);
      setactiveHeadingName("Safe 1");
      setActiveId("safe_1");
      setEntityType("safe");
      setselectedAmount("");
      setclearFlag(false);
      setinvalidFund(false);
    }
  }, [location]);

  useEffect(() => {
    ruleList.filter((item) => {
      if (item.ruleType === "transfer_out") {
        if (selectedTask.entityID === "safe_1") {
          return setselectedRule(item);
        } else if (selectedTask.associatedCounterTerminalID === item.entityID) {
          return setselectedRule(item);
        }
      }
    });
    if (parseInt(selectedRule?.permittedLimit) < parseInt(selectedAmount.substring(1))) {
      setwarningFlag(true);
    } else {
      setwarningFlag(false);
    }

    if (parseInt(selectedRule?.maximumLimit) < parseInt(selectedAmount.substring(1))) {
      setinvaildLimitFlag(true);
    } else {
      setinvaildLimitFlag(false);
    }
  });

  const selectOption = (heading, Id, entityType, sequenceID) => {
    setdisable(true);
    setactiveHeadingName(heading);
    setActiveId(Id);
    setSequenceID(sequenceID);
    setEntityType(entityType);
    setselectedAmount("");
    setclearFlag(false);
    setinvalidFund(false);
  };

  const onSelectHandleChange = (e) => {
    selectOption(e.value, e.ID, e.ACT, e.sequenceID);
  };

  const listItems = isDrawers
    ? transferListData?.unassociatedCashDrawer
    : transferListData?.associatedCashDrawer;
  const selectView =
    routeState.RouteState === null
      ? []
      : listItems?.map((headingName) => ({
          value: headingName.associatedCounterTerminal,
          label: headingName.associatedCounterTerminal,
          ID: headingName.entityID,
          ACT: headingName.entity,
          sequenceID: headingName.sequenceID,
        }));

  function handleValue(event) {
    if (
      event.which !== 9 &&
      event.which !== 8 &&
      event.which !== 0 &&
      (event.which < 48 || event.which > 57) &&
      event.which !== 190
    ) {
      event.preventDefault();
    }
  }

  const enterAmount = (e) => {
    setinvalidFund(false);

    e.preventDefault();
    const amount = e.target.value;
    const rx_live = /^[0-9,£\b]+(\.\d{0,2})?$/;
    setselectedAmount(amountFormatter(amount));
    setInvaildFlag(true);
    if (rx_live.test(amount)) {
      setselectedAmount(amountFormatter(amount));
      setInvaildFlag(true);
    } else {
      if (amount.length === 0) {
        setselectedAmount("");
      } else {
        setselectedAmount(selectedAmount);
        setInvaildFlag(true);
      }
    }
  };
  const saveCashRequestParams = (): CashTransfer => {
    return {
      sourceEntityType: selectedTask.entity
        ? selectedTask.entity
        : /**
           * @remarks = associatedEntityType doesn't exist, changed to associatedEntityName
           */
          selectedTask.associatedEntityType,
      sourceEntityID: selectedTask.associatedEntityID
        ? selectedTask.associatedEntityID
        : /**
           * @remarks = associatedEntityID doesn't exist, changed to entityID
           */
          selectedTask.entityID,
      destinationEntityType: EntityType,
      destinationEntityID: ActiveId,
      transferAmount: inputInPence(selectedAmount),
      fadcode: selectedTask.fadcode,
      userName: userName,
      operationType: "transfer_out",
      transferAt: BACK_OFFICE,
      stockunitIdentifier: getStockUnitIdentifier(selectedTask.entity, selectedTask.sequenceID),
      stockunitIdentifierDest: getStockUnitIdentifier(EntityType, sequenceID),
    };
  };
  //   const { mutate: postTransferOut, isSuccess, isError } = usePostTransferTransaction();

  const handleCashDetails = async () => {
    let BalanceCheck = true;
    let balance;
    if (PopupData?.data?.entity === "safe") {
      balance = PopupData?.data?.balance;
    } else {
      balance = PopupData?.data?.itemDetails.balance;
    }
    if (Number(balance) < inputInPence(selectedAmount)) {
      BalanceCheck = false;
      setinvalidFund(true);
      setwarningFlag(false);
      setclearFlag(false);
    } else {
      BalanceCheck = true;
      setinvalidFund(false);
      setwarningFlag(true);
      setclearFlag(true);
    }
    if (invaildFlag && BalanceCheck) {
      delete AXIOS_INSTANCE.defaults.headers.common["fadcode"];
      postTransferOutApi(transferOutParams(saveCashRequestParams()));
    }
  };

  useEffect(() => {
    if (isError) {
      setwarningFlag(false);
      setinvalidFund(true);
      setclearFlag(false);
    } else if (isSuccess) {
      setTransferAmount(inputInPence(selectedAmount));
      setTransferDateTime(Math.floor(Date.now() / 1000));
      setclearFlag(true);
      setinvalidFund(false);
    }

    AXIOS_INSTANCE.defaults.headers.common["fadcode"] = fadCode;
  }, [isSuccess, isError]);

  /**
   * @remarks - temporary solution to prevent app from
   * crashing until we implement a global loading solution
   */
  // if (transferListIsLoading) {
  //   return <div>Loading...</div>;
  // }
  const ReceiptPrint = (title: string) => {
    const branchAddressClean = removeEmptyLinesFromAddress(branchDetails.branch_address);
    receiptPrinterService.printReceipt({
      template: TransferOutTemplate,
      context: {
        branchName: branchDetails.branch_name,
        addressLine1: branchAddressClean[0],
        addressLine2: branchAddressClean[1],
        addressLine3: branchAddressClean[2],
        title: title,
        fadcode: branchDetails.fadcode,
        currentDate: currentDate(),
        currentTime: currentTime(),
        fromAccountingLocation: `${selectedTask?.entityName} ${
          selectedTask?.associatedEntityName !== undefined ? selectedTask?.associatedEntityName : ""
        }`,
        toAccountingLocation: activeHeadingName.replace("(", " ").replace(")", " "),
        amount: selectedAmount,
      },
    });
  };

  return {
    transferDateTime,
    transferAmount,
    selectOption,
    activeHeadingName,
    listItems,
    setisDrawers,
    transferListIsLoading,
    isDrawers,
    handleCashDetails,
    clearFlag,
    invalidFund,
    invaildLimitFlag,
    warningFlag,
    disable,
    onSelectHandleChange,
    selectView,
    ReceiptPrint,
    setclearFlag,
    selectedAmount,
    enterAmount,
    handleValue,
    printData,
    setPrintData,
  };
};
