/* eslint-disable no-empty */
import { DiscrepencyData } from "@bbo/api/generator";
import { DISCREPANCY_STATUS } from "@bbo/constants";
import { useAppSelector } from "@bbo/lib";
import { useGlobalState } from "@bbo/lib/state";
import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DetailedTableView } from "./DetailedTableView";
import HighLevelPopUp from "./HighLevelPopUp";
import "./index.css";
import SettleMentPopUp from "./SettleMentPopUp";
import { useDiscrepancyAccount } from "./useDiscrepancyAccount";

interface iProps {
  DetailedViewData?: {
    column: unknown[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    rowIndex: number;
  };
}

export const DetailedView = ({ DetailedViewData }: iProps) => {
  const [, setDetailedViewModel] = useGlobalState("DetailedViewModel");
  const [showSettleDispute] = useGlobalState("showSettleDispute");
  const [disConfimBtn, setdisConfimBtn] = useState(true);
  const [comment, setComment] = React.useState<string>("");
  const [status, setStatus] = useState<string>("");
  const navigate = useNavigate();
  const [apiChange, setapiChange] = useGlobalState("apiChange");
  const [showPopUP, setShowPopUp] = useState<boolean>(false);
  const [totalVariance, setTotalVariance] = useState<number>(0);
  const [selectedRowDetails, setSelectedRowDetails] = useState([]);
  const { isDiscrepancyStatus, DiscrepancyApi } = useDiscrepancyAccount();
  const branchDetails = useAppSelector((root) => root.branchDetails);

  const branchIndependent = "IN";

  //cannot bind to updateDiscrepancy payload type as it will call transaction engine
  const updateDiscrepancyStatusRequestParms = [
    {
      discrepencyDataID: DetailedViewData?.data?.discrepencyDataID,
      status: status,
      comments: comment,
      TCReference: DetailedViewData?.data?.TCReference,
      accountReferenceID: DetailedViewData?.data?.accountReferenceID,
      clientReference: DetailedViewData?.data?.clientReference,
      reason: DetailedViewData?.data?.reason,
      varianceBalance: DetailedViewData?.data.varianceBalance,
      branchRefenceNumber: undefined,
      description: undefined,
      discrepencyDateTime: undefined,
      discrepencyName: undefined,
      narration: undefined,
      pouchNumber: undefined,
    },
  ];
  const SubmitComment = () => {
    // eslint-disable-next-line no-empty
    if (!disConfimBtn) {
      setDetailedViewModel({ showModel: true });
    }
  };
  const handleComment = (e) => {
    const value = e.target.value;
    setComment(value);
    setdisConfimBtn(false);
  };
  const handleUpdateCall = () => {
    DiscrepancyApi(updateDiscrepancyStatusRequestParms);
    setShowPopUp(false);
  };

  const handleStatus = (status) => {
    setSelectedRowDetails([DetailedViewData?.data]);
    const TotalVariance = [DetailedViewData?.data].reduce(
      (total, value) => total + Number(value?.entity?.itemDetails?.toDateVariance),
      0,
    );
    switch (status) {
      case "help":
        setStatus(DISCREPANCY_STATUS.HELP_REQUESTED);
        handleUpdateCall();
        break;
      case "settled":
        setStatus(DISCREPANCY_STATUS.SETTLED);
        setShowPopUp(true);
        setTotalVariance(TotalVariance);
        break;
      case "dispute":
        setStatus(DISCREPANCY_STATUS.DISPUTE_RAISED);
        handleUpdateCall();
        break;
      default:
        setStatus("");
        break;
    }
  };

  const handleExitPreview = () => {
    setDetailedViewModel({ showModel: true });
  };
  useLayoutEffect(() => {
    if (isDiscrepancyStatus === "success") {
      setDetailedViewModel({ showModel: true });
    }
  }, [isDiscrepancyStatus]);
  return (
    <div className="flex flex-col lg:flex-row w-full rounded">
      <div className="px-4 py-10 md:p-6 mx-auto w-full mainlist">
        <div className="flex justify-between ">
          <div className="DetailHeadingText pl-2 mb-3">{DetailedViewData?.data?.reason?.title}</div>
          {DetailedViewData.data.reason.code === "BP" && (
            <div
              className="DetailSideHeadingText"
              onClick={() => {
                navigate("/balancePeriod", {
                  replace: true,
                  state: {
                    from: "discrepancyAccount",
                    balancingId: DetailedViewData?.data?.discrepencyName,
                  },
                });
              }}>
              Go to Balance Period
            </div>
          )}
          {DetailedViewData.data.reason.code === "TP" && (
            <div
              className="DetailSideHeadingText"
              onClick={() => {
                navigate("/tradingPeriod", {
                  replace: true,
                  state: {
                    from: "discrepancyAccount",
                    balancingId: DetailedViewData?.data?.discrepencyName,
                  },
                });
              }}>
              Go to Trading Period
            </div>
          )}
        </div>
        <div className="flex w-full shadowDetailsView my-4v">
          <div className="border-0 w-2/5 overflow-y-auto  bg-white outline-none focus:outline-none p-4	">
            <DetailedTableView DetailedTable={DetailedViewData} />
          </div>
          <div className="border-0 w-3/5	  overflow-y-auto  bg-white outline-none focus:outline-none p-4	">
            <div className="m-4">
              <div className="commentStyleBtn " onClick={() => SubmitComment}>
                Comment
              </div>
              <textarea
                className=" w-full textArea shadow "
                rows={21}
                value={comment}
                onChange={handleComment}
                placeholder="Please provide comments for your records."></textarea>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-6">
          <div>
            <button
              className="accounting-button ml-2 ExitPreviewStyle"
              onClick={() => handleExitPreview()}>
              Exit Preview
            </button>
          </div>
          {showPopUP && branchDetails.branch_type !== branchIndependent && (
            <HighLevelPopUp
              selectedRow={selectedRowDetails}
              totalAmount={totalVariance}
              onSuccess={() => handleUpdateCall()}
              onCancel={() => setShowPopUp(false)}
            />
          )}
          {showPopUP && branchDetails.branch_type === branchIndependent && (
            <SettleMentPopUp
              totalAmount={totalVariance}
              onSuccess={() => handleUpdateCall()}
              onCancel={() => setShowPopUp(false)}
            />
          )}
          {DetailedViewData?.data.statusCode === DISCREPANCY_STATUS.HELP_CODE &&
            showSettleDispute.showSettleDisputeButton && (
              <div className="flex w-full justify-end">
                <button
                  className="brandWhiteBtn mr-2  font-bold RightalignBtn"
                  onClick={() => handleStatus("settled")}>
                  Settle
                </button>
                {DetailedViewData.data?.reason.id !== DISCREPANCY_STATUS.TC && (
                  <button
                    className="accounting-button ml-2 RightalignBtn"
                    onClick={() => handleStatus("dispute")}>
                    Dispute
                  </button>
                )}
              </div>
            )}
          {DetailedViewData?.data.statusCode === DISCREPANCY_STATUS.AWAITING_CODE &&
            showSettleDispute.showSettleDisputeButton && (
              <div className="flex w-full justify-end">
                {DetailedViewData.data?.reason.id === DISCREPANCY_STATUS.TC && (
                  <button
                    className="accounting-button ml-2 RightalignBtn"
                    onClick={() => handleStatus("dispute")}>
                    Dispute
                  </button>
                )}
                {DetailedViewData.data?.reason.id === DISCREPANCY_STATUS.TP ||
                DetailedViewData.data?.reason.id === DISCREPANCY_STATUS.BP ? (
                  <button
                    className="brandWhiteBtn mr-2  font-bold RightalignBtn"
                    onClick={() => handleStatus("help")}>
                    Help
                  </button>
                ) : (
                  ""
                )}
                <button
                  className="accounting-button ml-2 RightalignBtn"
                  onClick={() => handleStatus("settled")}>
                  Settle
                </button>
              </div>
            )}{" "}
          {DetailedViewData.data.statusCode === DISCREPANCY_STATUS.DISPUTE_CODE &&
            showSettleDispute.showSettleDisputeButton && (
              <div className="flex w-full justify-end">
                <button
                  className="accounting-button ml-2 RightalignBtn"
                  onClick={() => handleStatus("settled")}>
                  Settle
                </button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
