import { usePutCashManagementVarianceReport } from "@bbo/api/generator";
import { useGlobalState } from "@bbo/lib/state";
import { amountFormatter } from "@bbo/utils/currencyFormatter";
import React, { useEffect, useLayoutEffect, useState } from "react";
import Danger from "../../assets/images/Danger.png";

/**
 * Alert/Popup view with
 * @param props
 * @returns
 */
interface iProps {
  RequiredDisplay?: boolean;
  VarianceSelect?: any;
  RequiredData?: any;
  onCancel: () => void;
  onCommit: (params: any) => any;
  handleChangefunc?: string;
  onShow: () => void;
  resetCount?: number;
}
export const RequiredDetails = (props: iProps) => {
  const [RecordKnownVarianceAPI, setRecordKnownVarianceAPI] = useState<any>({
    Request: null,
  });

  const [KnownVarianceAPI, setKnownVarianceAPI] = useGlobalState("KnownVarianceAPI");
  const [, setvalueOfVariance] = useGlobalState("valueOfVariance");
  const [RequiredDetails, setRequiredDetails] = useGlobalState("requiredDetails");
  const [disableButton, setdisableButton] = useGlobalState("requiredDetailsdisableBtn");
  const [, setShow] = useGlobalState("requiredDetailsShow");

  const [apiError, setapiError] = useState<boolean>(false);
  const [, sethitapi] = useState<boolean>(false);
  const [valueOfForm, setValueOfForm] = useState<any>({});
  const {
    mutate: recordVarianceApi,
    data: recordVarianceResponse,
    status: recordVarianceStatus,
    error,
  } = usePutCashManagementVarianceReport();

  useLayoutEffect(() => {
    if (recordVarianceStatus === "error") {
      setShow({ requiredDetailsShow: true });
      setKnownVarianceAPI({ Error: error.response });
      setdisableButton({ requiredDetailsdisableBtn: true });
    }
    if (recordVarianceStatus === "success") {
      setShow({ requiredDetailsShow: false });
      setdisableButton({ requiredDetailsdisableBtn: false });
      setKnownVarianceAPI({ Success: recordVarianceResponse });
      if (props.onShow) {
        props.onShow();
      }
      const newValue = props.VarianceSelect;
      for (let i = 0; i < props.VarianceSelect.length; i++) {
        newValue[i].error = false;
        newValue[i].errorMsg = "";
      }
      setRequiredDetails([...newValue]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordVarianceResponse, recordVarianceStatus]);
  useEffect(() => {
    let count = 0;
    RequiredDetails.map((item) => {
      Object.keys(item).map((x) => {
        if (x === "value") {
          if (item.value !== "" || valueOfForm[item.id] !== "") {
            count++;
          } else {
            count--;
          }
        }
      });
      return 0;
    });

    if (count === RequiredDetails.length || props.resetCount > 0) {
      setdisableButton({ requiredDetailsdisableBtn: false });
    } else {
      setdisableButton({ requiredDetailsdisableBtn: true });
    }
  }, [RequiredDetails, valueOfForm]);

  useEffect(() => {
    if (KnownVarianceAPI?.Error?.data?.length !== 0) {
      setapiError(true);
    }
    const newValue = RequiredDetails;
    for (let i = 0; i < RequiredDetails.length; i++) {
      newValue[i].error = false;
      newValue[i].errorMsg = "";
    }
    setRequiredDetails([...newValue]);

    if (KnownVarianceAPI?.Error?.status === 409) {
      null;
    } else if (KnownVarianceAPI?.Error?.status === 400) {
      const newdata = KnownVarianceAPI?.Error?.data;
      if (!newdata.message) {
        newdata.map((error) => {
          for (let i = 0; i < RequiredDetails.length; i++) {
            if (error.id === newValue[i].id) {
              newValue[i].errorMsg = error.errorMessage;
            }
          }
        });
      }
    }
    setRequiredDetails([...newValue]);
  }, [KnownVarianceAPI.Error]);

  const HandleInput = (index, e) => {
    e.preventDefault();
    if (props.VarianceSelect) {
      const retainValue = valueOfForm;
      const newValue = props.VarianceSelect;
      if (newValue[index].id === "VV1" || newValue[index].id === "PV1") {
        newValue[index].value = amountFormatter(e.target.value);
        const key = newValue[index].id;

        retainValue[key] = amountFormatter(e.target.value);
      } else {
        const key = newValue[index].id;
        retainValue[key] = e.target.value;
        newValue[index].value = e.target.value;
      }
      if (newValue[index].value?.trim().length !== 0) {
        newValue[index].error = false;
      } else {
        setapiError(false);
        newValue[index].error = true;
      }

      setRequiredDetails([...newValue]);
      setValueOfForm(retainValue);
    }
  };
  const onCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
    const newValue = props.VarianceSelect;
    for (let i = 0; i < props.VarianceSelect.length; i++) {
      if (newValue[i].value) {
        newValue[i].value = "";
        newValue[i].error = false;
        newValue[i].errorMsg = "";
      }
    }
    setRequiredDetails([...newValue]);
    setapiError(false);
    setdisableButton({ requiredDetailsdisableBtn: true });
  };

  useEffect(() => {
    return () => {
      setdisableButton({ requiredDetailsdisableBtn: true });
    };
  }, []);

  /**
   * Currency conversion GBP pound symbol to Pound string
   * @param value string GBP Pound symbol
   * @returns Pound value
   */
  const inputInPound = (value: string): string => {
    if (value) {
      return value.replace(/[^0-9]+/g, "");
    }
    return "0";
  };

  const hitApi = () => {
    sethitapi(true);
    if (!disableButton.requiredDetailsdisableBtn || props.resetCount > 0) {
      const formDetails = RequiredDetails.map((item) => {
        if (valueOfForm[item.id] !== "") {
          if (item.id === "VV1" || item.id === "PV1") {
            return { ...item, value: inputInPound(valueOfForm[item.id]) };
          }
          return { ...item, value: valueOfForm[item.id] };
        } else {
          if (item.id === "VV1" || item.id === "PV1") {
            return { ...item, value: inputInPound(item.value) };
          }
          return item;
        }
      });

      recordVarianceApi({
        data: {
          requiredDetails: formDetails,
          id: props.RequiredData.id,
          varianceType: props.RequiredData.varianceType,
        },
      });
    }
  };
  const onCommit = () => {
    const mValue = props.VarianceSelect;

    let mValueOfVariance = "";
    let branchReferenceNumber = "";
    let pouchNumber = "";
    let sessionID = "";
    for (let i = 0; i < RequiredDetails.length; i++) {
      if (mValue[i].id === "VV1") {
        mValueOfVariance = mValue[i].value;
      }
      if (mValue[i].id === "BSRN1") {
        branchReferenceNumber = mValue[i].value;
      }
      if (mValue[i].id === "PN1") {
        pouchNumber = mValue[i].value;
      }
      if (mValue[i].id === "SID1") {
        sessionID = mValue[i].value;
      }
      setvalueOfVariance({
        Variance: mValueOfVariance,
        pouchNumber: pouchNumber,
        branchReferenceNumber: branchReferenceNumber,
        sessionID: sessionID,
      });
      // mValue[i].value = "";
      mValue[i].error = false;
      mValue[i].errorMsg = "";
    }
    if (props.onCommit) {
      props.onCommit({
        valueOfVariance: mValueOfVariance,
      });
    }

    setRequiredDetails([...mValue]);
    setapiError(false);
    setdisableButton({ requiredDetailsdisableBtn: true });
  };
  useEffect(() => {
    if (props?.handleChangefunc === "commit") {
      onCommit();
    }
    if (props?.handleChangefunc === "cancel") {
      onCancel();
    }
  }, [props?.handleChangefunc]);
  return (
    <>
      <h2 className="mainHeadingRecord mb-1"> Required Details</h2>
      {props?.RequiredDisplay && (
        <div
          onKeyPress={(e) =>
            e.key === "Enter" && disableButton.requiredDetailsdisableBtn && hitApi()
          }>
          {props.VarianceSelect?.map((item, index) => (
            <React.Fragment key={index * 3 + 5}>
              <label className="mt-6 subHeadRecord float-left w-11/12" key={item.title}>
                {item.title}
              </label>

              {apiError ? (
                item.errorMsg && (
                  <div className="col-1 grid mt-2 float-left w-11/12" key={index + index + 2}>
                    <p className="errorTextRecord inline-flex">
                      {item.errorMsg}{" "}
                      <img className="pl-2 dangerIcon" src={Danger} alt="DangerIcon" />
                    </p>
                  </div>
                )
              ) : item?.error ? (
                <div className="col-1 grid mt-2 float-left w-11/12" key={index * 4 + 1}>
                  <p className="errorTextRecord inline-flex">
                    Please provide details{" "}
                    <img className="pl-2 dangerIcon" src={Danger} alt="DangerIcon" />
                  </p>
                </div>
              ) : (
                ""
              )}

              <input
                className={`px-3 py-3 my-3 w-full text-lg inputCash inputRecordfield ${
                  item?.error ? "paidTextAreaError" : "paidTextArea"
                }`}
                key={index}
                value={valueOfForm[item.id] || item.value || ""}
                name={item.title}
                id={item.id}
                placeholder={item.placeholder}
                onChange={(e) => {
                  HandleInput(index, e);
                }}
                onBlur={(e) => {
                  hitApi();
                  HandleInput(index, e);
                }}
              />
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
};
