import Accordion from "@bbo/components/Accordion";
import { ReceiptPrint } from "@bbo/components/PrintView";
import { useGlobalState } from "@bbo/lib/state";
import { amountInPound, amountPound } from "@bbo/utils/currencyFormatter";
import { getonlyDateTime, getTransferDateTime } from "@bbo/utils/dateTimeFormatter";
import { useEffect } from "react";
import "./index.css";
ReceiptPrint;
export default function CashBalanceDetailPopup(props) {
  const [cashBalanceModal, setCashBalanceModal] = useGlobalState("cashBalanceModal");
  const unUseNote = "Unusable Notes";
  const unUseCoin = "Unusable Coins";
  const [isPreparedActive, setIsPreparedActive] = useGlobalState("isPreparedActive");
  const [isSuspenedActive, setIsSuspenedActive] = useGlobalState("isSuspenedActive");
  const [isActivitiesActive, setIsActivitiesActive] = useGlobalState("isActivitiesActive");

  useEffect(() => {
    setCashBalanceModal({ cashBalanceModal: props.data.showModal });
  }, [props.data]);
  const CashText = (cash) => {
    if (cash !== unUseNote && cash !== unUseCoin) {
      if (cash.includes("£")) {
        return <span> £{cash.split("£")[0]} Note</span>;
      } else {
        return <span> {cash.split("p")[0]}p Coin </span>;
      }
    } else {
      return <span> {cash} </span>;
    }
  };
  const preparedPouches = (
    <table className="dynamictable w-full border-collapse mb-5">
      <thead>
        <tr className="h-14 text-white">
          <th className="text-left text-lg font-bold ">Pouch Type</th>
          <th className="text-left text-lg font-bold ">Pouch ID</th>
          <th className="text-left text-lg font-bold ">Value</th>
        </tr>
      </thead>
      <tbody>
        {props.data.data?.preparedPouches?.map((item, index) => (
          <tr key={index}>
            <td>{item.pouchTypeName}</td>
            <td>{item.pouchID}</td>
            <td>£{amountInPound(item.totalPouchValue)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const suspendPouches = (
    <table className="dynamictable w-full border-collapse mb-5">
      <thead>
        <tr className="h-14 text-white">
          <th className="text-left text-lg font-bold">Pouch Type</th>
          <th className="text-left text-lg font-bold">Pouch ID</th>
          <th className="text-left text-lg font-bold">Value</th>
        </tr>
      </thead>
      <tbody>
        {props.data.data?.suspendedPouches?.map((item, index) => (
          <tr key={index}>
            <td>{item.pouchTypeName}</td>
            <td>{item.pouchID}</td>
            <td>£{amountInPound(item.totalPouchValue)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
  const transferActivities = (
    <table className="dynamictable w-full border-collapse mb-5">
      <thead>
        <tr className="h-14 text-white">
          <th className="text-left text-lg font-bold">User Name</th>
          <th className="text-left text-lg font-bold">Activity</th>
          <th className="text-left text-lg font-bold">Value</th>
        </tr>
      </thead>
      <tbody>
        {props.data.data?.transferActivities?.map((item, index) => {
          if (!item.operationType.includes("Pouch")) {
            return (
              <tr key={index}>
                <td>{item.userName}</td>
                <td>{item.operationType}</td>
                <td>£{amountInPound(item.transferAmount)}</td>
              </tr>
            );
          }
        })}
      </tbody>
    </table>
  );

  const PreparedActivetoggle = () => {
    if (isPreparedActive.isPreparedActive === false) {
      setIsPreparedActive({
        isPreparedActive: true,
      });
    } else if (isPreparedActive.isPreparedActive === true) {
      setIsPreparedActive({
        isPreparedActive: true,
      });
    }
  };
  return (
    <>
      {cashBalanceModal?.cashBalanceModal && (
        <>
          <div className="flex items-center justify-center rounded-b cashBalancePopup">
            {props.data.showModal && (
              <table className="w-full BalanceTable mb-5">
                <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td colSpan={2}>
                      <b className="text-color text-base">Accounting Location</b>
                    </td>
                    <td colSpan={3} className="text-color text-base">
                      {props.data.data.entity?.entityName}
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={2}>
                      <b className="text-color text-base">User Name</b>
                    </td>
                    <td colSpan={3} className="text-color text-base">
                      {props.data.data.entity?.userName}
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={2}>
                      <b className="text-color text-base">Smart ID</b>
                    </td>
                    <td colSpan={3} className="text-color text-base">
                      {props.data.data.entity?.smartID}
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={2}>
                      <b className="text-color text-base">Date & Time</b>
                    </td>
                    <td colSpan={3} className="text-color text-base">
                      {getonlyDateTime(props.data.data.entity?.itemDetails?.lastBalancedDateTime)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <b className="text-color text-base">Variance</b>
                    </td>
                    <td colSpan={3} className="text-color text-base">
                      {amountPound(props.data.data.entity?.itemDetails?.currentVariance)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <b className="text-color text-base">Status </b>
                    </td>
                    <td colSpan={3} className="text-color text-base">
                      {props.data.data?.entity?.status}
                    </td>
                  </tr>

                  <tr className="DenominationHead">
                    <td>
                      <b>Denomination</b>
                    </td>
                    <td></td>
                    <td>
                      <b>Quantity</b>
                    </td>
                    <td></td>
                    <td>
                      <b>Value</b>
                    </td>
                  </tr>
                  {props?.data?.data?.entity?.itemDetails?.denominationLadder?.map(
                    (item, index) => {
                      return (
                        <tr key={index}>
                          <td>{CashText(item?.denomination)}</td>
                          <td>X</td>
                          <td>{item?.looseCoin}</td>
                          <td>=</td>
                          <td>{amountInPound(item?.value)}</td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            )}
          </div>
          <Accordion
            title="Prepared Pouches in Safe"
            content={preparedPouches}
            feature={"adhocBalance"}
            onToggle={() => {
              PreparedActivetoggle();
            }}
          />
          <Accordion
            title="Suspend Pouches in Safe"
            content={suspendPouches}
            feature={"adhocBalance"}
            onToggle={() => {
              setIsSuspenedActive({
                isSuspenedActive: !isSuspenedActive,
              });
            }}
          />
          <Accordion
            title="Activities between Balances"
            content={transferActivities}
            feature={"adhocBalance"}
            onToggle={() => {
              setIsActivitiesActive({
                isActivitiesActive: !isActivitiesActive,
              });
            }}
          />
        </>
      )}
    </>
  );
}
