import Loader from "@bbo/components/Loader";
import { useGlobalState } from "@bbo/lib/state";
import { useEffect, useState } from "react";
import { ALL, DISCREPENCY_OVERVIEW_TABS } from "./constant";
import { DetailedView } from "./DetailedView";
import { DiscrepancyOverview } from "./DiscrepancyOverView";
import HeaderTabs from "./HeaderTabs";
import "./index.css";
import { useDiscrepancyAccount } from "./useDiscrepancyAccount";

export const DiscrepancyAccount = () => {
  const [tabLabels] = useState(DISCREPENCY_OVERVIEW_TABS);
  const [selectedTab, setSelectedTab] = useState(DISCREPENCY_OVERVIEW_TABS[0].value);
  const [DetailedViewModel, setDetailedViewModel] = useGlobalState("DetailedViewModel");
  const [, setSummaryTab] = useGlobalState("summaryTab");
  const { isStatus } = useDiscrepancyAccount();

  useEffect(() => {
    return () => {
      setDetailedViewModel({ showModel: true });
    };
  }, []);

  const handleSelectTab = (params) => {
    setSelectedTab(params.value);
    setSummaryTab(ALL);
  };

  return (
    <>
      {DetailedViewModel.showModel ? (
        isStatus ? (
          <Loader />
        ) : (
          <>
            {" "}
            <HeaderTabs
              tabLabels={tabLabels}
              selected={selectedTab}
              onSelectTab={handleSelectTab}
              isSummary={false}
            />
            <DiscrepancyOverview selectedTab={selectedTab} />
          </>
        )
      ) : (
        <DetailedView DetailedViewData={DetailedViewModel.params} />
      )}{" "}
    </>
  );
};
