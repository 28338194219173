import ProgressBar from "@bbo/components/ProgressBar";
import { useGlobalState } from "@bbo/lib";
import { setSelectedTask } from "@bbo/lib/reduxStore/selectedTask.slice";
import { amountSignedPound, currencyStyle } from "@bbo/utils/currencyFormatter";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GREENCODE, REDCODE } from "../constants";
import "./index.css";
type Props = {
  selectedValue: any;
  fromHistory?: any;
  fromDiscrepancyAccount?: boolean;
};
export const BalanceDetailSafeOverview = ({
  selectedValue,
  fromHistory,
  fromDiscrepancyAccount,
}: Props) => {
  const navigate = useNavigate();
  const [color, setColor] = useState<string>(REDCODE);
  const [text, setText] = useState<string>("Awaiting Action");
  const [recount, setRecount] = useState<boolean>(false);
  const [, setrouteState] = useGlobalState("routeState");
  const [, setPopupData] = useGlobalState("PopupData");
  const [, setTPRecount] = useGlobalState("TPRecount");
  const [, setIsbalancecheck] = useGlobalState("Isbalancecheck");
  const [, setIsReadonlyMode] = useGlobalState("isReadonlyMode");
  const dispatch = useDispatch();

  const CompleteBalance = (data) => {
    const mData = {
      head: data.entityName,
      data: data as any,
      index: "",
    };
    dispatch(
      setSelectedTask({
        fadcode: mData.data?.fadcode,
        entity: mData.data?.entity,
        entityID: mData.data?.entityID,
        entityName: mData.data?.entityName,
        itemDetails: mData.data?.ItemDetails,
      }),
    );
    setPopupData(mData);
    navigate("/cashBalance", { replace: true });
    if (fromHistory || fromDiscrepancyAccount) {
      setIsReadonlyMode({ isReadonlyMode: true });
      setrouteState({ RouteState: "TpBpHistory" });
    } else setrouteState({ RouteState: "BalancePeriodCashBalance" });
    setTPRecount({ TPRecount: false });
  };

  const recountFunc = (data) => {
    const mData = {
      head: data.entityName,
      data: data as any,
      index: "",
    };
    dispatch(
      setSelectedTask({
        fadcode: mData.data?.fadcode,
        entity: mData.data?.entity,
        entityID: mData.data?.entityID,
        entityName: mData.data?.entityName,
        itemDetails: mData.data?.ItemDetails,
      }),
    );
    setPopupData(mData);
    navigate("/cashBalance", { replace: true });
    setrouteState({ RouteState: "BalancePeriodCashBalance" });
    setTPRecount({ TPRecount: true });
    // setIsbalancecheck({ Isbalancecheck: false });
  };

  useEffect(() => {
    if (selectedValue) {
      if (fromHistory || fromDiscrepancyAccount) {
        setColor(GREENCODE);
        setRecount(false);
        setText("Completed");
      } else if (selectedValue.entity === "safe") {
        if (selectedValue?.balancePeriodDateTime) {
          setColor(GREENCODE);
          setRecount(true);
          setText("Completed");
        } else {
          setColor(REDCODE);
          setRecount(false);
          setText("Awaiting Action");
        }
      } else {
        if (selectedValue?.itemDetails?.balancePeriodDateTime) {
          setColor(GREENCODE);
          setRecount(true);
          setText("Completed");
        } else {
          setColor(REDCODE);
          setRecount(false);
          setText("Awaiting Action");
        }
      }
    }
  });
  return (
    <>
      <div className="w-full py-4">
        <div className="w-full balanceTpTable overflow-y-auto">
          <table className="table-fixed dynamictable w-full border-collapse">
            <thead>
              <tr className="h-14">
                <th className="text-left w-1/5 text-lg font-bold textColor">Commodity</th>
                <th className="text-left w-3/6 text-lg font-bold textColor">Status</th>
                <th className="text-left width-15 text-lg font-bold textColor">Variance</th>
                <th className="text-left width-15 text-lg font-bold textColor">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-20">
                <td className="sideTitle">Cash</td>
                <td>{<ProgressBar bgcolor={color} progress="100" reason={text} />}</td>
                {fromHistory || fromDiscrepancyAccount ? (
                  <td className={`valueDot ${currencyStyle(selectedValue?.varianceAmount)}`}>
                    {amountSignedPound(selectedValue?.varianceAmount)}
                  </td>
                ) : (
                  <td
                    className={`valueDot ${
                      selectedValue?.entity === "safe"
                        ? currencyStyle(selectedValue?.toDateVariance)
                        : currencyStyle(selectedValue?.itemDetails?.toDateVariance)
                    }`}>
                    {selectedValue?.entity === "safe"
                      ? amountSignedPound(selectedValue?.toDateVariance)
                      : amountSignedPound(selectedValue?.itemDetails?.toDateVariance)}
                  </td>
                )}
                {(fromHistory || fromDiscrepancyAccount) && (
                  <td className="linkBtnHighLevel" onClick={() => CompleteBalance(selectedValue)}>
                    Completed
                  </td>
                )}
                {!fromHistory && !fromDiscrepancyAccount && recount ? (
                  <td className="linkBtnHighLevel" onClick={() => recountFunc(selectedValue)}>
                    Recount
                  </td>
                ) : (
                  <td className="linkBtnHighLevel" onClick={() => CompleteBalance(selectedValue)}>
                    Complete Balance
                  </td>
                )}
              </tr>
              <tr>
                <td className="sideTitle">Forex</td>
                <td>{<ProgressBar bgcolor={GREENCODE} progress="100" reason="Completed" />}</td>
                <td className="valueDot greenLabel">+£300.00</td>
                <td className="linkBtnHighLevel">
                  {fromHistory || fromDiscrepancyAccount ? "Completed" : "Recount"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
