import React from "react";

type Props = {
  tabLabels: string[];
  varianceAmount?: number;
  selected: string;
  onSelectTab: (params) => void;
  loadNextData: () => void;
  loadPreviousData: () => void;
  totalPages: number;
  currentPage: number;
};

const HeaderTabs = ({
  varianceAmount,
  tabLabels,
  selected,
  onSelectTab,
  loadNextData,
  loadPreviousData,
  totalPages,
  currentPage,
}: Props) => {
  const handleTabClick = (value) => {
    onSelectTab({ name: value });
  };
  const [isEndOfLeftScrollInDetailView, setIsEndOfLeftScrollInDetailView] =
    React.useState<boolean>(false);
  const [isEndOfRightScrollInDetailView, setIsEndOfRightScrollInDetailView] =
    React.useState<boolean>(true);

  return (
    <div className="horizontal-tab-list">
      {currentPage > 1 && (
        <i className="fas fa-chevron-left left text-xl" onClick={loadPreviousData} />
      )}
      <div className="horizontal-tab-list-inner">
        <ul>
          {tabLabels.map((value) => (
            <li
              key={value}
              className={`tab-listview-trading-period ${selected === value ? " active" : ""}`}
              onClick={() => handleTabClick(value)}>
              {value}
            </li>
          ))}
        </ul>
      </div>
      {currentPage !== totalPages && (
        <i className="fas fa-chevron-right right text-xl" onClick={loadNextData} />
      )}
    </div>
  );
};

export default HeaderTabs;
