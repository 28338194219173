/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

PO Transactions

PO Transactions Viewer

Cash Drawer Association API
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { BranchBalancing, CashManagementApiResponse, GetBPTPListParams } from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Get list of BP/TP.
 * @summary get BP/TP list
 */
export const useGetBPTPListHook = () => {
  const getBPTPList = useCustomInstance<BranchBalancing[]>();

  return (fadcode: string, params?: GetBPTPListParams) => {
    return getBPTPList({
      url: `/cash_management/branch-balance/${fadcode}`,
      method: "get",
      params,
    });
  };
};

export const getGetBPTPListQueryKey = (fadcode: string, params?: GetBPTPListParams) => [
  `/cash_management/branch-balance/${fadcode}`,
  ...(params ? [params] : []),
];

export type GetBPTPListQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetBPTPListHook>>
>;
export type GetBPTPListQueryError = ErrorType<CashManagementApiResponse | void>;

export const useGetBPTPList = <
  TData = AsyncReturnType<ReturnType<typeof useGetBPTPListHook>>,
  TError = ErrorType<CashManagementApiResponse | void>,
>(
  fadcode: string,
  params?: GetBPTPListParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<ReturnType<typeof useGetBPTPListHook>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetBPTPListQueryKey(fadcode, params);

  const getBPTPList = useGetBPTPListHook();

  const queryFn: QueryFunction<AsyncReturnType<ReturnType<typeof useGetBPTPListHook>>> = () =>
    getBPTPList(fadcode, params);

  const query = useQuery<AsyncReturnType<ReturnType<typeof useGetBPTPListHook>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!fadcode, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};
