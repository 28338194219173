import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { amountUnSignedPound } from "@bbo/utils/currencyFormatter";
import { getTransferDateTime } from "@bbo/utils/dateTimeFormatter";

export const LeftSideView = ({ transferDateTime, transferAmount }) => {
  const { entity, entityName, itemDetails, associatedEntityName, transferAmt, transferAmtDate } =
    useAppSelector((root) => root.selectedTask);

  const { userName } = useAppSelector((root) => root.userName);

  return (
    <div className="pt-6 pl-8">
      <h2 className="heading-3 font-bold mb-5">Cash Transfer From</h2>
      <div className="w-full CashTransfer bg-white transferOutHeight">
        <table className="w-full table-fixed transfer-out-table height-95 border-none">
          <tbody>
            {entity === "safe" ? (
              <tr>
                <td>
                  <p className="heading">Terminal</p>
                  <p className="text-2xl font-bold">Safe</p>
                </td>
              </tr>
            ) : (
              <tr>
                <td>
                  <p className="heading">Terminal</p>
                  <p className="text-2xl font-bold">{entityName}</p>
                </td>
              </tr>
            )}
            <tr>
              <td>
                <p className="heading">User Logged In</p>
                <p className="text-2xl font-bold break-words">{userName}</p>
              </td>
            </tr>

            {entity === "safe" ? (
              <>
                <tr>
                  <td>
                    <p className="heading">Attached Cash Drawer</p>
                    <p className="text-2xl font-bold">{entityName}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="heading">Last Transferred Amount</p>
                    <p className="text-2xl font-bold">
                      {amountUnSignedPound(transferAmount || transferAmt)}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="heading">Last Transferred Date & Time</p>
                    <p className="text-2xl font-bold">
                      {getTransferDateTime(Number(transferDateTime || transferAmtDate))}
                    </p>
                  </td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td>
                    <p className="heading">Attached Cash Drawer</p>
                    <p className="text-2xl font-bold">{associatedEntityName}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="heading">Last Transferred Amount</p>
                    <p className="text-2xl font-bold">
                      {amountUnSignedPound(Number(transferAmount || itemDetails.transferAmt))}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="heading">Last Transferred Date & Time</p>
                    <p className="text-2xl font-bold">
                      {getTransferDateTime(Number(transferDateTime || itemDetails.transferAmtDate))}
                    </p>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
