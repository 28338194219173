import { AtmDetails } from "@bbo/api/generator";
import { amountSignedPound, currencyStyle } from "@bbo/utils/currencyFormatter";
import { formatDateYearMonthDay } from "@bbo/utils/dateTimeFormatter";
import { useState } from "react";
import { ActionList } from "./ActionList";

type Props = {
  header: string;
  data: AtmDetails;
};

const AtmItem = ({ data, ...rest }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
  };

  if (data) {
    const actionData = { ...data, currentVariance: data.lastVariance };
    return (
      <>
        <div
          className="accounting-card widthAccounting inline-block mr-8"
          onClick={() => setShowModal(true)}>
          <h2 className="account-group mb-1">ATM</h2>
          <div className="main bg-white Safe w-full cursor-pointer block mr-8 inline-block cursor-pointer">
            <table className="w-full border-none">
              <tbody>
                <tr className={currencyStyle(data?.lastVariance)}>
                  <td>Last Variance</td>
                  <td>
                    <b>{amountSignedPound(data?.lastVariance)}</b>
                  </td>
                </tr>
                <tr className="border_bottom">
                  <td>To Date Variance</td>
                  <td>
                    <b>{amountSignedPound(data?.toDateVariance)}</b>
                  </td>
                </tr>
                <tr>
                  <td>Last Balance Date </td>
                  <td>
                    <b>{formatDateYearMonthDay(data?.lastBalancedDateTime)}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {showModal && <ActionList data={actionData} header={rest.header} onClose={handleClose} />}
      </>
    );
  }

  return null;
};

export default AtmItem;
