import { apiConfig } from "@bbo/config";
import { useAppDispatch } from "@bbo/lib";
import { logoutFederatedUser } from "@bbo/lib/reduxStore/auth.slice";
import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const baseURL = apiConfig.baseApi;
export const AXIOS_INSTANCE = Axios.create({ baseURL });

export const useCustomInstance = <T>(): ((config: AxiosRequestConfig) => Promise<T>) => {
  const dispatch = useAppDispatch();

  AXIOS_INSTANCE.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        dispatch(logoutFederatedUser());
      }
      return Promise.reject(error);
    },
  );
  return (config: AxiosRequestConfig) => {
    const source = Axios.CancelToken.source();
    const promise = AXIOS_INSTANCE({
      ...config,
      cancelToken: source.token,
    }).then((res) => {
      const { data } = res;
      return data;
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    promise.cancel = () => {
      source.cancel("Query was cancelled by React Query");
    };

    return promise;
  };
};

interface AxiosError<T> {
  config: AxiosRequestConfig;
  code?: string;
  request?: unknown;
  response?: AxiosResponse<T>;
}

export type ErrorType<Error> = AxiosError<Error>;
