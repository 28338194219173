import { ATM, CASH_DRAWERS, COUNTERS, SAFE } from "@bbo/constants";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import useBranchApi from "@bbo/lib/hooks/useBranchLogsApi";
import { useGlobalState } from "@bbo/lib/state";
import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AtmItem from "./AtmItem";
import CashDrawerList from "./CashDrawerList";
import CounterList from "./CounterList";
import LimitWarningPopUp from "./LimitWarningPopUp";
import SafeItem from "./SafeItem";

type LocationState = { showLimit: boolean };
export const Overview = ({ shouldShowAtmItem = true }: { shouldShowAtmItem?: boolean }) => {
  const cashList = useAppSelector((root) => root.cashDetails);

  const [PopupData] = useGlobalState("PopupData");
  const [lastBalanceInLogs, setLastBalanceInLogs] = useGlobalState("lastBalanceInLogs");
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [maxLimitCounter, setMaxLimitCounter] = useGlobalState("maxLimitCounter");
  const [lastBalanceOnEscape, setLastBalanceOnEscape] = useState(0);
  const [status, setStatus] = useState("");
  const location = useLocation();
  const navigation = useNavigate();
  const [userName] = useGlobalState("userName");
  const showLimit = location?.state as LocationState;
  const saveCashRequestParams = {
    status: status,
    ...PopupData,
  };
  const requestPayload = PopupData?.data.itemDetails?.currentVariance && {
    status: status,
    fadcode: PopupData?.data?.fadcode,
    dateTime: 12312,
    username: userName.attributes.name,
    smartID: userName.attributes.preferred_username,
    entity: {
      entityName: PopupData.data?.entityName,
      sequenceNo: 1,
      entityID: PopupData.data?.entityID,
      entity: PopupData.data?.associatedEntityName,
      fadcode: PopupData?.data.fadcode,
      itemDetails: {
        balance: status === "escape_cash_transfer" ? lastBalanceOnEscape : lastBalanceInLogs,
        lastBalancedDateTime: PopupData.data.lastBalancedDateTime,
        toDateVariance: PopupData?.data.toDateVariance,
        currentVariance: PopupData?.data.itemDetails.currentVariance,
      },
    },
  };

  const [{ data: postLogsData, status: postLogsStatus }, postLogs] = useBranchApi(
    "",
    { fadcode: PopupData.data.fadcode },
    requestPayload,
    "POST",
    requestPayload,
  );

  useEffect(() => {
    if (showLimit?.showLimit) {
      if (
        PopupData?.data.itemDetails?.balance >= maxLimitCounter &&
        PopupData?.data.itemDetails?.balance - lastBalanceInLogs >= 500
      ) {
        setShowPopUp(true);
      }
      navigation("../branchOverview", {
        replace: true,
      });
    }
  }, []);

  const handleContinue = async (status) => {
    if (status === "continue") {
      setStatus("escape_cash_transfer");
      setLastBalanceOnEscape(PopupData?.data.itemDetails?.balance);
      postLogs("postLogsDetails");
      await postLogsData;
    } else {
      setStatus("continue_cash_transfer");
      postLogs("postLogsDetails");
      await postLogsData;
    }
  };

  useLayoutEffect(() => {
    if (postLogsData !== typeof undefined && postLogsStatus === "fetched") {
      // setLastBalanceInLogs(getLogsData[0].entity.item_details.last_balance);
      // navigate(URL);
      if (status === "escape_cash_transfer") {
        setShowPopUp(false);
      } else {
        navigation("../transferOut", {
          replace: true,
          state: { to: "safe_1", from: PopupData.data.entityID },
        });
      }
    }
  }, [postLogsData, postLogsStatus]);

  return (
    <>
      {showPopUp && (
        <LimitWarningPopUp
          tickicon={false}
          onSuccess={() => {
            handleContinue("transfer");
            navigation("../transferOut", { replace: true });
          }}
          onCancel={() => handleContinue("continue")}
        />
      )}
      <CounterList header={COUNTERS} items={cashList.counterTerminal} />
      <hr className="border-black border-bottom-none mb-5 w-full" />
      <div className="w-full accounting-card block float-left h-56 overflow-x-auto whitespace-nowrap">
        <SafeItem header={SAFE} data={cashList.safe} />
        {shouldShowAtmItem ? <AtmItem header={ATM} data={cashList.atm} /> : null}
      </div>
      <hr className="border-black border-bottom-none mb-5 w-full" />
      <CashDrawerList header={CASH_DRAWERS} items={cashList.cashDrawer} />
    </>
  );
};
