import "@fortawesome/fontawesome-free/css/all.min.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Auth } from "aws-amplify";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "../src/lib";
import App from "./App";
import "./assets/css/app.css";
import "./assets/css/tailwind.css";
import apiConfig from "./config";
import reportWebVitals from "./reportWebVitals";

const queryClient = new QueryClient();

Auth.configure(apiConfig.awsBranchBackOfficeConfig);
const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <App />
        </Router>
      </QueryClientProvider>
    </ReduxProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
