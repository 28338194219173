import PouchPreparationFeature from "@bbo/features/PouchPreparation/PouchPreparationFeature";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { useEffect } from "react";

const PouchPreparation = () => {
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Pouch Preparation");
  }, [setTitle]);

  return (
    <div>
      <PouchPreparationFeature />
    </div>
  );
};
export default PouchPreparation;
