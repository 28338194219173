import {
  useGetBPTPList,
  useGetBusinessRulesByBranchId,
  useGetCashTransactionDetails,
} from "@bbo/api/generator";
import Loader from "@bbo/components/Loader";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { TradingPeriodScreen } from "@bbo/features/TradingPeriod";
import { useAppSelector } from "@bbo/lib";
import { setBpTpDetails } from "@bbo/lib/reduxStore/BpTp.slice";
import { substractNumberOfDaysFromDate } from "@bbo/utils/dateTimeCalculation";
import { convertUnixTimeStampToDate, getOnlyDateDiscrepancy } from "@bbo/utils/dateTimeFormatter";
import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const TradingPeriod = () => {
  const { fadCode } = useAppSelector((root) => root.auth.authUserData);
  const location = useLocation();
  const [bpTpCommitttedData, setBpTpCommitttedData] = useState<any>();
  const { setTitle } = useTitle();
  const [fromHistory, setFromHistory] = useState<boolean>(false);
  const [fromDiscrepancyAccount, setFromDiscrepancyAccount] = useState<boolean>(false);
  const selectedTpBpHistoryRow = localStorage.getItem("selectedTpBpHistoryRow");
  const [isCtpButtonVisiblityInRange, setIsCtpButtonVisiblityInRange] = useState<boolean>();
  const { data: cashData, status: CashStatus } = useGetCashTransactionDetails("1");
  const parsm = {
    rule_type: "closingTradingPeriod",
  };
  const { data: closeTradePeriodRules, status: closeTradePriodRulesStatus } =
    useGetBusinessRulesByBranchId(fadCode, parsm);
  const [isTradingPeriodCompleted, setIsTradingPeriodCompleted] = useState<boolean>(false);
  const [param, setparam] = useState<any>();
  const [balancingId, setBalancingId] = useState<string>("");
  const { data: lastCommittedData, status: lastCommittedDataStatus } = useGetBPTPList(param);
  const dispatch = useDispatch();
  const titleSelector = () => {
    return `${JSON.parse(selectedTpBpHistoryRow)?.operationType} ${getOnlyDateDiscrepancy(
      JSON.parse(selectedTpBpHistoryRow)?.dateTime,
    )} - History`;
  };

  useEffect(() => {
    if (typeof balancingId === "string" && balancingId.length > 0) {
      setparam(fadCode);
    }
  }, [balancingId]);

  useEffect(() => {
    if (lastCommittedDataStatus === "success") {
      lastCommittedData.filter((item) => {
        if (item.name === location.state["balancingId"]) {
          setTitle(`${item?.operationType} ${getOnlyDateDiscrepancy(item?.dateTime)} - History`);
          setBpTpCommitttedData(item);
        }
      });
    }
  }, [lastCommittedData, lastCommittedDataStatus]);

  useEffect(() => {
    if (location?.state !== null) {
      const from = location.state["from"];
      if (from) {
        if (from === "TpBpHistory") {
          setFromHistory(true);
          setTitle(titleSelector());
        } else if (from === "discrepancyAccount") {
          setBalancingId(location.state["balancingId"]);
          setFromDiscrepancyAccount(true);
          setTitle(titleSelector());
        }
      }
    } else {
      setFromHistory(false);
      setFromDiscrepancyAccount(false);
      setTitle("Trading Period");
    }
  }, [location]);

  useEffect(() => {
    dispatch(setBpTpDetails(cashData));
  }, [cashData]);

  useLayoutEffect(() => {
    if (closeTradePriodRulesStatus === "success") {
      if (closeTradePeriodRules.length > 0) {
        const closeTradePeriodDate = convertUnixTimeStampToDate(
          closeTradePeriodRules[0].closingTradingPeriod,
        );

        const oneWeekBackDate = substractNumberOfDaysFromDate(7, closeTradePeriodDate);
        const currentDate = new Date();

        if (currentDate < closeTradePeriodDate && currentDate > oneWeekBackDate) {
          setIsCtpButtonVisiblityInRange(true);
        } else if (currentDate < oneWeekBackDate) {
          setIsCtpButtonVisiblityInRange(false);
          // setIsTradingPeriodCompleted(false);
        } else {
          setIsCtpButtonVisiblityInRange(false);
          // setIsTradingPeriodCompleted(true);
        }
      }
    }
  }, [closeTradePriodRulesStatus]);

  return (
    <>
      {CashStatus !== "success" ? (
        <Loader />
      ) : (
        <TradingPeriodScreen
          isCtpButtonVisiblityInRange={isCtpButtonVisiblityInRange}
          isTradingPeriodCompleted={isTradingPeriodCompleted}
          fromHistory={fromHistory}
          fromDiscrepancyAccount={fromDiscrepancyAccount}
          selectedRow={
            fromDiscrepancyAccount ? bpTpCommitttedData : JSON.parse(selectedTpBpHistoryRow)
          }
        />
      )}
    </>
  );
};

export default TradingPeriod;
