/**
 * Alert/Popup view with
 * @param props
 * @returns
 */
import Warning from "../../assets/images/Warning 1.svg";

import "@bbo/screens/Index.css";

export interface iProps {
  title?: string;
  header?: string;
  content?: string;
  labelCancel?: string;
  labelSuccess?: string;
  logoIcon?: string;
  renderLinkLabel?: string;
  onSuccess?: () => void;
  onCancel?: () => void;
  onRenderLink?: () => void;
  tickicon: boolean;
}

const PopupConfirm = ({
  title,
  header,
  content,
  labelCancel,
  labelSuccess,
  logoIcon,
  renderLinkLabel,
  onSuccess,
  onCancel,
  onRenderLink,
  tickicon,
}: iProps) => {
  const onSuccessHandle = () => {
    if (onSuccess) {
      onSuccess();
    }
  };
  const onCancelHandle = () => {
    if (onCancel) {
      onCancel();
    }
  };
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-8 mx-auto max-w-xl">
          <div className="border-0  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none py-16 px-20">
            <div className="relative p-4 flex-auto flex-nowrap font-bold text-center">
              <div className="warning-icon">
                <img className="iconBlock text-5xl" src={Warning} alt="Warning" />
              </div>
              Cash Drawer limit exceeded.
              <div className="font-light mt-3">Please transfer excess cash to the Safe.</div>
            </div>

            <div className="flex items-center mt-3 justify-center">
              <button
                className="restNoBtn ml-4 border-t  border-l borde border-grey  bg-transparent-500 text-black active:bg-transparent-600 font-bold text-sm px-6 py-4  shadow hover:shadow-lg outline-none focus:outline-none"
                type="button"
                value="No"
                data-cy="popupNo"
                onClick={onCancelHandle}>
                Continue
              </button>
              <button
                className="brandRedBtn text-white active:brandRedBtn ml-4 font-bold text-sm px-6 py-4 shadow hover:shadow-lg outline-none "
                type="button"
                value="Yes"
                data-cy="popupYes"
                onClick={onSuccessHandle}>
                Transfer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default PopupConfirm;
