import { amountSignedPound, currencyStyle } from "@bbo/utils/currencyFormatter";
import { formatDateYearMonthDay } from "@bbo/utils/dateTimeFormatter";

export const useTableList = (data) => {
  let last_varience = "Last Variance";
  let last_countdate = "To Date Variance";
  let lastbalancedLabel = "Last Balance Date";
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let orderRows = ["currentVariance", "toDateVariance", "lastBalancedDateTime"];
  if (location.pathname === "/branchOverviewForex") {
    last_varience = "Last Count Variance";
    last_countdate = "To Date Variance";
    lastbalancedLabel = "Last Count Date";
    orderRows = ["currentVariance", "lastBalancedDateTime", "toDateVariance"];
  }
  const orderedTable = orderRows.map((row, index) => {
    const { currentVariance = 0, lastVariance = 0, toDateVariance, lastBalancedDateTime } = data;
    switch (row) {
      case "currentVariance":
        return (
          <tr className={currencyStyle(currentVariance)} key={`variance#${index}`}>
            <td>{last_varience}</td>
            <td>
              <b>{amountSignedPound(currentVariance || lastVariance)}</b>
            </td>
          </tr>
        );
      case "toDateVariance":
        return (
          <tr key={`variance#${index}`}>
            <td>{last_countdate}</td>
            <td>
              <b>{amountSignedPound(toDateVariance)}</b>
            </td>
          </tr>
        );
      case "lastBalancedDateTime":
        return (
          <tr key={`BalnDT#${index}`}>
            <td>{lastbalancedLabel}</td>
            <td>
              <b>{formatDateYearMonthDay(lastBalancedDateTime)}</b>
            </td>
          </tr>
        );
      default:
        break;
    }
  });
  return { orderedTable, last_varience, last_countdate, lastbalancedLabel };
};
