import Card from "@bbo/components/card";
import Loader from "@bbo/components/Loader";
import PopupConfirm from "./PopUp";
import { PrintCashDrawer } from "./PrintCashDrawer";
import SuccessMessage from "./SuccessPopUp";
import Reprint from "./WarningPopUp";

import { featureFlags } from "@bbo/constants/FeatureConstants";
import { useManageCashDrawer } from "./useManageCashDrawer";

export const ManageCashDrawer = () => {
  const {
    setBalanceCheck,
    printData,
    isCashDrawLoading,
    cashDrawers,
    cashDrawer,
    setReprint,
    setAddedCashDrawer,
    setWarningLable,
    setActivity,
    reprint,
    warnningLabel,
    callBackHandlePopup,
    showPopUp,
    handleActivity,
    handleCashDrawer,
    balanceCheck,
    callBackHandleReprint,
    setSuccessMessage,
    setgetNewCashDrawer,
    refetch,
    addedCashDrawer,
    activity,
    isLoading,
    successMessage,
    printZpl,
    isCtAccess,
    addCashDrawerResponse,
    callBackHandelForceLog,
    setPrintData,
    unAssociateCD,
  } = useManageCashDrawer();
  return (
    <>
      <h2 className={"accounting-card-label-name mb-1 "}>Counters</h2>
      <div className="w-full accounting-card float-left h-52 overflow-x-scroll whitespace-nowrap">
        {cashDrawers?.counterTerminal?.map((counterData, index) => {
          return (
            <div className="main widthAccounting mr-8 inline-block" key={index}>
              <Card
                key={index}
                cellClass="balanceLayout "
                {...counterData}
                onClick={() => {
                  handleCashDrawer(counterData);
                }}
              />
            </div>
          );
        })}
      </div>
      <hr className="border-gray-200 border-bottom-none mb-5 w-full" />
      <h2 className={"accounting-card-label-name mb-1 "}>Safe</h2>
      <div className="w-full accounting-card float-left h-52 overflow-x-scroll whitespace-nowrap">
        {cashDrawers?.safe?.cashDrawer?.map((counterData, index) => {
          return (
            <div className="main widthAccounting mr-8 inline-block" key={index}>
              <Card
                key={index}
                cellClass="safeBalanceHeight "
                entityName={counterData.entityName}
                balance={counterData.itemDetails.balance}
                systemValue={counterData.itemDetails?.systemValue}
                onClick={() => {
                  handleCashDrawer(counterData);
                }}
              />
            </div>
          );
        })}
      </div>
      <hr className="border-gray-200 border-bottom-none mb-5 w-full" />
      <div className="whitespace-nowrap w-full  flex ">
        <div
          className="main  AddRemoveBtn morebtn justify-center flex-col cursor-pointer items-center flex block  grid-cols-4 gap-5"
          onClick={() => {
            handleActivity("ADD");
          }}>
          <div>
            <i className="fas fa-plus mb-2"></i>
          </div>
          <p> Add New Drawer</p>
        </div>
        <div
          className="main ml-4  AddRemoveBtn morebtn justify-center flex-col cursor-pointer items-center flex block  grid-cols-4 gap-5"
          onClick={() => {
            handleActivity("REMOVE");
          }}>
          <div>
            <i className="fas fa-minus mb-2"></i>
          </div>
          <p> Remove Drawer</p>
        </div>
      </div>
      {showPopUp && (
        <PopupConfirm
          {...cashDrawer}
          onCancel={() => {
            callBackHandlePopup();
          }}
          onSuccess={() => {
            callBackHandelForceLog();
          }}
        />
      )}
      {reprint && (
        <Reprint
          label={warnningLabel}
          onCancel={() => {
            setActivity("");
            setWarningLable("");
            setAddedCashDrawer("");
            setReprint(false);
          }}
          onSuccess={async () => {
            if (featureFlags.isLabelPrinterEnabled) {
              const title = cashDrawer.entityName
                ? cashDrawer.entityName
                : addCashDrawerResponse.entityName;
              const rawBarcode =
                cashDrawer.entityBarcode || cashDrawer.barcode
                  ? cashDrawer.entityBarcode || cashDrawer.barcode
                  : addCashDrawerResponse.barcode;
              isCtAccess && (await printZpl({ rawBarcode, title }));
            }
            callBackHandleReprint();
            setReprint(false);
          }}
        />
      )}
      {successMessage &&
        !isLoading &&
        (addedCashDrawer || activity === "ForcedLoggOff" ? (
          <SuccessMessage
            flow={activity}
            cashDrawerAdded={addedCashDrawer}
            onCancel={() => {
              refetch();
              setgetNewCashDrawer({ getNewCashDrawer: true });

              setActivity("");
              setWarningLable("");
              setAddedCashDrawer("");
              setSuccessMessage(false);
            }}
            onSuccess={() => {
              callBackHandleReprint();
              setActivity("");
            }}
          />
        ) : (
          <Loader />
        ))}
      {balanceCheck && (
        <Reprint
          label={
            unAssociateCD() || cashDrawers?.safe?.cashDrawer.length - 1 === 0
              ? "Cash drawer can only be deleted when moved to the safe and balance is zero."
              : "Cash drawer can only be deleted when Cash Balance is zero."
          }
          balancePopUP={balanceCheck}
          onCancel={() => {
            setBalanceCheck(false);
          }}
        />
      )}
      {isCashDrawLoading === "loading" && <Loader />}

      {printData && (
        <PrintCashDrawer
          title={printData.title}
          barcode={printData.barcode}
          onPrintClose={() => setPrintData(null)}
        />
      )}
    </>
  );
};
