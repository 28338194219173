import { HubPayload } from "@aws-amplify/core";
import { AXIOS_INSTANCE } from "@bbo/api/generator/mutator/useCustomInstance";
import { getValueFromStorage, setValueInStorage } from "@bbo/utils/util";
import { Auth, Hub } from "aws-amplify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { federatedSignInAsync, setAuthState, SysRole } from "../reduxStore/auth.slice";
import { setUserName } from "../reduxStore/userName.slice";
import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";

export const useHubListener = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, loading } = useAppSelector((root) => root.auth);

  useEffect(() => {
    const getUser = async () => {
      try {
        const authUser = await Auth.currentAuthenticatedUser();
        const jwtToken = authUser.signInUserSession?.idToken.jwtToken;
        const username =
          authUser?.signInUserSession?.idToken?.payload?.name ||
          authUser?.signInUserSession?.idToken?.payload?.given_name;
        const smartid = authUser?.signInUserSession.idToken.payload.identities[0].userId;
        const sysRole: SysRole[] = JSON.parse(
          decodeURIComponent(
            authUser?.signInUserSession.idToken.payload["custom:SysRole"],
          ).replaceAll(/([+])/gm, ""),
        );
        AXIOS_INSTANCE.defaults.headers.common["Authorization"] = `Bearer ${jwtToken}`;
        AXIOS_INSTANCE.defaults.headers.common["content-type"] = "application/json";
        AXIOS_INSTANCE.defaults.headers.common.Accept = "application/json";
        dispatch(
          setUserName({
            userName:
              authUser?.signInUserSession?.idToken?.payload?.name ||
              authUser?.signInUserSession?.idToken?.payload?.given_name,
            smartID: smartid,
          }),
        );
        if (sysRole && sysRole.length > 0) {
          if (sysRole.length > 1) {
            if (!getValueFromStorage("fadcode")) {
              navigate("/branchSelection");
              dispatch(
                setAuthState({
                  loading: false,
                  authUserData: { username, sysRole, smartid },
                }),
              );
            } else {
              const role = sysRole.filter((item) => {
                if (item.fadCode === getValueFromStorage("fadcode")) {
                  return item;
                }
              });

              AXIOS_INSTANCE.defaults.headers.common["fadcode"] = getValueFromStorage("fadcode");
              dispatch(
                setAuthState({
                  isLoggedIn: true,
                  loading: false,
                  authUserData: {
                    username,
                    role: role[0].role,
                    fadCode: role[0].fadCode,
                    sysRole,
                    smartid,
                  },
                }),
              );
            }
          } else {
            setValueInStorage("fadcode", sysRole[0].fadCode);
            AXIOS_INSTANCE.defaults.headers.common["fadcode"] = sysRole[0].fadCode;
            dispatch(
              setAuthState({
                isLoggedIn: true,
                loading: false,
                authUserData: {
                  username,
                  role: sysRole[0].role,
                  fadCode: sysRole[0].fadCode,
                  sysRole,
                  smartid,
                },
              }),
            );
          }
        }
      } catch (e) {
        await dispatch(federatedSignInAsync());
      }
    };

    const hubListener = async ({ payload: { event, data } }: { payload: HubPayload }) => {
      switch (event) {
        case "signIn":
          // TODO: set user data from data instead of calling currentAuthenticatedUser()
          getUser();
          break;
        case "cognitoHostedUI":
          getUser();
          break;
        case event:
          break;
      }
    };

    !isLoggedIn && getUser();

    Hub.listen("auth", hubListener);

    return () => Hub.remove("auth", hubListener);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoggedIn, loading };
};
