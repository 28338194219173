import React from "react";

interface PaidInOutButtonProps {
  key: number;
  disabled: boolean;
  onClick: () => void;
  text: string;
  className: string;
}

const PaidInOutButton = ({ key, disabled, onClick, text, className }: PaidInOutButtonProps) => {
  return (
    <button key={key} disabled={disabled} onClick={onClick} className={className}>
      {text}
    </button>
  );
};

export default PaidInOutButton;
