import TableView from "@bbo/components/table";
import { TRANSACTION_LOG_COLUMN } from "@bbo/components/table/tableConstants";
import { useAppSelector } from "@bbo/lib";
import { useEffect, useState } from "react";
import { useTransactionLogPage } from "./useTransactionLogPage";

const TransactionLogPage = () => {
  const { fadCode } = useAppSelector((root) => root.auth.authUserData);

  const { memoisedTransactionData, onPrintClick, isLoading } = useTransactionLogPage();
  const [transactionData, setTransactionData] = useState(null);
  useEffect(() => {
    setTransactionData(
      memoisedTransactionData?.map((item) => {
        const newDataT = `TR${fadCode}${item.nodeID}`;
        const transactionID = item.TransactionID.slice(newDataT.length);
        return {
          ...item,
          transactionID,
          basketOpenedBy: item.basketOpenedBy?.split("_")[1],
          TransactionID: transactionID,
        };
      }),
    );
  }, [memoisedTransactionData]);

  return (
    <>
      <>
        <div className="w-full transferOut-height">
          <div className="p-4 lg:w-full h-full relative dynamictable">
            <TableView
              columns={TRANSACTION_LOG_COLUMN}
              isDiscrpencyTable={true}
              showPrint={true}
              showResetFilter
              rows={transactionData || []}
              onPrintClick={onPrintClick}
              isLoading={isLoading}
            />
          </div>
        </div>
      </>
    </>
  );
};

export default TransactionLogPage;
