/**
 * Amount pound value formatter. It include minus(-) if value is negative and plus(+) if value is positive
 * @param value string amount in pence
 * @returns string amount in GBP pound as £#.00 | + £#.00 | -£#.00
 */
export const amountSignedPound = (value: number): string => {
  if (value) {
    const amount =
      value < 0 ? `-£${((value / 100) * -1).toFixed(2)}` : `+£${(value / 100).toFixed(2)}`;
    const parts = amount.toString().split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
  }
  return "£0.00";
};

/**
 * Amount pound value formatter. It includes minus if value is negative
 * @param value string amount in pence
 * @returns string amount in GBP pound as £#.00 | £#.00 | -£#.00
 */
export const amountPound = (value: number): string => {
  if (value) {
    const amount =
      value < 0 ? `-£${((value / 100) * -1).toFixed(2)}` : `£${(value / 100).toFixed(2)}`;
    const parts = amount.toString().split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
  }

  return "£0.00";
};

/**
 * Amount pound value formatter
 * @param value string amount in pence
 * @returns string amount in GBP pound as £#.00
 */
export const amountUnSignedPound = (value: number): string => {
  if (value < 0) {
    return `£${((-1 * value) / 100).toFixed(2)}`;
  } else if (value) {
    return `£${(value / 100).toFixed(2)}`;
  }

  return "£0.00";
};

/**
 * Currency conversion GBP pound to pence
 * @param value GBP Pound
 * @returns Pence value
 */
export const amountInPence = (value: number): number => {
  if (value) {
    return parseInt((value * 100).toFixed(2));
  }
  return 0;
};

/**
 * Currency conversion Pence to GBP pound
 * @param value in Pence
 * @returns GBP pound value
 */
export const amountInPound = (value: number): string => {
  if (value !== 0) {
    return (value / 100).toFixed(2);
  }
  return "0.00";
};

/**
 * Input amount pound value formatter
 * @param value string amount in pound
 * @returns string amount in GBP pound as £#.00
 */
export const amountFormatter = (value: string): string => {
  if (value) {
    let mValue = "";
    if (value.includes("-")) {
      mValue = "-";
    }
    const mAmount = value.replace(/[^0-9.]+/g, "");
    return mAmount.length > 0 ? `${mValue}£${mAmount}` : `${mValue}`;
  }
  return "";
};

/**
 * Currency conversion GBP pound to pence
 * @param value string GBP Pound
 * @returns Pence value
 */
export const inputInPence = (value: string): number => {
  if (value) {
    const currency = Number(value.replace(/[^0-9.]+/g, ""));
    const multiplier = value.includes("-") ? -1 : 1;
    return parseInt((currency * 100).toFixed(2)) * multiplier;
  }
  return 0;
};

/**
 * Parse currency formatted value into amount value
 * @param value string currency formatted value £#.00 | £#.00 | -£#.00
 * @returns amount number value as #.00
 */
export const currencyAmount = (value: string): number => {
  if (value) {
    const multiplier = value.includes("-") ? -1 : 1;
    return Number(value.replace(/[^0-9.]+/g, "")) * multiplier;
  }
  return 0;
};

/**
 * Display style
 * @param amount number currency value
 * @returns
 */
export const currencyStyle = (amount: number): string => {
  if (amount === 0) {
    return "";
  }

  if (amount < 0) {
    return "font-bold redLabel";
  }

  return "font-bold greenLabel";
};
export const removeNegativeSymbol = (value: number): string => {
  if (value) {
    return value < 0 ? `${value * -1}` : `${value}`;
  }

  return "0.00";
};
