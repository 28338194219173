/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

PO Transactions

PO Transactions Viewer

Cash Drawer Association API
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  PouchDataDTO,
  CashManagementApiResponse,
  GetPouchesInformationParams,
  PouchApiResponse,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Get Pouch information\
 * @summary This endpoint is used to getting Pouches references operation
 */
export const useGetPouchesInformationHook = () => {
  const getPouchesInformation = useCustomInstance<PouchDataDTO[]>();

  return (branchId: string, params?: GetPouchesInformationParams) => {
    return getPouchesInformation({
      url: `/cash_management/pouch-movement/${branchId}`,
      method: "get",
      params,
    });
  };
};

export const getGetPouchesInformationQueryKey = (
  branchId: string,
  params?: GetPouchesInformationParams,
) => [`/cash_management/pouch-movement/${branchId}`, ...(params ? [params] : [])];

export type GetPouchesInformationQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetPouchesInformationHook>>
>;
export type GetPouchesInformationQueryError = ErrorType<CashManagementApiResponse | void>;

export const useGetPouchesInformation = <
  TData = AsyncReturnType<ReturnType<typeof useGetPouchesInformationHook>>,
  TError = ErrorType<CashManagementApiResponse | void>,
>(
  branchId: string,
  params?: GetPouchesInformationParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetPouchesInformationHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetPouchesInformationQueryKey(branchId, params);

  const getPouchesInformation = useGetPouchesInformationHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetPouchesInformationHook>>
  > = () => getPouchesInformation(branchId, params);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetPouchesInformationHook>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!branchId, ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};

/**
 * update Pouch-preperation.
 * @summary This endpoint is used to update pouch-information
 */
export const useUpdatePouchDetailsHook = () => {
  const updatePouchDetails = useCustomInstance<CashManagementApiResponse>();

  return (pouchDataDTO: PouchDataDTO) => {
    return updatePouchDetails({
      url: `/cash_management/pouch`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: pouchDataDTO,
    });
  };
};

export type UpdatePouchDetailsMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useUpdatePouchDetailsHook>>
>;
export type UpdatePouchDetailsMutationBody = PouchDataDTO;
export type UpdatePouchDetailsMutationError = ErrorType<void>;

export const useUpdatePouchDetails = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof useUpdatePouchDetailsHook>>,
    TError,
    { data: PouchDataDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const updatePouchDetails = useUpdatePouchDetailsHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof useUpdatePouchDetailsHook>>,
    { data: PouchDataDTO }
  > = (props) => {
    const { data } = props || {};

    return updatePouchDetails(data);
  };

  return useMutation<
    AsyncReturnType<typeof updatePouchDetails>,
    TError,
    { data: PouchDataDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Get Pouch-preperation.
 * @summary This endpoint is used to validate Pouch
 */
export const useGetPouchInformationHook = () => {
  const getPouchInformation = useCustomInstance<PouchApiResponse>();

  return (branchId: string, pouchType: string, pouchId: string) => {
    return getPouchInformation({
      url: `/cash_management/pouch-preparation/${branchId}/${pouchType}/${pouchId}`,
      method: "get",
    });
  };
};

export const getGetPouchInformationQueryKey = (
  branchId: string,
  pouchType: string,
  pouchId: string,
) => [`/cash_management/pouch-preparation/${branchId}/${pouchType}/${pouchId}`];

export type GetPouchInformationQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetPouchInformationHook>>
>;
export type GetPouchInformationQueryError = ErrorType<CashManagementApiResponse | void>;

export const useGetPouchInformation = <
  TData = AsyncReturnType<ReturnType<typeof useGetPouchInformationHook>>,
  TError = ErrorType<CashManagementApiResponse | void>,
>(
  branchId: string,
  pouchType: string,
  pouchId: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetPouchInformationHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPouchInformationQueryKey(branchId, pouchType, pouchId);

  const getPouchInformation = useGetPouchInformationHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetPouchInformationHook>>
  > = () => getPouchInformation(branchId, pouchType, pouchId);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetPouchInformationHook>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(branchId && pouchType && pouchId), ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};
