import { amountSignedPound } from "@bbo/utils/currencyFormatter";
import { memo } from "react";
// import { formatDateYearMonthDay } from "@bbo/utils/dateTimeFormatter";

/**
 * card view with
 * @param props
 * @returns
 */

interface iProps {
  itemDetails?: any;
  entityType?: string;
  entityName?: string;
  associatedEntityName?: string;
  cellClass?: string;
  created_by?: string;
  balance?: number;
  systemValue?: number;
  dueVariance?: number;
  toDateVariance?: number;
  lastBalancedDateTime?: number;
  onClick?: () => void;
  attachedIcon?: boolean;
}

const Card = (props: iProps) => {
  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <div onClick={onClick}>
      <div className="heading">
        <span>{props?.entityName}</span>
        {props?.associatedEntityName && <i className="fas fa-link mx-2"></i>}
        <span>{props.associatedEntityName}</span>
      </div>
      <hr className="border-gray-200 border-bottom-none" />
      <div className="second-heading h-10 truncate">
        {props?.created_by && <i className="fas fa-user-check mr-2"></i>}
        <span>{props?.created_by}</span>
      </div>
      <hr className="border-gray-200 border-bottom-none" />
      <div className={`third-heading  ${props.cellClass} justify-center`}>
        <table className="w-full border-none  ">
          <tbody className="counterPad ">
            {props?.entityType === "counter_terminal" ? (
              <tr>
                <td>Balance</td>
                <td>
                  <b>{amountSignedPound(props?.itemDetails.balance)}</b>
                </td>
              </tr>
            ) : (
              <tr>
                <td>Balance</td>
                <td>
                  <b>{amountSignedPound(props?.balance)}</b>
                </td>
              </tr>
            )}
            {/* {props?.due_variance && <tr>
              <td>To Date Variance</td>
              <td>
                <b>{props?.due_variance}</b>
              </td>
            </tr>}
           { props?.last_balanced_date_time && <tr>
              <td>Last Balance Date</td>
              <td>
                <b>{formatDateYearMonthDay(props?.last_balanced_date_time)}</b>
              </td>
            </tr>} */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default memo(Card);
